import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { alpha, Divider, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { darkThemeColors } from '../../../common/theme/dark.theme';
import { ChatActionTypes, IChatActionFlowNodeData } from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface IChatActionNodeData {
  onDelete: () => void;
  onSelect: (chatActionData: IChatActionFlowNodeData) => void;
  onCopy: (
    chatActionData: IChatActionFlowNodeData,
    position: { x: number; y: number },
  ) => void;
  exitEditor: () => void;
  data: IChatActionFlowNodeData;
  position: { x: number; y: number };
  next: string | null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface ChatActionNodeProps extends Omit<NodeProps, 'data'> {
  data: IChatActionNodeData;
}

const ChatActionNode: FC<ChatActionNodeProps> = memo(
  ({ data, xPos, yPos, selected }) => {
    useEffect(() => {
      data.onNodePositionChange(data._id, {
        x: xPos,
        y: yPos,
      });
    }, [xPos, yPos]);

    const { t } = useTranslation();

    const nodeRef = useRef<HTMLDivElement>(null);

    const [isNewNode, setIsNewNode] = useState(false);

    useEffect(() => {
      const digitsId = Number(data._id);

      setIsNewNode(!isNaN(digitsId));
    }, []);

    useEffect(() => {
      const node = nodeRef.current;
      const preventZoom = (event: WheelEvent) => event.stopPropagation();

      if (node) {
        node.addEventListener('wheel', preventZoom);
        return () => {
          node.removeEventListener('wheel', preventZoom);
        };
      }
    }, []);

    useEffect(() => {
      if (!selected) {
        data.exitEditor();
      }
    }, [selected]);

    const edit = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onSelect({
        actionType: data.data.actionType,
        duration: data.data.duration,
      });
    };

    return (
      <Stack
        sx={{
          position: 'relative',
        }}
        onDoubleClick={edit}
      >
        {selected && (
          <Stack
            direction="row"
            justifyContent="center"
            gap="10px"
            sx={{
              position: 'absolute',
              top: -50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={edit}
            >
              <ModeEditOutlineRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={() => {
                data.onCopy(
                  {
                    actionType: data.data.actionType,
                    duration: data.data.duration,
                  },
                  { x: data.position.x + 400, y: data.position.y },
                );
              }}
            >
              <ContentCopyRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={data.onDelete}
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'red.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <CloseRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
          </Stack>
        )}

        <Stack
          className="dragger"
          sx={{
            width: '340px',
            border: '1px solid',
            borderColor: selected ? 'blue.2' : 'grey.10',
            backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
            padding: '10px',
            borderRadius: '18px',
            cursor: 'default',
            animation: isNewNode
              ? `${pulsingAnimation} 0.5s linear infinite  alternate`
              : '',
            animationIterationCount: 6,
          }}
          ref={nodeRef}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={'edge-' + data._id}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              height: '100%',
              width: '100%',
              borderRadius: '0',
              zIndex: -1,
            }}
          />
          <Handle
            type="source"
            id={'src-' + data._id}
            position={Position.Right}
            style={{
              width: '20px',
              height: '20px',
              marginRight: '-5px',
              border: '4px solid',
              borderColor: darkThemeColors.green['2'],
            }}
          />

          <Stack direction="column" justifyContent="center">
            <Stack alignItems="center" sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {t('flowNodes.chatAction')}
              </Typography>
            </Stack>

            <Divider sx={{ m: '10px 0' }} />

            <Stack>
              <Typography>
                {t('flowNodes.actionType')}:
                {t(
                  `flowNodes.actionTypes.${
                    data.data.actionType as ChatActionTypes
                  }`,
                )}
              </Typography>
              <Typography>
                {t('flowNodes.duration')}: {data.data.duration}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <Stack gap="6px" flexDirection="column"></Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

ChatActionNode.displayName = 'ChatActionNode';

export default ChatActionNode;
