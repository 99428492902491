import React, { FC } from 'react';

import BotSettings from './settings/bot.settings';
import { ITelegramBot } from './telegram-bot.interface';

interface SettingsTabProps {
  bot: ITelegramBot | null;
}

const SettingsTab: FC<SettingsTabProps> = ({ bot }) => {
  if (!bot) return null;
  return <BotSettings bot={bot} />;
};

export default SettingsTab;
