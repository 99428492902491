import { IFlow } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const changeFlowDefault = async (
  flowId: IFlow['_id'],
  makeDefault: boolean,
) => {
  const { data } = await axios.patch(`/flows/${flowId}/change-default`, {
    makeDefault,
  });

  return data;
};

export default changeFlowDefault;
