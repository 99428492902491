import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyableText from '../../common/copyableText/copyableText';
import { IOSSwitch } from '../../common/iosSwitch';
import { convertNumberToMinutes } from '../../utils/convertNumberToMinutes';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import {
  ChannelParamObject,
  IChannelActivatorWithParams,
  UpdateChannelActivatorWithParams,
} from './interfaces';

const MAX_NAME_LENGTH = 50;

interface CreateChannelActivatorProps {
  onCreateActivator: (
    newChannelActivator: Omit<
      IChannelActivatorWithParams,
      'createdAt' | 'updatedAt' | '_id' | 'channel' | 'inviteLink' | 'hidden'
    >,
  ) => void;
  isCopyActivator: boolean;
  channelFlows: IFlowWithActivatorsAndFolder[];
  creatingNewActivator: boolean;
  selectedActivator: IChannelActivatorWithParams | null;
  onEditActivator: (
    activatorToUpdate: UpdateChannelActivatorWithParams,
  ) => void;
  onResetSelectedActivator: () => void;
}

const CreateEditChannelActivator: FC<CreateChannelActivatorProps> = ({
  onCreateActivator,
  isCopyActivator,
  onResetSelectedActivator,
  channelFlows,
  onEditActivator,
  creatingNewActivator,
  selectedActivator,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [joinFlow, setJoinFlow] = useState<string | null>(null);
  const [leaveFlow, setLeaveFlow] = useState<string | null>(null);
  const [kickFlow, setKickFlow] = useState<string | null>(null);
  const [isMembershipTemporary, setIsMembershipTemporary] =
    useState<boolean>(false);
  const [acceptRequests, setAcceptRequests] = useState<boolean>(true);
  const [params, setParams] = useState<ChannelParamObject[]>([]);

  const [expiresInDays, setExpiresInDays] = useState<number>(0);
  const [expiresInHours, setExpiresInHours] = useState<number>(0);
  const [expiresInMinutes, setExpiresInMinutes] = useState<number>(0);

  const membershipExpiresInMinutes =
    expiresInMinutes + expiresInHours * 60 + expiresInDays * 1440;

  const expireValid = !isNaN(membershipExpiresInMinutes);

  const valid = useMemo(
    () =>
      Boolean(name) &&
      Boolean(
        selectedActivator?.params !== params ||
          selectedActivator?.name !== name ||
          selectedActivator?.joinFlow !== joinFlow ||
          selectedActivator?.kickFlow !== kickFlow ||
          selectedActivator?.membershipExpiresInMinutes !==
            membershipExpiresInMinutes ||
          selectedActivator?.leaveFlow !== leaveFlow ||
          selectedActivator?.acceptRequests !== acceptRequests,
      ) &&
      params.every(({ key, value }) => Boolean(key) && Boolean(value)) &&
      expireValid,
    [
      name,
      params,
      expireValid,
      selectedActivator,
      joinFlow,
      kickFlow,
      membershipExpiresInMinutes,
      leaveFlow,
      acceptRequests,
    ],
  );

  const onAddParam = () => {
    setParams((prev) => [...prev, { key: '', value: '' }]);
  };

  const onParamChange = (
    index: number,
    fieldName: 'key' | 'value',
    newValue: string,
  ) => {
    setParams((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          [fieldName]: newValue,
        });
      }

      return result;
    });
  };

  const onParamDelete = (index: number) => {
    setParams((prev) => prev.filter((_, i) => i !== index));
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onDaysInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInDays(number);
    } else {
      setExpiresInDays(0);
    }
  };

  const onHoursInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInHours(number);
    } else {
      setExpiresInHours(0);
    }
  };

  const onMinutesInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setExpiresInMinutes(number);
    } else {
      setExpiresInMinutes(0);
    }
  };

  const handleChangeJoinFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setJoinFlow(value);
  };

  const handleChangeLeaveFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setLeaveFlow(value);
  };

  const handleChangeKickFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setKickFlow(value);
  };

  const onChangeTemporaryMembership = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.target.checked;
    setIsMembershipTemporary(checked);
    if (!Boolean(checked)) {
      setKickFlow(null);
      setExpiresInDays(0);
      setExpiresInHours(0);
      setExpiresInMinutes(0);
    }
  };

  const onSave = () => {
    if (creatingNewActivator || isCopyActivator) {
      onCreateActivator({
        params,
        name,
        joinFlow,
        leaveFlow,
        kickFlow,
        acceptRequests,
        membershipExpiresInMinutes,
        joinRequestsCount: 0,
      });
      onResetSelectedActivator();
    } else {
      onEditActivator({
        name,
        params,
        joinFlow,
        leaveFlow,
        acceptRequests,
        kickFlow,
        membershipExpiresInMinutes,
      });
    }
    setJoinFlow(null);
    setLeaveFlow(null);
    setKickFlow(null);
    setAcceptRequests(true);
    setIsMembershipTemporary(false);
    setExpiresInDays(0);
    setExpiresInHours(0);
    setExpiresInMinutes(0);
    setName('');
    setParams([]);
  };

  useEffect(() => {
    if (!creatingNewActivator) {
      const days = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).days;
      const hours = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).hours;
      const minutes = convertNumberToMinutes(
        selectedActivator?.membershipExpiresInMinutes ?? 0,
      ).minutes;
      setName(selectedActivator?.name as string);
      setParams(selectedActivator?.params as ChannelParamObject[]);
      setJoinFlow(selectedActivator?.joinFlow ?? null);
      setLeaveFlow(selectedActivator?.leaveFlow ?? null);
      setKickFlow(selectedActivator?.kickFlow ?? null);
      setAcceptRequests(selectedActivator?.acceptRequests ?? true);
      setExpiresInDays(days);
      setExpiresInHours(hours);
      setExpiresInMinutes(minutes);
      if (!days && !hours && !minutes) {
        setIsMembershipTemporary(false);
      } else {
        setIsMembershipTemporary(true);
      }
    } else {
      setName('');
      setJoinFlow(null);
      setLeaveFlow(null);
      setKickFlow(null);
      setAcceptRequests(true);
      setParams([]);
      setExpiresInDays(0);
      setExpiresInHours(0);
      setExpiresInMinutes(0);
      setIsMembershipTemporary(false);
    }
  }, [selectedActivator]);
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap="15px">
        <Typography>{t('channels.channelActivators.name')}</Typography>
        <TextField
          placeholder={t('channels.channelActivators.namePlaceholder')}
          value={name}
          onChange={onNameInput}
          sx={{
            width: '100%',
          }}
        />
      </Stack>
      <Stack sx={{ m: '15px 0' }}>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={acceptRequests}
              onChange={(event) => setAcceptRequests(event.target.checked)}
            />
          }
          label={t('channels.channelActivators.acceptRequest')}
        />
      </Stack>
      {selectedActivator && !isCopyActivator && (
        <Stack
          direction="row"
          alignItems="center"
          gap="15px"
          sx={{
            mt: '10px',
          }}
        >
          <Typography sx={{ width: '100px' }}>
            {t('channels.channelActivators.inviteLink')}
          </Typography>
          <CopyableText text={selectedActivator.inviteLink} />
        </Stack>
      )}
      <Stack
        flexDirection="row"
        alignItems="center"
        gap="10px"
        sx={{ mt: '12px' }}
      >
        <FormControl fullWidth>
          <InputLabel>{t('common.subscriptionFlow')}</InputLabel>
          <Select
            sx={{
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
            }}
            label={t('common.subscriptionFlow')}
            value={joinFlow ?? ''}
            onChange={handleChangeJoinFlow}
          >
            {channelFlows.map(({ _id, name }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          onClick={() => setJoinFlow(null)}
          sx={{ width: '40px', height: '40px' }}
        >
          <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
        </IconButton>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        gap="10px"
        sx={{ mt: '12px' }}
      >
        <FormControl fullWidth>
          <InputLabel>{t('common.unsubscribeFlow')}</InputLabel>
          <Select
            sx={{
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
            }}
            label={t('common.unsubscribeFlow')}
            value={leaveFlow ?? ''}
            onChange={handleChangeLeaveFlow}
          >
            {channelFlows.map(({ _id, name }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          sx={{ width: '40px', height: '40px' }}
          onClick={() => setLeaveFlow(null)}
        >
          <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" gap="10px" sx={{ mt: '12px' }}>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={isMembershipTemporary}
              onChange={onChangeTemporaryMembership}
            />
          }
          label={t('channels.automaticUnsubscribe')}
        />
      </Stack>

      {isMembershipTemporary && (
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="10px"
          sx={{ mt: '12px' }}
        >
          <FormControl fullWidth>
            <InputLabel>{t('common.removalFlow')}</InputLabel>
            <Select
              sx={{
                borderRadius: '6px',
                border: '1px solid',
                borderColor: 'grey.10',
                backgroundColor: 'grey.15',
              }}
              label={t('common.removalFlow')}
              value={kickFlow ?? ''}
              onChange={handleChangeKickFlow}
            >
              {channelFlows.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            sx={{ width: '40px', height: '40px' }}
            onClick={() => setKickFlow(null)}
          >
            <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
          </IconButton>
        </Stack>
      )}

      {isMembershipTemporary && (
        <Stack
          sx={{ mt: '12px' }}
          direction="row"
          alignItems="center"
          gap="10px"
        >
          <Typography>{t('channels.expiresIn')}</Typography>
          <Stack direction="row" alignItems="center" gap="8px">
            <TextField
              value={expiresInDays ? expiresInDays : ''}
              onChange={onDaysInput}
              sx={{
                width: '90px',
              }}
              placeholder={t('common.days')}
            />
            <TextField
              value={expiresInHours ? expiresInHours : ''}
              onChange={onHoursInput}
              sx={{
                width: '90px',
              }}
              placeholder={t('common.hours')}
            />
            <TextField
              value={expiresInMinutes ? expiresInMinutes : ''}
              onChange={onMinutesInput}
              sx={{
                width: '90px',
              }}
              placeholder={t('common.minutes')}
            />
          </Stack>
        </Stack>
      )}

      {Boolean(params.length) && (
        <Typography
          sx={{
            mt: '15px',
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {t('common.parameters')}
        </Typography>
      )}
      <Stack
        sx={{
          mt: '15px',
        }}
      >
        {params.map((param, index) => (
          <Stack
            key={'param' + index}
            direction="row"
            alignItems="center"
            gap="15px"
            sx={{
              mt: index && '10px',
            }}
          >
            <TextField
              sx={{
                width: '30%',
                maxWidth: '153px',
              }}
              placeholder="Key"
              value={param.key}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'key', value);
              }}
            />
            <TextField
              sx={{
                width: '70%',
                maxWidth: '280px',
              }}
              placeholder="Value"
              value={param.value}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'value', value);
              }}
            />
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'red.1',
                border: '1px solid ',
                borderColor: 'red.2',
                borderRadius: ' 6px',
                minWidth: '42px',
                height: '42px',
                cursor: 'pointer',
              }}
              onClick={() => {
                onParamDelete(index);
              }}
            >
              <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Button
        sx={{
          textTransform: 'none',
          color: 'blue.2',
          mt: '10px',
        }}
        onClick={onAddParam}
      >
        {t('channels.channelActivators.addParam')}
        <AddRoundedIcon
          sx={{
            ml: '5px',
          }}
        />
      </Button>

      <Button
        disabled={!valid}
        variant="contained"
        sx={{
          mt: '10px',
          backgroundColor: 'blue.2',
          textTransform: 'none',
          color: 'grey.1',
        }}
        onClick={onSave}
      >
        {t('channels.channelActivators.save')}
      </Button>
    </Stack>
  );
};

export default CreateEditChannelActivator;
