import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TelegramLeadStatus } from '../../../interfaces/common';
import { ProjectContext } from '../../../projects/project.context';
import { ITelegramChannelWithActivators } from '../../channels/interfaces';
import {
  ConditionsFlowNodeConditionData,
  ConditionTypes,
  IConditionsFlowNodeData,
} from '../interfaces';

interface ConditionsEditorProps {
  open: boolean;
  initialConditions: IConditionsFlowNodeData['conditions'];
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    conditions: IConditionsFlowNodeData['conditions'],
  ) => void;
  channels: ITelegramChannelWithActivators[];
}

const ConditionsEditor: FC<ConditionsEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialConditions,
  channels,
}) => {
  const { t } = useTranslation();

  const [conditions, setConditions] = useState<
    IConditionsFlowNodeData['conditions']
  >([]);

  const { projectParams } = useContext(ProjectContext);

  useEffect(() => {
    setConditions(initialConditions);
  }, [initialConditions]);

  const onAddCheckParam = () => {
    setConditions((prev) => [
      ...prev,
      {
        type: ConditionTypes.checkParam,
        key: '',
        value: '',
        truthyNext: '',
      },
    ]);
  };

  const onEditConditions = (
    index: number,
    data: ConditionsFlowNodeConditionData,
  ) => {
    setConditions((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          ...data,
        });
      }

      return result;
    });
  };

  const onDeleteCondition = (index: number) => {
    setConditions((prev) => {
      const result = [...prev];

      return result.filter((_, i) => index !== i);
    });
  };

  const onSelectChannel = (event: SelectChangeEvent, index: number) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    onEditConditions(index, {
      type: ConditionTypes.checkChannelMembership,
      channelId: selected._id,
      channelName: selected.title,
      truthyNext: '',
    });
  };

  const onSelectStatus = (event: SelectChangeEvent, index: number) => {
    onEditConditions(index, {
      type: ConditionTypes.checkStatus,
      status: event.target.value,
      truthyNext: '',
    });
  };

  const onAddCheckChannelMembership = () => {
    setConditions((prev) => [
      ...prev,
      {
        type: ConditionTypes.checkChannelMembership,
        channelName: '',
        channelId: '',
        truthyNext: '',
      },
    ]);
  };

  const onAddCheckStatus = () => {
    setConditions((prev) => [
      ...prev,
      {
        type: ConditionTypes.checkStatus,
        status: TelegramLeadStatus.lead,
        truthyNext: '',
      },
    ]);
  };

  if (!open) return null;

  const buttonDisabled =
    conditions.length === 0 ||
    conditions.some((condition) => {
      if (condition.type === ConditionTypes.checkChannelMembership) {
        return (
          condition.channelName.trim() === '' ||
          condition.channelId.trim() === ''
        );
      } else if (condition.type === ConditionTypes.checkParam) {
        return condition.key.trim() === '' || condition.value.trim() === '';
      }
      return false;
    });

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
        }}
        gap="5px"
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '12px',
            p: '5px',
            backgroundColor: 'purple.2',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'grey.1',
            }}
          >
            {t('flowNodes.conditionsLabel')}
          </Typography>
        </Stack>

        {conditions.map((condition, index) => {
          switch (condition.type) {
            case ConditionTypes.checkParam: {
              return (
                <Stack className="conditionsContainer" sx={{ m: '7px 0' }}>
                  <Typography> {t('flowNodes.checkParam')}</Typography>
                  <Stack
                    key={'check-param' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Stack
                      direction="row"
                      gap="10px"
                      sx={{ maxWidth: '251px', position: 'relative' }}
                    >
                      <Autocomplete
                        sx={{
                          width: '120px',
                          '& .MuiInputBase-root': {
                            backgroundColor: 'grey.15',
                            height: '43px',
                            p: '0',
                          },
                        }}
                        freeSolo
                        options={projectParams}
                        value={condition.key}
                        onInputChange={(_, newValue) => {
                          onEditConditions(index, {
                            ...condition,
                            key: newValue ?? '',
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Key"
                            sx={{
                              p: '0',
                              '& .MuiInputBase-input': {
                                p: '0',
                              },
                            }}
                          />
                        )}
                      />
                      <TextField
                        sx={{
                          width: '50%',
                        }}
                        placeholder="Value"
                        value={condition.value}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          if (value.length > 20) return;

                          onEditConditions(index, {
                            ...condition,
                            value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }

            case ConditionTypes.checkChannelMembership: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.checkIfInChanel')}</Typography>
                  <Stack
                    key={'assignParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Box sx={{ width: '80%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{t('common.channel')}</InputLabel>
                        <Select
                          value={condition?.channelId ?? ''}
                          label="Channel"
                          onChange={(event) => {
                            onSelectChannel(event, index);
                          }}
                          sx={{
                            '.MuiSelect-select': {
                              backgroundColor: 'grey.15',
                              py: '14px',
                            },
                          }}
                        >
                          {channels.map(({ title, _id }) => (
                            <MenuItem key={_id} value={_id}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }

            case ConditionTypes.checkStatus: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>Check status</Typography>
                  <Stack
                    key={'checkStatus' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Box sx={{ width: '80%' }}>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={condition?.status ?? ''}
                          label="Status"
                          onChange={(event) => {
                            onSelectStatus(event, index);
                          }}
                          sx={{
                            '.MuiSelect-select': {
                              backgroundColor: 'grey.15',
                              py: '14px',
                            },
                          }}
                        >
                          <MenuItem
                            key={'status_lead'}
                            value={TelegramLeadStatus.lead}
                          >
                            {TelegramLeadStatus.lead}
                          </MenuItem>
                          <MenuItem
                            key={'status_reg'}
                            value={TelegramLeadStatus.reg}
                          >
                            {TelegramLeadStatus.reg}
                          </MenuItem>
                          <MenuItem
                            key={'status_dep'}
                            value={TelegramLeadStatus.dep}
                          >
                            {TelegramLeadStatus.dep}
                          </MenuItem>
                          <MenuItem
                            key={'status_qual'}
                            value={TelegramLeadStatus.qual}
                          >
                            {TelegramLeadStatus.qual}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
          }
        })}
      </Stack>

      <Stack
        direction="row"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          startIcon={<PlaylistAddIcon />}
          sx={{ width: '100%' }}
          color="inherit"
          onClick={onAddCheckParam}
        >
          {t('flowNodes.checkParam')}
        </Button>
        <Button
          color="inherit"
          startIcon={<PersonAddIcon />}
          sx={{ width: '100%' }}
          onClick={onAddCheckChannelMembership}
        >
          {t('flowNodes.checkChanel')}
        </Button>
        <Button
          color="inherit"
          startIcon={<HowToRegRoundedIcon />}
          sx={{ width: '100%' }}
          onClick={onAddCheckStatus}
        >
          Check status
        </Button>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            width: '50%',
            color: 'red.2',
            '&:hover': {
              backgroundColor: 'red.2',
              color: 'grey.15',
            },
          }}
        >
          {t('common.cancel')}
        </Button>

        <Button
          sx={{
            width: '50%',
            fontSize: '18px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.2',
              color: 'grey.15',
            },
          }}
          variant="contained"
          onClick={(e) => {
            onSubmit(e, conditions);
          }}
          disabled={buttonDisabled}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ConditionsEditor;
