import {
  ITelegramBot,
  UpdateTelegramBot,
} from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const editBot = async (
  _id: ITelegramBot['_id'],
  newBotData: UpdateTelegramBot,
) => {
  const { data } = await axios.patch(`/telegram-bots/${_id}`, newBotData);

  return data;
};

export default editBot;
