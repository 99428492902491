import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import fetchProjects from './api/projects/fetch-projects';
import Modal from './common/modal/modal-box';
import NewProjectModal from './projects/new-project.modal';
import IProject from './projects/project.interface';

interface IGlobalContext {
  projects: IProject[];
  openNewProjectModal: () => void;
  closeNewProjectModal: () => void;
  refetchProjects: () => void;
  selectProject: (_id: string) => void;
}

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext,
);

const GlobalContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [projects, setProjects] = useState<IProject[]>([]);

  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);

  const navigate = useNavigate();

  const getProjects = () =>
    new Promise((resolve) => {
      fetchProjects().then((res: IProject[]) => {
        setProjects(res);
        resolve(0);
      });
    });

  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);

  const selectProject = (_id: string) => {
    navigate(`/project/${_id}}`);
  };

  useEffect(() => {
    getProjects().then(() => setLoading(false));
  }, []);

  if (loading) return <div>Global loading</div>;

  return (
    <GlobalContext.Provider
      value={{
        projects,
        openNewProjectModal,
        closeNewProjectModal,
        refetchProjects: getProjects,
        selectProject,
      }}
    >
      {children}
      <Modal open={newProjectModalOpen} onClose={closeNewProjectModal}>
        <NewProjectModal />
      </Modal>
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
