import { ITelegramMessage } from '../interfaces/common';

const isTelegramMessageValid = (
  telegramMessage: Omit<
    ITelegramMessage,
    | 'createdAt'
    | 'updatedAt'
    | 'telegramId'
    | 'seen'
    | 'sender'
    | 'reason'
    | 'direction'
    | 'dialog'
    | 'chatId'
  >,
) => {
  const isMediaEmpty = !Boolean(telegramMessage.media.length);

  const isTelegramButtonsValid =
    telegramMessage.buttons.every((arr) => arr.length) &&
    telegramMessage.buttons.every((arr) =>
      arr.every((button) =>
        button.type === 'url' ? button.url && button.label : true,
      ),
    );

  return (
    (!isMediaEmpty && isTelegramButtonsValid) ||
    (Boolean(telegramMessage.text) && isTelegramButtonsValid)
  );
};

export default isTelegramMessageValid;
