export enum MessagesClientEventNames {
  sendMessage = 'sendMessage',
  editMessage = 'editMessage',
  deleteMessage = 'deleteMessage',
  getMessages = 'getMessages',
  translateMessage = 'translateMessage',
}

export enum DialogsClientEventNames {
  getDialogs = 'getDialogs',
  updateDialog = 'updateDialog',
  readDialog = 'readDialog',
  createTag = 'createTag',
  assignTags = 'assignTags',
  updateTag = 'updateTag',
  deleteTag = 'deleteTag',
  setDialogNames = 'setDialogNames',
  setDescription = 'setDescription',
  updateBlockStatus = 'updateBlockStatus',
  getTags = 'getTags',
  acceptJoinRequest = 'acceptJoinRequest',
  updateParams = 'updateParams',
  deleteParam = 'deleteParam',
}

export enum AdminClientEventNames {
  setMessengerMode = 'setMessengerMode',
}
