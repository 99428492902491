import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { ChangeEvent, FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import InputChip from '../../common/input-chip/input-chip';
import ModalBox from '../../common/modal/modal-box';
import { TelegramLeadStatus } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { IFlow, IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import { BroadcastParamCondition, BroadcastType } from './interfaces';

interface BroadcastModalProps {
  open: boolean;
  onClose: () => void;
  flows: IFlowWithActivatorsAndFolder[];
  name: string;
  type?: BroadcastType;
  _id?: string;
  flow: IFlow['_id'];
  leadStatuses: string[];
  paramConditions: BroadcastParamCondition[];
  startTime: Date | null;
  onChangeName: (name: ChangeEvent<HTMLInputElement>) => void;
  onChangeFlow: (flow: SelectChangeEvent) => void;
  onChangeParamKey: (index: number, key: string) => void;
  onChangeParamValues: (
    index: number,
    newValue: string[],
    include: boolean,
  ) => void;
  onDeleteCondition: (index: number) => void;
  toggleStatus: (TelegramLeadStatus: string) => void;
  onAddCondition: () => void;
  onChangeStartTime: (date: dayjs.Dayjs | null) => void;
  isValid: boolean;
  onSubmit: (type: BroadcastType) => void;
  onChangeTabIndex: (newValue: number) => void;
  receiversNumber: number;
  calculationLoading: boolean;
}

const BroadcastModal: FC<BroadcastModalProps> = ({
  open,
  onClose,
  flows,
  _id,
  name,
  type,
  flow,
  leadStatuses,
  paramConditions,
  startTime,
  onChangeName,
  onChangeFlow,
  onChangeParamKey,
  onChangeParamValues,
  onDeleteCondition,
  toggleStatus,
  onAddCondition,
  onChangeStartTime,
  isValid,
  onSubmit,
  onChangeTabIndex,
  receiversNumber,
  calculationLoading,
}) => {
  const { t } = useTranslation();
  const { projectParams } = useContext(ProjectContext);

  const isEditing = Boolean(_id);

  return (
    <ModalBox open={open} onClose={onClose} maxWidth="50%">
      <Stack>
        <Typography
          sx={{
            color: 'grey.1',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
          }}
        >
          {t('broadcast.modal.broadcastSettings')}
        </Typography>
        <Divider sx={{ my: '15px' }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '10px',
          }}
        >
          <Typography>{t('common.name')}</Typography>
          <Stack sx={{ width: '80%' }} gap="10px" direction="row">
            <TextField
              sx={{
                width: '100%',
              }}
              placeholder={t('common.name')}
              value={name}
              onChange={onChangeName}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '10px',
          }}
        >
          <Typography>{t('broadcast.modal.flow')}</Typography>
          <Box sx={{ width: '80%' }}>
            <FormControl fullWidth>
              <InputLabel>Flow</InputLabel>
              <Select
                value={flow}
                label="Flow"
                onChange={onChangeFlow}
                sx={{
                  '.MuiSelect-select': {
                    backgroundColor: 'grey.15',
                    py: '14px',
                  },
                }}
              >
                {Boolean(flows.length) ? (
                  flows.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <Stack sx={{ p: '10px', textAlign: 'center' }}>
                    <Typography>{t('broadcast.modal.notFoundFlow')}</Typography>
                    <Button
                      onClick={() => onChangeTabIndex(0)}
                      sx={{ mt: '15px' }}
                    >
                      {t('broadcast.modal.createFlow')}
                    </Button>
                  </Stack>
                )}
              </Select>
            </FormControl>
          </Box>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '10px',
          }}
        >
          <Typography>{t('common.status')}</Typography>
          <Stack sx={{ width: '80%' }} gap="10px" direction="row">
            <Stack
              sx={{
                padding: '10px 20px',
                borderRadius: '3px',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: leadStatuses.includes(TelegramLeadStatus.lead)
                  ? 'blue.2'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.lead)
                  ? 'blue.1'
                  : 'transparent',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.lead);
              }}
            >
              Lead
            </Stack>

            <Stack
              sx={{
                padding: '10px 20px',
                borderRadius: '3px',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: leadStatuses.includes(TelegramLeadStatus.reg)
                  ? 'blue.2'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.reg)
                  ? 'blue.1'
                  : 'transparent',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.reg);
              }}
            >
              Reg
            </Stack>

            <Stack
              sx={{
                padding: '10px 20px',
                borderRadius: '3px',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: leadStatuses.includes(TelegramLeadStatus.dep)
                  ? 'blue.2'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.dep)
                  ? 'blue.1'
                  : 'transparent',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.dep);
              }}
            >
              Dep
            </Stack>
            <Stack
              sx={{
                padding: '10px 20px',
                borderRadius: '3px',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: leadStatuses.includes(TelegramLeadStatus.qual)
                  ? 'blue.2'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.qual)
                  ? 'blue.1'
                  : 'transparent',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.qual);
              }}
            >
              Qual
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            mt: '10px',
          }}
          gap="10px"
        >
          {paramConditions?.map(({ key, values, include }, index) => (
            <Stack
              key={key + index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ width: '15%' }}>
                <FormControl fullWidth>
                  <InputLabel>{t('common.key')}</InputLabel>
                  <Select
                    value={key}
                    label={t('common.key')}
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeParamKey(index, value);
                    }}
                    sx={{
                      '.MuiSelect-select': {
                        backgroundColor: 'grey.15',
                        py: '14px',
                      },
                    }}
                  >
                    {projectParams.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Stack
                onClick={() => {
                  onChangeParamValues(index, values, !include);
                }}
                sx={{
                  px: '2px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '21px',
                    cursor: 'pointer',
                    color: include ? 'green.2' : 'red.2',
                  }}
                >
                  {include ? 'in' : 'not'}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: '80%',
                }}
              >
                <InputChip
                  chips={values}
                  handleChipsChange={(newValue) => {
                    onChangeParamValues(index, newValue, include);
                  }}
                  onDeleteIconClick={() => {
                    onDeleteCondition(index);
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Button
          sx={{
            border: '1px solid',
            borderColor: 'blue.2',
            backgroundColor: 'blue.1',
            color: 'blue.2',
            mt: '20px',
          }}
          onClick={onAddCondition}
        >
          {t('broadcast.modal.condition')}
          <AddRoundedIcon />
        </Button>

        <Stack
          sx={{
            mt: '20px',
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{t('broadcast.modal.startDate')}</Typography>
          <Stack
            sx={{
              width: '80%',
            }}
          >
            <Stack
              sx={{
                width: '300px',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={dayjs(startTime)}
                  disablePast
                  ampm={false}
                  onChange={onChangeStartTime}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection="row" gap="12px">
          {(!isEditing || type === BroadcastType.draft) && (
            <Button
              variant="outlined"
              sx={{
                width: '100%',
                backgroundColor: 'blue.1',
                color: 'blue.2',
                fontWeight: 600,
                textTransform: 'none',
                mt: '15px',
                '&:hover': {
                  backgroundColor: 'blue.1',
                },
              }}
              onClick={() => onSubmit(BroadcastType.draft)}
            >
              {t('broadcast.modal.drafts')}
            </Button>
          )}

          <Button
            variant="contained"
            sx={{
              width: '100%',
              backgroundColor: 'blue.2',
              color: 'grey.1',
              fontWeight: 600,
              textTransform: 'none',
              mt: '15px',
              '&:hover': {
                backgroundColor: 'blue.3',
              },
            }}
            onClick={() => onSubmit(BroadcastType.flow)}
            disabled={!isValid || calculationLoading}
          >
            {t('broadcast.modal.plan')} (
            {calculationLoading ? '...' : receiversNumber})
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default BroadcastModal;
