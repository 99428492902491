import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { IMedia } from '../../interfaces/common';

interface PhotoItemProps {
  media: IMedia;
  isSelected: boolean;
  onSelectMedia: (media: IMedia) => void;
  number: number;
}

const PhotoItem: FC<PhotoItemProps> = ({
  media,
  isSelected,
  onSelectMedia,
  number,
}) => {
  return (
    <Stack
      key={media._id}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '6px',
        border: '2px solid',
        borderColor: isSelected ? 'green.2' : 'grey.10',
      }}
      onClick={() => {
        onSelectMedia(media);
      }}
    >
      <img
        src={media.url}
        style={{
          width: '123px',
          height: '123px',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '4px',
        }}
        alt=""
      />
      {isSelected && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            backgroundColor: 'green.2',
            top: 0,
            right: 5,
            width: '20px',
            height: '20px',
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'grey.14',
            }}
          >
            {number}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default PhotoItem;
