import { Stack, keyframes } from '@mui/material';
import React from 'react';

import bgPattern from '../../assets/bgPattern.png';

const bgAnimation = keyframes`
  0%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
    scale: 1.1;
  }
  
`;

const AppLoader = () => {
  return (
    <Stack
      sx={{
        height: '100vh',
        background: `url("${bgPattern}")`,
        backgroundSize: 'contain',
        animation: `${bgAnimation} 1s infinite alternate`,
      }}
    />
  );
};

export default AppLoader;
