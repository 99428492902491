import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { Button, IconButton, Stack } from '@mui/material';
import React, { FC, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { TelegramMessageType } from '../../../interfaces/common';
import { IMenuFlowNodeData } from '../interfaces';

export interface IMenuNodeData {
  onDelete: () => void;
  position: { x: number; y: number };
  data: IMenuFlowNodeData;
  next: null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface MenuNodeProps extends Omit<NodeProps, 'data'> {
  data: IMenuNodeData;
}

const MenuNode: FC<MenuNodeProps> = memo(({ data, xPos, yPos }) => {
  useEffect(() => {
    data.onNodePositionChange(data._id, {
      x: xPos,
      y: yPos,
    });
  }, [xPos, yPos]);

  const { t } = useTranslation();

  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = nodeRef.current;
    const preventZoom = (event: WheelEvent) => event.stopPropagation();

    if (node) {
      node.addEventListener('wheel', preventZoom);
      return () => {
        node.removeEventListener('wheel', preventZoom);
      };
    }
  }, []);

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '36px',
          overflow: 'hidden',
        }}
      >
        <IconButton
          sx={{
            backgroundColor: 'red.2',
            width: '36px',
            padding: '5px',
            borderRadius: '6px',
            cursor: 'pointer',
          }}
          onClick={data.onDelete}
        >
          <CloseRoundedIcon
            sx={{
              color: 'grey.14',
            }}
          />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          borderRadius: '12px',
          border: '2px solid',
          borderColor: 'blue.2',
          backgroundColor: 'grey.15',
          width: '180px',
          overflow: 'hidden',
          cursor: 'default',
        }}
        ref={nodeRef}
      >
        <Handle
          type="target"
          position={Position.Left}
          id={'edge-' + data._id}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            height: '100%',
            width: '100%',
            borderRadius: '0',
            zIndex: -1,
          }}
        />
        <Stack direction="row" alignItems="center">
          <Stack
            sx={{
              width: '100%',
            }}
          >
            <Button
              sx={{
                paddingLeft: '30px',
                justifyContent: 'start',
                color: 'grey.1',
                borderBottom: '1px solid',
                borderColor: 'grey.12',
                backgroundColor: 'grey.15',
                '&:hover': { backgroundColor: 'blue.2' },
              }}
              startIcon={<ChatBubbleRoundedIcon />}
              onClick={() => {
                data.onDelete();
                data.data.addTelegramMessageNode(
                  {
                    text: '',
                    buttons: [],
                    media: [],
                    telegramMessageType: TelegramMessageType.media,
                  },
                  data.position,
                  data._id,
                );
              }}
            >
              {t('flowNodes.messageLabel')}
            </Button>
            <Button
              sx={{
                paddingLeft: '30px',
                justifyContent: 'start',
                color: 'grey.1',
                borderBottom: '1px solid',
                borderColor: 'grey.12',
                backgroundColor: 'grey.15',
                '&:hover': { backgroundColor: 'blue.2' },
              }}
              startIcon={<BoltRoundedIcon />}
              onClick={() => {
                data.onDelete();
                data.data.addActionsNode([], data.position, data._id);
              }}
            >
              {t('flowNodes.actionsLabel')}
            </Button>
            <Button
              sx={{
                paddingLeft: '30px',
                justifyContent: 'start',
                color: 'grey.1',
                borderBottom: '1px solid',
                borderColor: 'grey.12',
                backgroundColor: 'grey.15',
                '&:hover': { backgroundColor: 'blue.2' },
              }}
              startIcon={<MediationRoundedIcon />}
              onClick={() => {
                data.onDelete();
                data.data.addConditionsNode([], data.position, data._id);
              }}
            >
              {t('flowNodes.conditionsLabel')}
            </Button>
            <Button
              sx={{
                paddingLeft: '30px',
                justifyContent: 'start',
                color: 'grey.1',
                borderBottom: '1px solid',
                borderColor: 'grey.12',
                backgroundColor: 'grey.15',
                '&:hover': { backgroundColor: 'blue.2' },
              }}
              startIcon={<TimerRoundedIcon />}
              onClick={() => {
                data.onDelete();
                data.data.addDelayNode(
                  {
                    delayForSeconds: 0,
                    applyTimeRange: false,
                    days: [],
                    startTime: '',
                    endTime: '',
                    timezone: '',
                  },
                  data.position,
                  data._id,
                );
              }}
            >
              {t('flowNodes.delayLabel')}
            </Button>
            <Button
              sx={{
                paddingLeft: '30px',
                justifyContent: 'start',
                color: 'grey.1',
                borderBottom: '1px solid',
                borderColor: 'grey.12',
                backgroundColor: 'grey.15',
                '&:hover': { backgroundColor: 'blue.2' },
              }}
              startIcon={<FmdBadIcon />}
              onClick={() => {
                data.onDelete();
                data.data.addChatActionNode(
                  {
                    actionType: 'typing',
                    duration: 1,
                  },
                  data.position,
                  data._id,
                );
              }}
            >
              {t('flowNodes.chatAction')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});

MenuNode.displayName = 'MenuNode';

export default MenuNode;
