import { useState } from 'react';

export function useScrollRestoration() {
  const [scrollInfo, setScrollInfo] = useState<{
    scrollTop: number;
    scrollHeight: number;
  } | null>(null);

  const handleScroll = (ref: HTMLDivElement | null) => {
    if (ref) {
      setScrollInfo({
        scrollTop: ref.scrollTop,
        scrollHeight: ref.scrollHeight,
      });
    }
  };

  const restoreScroll = (ref: HTMLDivElement | null) => {
    if (ref && scrollInfo) {
      ref.scrollTop =
        (scrollInfo.scrollTop / ref.scrollHeight) * ref.scrollHeight;
    }
  };

  return {
    handleScroll,
    restoreScroll,
  };
}
