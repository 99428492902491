import { Stack } from '@mui/material';
import React, { FC } from 'react';

import ProjectStatistics from '../../project-statistics';
import IProject from '../project.interface';

interface StatisticsProps {
  project: IProject;
}

const Statistics: FC<StatisticsProps> = ({ project }) => {
  return (
    <Stack>
      <ProjectStatistics project={project} />
    </Stack>
  );
};

export default Statistics;
