import {
  Divider,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import calculateBroadcastReceivers from '../api/broadcasts/calculate-receivers';
import createBroadcast from '../api/broadcasts/create-broadcast';
import deleteBroadcast from '../api/broadcasts/delete-broadcast';
import editBroadcast from '../api/broadcasts/edit-broadcast';
import fetchBroadcasts from '../api/broadcasts/fetch-broadcasts';
import { AppContext } from '../AppContext';

import { BotContext } from './bot.context';
import BroadcastList from './broadcasts/broadcast-list';
import BroadcastModal from './broadcasts/broadcast.modal';
import DraftsTab from './broadcasts/drafts.tab';
import {
  BroadcastParamCondition,
  BroadcastStatus,
  BroadcastType,
  CreateBroadcast,
  IBroadcast,
} from './broadcasts/interfaces';
import { FlowTypes, IFlow } from './flows/interfaces';

interface BroadcastTab {
  label: string;
}

interface BroadcastsTabProps {
  onChangeTabIndex: (newValue: number) => void;
}

const BroadcastsTab: FC<BroadcastsTabProps> = ({ onChangeTabIndex }) => {
  const { t } = useTranslation();

  const BROADCAST_TABS: BroadcastTab[] = [
    { label: t('bot-page.broadcastTabs.drafts') },
    { label: t('bot-page.broadcastTabs.planned') },
    { label: t('bot-page.broadcastTabs.started') },
  ];

  const { bot, flows } = useContext(BotContext);
  const { triggerSnackbar } = useContext(AppContext);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [broadcasts, setBroadcasts] = useState<IBroadcast[]>([]);

  const [name, setName] = useState('');
  const [flow, setFlow] = useState<IFlow['_id']>('');
  const [leadStatuses, setLeadStatuses] = useState<string[]>([]);
  const [paramConditions, setParamConditions] = useState<
    BroadcastParamCondition[]
  >([]);
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [calculationLoading, setCalculationLoading] = useState(false);

  const [receiversNumber, setReceiversNumber] = useState(0);

  const [currentBroadcast, setCurrentBroadcast] = useState<IBroadcast | null>(
    null,
  );

  const paramsValid = Boolean(
    paramConditions.every(({ key, values }) => Boolean(key) && values.length),
  );

  useEffect(() => {
    if (!leadStatuses.length || !bot || !paramsValid) return;

    setCalculationLoading(true);

    calculateBroadcastReceivers(bot._id, {
      statusConditions: {
        values: leadStatuses,
      },
      paramConditions,
    })
      .then(setReceiversNumber)
      .finally(() => setCalculationLoading(false));
  }, [leadStatuses, paramConditions, paramsValid]);

  useEffect(() => {
    if (!broadcastModalOpen) {
      setCurrentBroadcast(null);
    }
  }, [broadcastModalOpen]);

  const getBroadcasts = () => {
    fetchBroadcasts(bot._id).then(setBroadcasts);
  };

  const onCreateBroadcast = (data: CreateBroadcast) => {
    createBroadcast(bot._id, data)
      .then(getBroadcasts)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorCreatingBroadcast'), 'error');
      });

    setBroadcastModalOpen(false);
  };

  const onEditBroadcast = (type: BroadcastType) => {
    if (!currentBroadcast) return;

    if (currentBroadcast.type !== BroadcastType.draft && !Boolean(startTime))
      return;

    editBroadcast(bot._id, currentBroadcast._id, {
      name,
      flow,
      statusConditions: {
        values: leadStatuses,
      },
      paramConditions,
      startTime: (startTime as Date).toISOString(),
      type,
    })
      .then(getBroadcasts)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorEditingBroadcast'), 'error');
      });
    setBroadcastModalOpen(false);
  };

  const handleBroadcastOpenModal = () => {
    setName('');
    setFlow('');
    setLeadStatuses([]);
    setParamConditions([]);
    setStartTime(new Date());
    setBroadcastModalOpen(true);
  };

  const onCopyBroadcastData = (broadcast: IBroadcast) => {
    setName(broadcast.name ?? '');
    setFlow(broadcast.flow ?? '');
    setLeadStatuses(broadcast.statusConditions?.values ?? []);
    setParamConditions(broadcast.paramConditions ?? []);
    setStartTime(
      broadcast.startTime ? new Date(broadcast.startTime) : new Date(),
    );
    setCurrentBroadcast({ ...broadcast });
    setBroadcastModalOpen(true);
  };

  const onDeleteBroadcast = (broadcastId: string) => {
    deleteBroadcast(bot._id, broadcastId).then(getBroadcasts);
  };

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeFlow = (event: SelectChangeEvent) => {
    setFlow(event.target.value);
  };

  const onChangeParamKey = (index: number, key: string) => {
    setParamConditions((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          key,
        };
      });
    });
  };

  const onChangeParamValues = (
    index: number,
    values: string[],
    include: boolean,
  ) => {
    setParamConditions((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          values,
          include,
        };
      });
    });
  };

  const onDeleteCondition = (index: number) => {
    setParamConditions((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  const toggleStatus = (status: string) => {
    setLeadStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((st) => st !== status)
        : [...prev, status],
    );
  };

  const onAddParamCondition = () => {
    setParamConditions((prev) => [
      ...prev,
      { key: '', values: [], include: true },
    ]);
  };

  const onChangeStartTime = (date: dayjs.Dayjs | null) => {
    setStartTime(date?.toDate() ?? new Date());
  };

  const isValid = useMemo(
    () =>
      Boolean(name) &&
      Boolean(flow) &&
      paramsValid &&
      Boolean(leadStatuses.length) &&
      Boolean(startTime && dayjs(startTime) > dayjs(new Date())),
    [name, flow, paramsValid, leadStatuses, startTime, currentBroadcast],
  );

  const onCreateClick = (type: BroadcastType) => {
    if (!isValid || !startTime) return;

    if (currentBroadcast?._id) {
      onEditBroadcast(type);
      return;
    }

    onCreateBroadcast({
      name,
      flow,
      type,
      paramConditions,
      startTime: startTime.toISOString(),
      statusConditions: {
        values: leadStatuses,
      },
    });
  };

  useEffect(() => {
    getBroadcasts();
  }, [bot]);

  return (
    <Stack>
      <Tabs
        variant="fullWidth"
        value={currentTabIndex}
        onChange={onChangeTab}
        TabIndicatorProps={{
          sx: {
            left: '0',
            width: '5px',
          },
        }}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'green.2',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'green.2',
          },
        }}
      >
        {BROADCAST_TABS.map(({ label }) => (
          <Tab
            key={'tab-' + label}
            label={
              <Stack>
                <Typography
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {label}
                </Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>

      <Divider />

      <Stack
        sx={{
          padding: '20px 20px 10px',
        }}
      >
        {currentTabIndex === 0 && (
          <DraftsTab
            openModal={handleBroadcastOpenModal}
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            onDelete={onDeleteBroadcast}
            broadcasts={broadcasts.filter(
              (broadcast: IBroadcast) => broadcast.type === BroadcastType.draft,
            )}
          />
        )}

        {currentTabIndex === 1 && (
          <BroadcastList
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            onDelete={onDeleteBroadcast}
            broadcasts={broadcasts.filter(
              (broadcast: IBroadcast) =>
                broadcast.status === BroadcastStatus.planned &&
                broadcast.type !== BroadcastType.draft,
            )}
          />
        )}

        {currentTabIndex === 2 && (
          <BroadcastList
            onDelete={onDeleteBroadcast}
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            broadcasts={broadcasts.filter(
              (broadcast: IBroadcast) =>
                broadcast.status === BroadcastStatus.started,
            )}
          />
        )}

        <BroadcastModal
          _id={currentBroadcast?._id}
          type={currentBroadcast?.type}
          receiversNumber={receiversNumber}
          open={broadcastModalOpen}
          onClose={() => setBroadcastModalOpen(false)}
          flows={flows.filter((flow) =>
            flow.type.includes(FlowTypes.broadcast),
          )}
          name={name}
          flow={flow}
          leadStatuses={leadStatuses}
          paramConditions={paramConditions}
          startTime={startTime}
          onChangeName={onChangeName}
          onChangeFlow={onChangeFlow}
          onChangeParamKey={onChangeParamKey}
          onChangeParamValues={onChangeParamValues}
          onDeleteCondition={onDeleteCondition}
          toggleStatus={toggleStatus}
          onAddCondition={onAddParamCondition}
          onChangeStartTime={onChangeStartTime}
          isValid={isValid}
          onSubmit={onCreateClick}
          onChangeTabIndex={onChangeTabIndex}
          calculationLoading={calculationLoading}
        />
      </Stack>
    </Stack>
  );
};

export default BroadcastsTab;
