import { Stack } from '@mui/material';
import React, { ChangeEvent, useContext, useState } from 'react';

import { TelegramLeadStatus } from '../../interfaces/common';
import DialogsFilter from '../interfaces/dialogs-filter.interface';
import { MessengerContext } from '../messenger.context';

import FilterPopup from './filter.popup';
import SearchInput from './search-input';

const FilterContainer = () => {
  const { tags, dialogsFilter, updateDialogsFilter } =
    useContext(MessengerContext);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateDialogsFilter({
      search: value,
    });
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleStatus = (status: TelegramLeadStatus) => {
    updateDialogsFilter({
      status: dialogsFilter.status.includes(status)
        ? dialogsFilter.status.filter((st) => st !== status)
        : [...dialogsFilter.status, status],
    });
  };

  const updateSearchMode = (newMode: DialogsFilter['searchMode']) => {
    updateDialogsFilter({
      searchMode: newMode,
    });
  };

  const selectTag = (newTagId: string) => {
    updateDialogsFilter({
      tags: [...dialogsFilter.tags, newTagId],
    });
  };

  const deleteTag = (newTagId: string) => {
    updateDialogsFilter({
      tags: [...dialogsFilter.tags.filter((_id) => newTagId !== _id)],
    });
  };

  const resetAllFilters = () => {
    updateDialogsFilter({
      status: [],
      search: '',
      tags: [],
      searchMode: 'all',
    });
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap="6px">
      <SearchInput
        search={dialogsFilter.search}
        handleChangeSearch={handleChangeSearch}
      />
      <FilterPopup
        openMenu={openMenu}
        menuOpen={menuOpen}
        closeMenu={closeMenu}
        anchorEl={anchorEl}
        dialogStatus={dialogsFilter.status}
        toggleStatus={toggleStatus}
        tags={tags}
        selectedTagIds={dialogsFilter.tags}
        handleSelectTag={selectTag}
        handleDeleteTag={deleteTag}
        searchMode={dialogsFilter.searchMode}
        updateSearchMode={updateSearchMode}
        resetAllFilters={resetAllFilters}
      />
    </Stack>
  );
};

export default FilterContainer;
