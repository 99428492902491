import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import fetchFlows from '../api/flows/fetch-flows';
import { AppContext } from '../AppContext';
import { ProjectContext } from '../projects/project.context';

import { IFlowWithActivatorsAndFolder } from './flows/interfaces';
import {
  ITelegramBot,
  ITelegramBotAccess,
  TelegramMessengerMode,
} from './telegram-bot.interface';

interface IBotContext {
  bot: ITelegramBot;
  access: ITelegramBotAccess;
  flows: IFlowWithActivatorsAndFolder[];
  refetchFlows: () => void;
  firstTab: number;
  onUpdateMessengerMode: (
    success: boolean,
    newMode: TelegramMessengerMode,
  ) => void;
}

export const BotContext = createContext<IBotContext>({} as IBotContext);

const BotContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { telegramBots } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);

  const [bot, setBot] = useState<ITelegramBot>();
  const [access, setAccess] = useState<ITelegramBotAccess>();
  const [flows, setFlows] = useState<IFlowWithActivatorsAndFolder[]>([]);

  const [firstTab, setFirstTab] = useState(20);

  const { botId } = useParams();

  useEffect(() => {
    const found = telegramBots.find(({ bot }) => bot._id === botId);

    if (found) {
      setBot(found.bot);
      setAccess(found.access);
    }
  }, [botId, telegramBots]);

  useEffect(() => {
    if (!access) return;

    if (access.flows) {
      setFirstTab(0);
      return;
    }

    if (access.broadcasts) {
      setFirstTab(1);
      return;
    }

    if (access.channels) {
      setFirstTab(2);
      return;
    }

    if (access.statistics) {
      setFirstTab(4);
      return;
    }
  }, [access]);

  const getFlows = () => {
    if (!bot) return;

    fetchFlows(bot._id).then(setFlows);
  };

  const onUpdateMessengerMode = (
    success: boolean,
    newMode: TelegramMessengerMode,
  ) => {
    if (!success) {
      triggerSnackbar('Error updating mode', 'error');
      return;
    }

    setAccess((prev) => {
      if (!prev) return prev;
      return { ...prev, messengerMode: newMode };
    });
  };

  useEffect(() => {
    getFlows();
  }, [bot]);

  if (!bot) return null;
  if (!access) return null;

  return (
    <BotContext.Provider
      value={{
        bot,
        access,
        firstTab,
        onUpdateMessengerMode,
        flows,
        refetchFlows: getFlows,
      }}
    >
      {children}
    </BotContext.Provider>
  );
};

export default BotContextProvider;
