import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../user/user.context';
import { ProjectContext } from '../project.context';

const Empty = () => {
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 'calc(100vh - 70px)',
      }}
    >
      <Typography
        sx={{
          color: 'grey.1',
          textAlign: 'center',
          fontSize: '24px',
          fontFamily: 'Gilroy',
          fontWeight: '600',
          lineHeight: '130%',
          mb: '32px',
        }}
      >
        {t('projects.empty.title')} {project.name}
      </Typography>
      <Typography
        sx={{
          color: 'grey.5',
          textAlign: 'center',
          fontSize: '18px',
          fontFamily: 'Gilroy',
          fontWeight: '500',
          lineHeight: '130%',
          maxWidth: '370px',
        }}
      >
        {project.owner === user?._id
          ? t('projects.empty.paragraphOwner')
          : t('projects.empty.paragraph')}
      </Typography>
    </Stack>
  );
};

export default Empty;
