import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { Button, Divider, Stack, Typography } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import VoicePreview from '../../common/media/voice.preview';
import TelegramButtons from '../../common/telegram/telegram.buttons';
import { IMedia, MediaType, TelegramButton } from '../../interfaces/common';
import truncate from '../../utils/truncate';

interface TemplateCardProps {
  title: string;
  media: IMedia[];
  text: string | null;
  buttons: TelegramButton[][];
  onEdit: () => void;
  isDisabledDelete: boolean;
  onDelete: () => void;
}

const TemplateCard: FC<TemplateCardProps> = ({
  title,
  media,
  text,
  buttons,
  onEdit,
  isDisabledDelete,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      gap="12px"
      sx={{
        maxWidth: '268px',
        width: '100%',
        p: '10px',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: 'grey.10',
        backgroundColor: 'grey.14',
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>
        {truncate(title, 25)}
      </Typography>
      <Divider />
      <Stack flexDirection="row" gap="18px" sx={{ overflow: 'auto' }}>
        {media.length
          ? media?.map((mediaItem) => {
              switch (mediaItem.type) {
                case MediaType.photo: {
                  return (
                    <Stack
                      key={mediaItem._id}
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '6px',
                      }}
                    >
                      <img
                        src={mediaItem.url}
                        style={{
                          width: '123px',
                          height: '123px',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '4px',
                        }}
                        alt=""
                      />
                    </Stack>
                  );
                }

                case MediaType.videoNote: {
                  const [isPlaying, setIsPlaying] = useState(false);

                  const togglePlay = (e: SyntheticEvent) => {
                    e.stopPropagation();

                    setIsPlaying((prev) => !prev);
                  };

                  const onVideoEnd = () => {
                    setIsPlaying(false);
                  };
                  return (
                    <Stack
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: '2px solid',
                        borderColor: 'grey.10',
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'relative',
                          ':hover': {
                            '.pauseButton': {
                              display: 'block',
                            },
                          },
                        }}
                      >
                        <ReactPlayer
                          url={mediaItem.url}
                          width={123}
                          height={123}
                          playing={isPlaying}
                          onEnded={onVideoEnd}
                        />

                        {!isPlaying && (
                          <PlayCircleRoundedIcon
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              fontSize: '48px',
                              cursor: 'pointer',
                            }}
                            onClick={togglePlay}
                          />
                        )}

                        {isPlaying && (
                          <PauseCircleFilledRoundedIcon
                            className="pauseButton"
                            sx={{
                              display: 'none',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              fontSize: '48px',
                              cursor: 'pointer',
                            }}
                            onClick={togglePlay}
                          />
                        )}
                      </Stack>
                    </Stack>
                  );
                }
                case MediaType.voice: {
                  return (
                    <Stack
                      sx={{
                        width: '220px',
                        backgroundColor: 'grey.15',
                        padding: '5px 15px 5px 5px',
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.10',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'grey.4',
                        }}
                      >
                        {truncate(mediaItem?.name ?? '', 20)}
                      </Typography>
                      <VoicePreview voiceUrl={mediaItem?.url} />
                    </Stack>
                  );
                }
              }
            })
          : null}
      </Stack>
      <Stack sx={{ maxHeight: '200px', overflow: 'auto' }}>{text}</Stack>
      <Stack>
        <TelegramButtons buttons={buttons} />
      </Stack>
      <Divider sx={{ mt: 'auto' }} />
      <Stack flexDirection="row">
        <Button
          fullWidth
          sx={{
            borderRadius: '3px',
            border: ' 1px solid',
            borderColor: 'blue.2',
            color: 'blue.2',
            mr: '10px',
            backgroundColor: 'blue.1',
            '&:hover': { backgroundColor: 'blue.2', color: 'blue.1' },
          }}
          onClick={onEdit}
        >
          {t('common.edit')}
        </Button>
        <Button
          fullWidth
          sx={{
            borderRadius: '3px',
            border: ' 1px solid',
            color: 'red.2',
            borderColor: 'red.2',
            backgroundColor: 'red.1',
            '&:hover': { backgroundColor: 'red.2', color: 'red.1' },
          }}
          disabled={isDisabledDelete}
          onClick={onDelete}
        >
          {t('common.delete')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TemplateCard;
