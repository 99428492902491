import {
  IFlowFolder,
  updateFlowFolder,
} from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const editFolder = async (
  folderId: IFlowFolder['_id'],
  newFolderData: updateFlowFolder,
) => {
  const { data } = await axios.patch(
    `/flows/folders/${folderId}`,
    newFolderData,
  );

  return data;
};

export default editFolder;
