import { IFlowNode, IFlow } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const saveFlowNodes = async (
  flowId: IFlow['_id'],
  data: {
    newNodes: Omit<IFlowNode, 'createdAt' | 'updatedAt'>[];
    removedNodeIds: IFlowNode['_id'][];
    updatedNodes: Omit<IFlowNode, 'createdAt' | 'updatedAt'>[];
    startNode: {
      position: {
        x: number;
        y: number;
      };
      next: string | null;
    };
  },
) => {
  const response = await axios.patch(`/flows/${flowId}/update-nodes`, data);

  return response.data;
};

export default saveFlowNodes;
