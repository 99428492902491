import React from 'react';
import { Edge, MarkerType, Node } from 'reactflow';

import { darkThemeColors } from '../../../common/theme/dark.theme';
import {
  ITelegramActionButton,
  TelegramButtonType,
} from '../../../interfaces/common';
import useThemeColors from '../../../utils/hooks/useThemeColors';
import { IConditionsNodeData } from '../flow-nodes/conditions.node';
import { ITelegramMessageNodeData } from '../flow-nodes/telegram-message-node';
import { ConditionsFlowNodeConditionData, FlowNodeTypes } from '../interfaces';
import { getConnectionLineStyle } from '../nodes-editor';

const useUpdateEdges = (
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>,
  deleteEdge: (id: string) => void,
) => {
  const colors = useThemeColors();

  const style = getConnectionLineStyle(colors);

  return (nodes: Node[]) => {
    setEdges(() => {
      const edges: Edge[] = [];
      const actionEdges: string[][] = [];
      const conditionsEdges: string[][] = [];

      for (const n of nodes) {
        if (n.data.next) {
          edges.push({
            source: n.id,
            target: n.data.next,
            id: `src-${n.data._id}-${n.data.next}`,
            type: 'connection',
            style,
            markerEnd: {
              type: MarkerType.Arrow,
              color: darkThemeColors.green['2'],
            },
            data: {
              deleteEdge,
              source: n.data._id,
              target: n.data.next,
            },
          });
        }

        if (n.type === FlowNodeTypes.telegramMessage) {
          const data = (n.data as ITelegramMessageNodeData).data;

          for (let i = 0; i < data.buttons.length; i++) {
            for (let j = 0; j < data.buttons[i].length; j++) {
              if (data.buttons[i][j].type !== TelegramButtonType.action) {
                continue;
              }

              //includes target
              const split = (
                data.buttons[i][j] as ITelegramActionButton
              ).action.split('-');

              actionEdges.push([`action-${n.data._id}-${i}-${j}`, split[1]]);
            }
          }
        }

        if (n.type === FlowNodeTypes.conditions) {
          const data = (n.data as IConditionsNodeData).data;

          for (let i = 0; i < data.conditions.length; i++) {
            const split = (
              data.conditions[i] as ConditionsFlowNodeConditionData
            ).truthyNext.split('-');

            conditionsEdges.push([
              `condition-${n.data._id}-${i}-none`,
              split[1],
            ]);
          }
        }
      }

      for (const pair of actionEdges) {
        const [source, target] = pair;

        const edge = {
          source: source.split('-')[1],
          target,
          sourceHandle: source,
          targetHandle: 'edge-' + target,
          id: `${source}-edge-${target}`,
          type: 'connection',
          style,
          markerEnd: {
            type: MarkerType.Arrow,
            color: darkThemeColors.green['2'],
          },
          data: {
            deleteEdge,
            source,
            target,
          },
        };

        edges.push(edge);
      }

      for (const pair of conditionsEdges) {
        const [source, target] = pair;

        const edge = {
          source: source.split('-')[1],
          target,
          sourceHandle: source,
          targetHandle: 'edge-' + target,
          id: `${source}-edge-${target}`,
          type: 'connection',
          style,
          markerEnd: {
            type: MarkerType.Arrow,
            color: darkThemeColors.green['2'],
          },
          data: {
            deleteEdge,
            source,
            target,
          },
        };

        edges.push(edge);
      }

      return edges;
    });
  };
};

export default useUpdateEdges;
