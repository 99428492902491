import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BotContext } from './bot.context';
import { FlowTypes, IFlowWithActivatorsAndFolder } from './flows/interfaces';
import BuildPostback from './postback/build-postback';

const PostbackTab = () => {
  const { t } = useTranslation();

  const { flows } = useContext(BotContext);

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 70px)',
        width: '72vw',
        maxWidth: '1000px',
        padding: '24px',
        margin: '0 auto',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="10px"
        sx={{
          width: '100%',
          maxWidth: '700px',
          padding: '12px',
          borderRadius: '12px',
          backgroundColor: 'grey.13',
          border: '1px solid',
          borderColor: 'grey.10',
          margin: '0 auto',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            alignSelf: 'start',
            height: '100%',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                color: 'grey.5',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: '500',
                width: '40%',
                mb: '15px',
              }}
            >
              {t('postback.title')}
            </Typography>
          </Stack>
          <BuildPostback
            postbackFlows={
              flows
                ? flows.filter((flow: IFlowWithActivatorsAndFolder) =>
                    flow.type.includes(FlowTypes.postback),
                  )
                : []
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PostbackTab;
