import { Button, Divider, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BroadcastStatus, BroadcastType } from './interfaces';

interface BroadcastCardProps {
  name: string;
  type?: BroadcastType;
  status: BroadcastStatus;
  recipientsCount: number;
  startDate: Date | null;
  endDate: Date | null;
  onCopy: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const BroadcastCard: FC<BroadcastCardProps> = ({
  name,
  type = 'FLOW',
  status,
  recipientsCount,
  startDate,
  endDate,
  onCopy,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const broadcastStatus = (status: BroadcastStatus) => {
    switch (status) {
      case BroadcastStatus.planned:
        return t('broadcast.broadcastStatusEnum.planned');
      case BroadcastStatus.started:
        return t('broadcast.broadcastStatusEnum.started');
    }
  };
  return (
    <Stack
      sx={{
        width: '330px',
        backgroundColor: 'grey.14',
        border: '1px solid',
        borderColor: 'grey.10',
        borderRadius: '12px',
        maxHeight: '302px',
        height: 'max-content',
        p: '10px',
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>{name}</Typography>
      <Divider sx={{ m: '12px' }} />
      <Stack flexDirection="row" gap="30px">
        <Stack gap="12px">
          <Typography sx={{ color: 'grey.5' }}>{t('common.status')}</Typography>
          {type !== 'DRAFT' && (
            <>
              <Typography sx={{ color: 'grey.5' }}>
                {t('broadcast.broadcastCard.recipients')}
              </Typography>
              {startDate && (
                <Typography sx={{ color: 'grey.5' }}>
                  {t('broadcast.broadcastCard.start')}
                </Typography>
              )}
            </>
          )}
          {endDate && (
            <Typography sx={{ color: 'grey.5' }}>
              {t('broadcast.broadcastCard.end')}
            </Typography>
          )}
        </Stack>
        <Stack gap="12px">
          <Typography>
            {type === 'DRAFT'
              ? t('broadcast.broadcastType.draft')
              : broadcastStatus(status)}
          </Typography>
          {type !== 'DRAFT' && (
            <>
              <Typography>{recipientsCount}</Typography>
              {startDate && (
                <Typography>{new Date(startDate).toLocaleString()}</Typography>
              )}
            </>
          )}
          {endDate && (
            <Typography>{new Date(endDate).toLocaleString()}</Typography>
          )}
        </Stack>
      </Stack>
      <Divider sx={{ m: '12px' }} />
      <Stack flexDirection="row" gap="6px" sx={{ mt: 'auto' }}>
        {status !== BroadcastStatus.started && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'blue.1',
              border: '1px solid ',
              borderColor: 'blue.2',
              color: 'blue.2',
              borderRadius: '3px',
              width: '100%',
              '&:hover': {
                backgroundColor: 'inherit',
              },
            }}
            onClick={onEdit}
          >
            Edit
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'blue.1',
            color: 'blue.2',
            border: '1px solid ',
            borderColor: 'blue.2',
            borderRadius: '3px',
            width: '100%',
            '&:hover': {
              backgroundColor: 'inherit',
            },
          }}
          onClick={onCopy}
        >
          Copy
        </Button>
        {status !== BroadcastStatus.started && (
          <Button
            onClick={onDelete}
            variant="contained"
            sx={{
              backgroundColor: 'red.1',
              color: 'red.2',
              border: '1px solid ',
              borderColor: 'red.2',
              borderRadius: '3px',
              width: '100%',
              '&:hover': {
                backgroundColor: 'inherit',
              },
            }}
          >
            Delete
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default BroadcastCard;
