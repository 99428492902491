import { Stack } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';

import fetchActiveChannels from '../api/channels/fetch-active-channels';

import { BotContext } from './bot.context';
import ChannelsList from './channels/channels.list';
import { ITelegramChannelWithActivators } from './channels/interfaces';
import SelectedChannel from './channels/selected-channel';
import { ITelegramBot } from './telegram-bot.interface';

interface ChannelsTabProps {
  bot: ITelegramBot;
}

const ChannelsTab: FC<ChannelsTabProps> = ({ bot }) => {
  const { flows } = useContext(BotContext);

  const [channels, setChannels] = useState<ITelegramChannelWithActivators[]>(
    [],
  );
  const [selectedChannel, setSelectedChannel] =
    useState<ITelegramChannelWithActivators | null>(null);

  const getChannels = () => {
    fetchActiveChannels(bot._id).then(setChannels);
  };

  useEffect(() => {
    getChannels();
  }, [bot]);

  const onSelect = (channel: ITelegramChannelWithActivators) => {
    setSelectedChannel(channel);
    localStorage.setItem('channelId', channel._id);
  };

  const onBackClick = () => {
    setSelectedChannel(null);
    localStorage.removeItem('channelId');
    localStorage.removeItem('channelTabIndex');
  };

  useEffect(() => {
    const lsChannelId = localStorage.getItem('channelId');
    if (!lsChannelId) return;

    const foundChannel = channels.find(({ _id }) => _id === lsChannelId);

    if (foundChannel) {
      setSelectedChannel(foundChannel);
    }
  }, [channels]);

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 70px)',
        padding: '24px',
      }}
    >
      {!selectedChannel && (
        <ChannelsList channels={channels} onSelect={onSelect} />
      )}

      {selectedChannel && (
        <SelectedChannel
          channel={selectedChannel}
          onBackClick={onBackClick}
          getChannels={getChannels}
          flows={flows}
        />
      )}
    </Stack>
  );
};

export default ChannelsTab;
