import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';

const DialogConfirmation = () => {
  const { t } = useTranslation();
  const {
    confirmationDialogOpen,
    closeConfirmationDialog,
    confirmationDialogText,
    confirmationDialogButtonLabel,
    onConfirm,
  } = useContext(AppContext);

  return (
    <Dialog open={confirmationDialogOpen} onClose={closeConfirmationDialog}>
      <DialogContent sx={{ width: '300px' }}>
        <DialogContentText sx={{ wordWrap: 'break-word' }}>
          {confirmationDialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            color: 'grey.1',
            backgroundColor: 'grey.11',
            ':hover': { backgroundColor: 'grey.11' },
          }}
          fullWidth
          onClick={closeConfirmationDialog}
        >
          {t('common.cancel')}
        </Button>
        <Button
          sx={{
            color: 'grey.1',
            backgroundColor: 'red.2',
            ':hover': { backgroundColor: 'red.2' },
          }}
          fullWidth
          onClick={onConfirm}
          autoFocus
        >
          {confirmationDialogButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
