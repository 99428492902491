import { useContext } from 'react';

import { darkThemeColors } from '../../common/theme/dark.theme';
import { lightThemeColors } from '../../common/theme/light.theme';
import { UserContext } from '../../user/user.context';

const useThemeColors = () => {
  const { currentTheme } = useContext(UserContext);

  const colors = {
    dark: darkThemeColors,
    light: lightThemeColors,
    lol: lightThemeColors,
  };

  return colors[currentTheme] ?? darkThemeColors;
};

export default useThemeColors;
