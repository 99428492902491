import {
  IFlow,
  IFlowActivator,
  UpdateFlowActivatorWithParams,
} from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const editFlowActivator = async (
  flowId: IFlow['_id'],
  flowActivatorId: IFlowActivator['_id'],
  newFlowActivatorData: UpdateFlowActivatorWithParams,
) => {
  const { data } = await axios.patch(
    `/flows/${flowId}/activator/${flowActivatorId}`,
    newFlowActivatorData,
  );

  return data;
};

export default editFlowActivator;
