import { Button, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import WithNav from '../common/layout/with-nav';
import { GlobalContext } from '../GlobalContext';

const CreateFirstProject = () => {
  const { openNewProjectModal } = useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <WithNav>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            width: '370px',
          }}
        >
          <Typography
            sx={{
              color: 'grey.1',
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '130%',
            }}
          >
            {t('projects.yourFirstProjectTitle')}
          </Typography>
          <Typography
            sx={{
              mt: '32px',
              px: '50px',
              textAlign: 'center',
              color: 'grey.5',
            }}
          >
            {t('projects.yourFirstProjectDescription')}
          </Typography>

          <Button
            sx={{
              mt: '32px',
              width: '370px',
              height: '68px',
              backgroundColor: 'blue.2',
              color: 'grey.1',
              borderRadius: '12px',
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '130%',
            }}
            onClick={openNewProjectModal}
          >
            {t('projects.createProjectButton')}
          </Button>
        </Stack>
      </Stack>
    </WithNav>
  );
};

export default CreateFirstProject;
