import { useMemo } from 'react';

import {
  ITelegramDisplayedMessage,
  ITelegramMessage,
  TelegramMessageType,
} from '../../interfaces/common';

const useDisplayedMessages = (
  messages: {
    [key: string]: {
      messages: ITelegramMessage[];
      hasMore: boolean;
    };
  },
  dialogId: string,
) => {
  return useMemo(() => {
    const result: Array<ITelegramDisplayedMessage> = [];
    let currentDate = null;

    const telegramIdToIndex = new Map<number, number>();

    const messagesInDialog = messages[dialogId]?.messages ?? [];

    for (let i = 0; i < messagesInDialog.length; i++) {
      telegramIdToIndex.set(messagesInDialog[i].telegramId, i);
    }

    for (let i = 0; i < messagesInDialog.length; i++) {
      const messageDate = new Date(
        messagesInDialog[i].createdAt as string,
      ).toLocaleString('uk-UA', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });

      const isReply = Boolean(messagesInDialog[i].replyMessageId);

      if (currentDate !== messageDate) {
        const replyText = isReply
          ? messagesInDialog[
              telegramIdToIndex.get(
                messagesInDialog[i].replyMessageId as number,
              ) ?? 0
            ].text ?? ''
          : '';
        let replyMedia;

        if (!replyText) {
          replyMedia = isReply
            ? messagesInDialog[
                telegramIdToIndex.get(
                  messagesInDialog[i].replyMessageId as number,
                ) ?? 0
              ].media[0]
            : [];
        }

        if (replyMedia) {
          result.push({
            ...messagesInDialog[i],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            replyMedia: replyMedia?.url ?? '',
          });
        } else {
          result.push({
            ...messagesInDialog[i],
            replyText,
          });
        }

        result.push({
          type: TelegramMessageType.date,
          text: messageDate,
          dialog: messagesInDialog[i].dialog,
          reason: messagesInDialog[i].reason,
          seen: messagesInDialog[i].seen,
          createdAt: messagesInDialog[i].createdAt,
          updatedAt: messagesInDialog[i].updatedAt,
          _id: messagesInDialog[i]._id,
        });
      } else {
        result.length = result?.length - 1;

        const replyText = isReply
          ? messagesInDialog[
              telegramIdToIndex.get(
                messagesInDialog[i].replyMessageId as number,
              ) ?? 0
            ].text ?? ''
          : '';
        let replyMedia;

        if (!replyText) {
          replyMedia =
            isReply &&
            messagesInDialog[
              telegramIdToIndex.get(
                messagesInDialog[i].replyMessageId as number,
              ) ?? 0
            ].media[0];
        }

        if (replyMedia) {
          result.push({
            ...messagesInDialog[i],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            replyMedia: replyMedia?.url ?? '',
          });
        } else {
          result.push({
            ...messagesInDialog[i],
            replyText,
          });
        }

        result.push({
          type: TelegramMessageType.date,
          text: messageDate,
          dialog: messagesInDialog[i].dialog,
          reason: messagesInDialog[i].reason,
          seen: messagesInDialog[i].seen,
          createdAt: messagesInDialog[i].createdAt,
          updatedAt: messagesInDialog[i].updatedAt,
          _id: messagesInDialog[i]._id,
        });
      }

      currentDate = messageDate;
    }

    return result;
  }, [messages, dialogId]);
};

export default useDisplayedMessages;
