import { Avatar, Button, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UserCardProps {
  avatarUrl: string;
  handleOpenModal: () => void;
  editUser: () => void;
  disabledBtn: boolean;
  firstName: string;
  lastName: string;
  clientLang: string;
  onChangeClientLang: (newLang: string) => void;
  handleChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeLastName: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UserCard: FC<UserCardProps> = ({
  avatarUrl,
  editUser,
  handleOpenModal,
  disabledBtn,
  firstName,
  lastName,
  handleChangeName,
  handleChangeLastName,
  clientLang,
  onChangeClientLang,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        width: '310px',
        backgroundColor: 'grey.14',
        borderRadius: '24px',
        border: '1px solid',
        borderColor: 'grey.10',
        gap: '16px',
        maxHeight: '70vh',
        padding: '22px 20px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '130%',
          color: 'grey.1',
        }}
      >
        {t('user.account')}
      </Typography>
      <Stack alignItems="center">
        <Avatar
          sx={{
            backgroundColor: 'red.2',
            color: 'grey.1',
            width: '90px',
            height: '90px',
          }}
          src={avatarUrl}
        />
      </Stack>
      <Stack gap="10px">
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'green.2',
            border: 'none',
            borderRadius: '6px',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '130%',
            alignItems: 'center',
            textAlign: 'center',
            color: 'grey.15',
            textTransform: 'none',
            paddingY: '10px',
            '&:hover': { backgroundColor: 'green.3' },
          }}
          onClick={handleOpenModal}
        >
          API key
        </Button>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="20px"
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100px',
              padding: '10px',
              border: '1px solid',
              borderColor: clientLang === 'en' ? 'blue.2' : 'transparent',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onChangeClientLang('en');
            }}
          >
            🇺🇸
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100px',
              padding: '10px',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: clientLang === 'uk' ? 'blue.2' : 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => {
              onChangeClientLang('uk');
            }}
          >
            🇺🇦
          </Stack>
        </Stack>

        <TextField
          value={firstName}
          onChange={handleChangeName}
          placeholder={''}
        />
        <TextField
          value={lastName}
          onChange={handleChangeLastName}
          placeholder={''}
        />
      </Stack>
      <Button
        onClick={editUser}
        variant="contained"
        sx={{
          backgroundColor: 'blue.2',
          color: 'grey.1',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '130%',
          height: '58px',
          textTransform: 'none',
          minHeight: '58px',
          '&:hover': { backgroundColor: 'blue.3' },
        }}
        disabled={disabledBtn}
      >
        {t('user.saveChanges')}
      </Button>
    </Stack>
  );
};

export default UserCard;
