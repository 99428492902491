import { Stack } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';

import CurrentDialog from './dialogs/current-dialog';
import DialogsList from './dialogs/dialogs.list';
import { MessengerContext, TelegramDialog } from './messenger.context';

interface DialogsTabProps {
  readDialogs: boolean;
}

const DialogsTab: FC<DialogsTabProps> = ({ readDialogs }) => {
  const { readDialog, dialogs } = useContext(MessengerContext);

  const [currentDialog, setCurrentDialog] = useState<TelegramDialog | null>(
    null,
  );

  useEffect(() => {
    if (
      readDialogs &&
      currentDialog &&
      currentDialog.notSeenMessagesCount > 0
    ) {
      readDialog(currentDialog._id);
    }
  }, [currentDialog, dialogs]);

  const onSelectDialog = (dialog: TelegramDialog) => setCurrentDialog(dialog);

  useEffect(() => {
    if (!currentDialog) return;
    if (!dialogs) return;
    setCurrentDialog((prev) => {
      const newDialog = (dialogs as TelegramDialog[]).find(
        (dialog: TelegramDialog) => dialog._id === prev?._id,
      );
      return newDialog ?? prev;
    });
  }, [dialogs]);

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        sx={{
          width: '240px',
          height: '100vh',
          minWidth: '240px',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: 'grey.10',
          backgroundColor: 'grey.15',
        }}
      >
        <DialogsList
          currentDialog={currentDialog}
          onSelectDialog={onSelectDialog}
        />
      </Stack>
      {currentDialog && (
        <CurrentDialog dialog={currentDialog} readDialogs={readDialogs} />
      )}
    </Stack>
  );
};

export default DialogsTab;
