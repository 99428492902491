import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { Stack } from '@mui/material';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

const WAVE_MAX_LENGTH = 40;

function convertStringToWaveHeight(input: string, length: number): number[] {
  const start = input.length - length - 4;

  const cutString = input.slice(start, -4);

  return cutString.split('').map((char) => (char.charCodeAt(0) % 10) + 1);
}

const AudioWave = ({
  wavesCount,
  url,
}: {
  wavesCount: number;
  url: string;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap="2px">
      {[...new Array(wavesCount)].map((_, i) => (
        <Stack
          key={url + 'wave' + i}
          sx={{
            width: '2px',
            height: convertStringToWaveHeight(url, wavesCount)[i] * 2 + 'px',
            backgroundColor: 'grey.2',
            borderRadius: '3px',
          }}
        />
      ))}
    </Stack>
  );
};

interface VoicePreviewProps {
  voiceUrl: string;
}

const VoicePreview: FC<VoicePreviewProps> = ({ voiceUrl }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [wavesCount, setWavesCount] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const play = (e: SyntheticEvent) => {
    if (!audio) return;
    e.stopPropagation();

    audio.play();
    setIsPlaying(true);
  };

  const pause = (e: SyntheticEvent) => {
    if (!audio) return;
    e.stopPropagation();

    audio.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    const voiceAudio = new Audio(voiceUrl);

    voiceAudio.load();

    voiceAudio.onloadedmetadata = function () {
      setAudio(voiceAudio);

      const durationX10 = Math.floor(voiceAudio.duration ?? 0) * 10;

      setWavesCount(
        durationX10 > WAVE_MAX_LENGTH ? WAVE_MAX_LENGTH : durationX10,
      );
    };

    voiceAudio.ontimeupdate = () => {
      //
    };

    voiceAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [voiceUrl]);

  return (
    <Stack direction="row" gap="5px">
      <Stack>
        {!isPlaying && (
          <PlayCircleRoundedIcon
            sx={{
              fontSize: '32px',
              cursor: 'pointer',
            }}
            onClick={play}
          />
        )}

        {isPlaying && (
          <PauseCircleFilledRoundedIcon
            sx={{
              fontSize: '32px',
              cursor: 'pointer',
            }}
            onClick={pause}
          />
        )}
      </Stack>

      <AudioWave wavesCount={wavesCount} url={voiceUrl} />
    </Stack>
  );
};

export default VoicePreview;
