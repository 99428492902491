import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectNotFound = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 70px)',
      }}
    >
      {t('projects.notFound')}
    </Stack>
  );
};

export default ProjectNotFound;
