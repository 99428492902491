import TelegramIcon from '@mui/icons-material/Telegram';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const authBotUsername = process.env.REACT_APP_TELEGRAM_AUTH_BOT_USERNAME;

interface TelegramLoginButtonProps {
  onClickCallback: () => void;
}

const TelegramLoginButton: FC<TelegramLoginButtonProps> = ({
  onClickCallback,
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`https://t.me/${authBotUsername}?start=login`}
      target="_blank"
      style={{
        display: 'inline-block',
        textDecoration: 'none',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="16px"
        sx={{
          width: '367px',
          padding: '20px 30px',
          backgroundColor: 'blue.2',
          borderRadius: '100px',
          textDecoration: 'none',
          textAlign: 'center',
        }}
        onClick={onClickCallback}
      >
        <TelegramIcon
          sx={{
            color: 'grey.1',
            fontSize: '38px',
          }}
        />
        <Typography
          sx={{
            color: 'grey.1',
            fontSize: '22px',
            fontWeight: 600,
          }}
        >
          {t('login.button')}
        </Typography>
      </Stack>
    </Link>
  );
};

export default TelegramLoginButton;
