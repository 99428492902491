import { keyframes } from '@mui/material';

const pulsingAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  100% {
    transform: scale(1.05);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export default pulsingAnimation;
