import React from 'react';
import { Node } from 'reactflow';

import { IConditionsNodeData } from '../flow-nodes/conditions.node';
import { ITelegramMessageNodeData } from '../flow-nodes/telegram-message-node';
import { FlowNodeTypes, NodeData } from '../interfaces';

import updateActionButtons from './updateActionButtons';
import updateConditionsOnConnect from './updateConditionsOnConnect';

const useDeleteEdge = (
  setNodes: React.Dispatch<
    React.SetStateAction<Node<NodeData, string | undefined>[]>
  >,
) => {
  return (id: string) => {
    const [type, nodeId, primeIndex, subIndex] = id?.split('-') ?? [];

    const isAction = type === 'action';
    const isCondition = type === 'condition';

    setNodes((prev) => {
      const result = [];

      for (const node of prev) {
        switch (node.type) {
          case FlowNodeTypes.telegramMessage: {
            if (isAction) {
              if (node.id !== nodeId) {
                result.push(node as Node);
              } else {
                const newButtons = updateActionButtons(
                  (node.data as ITelegramMessageNodeData).data.buttons,
                  parseInt(primeIndex),
                  parseInt(subIndex),
                );

                result.push({
                  ...node,
                  data: {
                    ...node.data,
                    data: {
                      ...(node.data as ITelegramMessageNodeData).data,
                      buttons: newButtons,
                    },
                  },
                });
              }

              continue;
            }

            if (node.data._id === nodeId) {
              result.push({
                ...node,
                data: {
                  ...node.data,
                  next: null,
                },
              });
            } else {
              result.push(node);
            }

            break;
          }
          case FlowNodeTypes.actions:
          case FlowNodeTypes.start:
          case FlowNodeTypes.chatAction:
          case FlowNodeTypes.menu:
          case FlowNodeTypes.delay: {
            if (node.data._id === nodeId) {
              result.push({
                ...node,
                data: {
                  ...node.data,
                  next: null,
                },
              });
            } else {
              result.push(node);
            }
            break;
          }
          case FlowNodeTypes.conditions: {
            if (isCondition) {
              if (node.id !== nodeId) {
                result.push(node as Node);
              } else {
                const newConditions = updateConditionsOnConnect(
                  (node.data as IConditionsNodeData).data.conditions,
                  parseInt(primeIndex),
                );

                result.push({
                  ...node,
                  data: {
                    ...node.data,
                    data: {
                      ...(node.data as IConditionsNodeData).data,
                      conditions: newConditions,
                    },
                  },
                });
              }

              continue;
            }

            if (node.data._id === nodeId) {
              result.push({
                ...node,
                data: {
                  ...node.data,
                  next: null,
                },
              });
            } else {
              result.push(node);
            }

            break;
          }

          default: {
            result.push(node);
          }
        }
      }

      return result;
    });
  };
};

export default useDeleteEdge;
