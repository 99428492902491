import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createProject from '../api/projects/create-project';
import ColorPicker from '../common/color-picker';
import { GlobalContext } from '../GlobalContext';

const MAX_NAME_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 30;

const NewProjectModal = () => {
  const { refetchProjects, closeNewProjectModal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');

  const createButtonDisabled = Boolean(!name);

  const onCreateClick = () => {
    createProject({ name, description, color, webhookUrl }).then(
      refetchProjects,
    );
    setColor('');
    setWebhookUrl('');
    setName('');
    setDescription('');

    closeNewProjectModal();
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onDescriptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(value);
    }
  };

  const onWebhookInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setWebhookUrl(value);
  };

  return (
    <Stack>
      <Typography>{t('projects.newProjectModalTitle')}</Typography>

      <Typography
        sx={{
          mt: '30px',
        }}
      >
        {t('common.nameLabel')}
      </Typography>
      <TextField value={name} onChange={onNameInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('common.descriptionLabel')}
      </Typography>
      <TextField value={description} onChange={onDescriptionInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        Webhook URL
      </Typography>
      <TextField value={webhookUrl} onChange={onWebhookInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('common.colorLabel')}
      </Typography>
      <ColorPicker color={color} setColor={setColor} />

      <Divider sx={{ margin: '20px 0' }} />
      <Button
        variant="contained"
        disabled={createButtonDisabled}
        sx={{
          backgroundColor: 'blue.2',
          color: 'grey.1',
        }}
        onClick={onCreateClick}
      >
        {t('common.create')}
      </Button>
    </Stack>
  );
};

export default NewProjectModal;
