import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFlowFolder } from './interfaces';

interface DeleteFolderModalProps {
  folder: IFlowFolder | null;
  onDeleteFolder: (checked: boolean) => void;
  onCloseModalFolder: () => void;
}

const DeleteFolderModal: FC<DeleteFolderModalProps> = ({
  folder,
  onDeleteFolder,
  onCloseModalFolder,
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: '24px',
        }}
      >
        {t('flow.folders.deleteFolder')} {folder?.name}?
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={t('flow.folders.folderCheckbox')}
      />
      <Stack flexDirection="row" gap="20px" sx={{ width: '100%' }}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: 'blue.2',
            color: 'grey.1',
            mt: '20px',
          }}
          type="submit"
          onClick={onCloseModalFolder}
        >
          {t('common.cancel')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: 'red.2',
            color: 'grey.1',
            mt: '20px',
            '&:hover': { backgroundColor: 'red.3' },
          }}
          type="submit"
          onClick={() => onDeleteFolder(checked)}
        >
          {t('common.delete')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteFolderModal;
