import { CreateProject } from '../../projects/project.interface';
import axios from '../axios';

const createProject = async (newProject: CreateProject) => {
  const { data } = await axios.post('/projects', newProject);

  return data;
};

export default createProject;
