import { Stack } from '@mui/material';
import React, { useContext, useState } from 'react';

import Logo from '../common/logo';
import NavigateToDefault from '../router/NavigateToDefault';
import { UserContext } from '../user/user.context';

import LoginCodeForm from './login-code.form';
import TelegramLoginButton from './telegram-login.button';

const LoginPage = () => {
  const { user } = useContext(UserContext);

  const [clickedLoginButton, setClickedLoginButton] = useState<boolean>(false);

  const clickLoginCallback = () => setClickedLoginButton(true);

  if (user) return <NavigateToDefault />;

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo
        width={416}
        alignSubtitle="center"
        onClick={() => {
          //
        }}
      />

      {!clickedLoginButton && (
        <Stack
          sx={{
            mt: '60px',
          }}
        >
          <TelegramLoginButton onClickCallback={clickLoginCallback} />
        </Stack>
      )}

      {clickedLoginButton && <LoginCodeForm />}

      <Stack
        sx={{
          position: 'absolute',
          width: '157px',
          height: '291px',
          bottom: '-100px',
          left: '-5%',
          backgroundColor: 'blue.2',
          borderRadius: '50%',
          filter: 'blur(246px)',
        }}
      />
      <Stack
        sx={{
          position: 'absolute',
          width: '157px',
          height: '291px',
          top: '-10px',
          right: '-5%',
          backgroundColor: 'green.2',
          borderRadius: '50%',
          filter: 'blur(246px)',
        }}
      />
    </Stack>
  );
};

export default LoginPage;
