import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateNewBroadcastButtonProps {
  onClick: () => void;
}

const CreateNewBroadcastButton: FC<CreateNewBroadcastButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      role="button"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      sx={{
        width: '330px',
        height: '158px',
        borderRadius: '12px',
        backgroundColor: 'blue.2',
        cursor: 'pointer',
      }}
    >
      <AddRoundedIcon
        sx={{
          fontSize: '32px',
        }}
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          mt: '6px',
        }}
      >
        {t('broadcast.createBroadCast')}
      </Typography>
    </Stack>
  );
};

export default CreateNewBroadcastButton;
