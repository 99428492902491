import { Stack } from '@mui/material';
import React, { FC } from 'react';

import { IMedia, MediaType } from '../../interfaces/common';

import PhotoItem from './photo.item';
import VideoItem from './video.item';

interface MediaContentProps {
  media: IMedia[];
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
}

const MediaContent: FC<MediaContentProps> = ({
  media,
  selectedMedia,
  onSelectMedia,
}) => {
  return (
    <Stack>
      <Stack
        direction="row"
        gap="10px"
        sx={{
          flexWrap: 'wrap',
          mb: '20px',
        }}
      >
        {media.map((media) => {
          const selectedIds = selectedMedia.map(({ _id }) => _id);
          const isSelected = selectedIds.includes(media._id);

          const number = selectedIds.indexOf(media._id) + 1;

          switch (media.type) {
            case MediaType.photo: {
              return (
                <PhotoItem
                  media={media}
                  isSelected={isSelected}
                  onSelectMedia={onSelectMedia}
                  number={number}
                />
              );
            }

            case MediaType.video: {
              return (
                <VideoItem
                  media={media}
                  isSelected={isSelected}
                  onSelectMedia={onSelectMedia}
                  number={number}
                />
              );
            }
          }
        })}
      </Stack>
    </Stack>
  );
};

export default MediaContent;
