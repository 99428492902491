import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplyIcon from '@mui/icons-material/Reply';
import { IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormattedText from '../../common/formatted-text';
import VoicePreview from '../../common/media/voice.preview';
import TelegramButtons from '../../common/telegram/telegram.buttons';
import {
  TelegramButton,
  TelegramMessageDirection,
} from '../../interfaces/common';

const getMessageStyle = (direction: TelegramMessageDirection) => {
  switch (direction) {
    case TelegramMessageDirection.income: {
      return {
        align: 'start',
        timeOffset: {
          bottom: '1px',
          right: '5px',
        },
        backgroundColor: 'grey.14',
        padding: '10px 20px 20px 10px',
      };
    }
    case TelegramMessageDirection.outcome: {
      return {
        align: 'end',
        backgroundColor: 'blue.4',
        timeOffset: {
          bottom: '1px',
          right: '5px',
        },
        padding: '10px 20px 20px 10px',
      };
    }

    default: {
      return {
        align: 'center',
        timeOffset: {
          bottom: '10px',
          right: '-45px',
        },
        padding: '10px',
      };
    }
  }
};

interface VoiceMessageProps {
  direction: TelegramMessageDirection;
  voiceUrl: string;
  date: Date;
  text: string;
  buttons: TelegramButton[][];
  edited: boolean;
  deleted: boolean | undefined;
  replyText: string;
  translation: string;
  replyMedia: string;
  openContextMenu: (e: React.MouseEvent) => void;
}

const VoiceMessage: FC<VoiceMessageProps> = ({
  voiceUrl,
  date,
  direction,
  buttons,
  text,
  edited,
  deleted,
  replyText,
  replyMedia,
  translation,
  openContextMenu,
}) => {
  const { t } = useTranslation();
  const { align, backgroundColor } = getMessageStyle(direction);

  const displayButtons = Boolean(buttons?.length);

  return (
    <Stack
      alignSelf={align}
      direction="row"
      alignItems="center"
      gap="12px"
      onContextMenu={openContextMenu}
      sx={{
        maxWidth: '340px',
        minWidth: displayButtons ? '340px' : '0',
      }}
    >
      {!deleted && (
        <IconButton
          onClick={openContextMenu}
          sx={{
            order: direction === TelegramMessageDirection.outcome ? '1' : '2',
            transform: 'rotate(90deg)',
            color: 'grey.5',
            alignItems: 'start',
            height: 'fit-content',
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <Stack
        direction="row"
        gap="5px"
        sx={{
          width: '100%',
          order: direction === TelegramMessageDirection.outcome ? '2' : '1',
          position: 'relative',
          '&:hover': {
            '.editIcon': {
              opacity: 1,
            },
          },
        }}
      >
        {Boolean(translation) && (
          <Stack
            className="controlIcon"
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer',
              transition: 'opacity 0.1s ease',
              backgroundColor: deleted ? 'red.1' : backgroundColor,
              borderRadius: '6px',
              zIndex: 1,
              border:
                direction !== TelegramMessageDirection.outcome
                  ? '1px solid'
                  : 'none',
              borderColor:
                direction !== TelegramMessageDirection.outcome
                  ? 'grey.15'
                  : 'none',
              padding: '10px',
            }}
          >
            <FormattedText text={translation} />
          </Stack>
        )}
        <Stack
          sx={{
            borderRadius: '6px',
            minWidth: '100px',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Stack
            sx={{
              backgroundColor: deleted ? 'red.1' : backgroundColor,
              padding: '10px',
            }}
          >
            {replyText && (
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                sx={{
                  p: '4px',
                  m: '2px',
                  borderRadius: '12px',
                  borderTop: '1px solid',
                  borderColor: 'blue.2',
                  backgroundColor: 'blue.1',
                }}
              >
                <ReplyIcon
                  sx={{
                    fontSize: '18px',
                    mt: '-5px',
                  }}
                />
                <Typography>{replyText}</Typography>
              </Stack>
            )}
            {replyMedia && (
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                sx={{
                  p: '4px',
                  m: '4px',
                  borderRadius: '12px',
                  borderTop: '1px solid',
                  borderColor: 'blue.2',
                  backgroundColor: 'blue.1',
                }}
              >
                <ReplyIcon
                  sx={{
                    fontSize: '18px',
                    mt: '-5px',
                  }}
                />
                <img
                  src={replyMedia}
                  style={{ width: '25px', height: '25px' }}
                  alt="alt"
                />
              </Stack>
            )}
            <VoicePreview voiceUrl={voiceUrl} />
            {Boolean(text) && <FormattedText text={text} />}
            <Typography
              sx={{
                fontSize: '12px',
                color: 'grey.5',
                textAlign: 'right',
                ':hover': {
                  opacity: 1,
                },
              }}
            >
              {(edited || deleted) && (
                <Typography
                  component="span"
                  sx={{
                    color: 'grey.5',
                    fontSize: '10px',
                    fontWeight: '500',
                    lineHeight: '130%',
                  }}
                >
                  {deleted ? t('common.deleted') : t('common.edited')}
                </Typography>
              )}
              {dayjs(date).format('HH:mm')}
            </Typography>
          </Stack>

          {displayButtons && (
            <Stack
              sx={{
                mt: '5px',
              }}
            >
              <TelegramButtons buttons={buttons} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VoiceMessage;
