import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, IconButton, Stack, TextField } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface InputChipProps {
  chips: string[];
  handleChipsChange: (newValue: string[]) => void;
  onDeleteIconClick?: () => void;
}

const InputChip: FC<InputChipProps> = ({
  chips,
  handleChipsChange,
  onDeleteIconClick,
}) => {
  const { t } = useTranslation();

  const [textFieldValue, setTextFieldValue] = useState('');

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTextFieldValue(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    const { key } = e;
    if (!value && key === 'Backspace') {
      handleChipsChange(chips.slice(0, -1));
    }
    if (value && !chips?.includes(value) && key === 'Enter') {
      handleChipsChange([...chips, value.trim()]);
      setTextFieldValue('');
    }
  };

  const onBlur = () => {
    if (textFieldValue && !chips?.includes(textFieldValue)) {
      handleChipsChange([...chips, textFieldValue]);
      setTextFieldValue('');
    }
  };

  const handleChipDelete = (deletedValue: string) => {
    handleChipsChange(chips.filter((value) => value !== deletedValue));
  };
  const handleClearIconClick = () => {
    if (onDeleteIconClick) {
      onDeleteIconClick();
      return;
    }

    handleChipsChange([]);
    setTextFieldValue('');
  };
  const handleAddChip = () => {
    if (chips.includes(textFieldValue)) return;
    handleChipsChange([...chips, textFieldValue]);
    setTextFieldValue('');
  };

  return (
    <Stack flexDirection="row" alignItems="center">
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        gap={'10px'}
        sx={{
          backgroundColor: 'grey.15',
          border: '1px solid',
          borderColor: 'grey.10',
          borderRadius: '6px',
          width: '100%',
          margin: '0 auto',
          padding: '8px 10px',
          position: 'relative',
        }}
      >
        {chips.map((chip) => (
          <Chip
            key={chip}
            sx={{
              width: 'fit-content',
              backgroundColor: 'blue.1',
              border: '1px solid',
              borderColor: 'blue.2',
              borderRadius: '45px',
            }}
            variant="outlined"
            label={chip}
            onDelete={() => handleChipDelete(chip)}
          />
        ))}
        <TextField
          sx={{
            width: 'auto',
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0',
            flexGrow: '1',
            '& .MuiInputBase-root': {
              backgroundColor: 'transparent',
              border: 'none',
              ':before': {
                border: 'none!important',
                backgroundColor: 'transparent',
              },
              ':active': {
                border: 'none!important',
                backgroundColor: 'transparent',
              },
              ':focus': {
                border: 'none!important',
                backgroundColor: 'transparent',
              },
              ':after': {
                border: 'none!important',
                backgroundColor: 'transparent',
              },
              ':hover': {
                border: 'none!important',
                backgroundColor: 'transparent',
              },
            },
          }}
          variant={'standard'}
          placeholder={t('common.input.inputChip')}
          value={textFieldValue}
          onChange={handleChangeTextField}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
        />
        <Stack
          flexDirection="row"
          sx={{ position: 'absolute', top: 'calc(50% - 18px)', right: '10px' }}
        >
          <IconButton
            disabled={!textFieldValue || chips.includes(textFieldValue)}
            onClick={handleAddChip}
            edge="end"
          >
            <AddIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: ' 6px',
          width: '10%',
          height: '50px',
          ml: '8px',
          cursor: 'pointer',
          ':hover *': {
            color: 'red.2',
          },
        }}
        onClick={handleClearIconClick}
      >
        <DeleteIcon sx={{ color: 'red.3', padding: '0', margin: '0' }} />
      </Stack>
    </Stack>
  );
};
export default InputChip;
