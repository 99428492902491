import React from 'react';
import { Node } from 'reactflow';

import { IConditionsNodeData } from '../flow-nodes/conditions.node';
import { ITelegramMessageNodeData } from '../flow-nodes/telegram-message-node';
import { NodeData } from '../interfaces';

import updateActionButtons from './updateActionButtons';
import updateConditionsOnConnect from './updateConditionsOnConnect';

const UseUpdateNodes = (
  setNodes: React.Dispatch<
    React.SetStateAction<Node<NodeData, string | undefined>[]>
  >,
  sourceHandle: string | null,
  target: string | null,
) => {
  return setNodes((prev) => {
    const result = [];

    const split = sourceHandle?.split('-');

    let isAction = false;
    let isCondition = false;
    let isStart = false;

    const [handleType, source, primeIndex, subIndex] = split ?? [];

    if (split) {
      isAction = Boolean(handleType === 'action');
      isStart = Boolean(handleType === 'start');
      isCondition = Boolean(handleType === 'condition');
    }
    for (const node of prev) {
      if (isAction) {
        if (node.id !== source) {
          result.push(node as Node);
        } else {
          const newButtons = updateActionButtons(
            (node.data as ITelegramMessageNodeData).data.buttons,
            parseInt(primeIndex),
            parseInt(subIndex),
            target ?? '',
          );
          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                ...(node.data as ITelegramMessageNodeData).data,
                buttons: newButtons,
              },
            },
          });
        }

        continue;
      }

      if (isCondition) {
        if (node.id !== source) {
          result.push(node as Node);
        } else {
          const newConditions = updateConditionsOnConnect(
            (node.data as IConditionsNodeData).data.conditions,
            parseInt(primeIndex),
            target ?? '',
          );

          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                ...(node.data as IConditionsNodeData).data,
                conditions: newConditions,
              },
            },
          });
        }

        continue;
      }
      if (isStart) {
        if (node.id !== 'start_node') {
          result.push(node as Node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              next: target,
            },
          });
        }

        continue;
      }

      if (node.id !== source) {
        result.push(node);
      } else {
        result.push({
          ...node,
          data: {
            ...node.data,
            next: target,
          },
        });
      }
    }

    return result;
  });
};

export default UseUpdateNodes;
