import { Box, Chip, FormControl, MenuItem, Select, Stack } from '@mui/material';
import React, { FC } from 'react';

import { ITag } from '../../interfaces/common';

interface TagSearchDialogProps {
  allTags: ITag[];
  selectedTagIds: ITag['_id'][];
  handleSelectTag: (newTag: ITag['_id']) => void;
  handleDeleteTag: (tagToDelete: ITag['_id']) => void;
}

const TagsFilter: FC<TagSearchDialogProps> = ({
  allTags,
  selectedTagIds,
  handleDeleteTag,
  handleSelectTag,
}) => {
  const isSelected = ({ _id }: ITag) => selectedTagIds.includes(_id);

  const selectedTags = allTags.filter(isSelected);

  return (
    <Stack sx={{ mt: '10px' }}>
      <FormControl
        sx={{
          '& fieldset': {
            borderRadius: '6px',
            border: '1px solid',
            borderColor: '#751DCD!important',
            backgroundColor: 'purple.2',
          },
          '& svg': {
            zIndex: '1',
          },
        }}
      >
        <Select
          value={selectedTagIds}
          onChange={(e) => {
            handleSelectTag(e.target.value as string);
          }}
          renderValue={() => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  '& .MuiMenu-list': {
                    backgroundColor: 'purple.4',
                  },
                  '.MuiMenu-paper &': {
                    border: 'none',
                  },
                }}
              >
                {selectedTags.map(({ text, _id }) => (
                  <Chip
                    sx={{ backgroundColor: 'purple.4', zIndex: '1' }}
                    key={_id}
                    label={text}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    onDelete={() => handleDeleteTag(_id)}
                  />
                ))}
              </Box>
            );
          }}
        >
          {allTags
            .filter((tag) => !isSelected(tag))
            .map((tag) => (
              <MenuItem
                sx={{
                  justifyContent: 'center',
                  color: 'grey.5',
                  backgroundColor: '#631BAB!important',
                  '&:hover': { backgroundColor: 'purple.3', outline: 'none' },
                  '&:active': { backgroundColor: 'purple.3', outline: 'none' },
                  '&:focus-visible': {
                    backgroundColor: 'purple.2',
                    outline: 'none',
                  },
                }}
                key={tag._id}
                value={tag._id}
              >
                {tag.text}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default TagsFilter;
