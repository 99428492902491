import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const getDialogsDb = async (botId: ITelegramBot['_id']) => {
  const response = await axios.get(`/telegram-bots/${botId}/db`, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'users-db.csv'); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export default getDialogsDb;
