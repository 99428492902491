import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ChannelCard from './channel.card';
import { ITelegramChannelWithActivators } from './interfaces';

interface ChannelsListProps {
  channels: ITelegramChannelWithActivators[];
  onSelect: (channel: ITelegramChannelWithActivators) => void;
}

const ChannelsList: FC<ChannelsListProps> = ({ channels, onSelect }) => {
  const { t } = useTranslation();

  if (!channels.length) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
        }}
        justifyContent="center"
        alignItems="center"
        gap="18px"
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {t('channels.channelList.noChannels')}
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            color: 'grey.5',
            textAlign: 'center',
            maxWidth: '371px',
          }}
        >
          1. {t('channels.empty.firstStep')}
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            color: 'grey.5',
            textAlign: 'center',
            maxWidth: '371px',
          }}
        >
          2. {t('channels.empty.secondStep')}
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            color: 'grey.5',
            textAlign: 'center',
            maxWidth: '420px',
          }}
        >
          3. {t('channels.empty.thirdStep')}
        </Typography>
        <Stack>
          <Button
            disabled
            variant="contained"
            sx={{
              width: '316px',
              height: '57px',
              borderRadius: '12px',
              border: ' 1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.1',
              color: 'blue.2',
              fontSize: '18px',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'blue.2',
                color: 'blue.1',
              },
              '&:disabled': {
                borderColor: 'grey.10',
              },
            }}
          >
            {t('channels.empty.viewDocumentation')}
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="12px" flexWrap="wrap">
      {channels.map((channel) => (
        <ChannelCard
          key={channel._id}
          channel={channel}
          onClick={() => {
            onSelect(channel);
          }}
        />
      ))}
    </Stack>
  );
};

export default ChannelsList;
