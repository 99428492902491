import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';

import { IMedia, TelegramMessageDirection } from '../../interfaces/common';

const getMessageStyle = (direction: TelegramMessageDirection) => {
  switch (direction) {
    case TelegramMessageDirection.income: {
      return {
        align: 'start',
        timeOffset: {
          bottom: '1px',
          right: '5px',
        },
        backgroundColor: 'transparent',
        padding: '10px 20px 20px 10px',
      };
    }
    case TelegramMessageDirection.outcome: {
      return {
        align: 'end',
        backgroundColor: 'transparent',
        timeOffset: {
          bottom: '1px',
          right: '5px',
        },
        padding: '10px 20px 20px 10px',
      };
    }

    default: {
      return {
        align: 'center',
        timeOffset: {
          bottom: '10px',
          right: '-45px',
        },
        padding: '10px',
      };
    }
  }
};

interface StickerMessageComponentProps {
  direction: TelegramMessageDirection;
  media: IMedia[];
  date: Date;
  telegramId: number;
  text?: string | null;
}

const StickerMessageComponent: FC<StickerMessageComponentProps> = ({
  media,
  date,
  text = null,
  telegramId,
  direction,
}) => {
  const [animationData, setAnimationData] = useState();

  const { align, timeOffset, padding, backgroundColor } =
    getMessageStyle(direction);

  const getFileExtension = (url: string) => {
    const parts = url.split('.');
    return parts.length > 1 ? parts?.pop()?.toLowerCase() : '';
  };
  const fileType = getFileExtension(media[0]?.url ?? '');

  useEffect(() => {
    if (fileType === 'json') {
      axios
        .get(media[0]?.url, { responseType: 'json' })
        .then((res) => setAnimationData(res.data));
    }
  }, [fileType]);
  return (
    <Stack
      alignSelf={align}
      gap="12px"
      sx={{
        maxWidth: '150px',
        maxHeight: '150px',
      }}
    >
      <Stack>
        <Stack
          sx={{
            padding: media.length > 0 ? '0' : padding,
            backgroundColor,
            border: media.length > 0 ? 'none' : '1px solid',

            minWidth: '100px',
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              '.editIcon': {
                opacity: 1,
              },
            },
          }}
        >
          <Typography
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
              position: 'absolute',
              width: 'max-content',
              height: 'max-content',
              bottom: timeOffset.bottom,
              right: timeOffset.right,
              fontSize: '12px',
              color: 'grey.5',
              opacity: media?.length > 1 ? 0 : 1,
              ':hover': {
                opacity: 1,
              },
            }}
          >
            {dayjs(date).format('HH:mm')}
          </Typography>
          {Boolean(telegramId) &&
            Boolean(direction === TelegramMessageDirection.outcome) && (
              <EditRoundedIcon
                className="editIcon"
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '14px',
                  opacity: 0,
                  cursor: 'pointer',
                  transition: 'opacity 0.1s ease',
                }}
              />
            )}
          <Stack sx={{ height: 'auto', width: '100%' }}>
            {fileType === 'webm' && (
              <video
                style={{ height: 'auto', width: '100%' }}
                autoPlay
                loop
                muted
                preload="auto"
              >
                <source src={media[0]?.url} type="video/mp4" />
              </video>
            )}
            {fileType === 'webp' && (
              <img
                style={{ height: 'auto', width: '100%' }}
                src={media[0]?.url}
                alt=""
              />
            )}
            {fileType === 'json' && <Lottie animationData={animationData} />}
          </Stack>
          {!animationData && text && (
            <Stack>
              <Typography>{text}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StickerMessageComponent;
