export const convertNumberToMinutes = (number: number) => {
  const minutesInADay = 24 * 60;
  const minutesInAnHour = 60;

  const days = Math.floor(number / minutesInADay);
  const remainingMinutes = number % minutesInADay;
  const hours = Math.floor(remainingMinutes / minutesInAnHour);
  const minutes = remainingMinutes % minutesInAnHour;

  return { days, hours, minutes };
};
