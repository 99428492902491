import { IBroadcast } from '../../telegram-bots/broadcasts/interfaces';
import axios from '../axios';

const deleteBroadcast = async (
  telegramBotId: string,
  _id: IBroadcast['_id'],
) => {
  const { data } = await axios.delete(`/broadcasts/${telegramBotId}/${_id}`);

  return data;
};

export default deleteBroadcast;
