import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { alpha, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import FormattedText from '../../../common/formatted-text';
import PhotoPreview from '../../../common/media/photo.preview';
import VideoNotePreview from '../../../common/media/video-note.preview';
import VideoPreview from '../../../common/media/video.preview';
import VoicePreview from '../../../common/media/voice.preview';
import { TelegramUrlButton } from '../../../common/telegram/telegram.buttons';
import { darkThemeColors } from '../../../common/theme/dark.theme';
import {
  IMedia,
  MediaType,
  TelegramButton,
  TelegramButtonType,
  TelegramMessageType,
} from '../../../interfaces/common';
import truncate from '../../../utils/truncate';
import { ITelegramMessageFlowNodeDataWithMedia } from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface ITelegramMessageNodeData {
  onDelete: () => void;
  onSelect: (
    newText: string,
    newButtons: TelegramButton[][],
    newMedia: IMedia[],
    newType: TelegramMessageType,
  ) => void;
  onCopy: (
    buttons: TelegramButton[][],
    media: IMedia[],
    text: string,
    messageType: TelegramMessageType,
    position: { x: number; y: number },
  ) => void;
  exitEditor: () => void;
  data: ITelegramMessageFlowNodeDataWithMedia;
  next: string | null;
  position: { x: number; y: number };
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface TelegramMessageNodeProps extends Omit<NodeProps, 'data'> {
  data: ITelegramMessageNodeData;
}

const TelegramMessageNode: FC<TelegramMessageNodeProps> = memo(
  ({ data, xPos, yPos, selected }) => {
    useEffect(() => {
      data.onNodePositionChange(data._id, {
        x: xPos,
        y: yPos,
      });
    }, [xPos, yPos]);

    const { t } = useTranslation();

    const nodeRef = useRef<HTMLDivElement>(null);

    const [isNewNode, setIsNewNode] = useState(false);

    const message = data.data.text;
    const media = data.data.media;

    useEffect(() => {
      const digitsId = Number(data._id);

      setIsNewNode(!isNaN(digitsId));
    }, []);

    useEffect(() => {
      const node = nodeRef.current;
      const preventZoom = (event: WheelEvent) => event.stopPropagation();

      if (node) {
        node.addEventListener('wheel', preventZoom);
        return () => {
          node.removeEventListener('wheel', preventZoom);
        };
      }
    }, []);

    useEffect(() => {
      if (!selected) {
        data.exitEditor();
      }
    }, [selected]);

    const edit = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onSelect(
        data.data.text,
        data.data.buttons,
        data.data.media,
        data.data.telegramMessageType,
      );
    };
    return (
      <Stack
        sx={{
          position: 'relative',
        }}
        onDoubleClick={edit}
      >
        {selected && (
          <Stack
            direction="row"
            justifyContent="center"
            gap="10px"
            sx={{
              position: 'absolute',
              top: -50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={edit}
            >
              <ModeEditOutlineRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={() => {
                data.onCopy(
                  data.data.buttons,
                  data.data.media,
                  data.data.text,
                  data.data.telegramMessageType,
                  { x: data.position.x + 400, y: data.position.y },
                );
              }}
            >
              <ContentCopyRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={data.onDelete}
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'red.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <CloseRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
          </Stack>
        )}

        <Stack
          className="dragger"
          sx={{
            width: '340px',
            border: '1px solid',
            borderColor: selected ? 'blue.2' : 'grey.10',
            backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
            padding: '10px',
            borderRadius: '18px',
            cursor: 'default',
            animation: isNewNode
              ? `${pulsingAnimation} 0.5s linear infinite  alternate`
              : '',
            animationIterationCount: 6,
          }}
          ref={nodeRef}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={'edge-' + data._id}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              height: '100%',
              width: '100%',
              borderRadius: '0',
              zIndex: -1,
            }}
          />
          <Handle
            type="source"
            id={'src-' + data._id}
            position={Position.Right}
            style={{
              width: '20px',
              height: '20px',
              marginRight: '-5px',
              border: '4px solid',
              borderColor: darkThemeColors.green['2'],
            }}
          />

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {t('flowNodes.messageLabel')}
              </Typography>
              {!message.length && !media.length && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    backgroundColor: 'grey.12',
                    p: '5px',
                    mt: '10px',
                    borderRadius: '6px',
                  }}
                >
                  <ChatBubbleRoundedIcon
                    sx={{
                      fontSize: '28px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '22px',
                      fontStyle: 'italic',
                    }}
                  >
                    {t('flowNodes.messageEmpty')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          {Boolean(data.data.media.length) && (
            <Stack
              direction="row"
              gap="10px"
              sx={{
                mt: '10px',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                sx={{
                  maxWidth: '100%',
                  minHeight: '30px',
                  overflow: 'scroll',
                }}
              >
                {data.data.media.map(({ url, _id, type }) => (
                  <Stack key={_id}>
                    {type === MediaType.photo && (
                      <PhotoPreview
                        imageSrc={url}
                        imgStyle={{
                          width: '100px',
                          minWidth: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '9px',
                        }}
                      />
                    )}
                    {type === MediaType.video && (
                      <VideoPreview videoSrc={url} playerHeight={100} />
                    )}
                    {type === MediaType.voice && (
                      <VoicePreview voiceUrl={url} />
                    )}

                    {type === MediaType.videoNote && (
                      <VideoNotePreview
                        videoSrc={url}
                        playerHeight={100}
                        playerWidth={100}
                      />
                    )}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {Boolean(message) && (
            <Stack
              sx={{
                mt: '10px',
              }}
            >
              <FormattedText text={data.data.text} />
            </Stack>
          )}
          <Stack>
            <Stack gap="6px" flexDirection="column">
              <Stack spacing={1} width="100%">
                {data.data.buttons.map((buttonGroup, rowIndex) => (
                  <Stack
                    key={rowIndex}
                    direction="row"
                    spacing={1}
                    width="100%"
                  >
                    {buttonGroup.map((button, index) => {
                      if (button.type === TelegramButtonType.url) {
                        const isValid =
                          Boolean(button?.url) && Boolean(button?.label);
                        return (
                          <TelegramUrlButton
                            key={index}
                            button={button}
                            fontSize="12px"
                            isValid={isValid}
                          />
                        );
                      }

                      if (button.type === TelegramButtonType.action) {
                        const isValid = Boolean(button?.label);
                        return (
                          <Stack
                            key={button.label + index}
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width: '100%',
                              backgroundColor: isValid
                                ? `${alpha(darkThemeColors.blue['3'], 0.2)}`
                                : 'red.1',
                              fontSize: '12px',
                              padding: '5px',
                              borderRadius: '6px',
                              color: 'white',
                              textDecoration: 'none',
                              position: 'relative',
                            }}
                          >
                            {truncate(button.label, 13)}
                            <Stack
                              sx={{
                                top: 3,
                                right: 3,
                                position: 'absolute',
                              }}
                            >
                              <FlashOnRoundedIcon
                                sx={{
                                  fontSize: '10px',
                                }}
                              />
                            </Stack>
                            <Handle
                              type="source"
                              id={`action-${data._id}-${rowIndex}-${index}`}
                              position={Position.Right}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: '4px solid',
                                borderColor: darkThemeColors.green['2'],
                              }}
                            />
                          </Stack>
                        );
                      }
                    })}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

TelegramMessageNode.displayName = 'TelegramMessageNode';

export default TelegramMessageNode;
