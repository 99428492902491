import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectContext } from '../../projects/project.context';
import SelectChip from '../select-chip/select-chip';

import StatisticsLeads from './statistics-leads';

interface StatisticsContainerProps {
  onFetchStatistics: (
    params: string[],
    endDate: string,
    startDate: string,
    endDate2: string,
    startDate2: string,
  ) => void;
  statistics: (string | number)[][];
  loadingStatistics: boolean;
}

const StatisticsContainer: FC<StatisticsContainerProps> = ({
  onFetchStatistics,
  statistics,
  loadingStatistics,
}) => {
  const { t } = useTranslation();

  const periodState = [
    t('statistics.period.today'),
    t('statistics.period.yesterday'),
    t('statistics.period.thisWeek'),
    t('statistics.period.thisMonth'),
    t('statistics.period.allTime'),
    t('statistics.period.dateRange'),
  ];

  const periodState2 = [
    t('statistics.period.today'),
    t('statistics.period.yesterday'),
    t('statistics.period.thisWeek'),
    t('statistics.period.thisMonth'),
    t('statistics.period.dateRange'),
  ];

  const { projectParams } = useContext(ProjectContext);

  const [period, setPeriod] = useState<string>(t('statistics.period.today'));
  const [period2, setPeriod2] = useState<string>(t('statistics.period.today'));
  const [params, setParams] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [isSecondPeriod, setIsSecondPeriod] = useState<boolean>(false);
  const [startDate2, setStartDate2] = useState<dayjs.Dayjs | null>(null);
  const [endDate2, setEndDate2] = useState<dayjs.Dayjs | null>(null);

  const handleChangePeriod = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'period') {
      setPeriod(value);
    } else {
      setPeriod2(value);
    }
  };

  const handleChangeParam = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setParams((prevParams) => [...prevParams, value as string]);
  };

  const onRemoveParam = (value: string) => {
    setParams((prevState) => [
      ...prevState.filter((state) => !state.includes(value)),
    ]);
  };

  const handleChangeStartDate = (date: dayjs.Dayjs | null) => {
    setStartDate(dayjs(date).endOf('day') ?? dayjs(new Date()));
  };

  const handleChangeEndDate = (date: dayjs.Dayjs | null) => {
    setEndDate(dayjs(date) ?? dayjs(new Date()));
  };

  const handleChangeStartDate2 = (date: dayjs.Dayjs | null) => {
    setStartDate2(dayjs(date).endOf('day') ?? dayjs(new Date()));
  };

  const handleChangeEndDate2 = (date: dayjs.Dayjs | null) => {
    setEndDate2(dayjs(date) ?? dayjs(new Date()));
  };

  useEffect(() => {
    const currentDay = dayjs();
    switch (period) {
      case t('statistics.period.allTime'): {
        setStartDate(null);
        handleChangeEndDate(currentDay.endOf('day'));
        return;
      }
      case t('statistics.period.today'): {
        handleChangeStartDate(currentDay.endOf('day'));
        handleChangeEndDate(currentDay.endOf('day'));
        return;
      }
      case t('statistics.period.yesterday'): {
        const yesterdayDate = dayjs().subtract(1, 'day');
        handleChangeStartDate(yesterdayDate.endOf('day'));
        handleChangeEndDate(yesterdayDate.endOf('day'));
        return;
      }
      case t('statistics.period.thisWeek'): {
        const currentWeek = dayjs().startOf('week');
        handleChangeStartDate(currentWeek.add(1, 'day'));
        handleChangeEndDate(currentWeek.endOf('week').subtract(-1, 'day'));
        return;
      }
      case t('statistics.period.thisMonth'): {
        const currentMonth = dayjs().startOf('month');
        handleChangeStartDate(currentMonth);
        handleChangeEndDate(currentMonth.endOf('month'));
        return;
      }
      case t('statistics.period.dateRange'): {
        handleChangeStartDate(currentDay.endOf('day'));
        handleChangeEndDate(currentDay.endOf('day'));
        return;
      }
      default:
        handleChangeStartDate(currentDay.endOf('day'));
        handleChangeEndDate(currentDay.endOf('day'));
        return;
    }
  }, [period]);

  useEffect(() => {
    if (isSecondPeriod) {
      const currentDay = dayjs();
      switch (period2) {
        case t('statistics.period.allTime'): {
          setStartDate(null);
          handleChangeEndDate2(currentDay.endOf('day'));
          return;
        }
        case t('statistics.period.today'): {
          handleChangeStartDate2(currentDay.endOf('day'));
          handleChangeEndDate2(currentDay.endOf('day'));
          return;
        }
        case t('statistics.period.yesterday'): {
          const yesterdayDate = dayjs().subtract(1, 'day');
          handleChangeStartDate2(yesterdayDate.endOf('day'));
          handleChangeEndDate2(yesterdayDate.endOf('day'));
          return;
        }
        case t('statistics.period.thisWeek'): {
          const currentWeek = dayjs().startOf('week');
          handleChangeStartDate2(currentWeek.add(1, 'day'));
          handleChangeEndDate2(currentWeek.endOf('week').subtract(-1, 'day'));
          return;
        }
        case t('statistics.period.thisMonth'): {
          const currentMonth = dayjs().startOf('month');
          handleChangeStartDate2(currentMonth);
          handleChangeEndDate2(currentMonth.endOf('month'));
          return;
        }
        case t('statistics.period.dateRange'): {
          handleChangeStartDate2(currentDay.endOf('day'));
          handleChangeEndDate2(currentDay.endOf('day'));
          return;
        }
        default:
          handleChangeStartDate2(currentDay.endOf('day'));
          handleChangeEndDate2(currentDay.endOf('day'));
          return;
      }
    }
  }, [period2]);

  const onClickFetchStatistics = () => {
    const currentDay = dayjs();
    onFetchStatistics(
      params,
      endDate?.toISOString() ?? '',
      startDate ? startDate?.subtract(1, 'day').endOf('day').toISOString() : '',
      !isSecondPeriod
        ? ''
        : endDate2
        ? endDate2?.toISOString()
        : currentDay.endOf('day').toISOString(),
      !isSecondPeriod
        ? ''
        : startDate2
        ? startDate2?.subtract(1, 'day').endOf('day').toISOString()
        : currentDay.endOf('day').toISOString(),
    );
  };

  useEffect(() => {
    const currentDay = dayjs();
    onFetchStatistics(
      params,
      currentDay.endOf('day').toISOString() ?? '',
      currentDay?.subtract(1, 'day').endOf('day').toISOString(),
      isSecondPeriod ? currentDay.endOf('day').toISOString() : '',
      isSecondPeriod
        ? currentDay?.subtract(1, 'day').endOf('day').toISOString()
        : '',
    );
  }, []);

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.14',
        border: '1px solid ',
        borderColor: 'grey.10',
        borderRadius: '24px',
        width: '70vw',
        padding: '17px',
        height: 'max-content',
        margin: '0 auto 20px',
        position: 'relative',
      }}
    >
      <Stack flexDirection="row" alignItems="center" gap="12px">
        <Stack flexDirection="row" sx={{ maxWidth: '35%' }}>
          <Stack flexDirection="column" sx={{ width: '100%' }}>
            <Typography sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}>
              {t('statistics.filterBy')}:
            </Typography>
            <Stack flexDirection="row" gap="5px" flexWrap="wrap">
              <SelectChip
                projectParams={projectParams}
                selectedParams={params}
                onSelectParam={handleChangeParam}
                onRemoveParam={onRemoveParam}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          flexDirection={
            period2 === t('statistics.period.dateRange') ||
            period === t('statistics.period.dateRange')
              ? 'column'
              : 'row'
          }
          gap="15px"
        >
          <Stack>
            {period === t('statistics.period.dateRange') ? (
              <Stack
                flexDirection="row"
                gap="10px"
                alignItems={params.length > 2 ? 'start' : 'end'}
                sx={{ height: '100%' }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack>
                    <Typography
                      sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}
                    >
                      {t('statistics.startDate')}
                    </Typography>
                    <DatePicker
                      sx={{
                        height: '56px',
                        backgroundColor: 'grey.15',
                        '& .MuiInputBase-root': {
                          height: '100%',
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'grey.15',
                        },
                      }}
                      value={startDate ? dayjs(startDate) : null}
                      disableFuture
                      onChange={handleChangeStartDate}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}
                    >
                      {t('statistics.endDate')}
                    </Typography>
                    <DatePicker
                      sx={{
                        height: '56px',
                        backgroundColor: 'grey.15',
                        '& .MuiInputBase-root': {
                          height: '100%',
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'grey.15',
                        },
                      }}
                      value={dayjs(endDate)}
                      disableFuture
                      onChange={handleChangeEndDate}
                    />
                  </Stack>
                </LocalizationProvider>
                <IconButton
                  sx={{
                    mt: '35px',
                  }}
                  onClick={() => setPeriod(t('statistics.period.today'))}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack flexDirection="row" alignItems="center" gap="10px">
                <Stack>
                  <Typography
                    sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}
                  >
                    {t('statistics.periodLabel')}:
                  </Typography>
                  <Select
                    sx={{
                      width: '250px',
                      height: '56px',
                      backgroundColor: 'grey.15',
                      '& .MuiSelect-select': {
                        padding: '10px 5px',
                        backgroundColor: 'grey.15',
                      },
                    }}
                    value={period}
                    name="period"
                    onChange={handleChangePeriod}
                  >
                    {periodState.map((periodItem, index) => {
                      return (
                        <MenuItem key={index} value={periodItem}>
                          {periodItem}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Stack>
            )}
          </Stack>
          {isSecondPeriod && period2 === t('statistics.period.dateRange') ? (
            <Stack
              flexDirection="row"
              gap="10px"
              alignItems={params.length > 2 ? 'start' : 'end'}
              sx={{ height: '100%' }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack>
                  <Typography
                    sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}
                  >
                    {t('statistics.startDate')}
                  </Typography>
                  <DatePicker
                    sx={{
                      height: '56px',
                      backgroundColor: 'grey.15',
                      '& .MuiInputBase-root': {
                        height: '100%',
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'grey.15',
                      },
                    }}
                    value={startDate2 ? dayjs(startDate2) : null}
                    disableFuture
                    onChange={handleChangeStartDate2}
                  />
                </Stack>
                <Stack>
                  <Typography
                    sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}
                  >
                    {t('statistics.endDate')}
                  </Typography>
                  <DatePicker
                    sx={{
                      height: '56px',
                      backgroundColor: 'grey.15',
                      '& .MuiInputBase-root': {
                        height: '100%',
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'grey.15',
                      },
                    }}
                    value={dayjs(endDate2)}
                    disableFuture
                    onChange={handleChangeEndDate2}
                  />
                </Stack>
              </LocalizationProvider>
              <IconButton
                sx={{
                  mt: '35px',
                }}
                onClick={() => setPeriod2(t('statistics.period.today'))}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          ) : isSecondPeriod && period2 !== t('statistics.period.dateRange') ? (
            <Stack>
              <Typography sx={{ mb: '6px', fontSize: '14px', color: 'grey.5' }}>
                {'Додатковий період'}:
              </Typography>
              <Select
                sx={{
                  width: '250px',
                  height: '56px',
                  backgroundColor: 'grey.15',
                  '& .MuiSelect-select': {
                    padding: '10px 5px',
                    backgroundColor: 'grey.15',
                  },
                }}
                value={period2}
                name="additionalPeriod"
                onChange={handleChangePeriod}
              >
                {periodState2.map((periodItem, index) => {
                  return (
                    <MenuItem key={index} value={periodItem}>
                      {periodItem}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          ) : null}
        </Stack>
        <Stack
          justifyContent={params.length > 2 ? 'start' : 'center'}
          sx={{ ml: 'auto', mt: '25px' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setIsSecondPeriod(e.currentTarget.checked)}
                checked={isSecondPeriod}
              />
            }
            sx={{ span: { fontSize: '14px' } }}
            label={'Додатковий період'}
          />
          <Button
            variant="contained"
            sx={{ height: '56px', width: '180px' }}
            onClick={onClickFetchStatistics}
          >
            {t('statistics.search')}
          </Button>
        </Stack>
      </Stack>
      <StatisticsLeads
        statistics={statistics}
        loadingStatistics={loadingStatistics}
      />
    </Stack>
  );
};

export default StatisticsContainer;
