import React, { FC, ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { UserContext } from '../user/user.context';

interface LoggedInRouteProps {
  children: ReactNode;
}

const LoggedInRoute: FC<LoggedInRouteProps> = ({ children }) => {
  const { user } = useContext(UserContext);

  if (!user) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default LoggedInRoute;
