import React, { useState } from 'react';

const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    menuOpen,
    openMenu,
    closeMenu,
  };
};

export default useMenu;
