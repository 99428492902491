import { Table, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ModalBox from '../../common/modal/modal-box';

import FlowActivatorsModal from './flow-activators.modal';
import FlowItem from './flow.item';
import { IFlowWithActivatorsAndFolder } from './interfaces';

interface FlowListItemProps {
  shownFlows: IFlowWithActivatorsAndFolder[];
  openNodesEditor: (flow: IFlowWithActivatorsAndFolder) => void;
  openEditFlowModal: (flow: IFlowWithActivatorsAndFolder) => void;
  onCopyFlow: (flowId: IFlowWithActivatorsAndFolder) => void;
}

dayjs.extend(relativeTime);

const FlowTable: FC<FlowListItemProps> = ({
  shownFlows,
  openNodesEditor,
  openEditFlowModal,
  onCopyFlow,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<string>(
    searchParams.get('order') || 'asc',
  );
  const [orderBy, setOrderBy] = useState<string>(
    searchParams.get('orderBy') || 'name',
  );
  const [activatorsModalOpen, setActivatorsModalOpen] = useState(false);
  const [selectedFlow, setSelectedFlow] =
    useState<IFlowWithActivatorsAndFolder | null>(null);

  useEffect(() => {
    setSearchParams({ order, orderBy }, { replace: true });
  }, [order, orderBy, setSearchParams]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedFlows = [...shownFlows].sort((a, b) => {
    let aValue, bValue;
    if (orderBy === 'name') {
      aValue = a.name;
      bValue = b.name;
    } else if (orderBy === 'activationsCount') {
      aValue = a.activationsCount;
      bValue = b.activationsCount;
    } else if (orderBy === 'updatedAt') {
      aValue = new Date(a.updatedAt);
      bValue = new Date(b.updatedAt);
    } else if (orderBy === 'folder.name') {
      aValue = a.folder?.name;
      bValue = b.folder?.name;
    }

    if (order === 'asc') {
      return (aValue ?? '') > (bValue ?? '') ? 1 : -1;
    } else {
      return (bValue ?? '') > (aValue ?? '') ? 1 : -1;
    }
  });

  useEffect(() => {
    if (selectedFlow && shownFlows) {
      setSelectedFlow(
        shownFlows?.find((flow) => flow?._id === selectedFlow?._id) ?? null,
      );
    }
  }, [shownFlows]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minHeight: '200px',
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              borderRadius: '6px',
              backgroundColor: 'grey.10',
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'grey.10',
                  p: '8px',
                }}
                sortDirection={
                  orderBy === 'name' ? (order as 'asc' | 'desc') : false
                }
              >
                <TableSortLabel
                  active={orderBy === 'name'}
                  onClick={() => handleRequestSort('name')}
                  direction={
                    orderBy === 'name' ? (order as 'asc' | 'desc') : undefined
                  }
                >
                  {t('flow.flowTableLabel.name')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'grey.10',
                  p: '8px',
                }}
                sortDirection={
                  orderBy === 'activationsCount'
                    ? (order as 'asc' | 'desc')
                    : false
                }
              >
                <TableSortLabel
                  active={orderBy === 'activationsCount'}
                  onClick={() => handleRequestSort('activationsCount')}
                  direction={
                    orderBy === 'activationsCount'
                      ? (order as 'asc' | 'desc')
                      : undefined
                  }
                >
                  {t('flow.flowTableLabel.activation')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'grey.10',
                  p: '8px',
                }}
                sortDirection={
                  orderBy === 'updatedAt' ? (order as 'asc' | 'desc') : false
                }
              >
                <TableSortLabel
                  active={orderBy === 'updatedAt'}
                  onClick={() => handleRequestSort('updatedAt')}
                  direction={
                    orderBy === 'updatedAt'
                      ? (order as 'asc' | 'desc')
                      : undefined
                  }
                >
                  {t('flow.flowTableLabel.changes')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'grey.10',
                  p: '8px',
                }}
                sortDirection={
                  orderBy === 'folder.name' ? (order as 'asc' | 'desc') : false
                }
              >
                <TableSortLabel
                  active={orderBy === 'folder.name'}
                  onClick={() => handleRequestSort('folder.name')}
                  direction={
                    orderBy === 'folder.name'
                      ? (order as 'asc' | 'desc')
                      : undefined
                  }
                >
                  {t('flow.flowTableLabel.folder')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'grey.10',
                  p: '8px',
                  textAlign: 'center',
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFlows?.map((flow) => (
              <FlowItem
                key={'list' + flow._id}
                flow={flow}
                onCopyFlow={onCopyFlow}
                openEditFlowModal={openEditFlowModal}
                openNodesEditor={openNodesEditor}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalBox
        open={activatorsModalOpen}
        onClose={() => setActivatorsModalOpen(false)}
        maxWidth="50%"
      >
        <FlowActivatorsModal flow={selectedFlow} />
      </ModalBox>
    </>
  );
};

export default FlowTable;
