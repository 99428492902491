import { CircularProgress, Stack } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { useScrollRestoration } from '../../utils/hooks/useScrollRestoration';
import FilterContainer from '../filter/filter.container';
import { MessengerContext, TelegramDialog } from '../messenger.context';

import DialogItem from './dialog.item';

dayjs.extend(relativeTime).locale('uk');

interface DialogsListProps {
  currentDialog: TelegramDialog | null;
  onSelectDialog: (dialog: TelegramDialog) => void;
}

const DialogsList: FC<DialogsListProps> = ({
  currentDialog,
  onSelectDialog,
}) => {
  const { dialogs, loadingDialogs, getDialogs, hasMoreDialogs, dialogsFilter } =
    useContext(MessengerContext);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });

  const { handleScroll, restoreScroll } = useScrollRestoration();

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    restoreScroll(ref.current);
  }, [dialogs]);

  useEffect(() => {
    if (inView && hasMoreDialogs) {
      getDialogs();
    }
  }, [inView, hasMoreDialogs, dialogsFilter]);

  return (
    <Stack
      ref={ref}
      onScroll={() => handleScroll(ref.current)}
      direction="column"
      sx={{
        height: 'auto',
        overflowY: 'scroll',
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          top: '0',
          width: '100%',
          position: 'sticky',
          p: '12px',
          zIndex: 2,
          backgroundColor: 'grey.15',
        }}
      >
        <FilterContainer />
      </Stack>
      <Stack
        gap="8px"
        sx={{
          px: '10px',
        }}
      >
        {dialogs.map((dialog) => (
          <DialogItem
            key={dialog._id}
            dialog={dialog}
            lastMessageDate={dayjs(dialog.updatedAt).fromNow(true)}
            onSelect={() => {
              onSelectDialog(dialog);
            }}
            isActive={currentDialog?._id === dialog._id}
          />
        ))}
        {loadingDialogs && (
          <Stack
            sx={{
              height: '50px',
              width: 'max-content',
              m: '10px auto 0',
              color: 'green.2',
            }}
          >
            <CircularProgress color="inherit" />
          </Stack>
        )}
        <Stack
          sx={{
            minHeight: '5px',
            mb: loadingDialogs ? '0' : '100px',
            zIndex: 2,
          }}
          ref={inViewRef}
        />
      </Stack>
    </Stack>
  );
};

export default DialogsList;
