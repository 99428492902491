const SECOND = 1000;
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DAY = 1000 * 60 * 60 * 24;

const buetifyMilliseconds = (ms: number) => {
  if (ms > DAY) {
    return Math.round(ms / DAY) + ' d';
  }

  if (ms > HOUR) {
    return Math.round(ms / HOUR) + ' h';
  }

  if (ms > MINUTE) {
    return Math.round(ms / MINUTE) + ' m';
  }

  if (ms > SECOND) {
    return Math.round(ms / SECOND) + ' s';
  }

  return '-';
};

export default buetifyMilliseconds;
