import { Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserContext } from '../user/user.context';

const authBotUsername = process.env.REACT_APP_TELEGRAM_AUTH_BOT_USERNAME;

const LoginCodeForm = () => {
  const { handleLogin, loginError } = useContext(UserContext);
  const { t } = useTranslation();

  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    if (authCode.length === 6) {
      handleLogin(authCode);
    }
  }, [authCode]);

  return (
    <Stack
      sx={{
        mt: '20px',
        backgroundColor: 'grey.13',
        padding: '10px',
        borderRadius: '6px',
        borderColor: 'grey.10',
        maxWidth: '300px',
      }}
    >
      <Typography
        sx={{
          color: 'grey.1',
          fontWeight: 600,
        }}
      >
        {t('login.codeTitle')}
      </Typography>
      <TextField
        value={authCode}
        sx={{
          mt: '10px',
        }}
        onChange={(e) => {
          setAuthCode(e.target.value.trim());
        }}
      />
      {loginError && (
        <Typography
          sx={{
            color: 'grey.5',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '130%',
            textAlign: 'center',
            mt: '10px',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: 'red.2',
              fontWeight: 600,
            }}
          >
            {t('login.errorTextRed')}
          </Typography>{' '}
          {t('login.errorText')}{' '}
          <Link
            to={`https://t.me/${authBotUsername}?start=login`}
            target="_blank"
            style={{
              display: 'inline-block',
              textDecoration: 'none',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: 'blue.2',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('login.newCode')}
            </Typography>
          </Link>
        </Typography>
      )}
    </Stack>
  );
};

export default LoginCodeForm;
