import axios from '../axios';

interface searchParamsProps {
  params: string[];
  startDate?: string;
  endDate: string;
  endDate2?: string;
  startDate2?: string;
}

const fetchTelegramBotStatistics = async (
  botId: string,
  searchParams: searchParamsProps,
) => {
  const { data } = await axios.post(
    `/telegram-bots/${botId}/statistics`,
    searchParams,
  );

  return data;
};

export default fetchTelegramBotStatistics;
