import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { UserContext } from '../user/user.context';

const NavigateToDefault = () => {
  const { user } = useContext(UserContext);
  return <Navigate to={user ? '/projects' : '/login'} />;
};

export default NavigateToDefault;
