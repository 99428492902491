export type BroadcastParamCondition = {
  key: string;
  values: string[];
  include: boolean;
};
export type BroadcastStatusCondition = { values: string[] };

export enum BroadcastStatus {
  planned = 'PLANNED',
  started = 'STARTED',
}

export enum BroadcastType {
  draft = 'DRAFT',
  flow = 'FLOW',
}

export interface IBroadcast {
  _id: string;
  name: string;
  type: BroadcastType;
  flow?: string;
  telegramBot: string;
  recipientsCount: number;
  startTime: Date | null;
  endTime: Date | null;
  paramConditions: BroadcastParamCondition[];
  statusConditions: BroadcastStatusCondition;
  status: BroadcastStatus;
  owner: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateBroadcast
  extends Omit<
    IBroadcast,
    | '_id'
    | 'createdAt'
    | 'updatedAt'
    | 'endTime'
    | 'startTime'
    | 'status'
    | 'owner'
    | 'telegramBot'
    | 'recipientsCount'
  > {
  startTime: string;
}
export interface UpdateBroadcast
  extends Omit<
    IBroadcast,
    | '_id'
    | 'createdAt'
    | 'updatedAt'
    | 'endTime'
    | 'startTime'
    | 'status'
    | 'owner'
    | 'telegramBot'
    | 'recipientsCount'
  > {
  startTime: string;
}
