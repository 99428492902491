import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Menu, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITag, TelegramLeadStatus } from '../../interfaces/common';
import DialogsFilter from '../interfaces/dialogs-filter.interface';

import TagsFilter from './tags.filter';

type FilterControlProps = {
  openMenu: (event: React.SyntheticEvent) => void;
  menuOpen: boolean;
  closeMenu: () => void;
  anchorEl: Element | null;
  dialogStatus: string[];
  toggleStatus: (status: TelegramLeadStatus) => void;
  tags: ITag[];
  selectedTagIds: ITag['_id'][];
  handleSelectTag: (newTag: ITag['_id']) => void;
  handleDeleteTag: (tagToDelete: ITag['_id']) => void;
  searchMode: DialogsFilter['searchMode'];
  updateSearchMode: (value: DialogsFilter['searchMode']) => void;
  resetAllFilters: () => void;
};

const FilterPopup: FC<FilterControlProps> = ({
  openMenu,
  menuOpen,
  closeMenu,
  anchorEl,
  dialogStatus,
  toggleStatus,
  tags,
  selectedTagIds,
  handleDeleteTag,
  handleSelectTag,
  searchMode,
  updateSearchMode,
  resetAllFilters,
}) => {
  const { t } = useTranslation();

  const filterApplied =
    selectedTagIds.length || searchMode !== 'all' || dialogStatus.length;

  return (
    <Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          transition: 'all 0.1s ease',
          backgroundColor:
            filterApplied || menuOpen ? 'purple.2' : 'transparent',
          '&:hover': {
            backgroundColor: !filterApplied ? 'purple.1' : 'purple.2',
          },
        }}
        onClick={openMenu}
        role="button"
      >
        <FilterListIcon sx={{ color: 'grey.1', fontSize: '18px' }} />
      </Stack>

      <Menu
        open={menuOpen}
        onClose={closeMenu}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            backgroundColor: 'purple.1',
            p: '12px',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'purple.2',
            backgroundImage: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack gap="12px" sx={{ width: '250px' }}>
          <Stack>
            <Typography
              sx={{
                color: 'grey.5',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '130%',
              }}
            >
              {t('messenger.filter.filterLabel')}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '130%',
                mb: '6px',
              }}
            >
              {t('common.status')}
            </Typography>

            <Stack flexWrap="wrap" gap="10px" direction="row">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.lead)
                    ? 'grey.1'
                    : 'grey.5',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.lead)
                    ? 'grey.1'
                    : 'grey.5',
                  backgroundColor: dialogStatus.includes(
                    TelegramLeadStatus.lead,
                  )
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.lead)}
              >
                <Typography>Lead</Typography>
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'grey.1'
                    : 'grey.5',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'grey.1'
                    : 'grey.5',
                  backgroundColor: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.reg)}
              >
                <Typography>Reg</Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'grey.1'
                    : 'grey.5',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'grey.1'
                    : 'grey.5',
                  backgroundColor: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.dep)}
              >
                <Typography>Dep</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '130%',
              }}
            >
              {t('messenger.filter.tagLabel')}
            </Typography>
            <TagsFilter
              allTags={tags}
              selectedTagIds={selectedTagIds}
              handleSelectTag={handleSelectTag}
              handleDeleteTag={handleDeleteTag}
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '130%',
                mb: '6px',
              }}
            >
              {t('messenger.filter.dialogsLabel')}
            </Typography>
            <Stack flexWrap="wrap" gap="10px" direction="row">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('all') ? 'grey.1' : 'grey.5',
                  borderColor: searchMode.includes('all') ? 'grey.1' : 'grey.5',
                  backgroundColor: searchMode.includes('all')
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => updateSearchMode('all')}
              >
                <Typography>{t('messenger.filter.all')}</Typography>
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('notSeen') ? 'grey.1' : 'grey.5',
                  borderColor: searchMode.includes('notSeen')
                    ? 'grey.1'
                    : 'grey.5',
                  backgroundColor: searchMode.includes('notSeen')
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => updateSearchMode('notSeen')}
              >
                <Typography>{t('messenger.filter.notSeen')}</Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 20px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('blockedByUser')
                    ? 'grey.1'
                    : 'grey.5',
                  borderColor: searchMode.includes('blockedByUser')
                    ? 'grey.1'
                    : 'grey.5',
                  backgroundColor: searchMode.includes('blockedByUser')
                    ? 'purple.2'
                    : 'transparent',
                }}
                onClick={() => updateSearchMode('blockedByUser')}
              >
                <Typography>{t('messenger.filter.blocked')}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Button
              onClick={() => {
                resetAllFilters();
                closeMenu();
              }}
              sx={{
                backgroundColor: 'transparent',
                color: 'grey.5',
              }}
              startIcon={<CloseIcon />}
            >
              {t('messenger.filter.resetFilters')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </Stack>
  );
};

export default FilterPopup;
