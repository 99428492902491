import {
  Autocomplete,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatActionTypes, IChatActionFlowNodeData } from '../interfaces';

interface ChatActionEditorProps {
  open: boolean;
  initialChatAction: IChatActionFlowNodeData;
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    chatAction: IChatActionFlowNodeData,
  ) => void;
}

const ChatActionEditor: FC<ChatActionEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialChatAction,
}) => {
  const { t } = useTranslation();

  const ACTION_TYPES: ChatActionTypes[] = [
    'typing',
    'upload_photo',
    'record_video',
    'record_voice',
    'upload_document',
    'choose_sticker',
    'record_video_note',
  ];

  const [chatAction, setChatAction] = useState<IChatActionFlowNodeData>({
    actionType: 'typing',
    duration: 1,
  });

  const changeAnimationDuration = (_: Event, duration: number | number[]) => {
    setChatAction((prev) => ({ ...prev, duration: duration as number }));
  };

  const changeAnimationType = (
    _: SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    setChatAction((prev) => ({
      ...prev,
      actionType: value ?? '',
    }));
  };

  useEffect(() => {
    setChatAction(initialChatAction);
  }, [initialChatAction]);

  if (!open) return null;

  const buttonDisabled = Boolean(
    !chatAction.actionType || chatAction.duration < 0,
  );

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
          overflow: 'auto',
        }}
        gap="5px"
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '12px',
            backgroundColor: 'yellow.1',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'grey.1',
            }}
          >
            {t('flowNodes.chatAction')}
          </Typography>
        </Stack>
        <Divider sx={{ m: '10px 0' }} />
        <Stack>
          <Stack>
            <Autocomplete
              options={ACTION_TYPES}
              getOptionLabel={(option) => {
                return t(`flowNodes.actionTypes.${option as ChatActionTypes}`);
              }}
              value={chatAction.actionType}
              onChange={changeAnimationType}
              sx={{
                width: '100%',
                p: '0 10px',
                '& .MuiInputBase-root': { backgroundColor: 'grey.15' },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    p: '0',
                    '& .MuiInputBase-input': { p: '0' },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            sx={{
              width: '100%',
              p: '10px 25px',
            }}
          >
            <Slider
              value={chatAction.duration}
              onChange={changeAnimationDuration}
              getAriaValueText={(value: number) => `${value}°C`}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            width: '50%',
            color: 'red.2',
            '&:hover': {
              backgroundColor: 'red.2',
              color: 'grey.15',
            },
          }}
        >
          {t('common.cancel')}
        </Button>

        <Button
          sx={{
            width: '50%',
            fontSize: '18px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.2',
              color: 'grey.15',
            },
          }}
          variant="contained"
          onClick={(e) => {
            onSubmit(e, chatAction);
          }}
          disabled={buttonDisabled}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ChatActionEditor;
