import { IConditionsFlowNodeData } from '../interfaces';

const updateConditionsOnConnect = (
  prevConditions: IConditionsFlowNodeData['conditions'],
  index: number,
  target?: string,
) => {
  const newConditions: IConditionsFlowNodeData['conditions'] = [];

  for (let i = 0; i < prevConditions.length; i++) {
    if (index !== i) {
      newConditions.push(prevConditions[i]);
      continue;
    }

    newConditions.push({
      ...prevConditions[i],
      truthyNext: target ? 'cond-' + target : '',
    });
  }

  return newConditions;
};

export default updateConditionsOnConnect;
