enum ProjectScreens {
  newTelegramBot = 'new-telegram-bot',
  newTelegramUserAccount = 'new-telegram-user',
  statistics = 'statistics',
  settings = 'settings',
  bot = 'bot',
  userAccount = 'user-account',
}

export default ProjectScreens;
