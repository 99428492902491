import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../GlobalContext';

interface CreateProjectButtonProps {
  backgroundColor?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  variant?: 'text' | 'contained' | 'outlined';
}

const CreateProjectButton: FC<CreateProjectButtonProps> = ({
  backgroundColor = 'blue.1',
  borderRadius = '18px',
  height = '84px',
  width = '239px',
  variant = 'outlined',
}) => {
  const { openNewProjectModal } = useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        width,
        backgroundColor,
        color: 'grey.1',
        borderRadius,
        height,
        fontSize: '18px',
        border: '2px solid',
        borderColor: 'blue.2',
        boxShadow: '0px 4px 10px rgba(4, 149, 255, 0.15)',
      }}
      startIcon={<AddIcon />}
      variant={variant}
      onClick={openNewProjectModal}
    >
      {t('projects.newProject')}
    </Button>
  );
};

export default CreateProjectButton;
