import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import ReplyIcon from '@mui/icons-material/Reply';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import {
  ITelegramDisplayedMessage,
  TelegramMessageDirection,
  TelegramMessageType,
} from '../../interfaces/common';

interface MessageContextMenuProps {
  anchorEl: Element | null;
  message: ITelegramDisplayedMessage;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onReplyMessage: () => void;
  onTranslate: () => void;
  onCopyText: () => void;
}

const getMessageProps = (message: ITelegramDisplayedMessage) => {
  let editable,
    translatable,
    replyable,
    copiable,
    deletable = false;

  if ('direction' in message) {
    editable =
      message.direction === TelegramMessageDirection.outcome &&
      message.type !== TelegramMessageType.videoNote &&
      message.type !== TelegramMessageType.voice &&
      !message.deleted;

    translatable =
      message.direction === TelegramMessageDirection.income &&
      Boolean(message.text) &&
      !message.text?.includes('/start');

    replyable = message.direction === TelegramMessageDirection.income;

    deletable =
      message.direction === TelegramMessageDirection.outcome &&
      !message.deleted;
  }

  if (message.text) {
    copiable = true;
  }

  return {
    editable,
    translatable,
    replyable,
    copiable,
    deletable,
  };
};

const MessageContextMenu: FC<MessageContextMenuProps> = ({
  anchorEl,
  message,
  onClose,
  onEdit,
  onDelete,
  onReplyMessage,
  onCopyText,
  onTranslate,
}) => {
  const { t } = useTranslation();
  const menuItems = [];

  const { editable, translatable, replyable, copiable, deletable } =
    getMessageProps(message);

  if (editable) {
    menuItems.push([
      {
        text: t('common.edit'),
        renderIcon: () => <EditIcon />,
        onClick: onEdit,
      },
    ]);
  }

  if (replyable) {
    menuItems.push([
      {
        text: t('common.response'),
        renderIcon: () => <ReplyIcon />,
        onClick: onReplyMessage,
      },
    ]);
  }

  if (translatable) {
    menuItems.push([
      {
        text: t('common.translate'),
        renderIcon: () => <GTranslateIcon />,
        onClick: onTranslate,
      },
    ]);
  }

  if (copiable) {
    menuItems.push([
      {
        text: t('common.copy'),
        renderIcon: () => <ContentCopyIcon />,
        onClick: onCopyText,
      },
    ]);
  }

  if (deletable) {
    menuItems.push([
      {
        text: t('common.delete'),
        renderIcon: () => <DeleteIcon sx={{ color: 'red.2' }} />,
        onClick: onDelete,
      },
    ]);
  }

  return (
    <ThreeDotsMenu
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchor={anchorEl}
      menuItems={menuItems}
    />
  );
};

export default MessageContextMenu;
