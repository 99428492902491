import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import HealingIcon from '@mui/icons-material/Healing';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectContext } from '../../../projects/project.context';
import { ITelegramChannelWithActivators } from '../../channels/interfaces';
import {
  ActionFlowNodeTaskData,
  ActionTaskTypes,
  IActionsFlowNodeData,
  IFlowWithActivatorsAndFolder,
} from '../interfaces';

interface ActionsEditorProps {
  open: boolean;
  initialTasks: IActionsFlowNodeData['tasks'];
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    tasks: IActionsFlowNodeData['tasks'],
  ) => void;
  flows: IFlowWithActivatorsAndFolder[];
  channels: ITelegramChannelWithActivators[];
}

//TODO: add proper icons to menu
const ActionsEditor: FC<ActionsEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialTasks,
  flows,
  channels,
}) => {
  const { t } = useTranslation();

  const [tasks, setTasks] = useState<IActionsFlowNodeData['tasks']>([]);

  const { projectParams } = useContext(ProjectContext);

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks]);

  const onAddAssignParam = () => {
    setTasks((prev) => [
      ...prev,
      {
        type: ActionTaskTypes.assignParam,
        key: '',
        value: '',
      },
    ]);
  };

  const onDeleteParam = () => {
    setTasks((prev) => [
      ...prev,
      {
        type: ActionTaskTypes.deleteParam,
        key: '',
      },
    ]);
  };

  const onEditTask = (index: number, data: ActionFlowNodeTaskData) => {
    setTasks((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          ...data,
        });
      }

      return result;
    });
  };

  const onDeleteTask = (index: number) => {
    setTasks((prev) => {
      const result = [...prev];

      return result.filter((_, i) => index !== i);
    });
  };

  const onSelectChannel = (
    event: SelectChangeEvent,
    index: number,
    actionType: ActionTaskTypes,
  ) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    onEditTask(index, {
      type:
        actionType === ActionTaskTypes.unbanInChannel
          ? ActionTaskTypes.unbanInChannel
          : ActionTaskTypes.acceptToChannel,
      channelId: selected._id,
      channelName: selected.title,
    });
  };

  const onSelectFlow = (event: SelectChangeEvent, index: number) => {
    const selected = flows?.find((flow) =>
      flow?._id?.includes(event.target.value),
    );

    if (!selected) return;
    onEditTask(index, {
      type: ActionTaskTypes.startFlow,
      flowId: selected._id,
      flowName: selected.name,
    });
  };

  const onAddAcceptToChannel = () => {
    setTasks((prev) => [
      ...prev,
      {
        type: ActionTaskTypes.acceptToChannel,
        channelName: '',
        channelId: '',
      },
    ]);
  };

  const onAddStartFlow = () => {
    setTasks((prev) => [
      ...prev,
      {
        type: ActionTaskTypes.startFlow,
        flowId: '',
        flowName: '',
      },
    ]);
  };

  const onAddUnbanFlow = () => {
    setTasks((prev) => [
      ...prev,
      {
        type: ActionTaskTypes.unbanInChannel,
        channelName: '',
        channelId: '',
      },
    ]);
  };

  if (!open) return null;

  const buttonDisabled =
    tasks.length === 0 ||
    tasks.some((task) => {
      if (task.type === ActionTaskTypes.acceptToChannel) {
        return task.channelName.trim() === '' || task.channelId.trim() === '';
      } else if (task.type === ActionTaskTypes.assignParam) {
        return task.key.trim() === '' || task.value.trim() === '';
      } else if (task.type === ActionTaskTypes.deleteParam) {
        return task.key.trim() === '';
      } else if (task.type === ActionTaskTypes.startFlow) {
        return task.flowName === '' || task.flowId.trim() === '';
      } else if (task.type === ActionTaskTypes.unbanInChannel) {
        return task.channelName.trim() === '' || task.channelId.trim() === '';
      }
      return false;
    });

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderLeft: '2px solid',
        borderRight: '2px solid',
        borderColor: 'grey.10',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
        }}
        gap="5px"
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '12px',
            backgroundColor: 'yellow.1',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'grey.1',
            }}
          >
            {t('flowNodes.actionsLabel')}
          </Typography>
        </Stack>
        <Divider sx={{ m: '10px 0' }} />
        <Stack direction="column" gap="10px">
          <Button
            startIcon={<PlaylistAddIcon />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
              height: '44px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              gap: '5px',
            }}
            color="inherit"
            onClick={onAddAssignParam}
          >
            {t('flowNodes.assignParam')}
          </Button>
          <Button
            startIcon={<PlaylistRemoveIcon />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
              height: '44px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              gap: '5px',
            }}
            color="inherit"
            onClick={onDeleteParam}
          >
            {t('flowNodes.deleteParam')}
          </Button>
          <Button
            color="inherit"
            startIcon={<PersonAddIcon />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
              height: '44px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              gap: '5px',
            }}
            onClick={onAddAcceptToChannel}
          >
            {t('common.channel')}
          </Button>
          <Button
            color="inherit"
            startIcon={<PlayCircleIcon />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
              height: '44px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              gap: '5px',
            }}
            onClick={onAddStartFlow}
          >
            {t('common.flow')}
          </Button>
          <Button
            color="inherit"
            startIcon={<HealingIcon />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              borderColor: 'grey.10',
              backgroundColor: 'grey.15',
              height: '44px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              gap: '5px',
            }}
            onClick={onAddUnbanFlow}
          >
            {t('flowNodes.unbanInChannel')}
          </Button>
        </Stack>

        {tasks.map((task, index) => {
          switch (task.type) {
            case ActionTaskTypes.assignParam: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.assignParam')}</Typography>
                  <Stack
                    key={'assignParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Stack
                      direction="row"
                      gap="10px"
                      sx={{ maxWidth: '251px', position: 'relative' }}
                    >
                      <Autocomplete
                        sx={{
                          width: '120px',
                          '& .MuiInputBase-root': {
                            backgroundColor: 'grey.15',
                            height: '43px',
                            p: '0',
                          },
                        }}
                        freeSolo
                        options={projectParams}
                        value={task.key}
                        onInputChange={(_, newValue) => {
                          onEditTask(index, {
                            ...task,
                            key: newValue ?? '',
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Key"
                            sx={{
                              p: '0',
                              '& .MuiInputBase-input': {
                                p: '0',
                              },
                            }}
                          />
                        )}
                      />
                      <TextField
                        sx={{
                          width: '50%',
                        }}
                        placeholder="Value"
                        value={task.value}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          onEditTask(index, {
                            ...task,
                            value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
            case ActionTaskTypes.deleteParam: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.deleteParam')}</Typography>
                  <Stack
                    key={'deleteParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Stack
                      direction="row"
                      gap="10px"
                      sx={{
                        maxWidth: '251px',
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <Autocomplete
                        sx={{
                          width: '251px',
                          '& .MuiInputBase-root': {
                            backgroundColor: 'grey.15',
                            height: '43px',
                            p: '0',
                          },
                        }}
                        freeSolo
                        options={projectParams}
                        value={task.key}
                        onInputChange={(_, newValue) => {
                          onEditTask(index, {
                            ...task,
                            key: newValue ?? '',
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Key"
                            sx={{
                              p: '0',
                              '& .MuiInputBase-input': {
                                p: '0',
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
            case ActionTaskTypes.acceptToChannel: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.acceptToChannel')}</Typography>
                  <Stack
                    key={'assignParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Box sx={{ width: '80%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{t('common.channel')}</InputLabel>
                        <Select
                          value={task?.channelId ?? ''}
                          label="Channel"
                          onChange={(event) => {
                            onSelectChannel(
                              event,
                              index,
                              ActionTaskTypes.acceptToChannel,
                            );
                          }}
                          sx={{
                            '.MuiSelect-select': {
                              backgroundColor: 'grey.15',
                              py: '14px',
                            },
                          }}
                        >
                          {channels.map(({ title, _id }) => (
                            <MenuItem key={_id} value={_id}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
            case ActionTaskTypes.startFlow: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.startFlow')}</Typography>
                  <Stack
                    key={'assignParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Box sx={{ width: '80%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{t('common.flow')}</InputLabel>
                        <Select
                          value={task?.flowId ?? ''}
                          label="Flow"
                          onChange={(event) => onSelectFlow(event, index)}
                          sx={{
                            '.MuiSelect-select': {
                              backgroundColor: 'grey.15',
                              py: '14px',
                            },
                          }}
                        >
                          {flows.map(({ _id, name }) => (
                            <MenuItem key={_id} value={_id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
            case ActionTaskTypes.unbanInChannel: {
              return (
                <Stack className="taskContainer" sx={{ m: '7px 0' }}>
                  <Typography>{t('flowNodes.unbanInChannel')}</Typography>
                  <Stack
                    key={'assignParam' + index}
                    direction="row"
                    alignItems="center"
                    justify-content="space-between"
                    gap="15px"
                    sx={{
                      mt: '10px',
                    }}
                  >
                    <Box sx={{ width: '80%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{t('common.channel')}</InputLabel>
                        <Select
                          value={task?.channelId ?? ''}
                          label="Channel"
                          onChange={(event) => {
                            onSelectChannel(
                              event,
                              index,
                              ActionTaskTypes.unbanInChannel,
                            );
                          }}
                          sx={{
                            '.MuiSelect-select': {
                              backgroundColor: 'grey.15',
                              py: '14px',
                            },
                          }}
                        >
                          {channels.map(({ title, _id }) => (
                            <MenuItem key={_id} value={_id}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'red.1',
                        border: '1px solid ',
                        borderColor: 'red.2',
                        borderRadius: ' 6px',
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
          }
        })}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            width: '50%',
            color: 'red.2',
            '&:hover': {
              backgroundColor: 'red.2',
              color: 'grey.15',
            },
          }}
        >
          {t('common.cancel')}
        </Button>

        <Button
          sx={{
            width: '50%',
            fontSize: '18px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.2',
              color: 'grey.15',
            },
          }}
          variant="contained"
          onClick={(e) => {
            onSubmit(e, tasks);
          }}
          disabled={buttonDisabled}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ActionsEditor;
