import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFlowFolder } from './interfaces';

const MAX_NAME_LENGTH = 40;

interface FolderModalProps {
  onClose: () => void;
  createFolder: boolean;
  folder?: IFlowFolder | null;
  onSubmit: (name: string) => void;
}

const CreateEditFolderModal: FC<FolderModalProps> = ({
  onSubmit,
  onClose,
  createFolder,
  folder,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');

  const createButtonDisabled = Boolean(!name);

  const onCreateClick = () => {
    onSubmit(name);
    setName('');
    onClose();
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  useEffect(() => {
    if (!createFolder) {
      setName(folder?.name as IFlowFolder['name']);
    } else {
      setName('');
    }
  }, [open]);

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: '24px',
        }}
      >
        {createFolder ? t('flow.createFolder') : t('flow.folders.editFolder')}
      </Typography>

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('common.nameLabel')}
      </Typography>
      <TextField value={name} onChange={onNameInput} />

      <Button
        variant="contained"
        disabled={createButtonDisabled}
        sx={{
          backgroundColor: 'blue.2',
          color: 'grey.1',
          mt: '20px',
        }}
        type="submit"
        onClick={onCreateClick}
      >
        {createFolder ? t('common.create') : t('common.edit')}
      </Button>
    </Stack>
  );
};

export default CreateEditFolderModal;
