import IProject from '../../projects/project.interface';
import axios from '../axios';

const editInviteLink = async (_id: IProject['_id']) => {
  const { data } = await axios.put(`/projects/${_id}/edit-invite-link`);

  return data;
};

export default editInviteLink;
