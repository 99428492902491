import { Stack } from '@mui/material';
import React, { useContext } from 'react';

import ProjectEdit from '../../edit-project';
import { ProjectContext } from '../../project.context';

import ProjectMembers from './project-members';

const ProjectSettings = () => {
  const { project } = useContext(ProjectContext);

  return (
    <Stack
      direction="row"
      sx={{
        padding: '40px',
      }}
      gap="40px"
      justifyContent="center"
    >
      <Stack
        sx={{
          backgroundColor: 'grey.14',
          border: '1px solid ',
          borderColor: 'grey.10',
          borderRadius: '24px',
          padding: '17px',
        }}
      >
        <ProjectEdit project={project} />
      </Stack>

      <ProjectMembers project={project} />
    </Stack>
  );
};

export default ProjectSettings;
