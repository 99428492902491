import { Button, Stack, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../AppContext';
import ModalBox from '../../common/modal/modal-box';
import { MessengerContext, TelegramDialog } from '../messenger.context';

interface NamesModalProps {
  namesModalOpen: boolean;
  onClose: () => void;
  dialog: TelegramDialog;
}
const NamesModal: FC<NamesModalProps> = ({
  namesModalOpen,
  dialog,
  onClose,
}) => {
  const { updateDialogNames } = useContext(MessengerContext);
  const { triggerSnackbar } = useContext(AppContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const saveButtonDisabled =
    !Boolean(firstName) ||
    dialog.firstName + dialog.lastName === firstName + lastName;

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'firstName') {
      setFirstName(e.target.value);
    } else {
      setLastName(e.target.value);
    }
  };

  useEffect(() => {
    setFirstName(dialog.firstName);
    setLastName(dialog.lastName);
  }, [dialog]);

  const onSave = () => {
    onClose();
    updateDialogNames(dialog._id, { firstName, lastName });
    triggerSnackbar(t('messenger.currentDialog.namesModal.notification'));
  };

  return (
    <ModalBox
      open={namesModalOpen}
      onClose={onClose}
      maxWidth="297px"
      sx={{ p: '12px' }}
    >
      <Stack sx={{ gap: '12px' }}>
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '130%',
            fontWeight: 500,
            textAlign: 'center',
            color: 'grey.1',
          }}
        >
          {t('messenger.currentDialog.namesModal.title')}
        </Typography>
        <TextField
          onChange={onNameChange}
          value={firstName}
          name="firstName"
          placeholder={t(
            'messenger.currentDialog.namesModal.firstNamePlaceholder',
          )}
          variant="outlined"
          sx={{ width: '273px', borderRadius: '6px' }}
        />
        <TextField
          onChange={onNameChange}
          value={lastName}
          name="lastName"
          placeholder={t(
            'messenger.currentDialog.namesModal.lastNamePlaceholder',
          )}
          variant="outlined"
          sx={{ width: '273px', borderRadius: '6px' }}
        />
        <Button
          sx={{
            width: '273px',
            borderRadius: '6px',
            backgroundColor: 'blue.2',
            color: 'grey.1',
            ':hover': {
              backgroundColor: 'blue.3',
            },
          }}
          disabled={saveButtonDisabled}
          onClick={onSave}
        >
          {t('messenger.currentDialog.namesModal.saveButton')}
        </Button>
      </Stack>
    </ModalBox>
  );
};

export default NamesModal;
