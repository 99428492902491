import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';
import { Stack, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TelegramMessageType } from '../../interfaces/common';

interface AddMediaButtonProps {
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  messageType: TelegramMessageType;
}

const AddMediaButton: FC<AddMediaButtonProps> = ({
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  messageType,
}) => {
  const { t } = useTranslation();

  const buttonDetails: {
    [key: string]: { icon: ReactNode; text: string };
  } = {
    [TelegramMessageType.media]: {
      icon: (
        <AddPhotoAlternateIcon
          sx={{
            width: '55px',
            height: '55px',
          }}
        />
      ),
      text: t('common.addPhoto'),
    },
    [TelegramMessageType.voice]: {
      icon: (
        <MicRoundedIcon
          sx={{
            width: '55px',
            height: '55px',
          }}
        />
      ),
      text: 'Add voice',
    },

    [TelegramMessageType.videoNote]: {
      icon: (
        <SlowMotionVideoRoundedIcon
          sx={{
            width: '55px',
            height: '55px',
          }}
        />
      ),
      text: 'Add video',
    },
  };

  const onClickAddMedia = () => {
    switch (messageType) {
      case TelegramMessageType.media: {
        openMediaModal();
        return;
      }
      case TelegramMessageType.voice: {
        openVoiceModal();
        return;
      }
      case TelegramMessageType.videoNote: {
        openVideoNoteModal();
        return;
      }
    }
  };

  return (
    <Stack
      onClick={onClickAddMedia}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: '6px',
        border: '2px dashed',
        borderColor: 'grey.10',
        background: 'grey.13',
        width: '135px',
        minWidth: '135px',
        height: '132px',
        cursor: 'pointer',
      }}
    >
      {buttonDetails[messageType].icon}
      <Typography
        sx={{
          mt: '13px',
        }}
      >
        {buttonDetails[messageType].text}
      </Typography>
    </Stack>
  );
};

export default AddMediaButton;
