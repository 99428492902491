import { Stack, darken } from '@mui/material';
import React, { FC } from 'react';

import { ProjectColors } from './project-colors.enum';

interface ColorPickerProps {
  color: string;
  setColor: (newColor: string) => void;
}

const circleBase = {
  width: '38px',
  height: '38px',
  cursor: 'pointer',
  borderRadius: '50%',
  borderStyle: 'solid',
  transition: 'all 0.3s ease',
};

const ColorPicker: FC<ColorPickerProps> = ({ color, setColor }) => {
  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.green,
          borderWidth: color === ProjectColors.green ? '6px' : '3px',
          borderColor:
            color === ProjectColors.green
              ? 'grey.1'
              : darken(ProjectColors.green, 0.5),
        }}
        onClick={() => setColor(ProjectColors.green)}
      />

      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.blue,
          borderWidth: color === ProjectColors.blue ? '6px' : '3px',
          borderColor:
            color === ProjectColors.blue
              ? 'grey.1'
              : darken(ProjectColors.blue, 0.5),
        }}
        onClick={() => setColor(ProjectColors.blue)}
      />

      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.pink,
          borderWidth: color === ProjectColors.pink ? '6px' : '3px',
          borderColor:
            color === ProjectColors.pink
              ? 'grey.1'
              : darken(ProjectColors.pink, 0.5),
        }}
        onClick={() => setColor(ProjectColors.pink)}
      />

      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.yellow,
          borderWidth: color === ProjectColors.yellow ? '6px' : '3px',
          borderColor:
            color === ProjectColors.yellow
              ? 'grey.1'
              : darken(ProjectColors.yellow, 0.5),
        }}
        onClick={() => setColor(ProjectColors.yellow)}
      />

      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.purple,
          borderWidth: color === ProjectColors.purple ? '6px' : '3px',
          borderColor:
            color === ProjectColors.purple
              ? 'grey.1'
              : darken(ProjectColors.purple, 0.5),
        }}
        onClick={() => setColor(ProjectColors.purple)}
      />

      <Stack
        sx={{
          ...circleBase,
          backgroundColor: ProjectColors.red,
          borderWidth: color === ProjectColors.red ? '6px' : '3px',
          borderColor:
            color === ProjectColors.red
              ? 'grey.1'
              : darken(ProjectColors.red, 0.5),
        }}
        onClick={() => setColor(ProjectColors.red)}
      />
    </Stack>
  );
};

export default ColorPicker;
