import { Stack } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import {
  EditTelegramMessage,
  ITelegramDisplayedMessage,
  ITelegramMediaMessage,
  ITelegramVideoNoteMessage,
  ITelegramVoiceMessage,
  TelegramMessageType,
} from '../../interfaces/common';
import { MessengerContext } from '../messenger.context';

import DateMessageComponent from './date-message.component';
import MediaMessageComponent from './media-message.component';
import MessageContextMenu from './message.context-menu';
import ServiceMessageComponent from './service-message.component';
import StickerMessageComponent from './sticker-message.component';
import VideoNoteMessage from './video-note-message';
import VoiceMessage from './voice-message';

interface MessageContainerProps {
  message: ITelegramDisplayedMessage;
  onEditMessage: (messageToEdit: EditTelegramMessage) => void;
  onDeleteMessage: (messageId: string) => void;
  onReplyMessage: (message: {
    telegramId: number;
    text?: string;
    media?: string;
  }) => void;
}

const MessageContainer: FC<MessageContainerProps> = ({
  message,
  onReplyMessage,
  onEditMessage,
  onDeleteMessage,
}) => {
  const { triggerSnackbar } = useContext(AppContext);
  const { translateMessage } = useContext(MessengerContext);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    openMenu(event);
  };

  const copyText = () => {
    navigator.clipboard.writeText(message?.text ?? '');

    triggerSnackbar(t('copyableText.text'));
  };

  let firstMediaUrl = '';

  if (
    message.type === TelegramMessageType.videoNote ||
    message.type === TelegramMessageType.voice ||
    message.type === TelegramMessageType.media
  ) {
    const media = (message.media as ITelegramMediaMessage['media']) ?? [];

    if (media && media[0]) {
      firstMediaUrl = media[0].url;
    }
  }

  const messageComponents = {
    [TelegramMessageType.media]: (
      <MediaMessageComponent
        senderName={(message as ITelegramMediaMessage).senderName}
        direction={(message as ITelegramMediaMessage).direction}
        reason={message.reason}
        text={message.text ?? ''}
        translation={message.translation ?? ''}
        media={
          (message as ITelegramMediaMessage)
            .media as ITelegramMediaMessage['media']
        }
        date={new Date(message.createdAt)}
        edited={Boolean(message.updatedAt !== message.createdAt)}
        buttons={(message as ITelegramMediaMessage).buttons}
        deleted={message.deleted}
        replyText={message.replyText ?? ''}
        replyMedia={message.replyMedia ?? ''}
        openContextMenu={handleContextMenu}
      />
    ),
    [TelegramMessageType.service]: (
      <ServiceMessageComponent text={message.text ?? ''} />
    ),
    [TelegramMessageType.date]: (
      <DateMessageComponent text={message.text ?? ''} />
    ),
    [TelegramMessageType.voice]: (
      <VoiceMessage
        voiceUrl={firstMediaUrl}
        date={new Date(message.createdAt)}
        direction={(message as ITelegramVoiceMessage).direction}
        buttons={(message as ITelegramVoiceMessage).buttons}
        text={message.text ?? ''}
        translation={message.translation ?? ''}
        edited={Boolean(message.updatedAt !== message.createdAt)}
        deleted={message.deleted}
        replyText={message.replyText ?? ''}
        replyMedia={message.replyMedia ?? ''}
        openContextMenu={handleContextMenu}
      />
    ),
    [TelegramMessageType.videoNote]: (
      <VideoNoteMessage
        videoUrl={firstMediaUrl}
        date={new Date(message.createdAt)}
        direction={(message as ITelegramVideoNoteMessage).direction}
        buttons={(message as ITelegramVideoNoteMessage).buttons}
        deleted={message.deleted}
        replyText={message.replyText ?? ''}
        replyMedia={message.replyMedia ?? ''}
        openContextMenu={handleContextMenu}
      />
    ),
    [TelegramMessageType.sticker]: (
      <StickerMessageComponent
        media={(message as ITelegramMediaMessage).media}
        text={message.text ?? null}
        date={new Date(message.createdAt)}
        telegramId={(message as ITelegramMediaMessage).telegramId}
        direction={(message as ITelegramMediaMessage).direction}
      />
    ),
  };

  return (
    <Stack className="allowCopy">
      <MessageContextMenu
        anchorEl={anchorEl}
        message={message}
        onClose={closeMenu}
        onEdit={() => {
          onEditMessage({
            _id: message._id,
            text: message.text,
            buttons: (message as ITelegramMediaMessage).buttons,
            media: (message as ITelegramMediaMessage).media,
            type: message.type,
          });
        }}
        onDelete={() => {
          onDeleteMessage(message._id);
        }}
        onReplyMessage={() => {
          if (message.text) {
            return onReplyMessage({
              text: message.text,
              telegramId: 'telegramId' in message ? message.telegramId : 0,
            });
          } else if (message) {
            return onReplyMessage({
              media: firstMediaUrl,
              telegramId: 'telegramId' in message ? message.telegramId : 0,
            });
          }
        }}
        onTranslate={() => {
          translateMessage(message.dialog, message._id);
        }}
        onCopyText={copyText}
      />
      {messageComponents[message.type]}
    </Stack>
  );
};

export default MessageContainer;
