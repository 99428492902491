import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StatisticsLeadsProps {
  statistics: (string | number)[][];
  loadingStatistics: boolean;
}

const StatisticsLeads: FC<StatisticsLeadsProps> = ({
  statistics,
  loadingStatistics,
}) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('LEAD');
  const header = statistics[0];

  const isStatisticsEmpty = Boolean(
    Array.isArray(statistics) && statistics?.length > 1,
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (loadingStatistics) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '200px',
          color: 'green.2',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
      </Stack>
    );
  }

  const sortedStatistics = statistics?.slice(1).sort((a, b) => {
    const aIndex = header.indexOf(orderBy);
    const bIndex = header.indexOf(orderBy);

    const aValue = a[aIndex];
    const bValue = b[bIndex];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      if (order === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    } else {
      if (order === 'asc') {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    }
  });

  const totalValue = Array(header?.length).fill(0);

  if (statistics?.length > 2) {
    sortedStatistics?.forEach((array) => {
      array?.forEach((value, index) => {
        if (typeof value === 'string') {
          totalValue[index] = ' ';
        }
        if (!isNaN(parseFloat(value as string)) && isFinite(value as number)) {
          totalValue[index] += Number(value);
        }
      });
    });
  }

  return (
    <TableContainer component={Paper} sx={{ mt: '20px', minHeight: '200px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: 'grey.12' }}>
          <TableRow>
            {header?.map((title) => {
              return (
                <TableCell
                  sx={{ textAlign: 'center', cursor: 'pointer' }}
                  key={title}
                  sortDirection={
                    orderBy === title ? (order as 'asc' | 'desc') : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === title}
                    onClick={() => handleRequestSort(title.toString())}
                    direction={
                      orderBy === title ? (order as 'asc' | 'desc') : undefined
                    }
                  >
                    {title}
                    {orderBy === title ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                    <Typography
                      sx={{
                        position: 'relative',
                        bottom: '24px',
                        left: '25px',
                      }}
                    ></Typography>
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedStatistics?.map((array, index) => {
            return (
              <TableRow key={index}>
                {array.map((value, index) => {
                  return (
                    <TableCell sx={{ textAlign: 'center' }} key={index}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {Boolean(statistics?.length > 2) && (
            <TableRow sx={{ backgroundColor: 'grey.10' }}>
              <TableCell sx={{ textAlign: 'center' }}>total</TableCell>
              {totalValue?.slice(1)?.map((value, index) => (
                <TableCell sx={{ textAlign: 'center' }} key={index}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!isStatisticsEmpty && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ mt: '15px', padding: '30px 0' }}
        >
          {t('statistics.emptyRequest')}
        </Stack>
      )}
    </TableContainer>
  );
};

export default StatisticsLeads;
