import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { TelegramLeadStatus } from '../interfaces/common';

interface StatusBadgeProps {
  status: string;
}

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
  let bgColor = 'grey.8';
  let color = 'grey.1';

  switch (status) {
    case TelegramLeadStatus.lead: {
      bgColor = 'red.2';
      break;
    }
    case TelegramLeadStatus.reg: {
      bgColor = 'blue.2';
      break;
    }

    case TelegramLeadStatus.dep: {
      bgColor = 'green.2';
      color = 'grey.15';
      break;
    }
  }

  return (
    <Stack
      sx={{
        padding: '5px 10px',
        borderRadius: '45px',
        backgroundColor: bgColor,
        position: 'relative',
      }}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Typography
        sx={{
          mt: '2px',
          color,
        }}
      >
        {status}
      </Typography>
    </Stack>
  );
};

export default StatusBadge;
