import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createFlowActivator from '../../api/flows/create-activator';
import { deleteFlowActivator } from '../../api/flows/delete-activator';
import editFlowActivator from '../../api/flows/edit-activator';
import { AppContext } from '../../AppContext';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import { BotContext } from '../bot.context';
import ModalScriptInfo from '../script/modal-script.info';

import CreateEditActivator from './create-edit-activator';
import {
  IFlowActivatorWithParams,
  IFlowWithActivatorsAndFolder,
  UpdateFlowActivatorWithParams,
} from './interfaces';

interface FlowActivatorsModalProps {
  flow: IFlowWithActivatorsAndFolder | null;
}

const FlowActivatorsModal: FC<FlowActivatorsModalProps> = ({ flow }) => {
  const { t } = useTranslation();
  const { bot, refetchFlows } = useContext(BotContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const [selectedActivator, setSelectedActivator] =
    useState<IFlowActivatorWithParams | null>(null);
  const [filteredActivators, setFilteredActivators] = useState<
    IFlowActivatorWithParams[] | null
  >(null);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [creatingNewActivator, setCreatingNewActivator] = useState(false);
  const [scriptModalOpen, setScriptModalOpen] = useState<boolean>(false);
  const [isCopyActivator, setIsCopyActivator] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState<string>('');
  const [activeFilterInput, setActiveFilter] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onCreateNewActivator = (
    newFlowActivator: Omit<
      IFlowActivatorWithParams,
      'createdAt' | 'updatedAt' | '_id' | 'flow'
    >,
  ) => {
    if (!flow) return;
    createFlowActivator(flow?._id, {
      ...newFlowActivator,
    }).then(() => {
      refetchFlows();
      setCreatingNewActivator(false);
    });
  };

  const onEditActivator = (
    activatorToUpdate: UpdateFlowActivatorWithParams,
  ) => {
    if (!selectedActivator || !flow) return;

    editFlowActivator(
      flow?._id,
      selectedActivator?._id,
      activatorToUpdate,
    ).then(refetchFlows);
  };

  const onCopyActivator = () => {
    setIsCopyActivator(true);
  };

  const onResetSelectedActivator = () => {
    setSelectedActivator(null);
  };

  const onOpenScriptModal = () => {
    setScriptModalOpen(true);
  };

  const onCloseScriptModal = () => {
    setScriptModalOpen(false);
  };

  const openProjectMenu = (
    e: React.SyntheticEvent,
    activator: IFlowActivatorWithParams,
  ) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setSelectedActivator(activator);
  };

  useEffect(() => {
    setSelectedActivator(
      flow?.activators.find(
        (activator) => activator._id === selectedActivator?._id,
      ) ?? null,
    );
  }, [flow]);

  useEffect(() => {
    setIsCopyActivator(false);
  }, [selectedActivator, creatingNewActivator]);

  useEffect(() => {
    if (!flow) return;
    setFilteredActivators(
      flow?.activators?.filter((activator) =>
        activator?.name.toLowerCase().includes(filterInput.toLowerCase()),
      ),
    );
  }, [flow, filterInput]);

  return (
    <Stack
      direction="row"
      gap="10px"
      sx={{
        minHeight: '500px',
      }}
    >
      <Stack
        sx={{
          width: '35%',
          alignSelf: 'start',
          height: '100%',
        }}
      >
        <Stack flexDirection="row" gap="5px">
          <Button
            variant="contained"
            sx={{
              width: activeFilterInput ? '22%' : '75%',
              textTransform: 'none',
              color: 'blue.3',
              borderRadius: '6px',
              minWidth: 'auto',
              border: '1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.1',
              fontWeight: 500,
              transition: 'all .1s',
              whiteSpace: 'pre',
              overflow: 'hidden',
              '&:hover': { color: 'blue.1', backgroundColor: 'blue.2' },
            }}
            onClick={() => {
              setCreatingNewActivator(true);
              setSelectedActivator(null);
            }}
          >
            {activeFilterInput ? <AddRoundedIcon /> : t('activators.newLink')}
          </Button>
          <Stack
            sx={{
              position: 'relative',
              width: activeFilterInput ? '78%' : '25%',
              transition: 'all .1s',
            }}
          >
            <TextField
              ref={inputRef}
              sx={{
                width: '100%',
                borderRadius: '6px',
                border: ' 1px solid',
                borderColor: 'grey.10',
                backgroundColor: 'gray.15',
              }}
              value={filterInput}
              onChange={(e) => setFilterInput(e.target.value)}
            />
            <Stack sx={{ position: 'absolute', top: '4px', right: '5px' }}>
              {activeFilterInput ? (
                <IconButton
                  onClick={() => {
                    setFilterInput('');
                    setActiveFilter(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setActiveFilter(true);
                    inputRef?.current?.focus();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
        {Boolean(filteredActivators?.length) ? (
          <Stack
            sx={{
              display: flow?.activators.length ? 'flex' : 'none',
              border: '1px solid',
              borderColor: 'grey.10',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              overflow: 'hidden',
              maxHeight: '400px',
              height: 'auto',
              overflowY: 'auto',
              mt: '10px',
            }}
          >
            {filteredActivators?.map((activator, index) => (
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{
                  transition: 'all .1s',
                  padding: '5px 15px',
                  cursor: 'pointer',
                  borderBottom:
                    flow && index !== flow?.activators?.length - 1
                      ? '1px solid'
                      : '',
                  borderColor: 'grey.10',
                  backgroundColor:
                    activator._id === selectedActivator?._id && !isCopyActivator
                      ? 'green.2'
                      : 'transparent',
                  color:
                    activator._id === selectedActivator?._id && !isCopyActivator
                      ? 'grey.16'
                      : 'grey.1',
                  '&:hover': {
                    backgroundColor:
                      activator._id !== selectedActivator?._id
                        ? 'green.1'
                        : 'green.2',
                  },
                }}
                key={activator._id}
                onClick={() => {
                  setSelectedActivator(activator);
                  setCreatingNewActivator(false);
                }}
              >
                <Typography>{activator.name}</Typography>
                <Stack onClick={(e) => e.stopPropagation()}>
                  <IconButton
                    onClick={(e) => openProjectMenu(e, activator)}
                    sx={{
                      alignItems: 'start',
                      height: 'fit-content',
                      padding: '0',
                      color:
                        selectedActivator?._id === activator._id &&
                        !isCopyActivator
                          ? 'grey.15'
                          : 'grey.1',
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <ThreeDotsMenu
                    open={Boolean(menuAnchor)}
                    onClose={() => setMenuAnchor(null)}
                    anchor={menuAnchor}
                    menuItems={[
                      [
                        {
                          text: t('common.copy'),
                          renderIcon: () => (
                            <ContentCopyIcon sx={{ color: 'grey.1' }} />
                          ),
                          onClick: () => {
                            onCopyActivator();
                          },
                        },
                      ],
                      [
                        {
                          text: t('common.delete'),
                          renderIcon: () => (
                            <DeleteIcon sx={{ color: 'red.2' }} />
                          ),
                          onClick: () => {
                            openConfirmationDialog(
                              `${t('activators.deleteActivatorLink')} ${
                                selectedActivator?.name
                              }?`,
                              t('common.delete'),
                              () => {
                                deleteFlowActivator(
                                  flow?._id ?? '',
                                  selectedActivator?._id ?? '',
                                ).then(refetchFlows);
                              },
                            );
                          },
                        },
                      ],
                    ]}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Stack sx={{ mt: '20px' }}>
            <Typography sx={{ textAlign: 'center' }}>
              {t('common.activators.notFound')}
            </Typography>
          </Stack>
        )}
        <Stack sx={{ mt: 'auto' }}>
          <Button
            onClick={onOpenScriptModal}
            startIcon={<ReceiptLongIcon />}
            sx={{
              mt: '10px',
              border: 'none',
              color: 'green.2',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: 'green.1',
                color: 'green.1',
              },
            }}
          >
            {t('common.activators.modalScript')}
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '1px',
          height: '100%',
          backgroundColor: 'grey.10',
        }}
      />
      <Stack
        sx={{
          width: '67%',
          alignSelf: 'start',
          height: '100%',
        }}
      >
        {!selectedActivator && !creatingNewActivator && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: 'grey.5',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: '500',
                width: '40%',
              }}
            >
              {t('channels.channelActivators.emptySelect')}
            </Typography>
          </Stack>
        )}
        {(creatingNewActivator || selectedActivator || isCopyActivator) && (
          <CreateEditActivator
            isCopyActivator={isCopyActivator}
            onCreateNewActivator={onCreateNewActivator}
            onEditActivator={onEditActivator}
            creatingNewActivator={creatingNewActivator}
            selectedActivator={selectedActivator}
            onResetSelectedActivator={onResetSelectedActivator}
          />
        )}
      </Stack>
      <ModalScriptInfo
        scriptModalOpen={scriptModalOpen}
        handleScriptModalClose={onCloseScriptModal}
        type="flow"
        flowId={flow?._id ?? ''}
        botId={flow?.telegramBot ?? ''}
        botUsername={bot?.username ?? ''}
      />
    </Stack>
  );
};

export default FlowActivatorsModal;
