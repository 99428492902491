import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import truncate from '../../utils/truncate';

import { ITelegramChannelWithActivators } from './interfaces';

interface ChannelCardProps {
  channel: ITelegramChannelWithActivators;
  onClick: () => void;
}

const ChannelCard: FC<ChannelCardProps> = ({ channel, onClick }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        padding: '12px',
        borderRadius: '12px',
        width: '240px',
        backgroundColor: 'grey.13',
      }}
    >
      <Stack direction="row" alignItems="center" gap="12px">
        {Boolean(channel.avatarUrl) ? (
          <img
            src={channel.avatarUrl}
            alt=""
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <CampaignRoundedIcon
            sx={{
              fontSize: '40px',
              color: 'blue.3',
            }}
          />
        )}

        <Stack>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {truncate(channel.title, 16)}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: 'grey.5',
            }}
          >
            {channel.subscribersCount} {t('channels.channelCard.subscribers')}
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{
          mt: '10px',
          textTransform: 'none',
          color: 'grey.1',
          backgroundColor: 'blue.2',
        }}
        onClick={onClick}
      >
        <SettingsRoundedIcon
          sx={{
            mr: '10px',
            mt: '-2px',
            fontSize: '21px',
          }}
        />
        {t('channels.channelCard.settings')}
      </Button>
    </Stack>
  );
};

export default ChannelCard;
