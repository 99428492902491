import {
  CreateTelegramBot,
  ITelegramBot,
} from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const createTelegramBot = async (newBot: CreateTelegramBot) => {
  const { data } = await axios.post('/telegram-bots/', {
    ...newBot,
  });

  return data as ITelegramBot;
};

export default createTelegramBot;
