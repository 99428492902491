import { Stack } from '@mui/material';
import React, { FC } from 'react';

import BroadcastCard from './broadcast-card';
import { IBroadcast } from './interfaces';

interface BroadcastListProps {
  broadcasts: IBroadcast[];
  onCopy: (broadcast: IBroadcast) => void;
  onEdit: (broadcast: IBroadcast) => void;
  onDelete: (broadcastId: IBroadcast['_id']) => void;
}

const BroadcastList: FC<BroadcastListProps> = ({
  broadcasts,
  onCopy,
  onEdit,
  onDelete,
}) => {
  return (
    <Stack
      direction="row"
      gap="18px"
      flexWrap="wrap"
      alignItems="start"
      sx={{
        margin: '0 auto',
        height: '100%',
        maxHeight: 'calc(100vh - 160px)',
        overflow: 'auto',
      }}
    >
      {broadcasts.map((broadcast) => (
        <BroadcastCard
          key={broadcast._id}
          name={broadcast.name}
          type={broadcast.type}
          status={broadcast.status}
          recipientsCount={broadcast.recipientsCount}
          startDate={broadcast.startTime}
          endDate={broadcast.endTime}
          onCopy={() => onCopy({ ...broadcast, _id: '' })}
          onEdit={() => onEdit(broadcast)}
          onDelete={() => onDelete(broadcast._id)}
        />
      ))}
    </Stack>
  );
};

export default BroadcastList;
