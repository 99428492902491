import { IFlowFolder } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const deleteFolder = async (_id: IFlowFolder['_id'], deleteFlow: boolean) => {
  const { data } = await axios.delete(
    `/flows/folders/${_id}?isFlowsDelete=${deleteFlow ? deleteFlow : ''}`,
  );

  return data;
};

export default deleteFolder;
