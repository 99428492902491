import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';
import { Button, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TELEGRAM_MESSAGE_MAX_LENGTH } from '../../../common/constants/constants';
import TelegramButtons from '../../../common/telegram/telegram.buttons';
import {
  IMedia,
  TelegramButton,
  TelegramMessageType,
} from '../../../interfaces/common';
import AttachedMediaPreview from '../../../telegram-messenger/dialogs/attached-media.preview';
import ButtonsModal from '../buttons-modal';

interface AddMediaButtonProps {
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  messageType: TelegramMessageType;
}

const AddMediaButton: FC<AddMediaButtonProps> = ({
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  messageType,
}) => {
  const { t } = useTranslation();

  const buttonDetails: {
    [key: string]: { icon: ReactNode; text: string };
  } = {
    [TelegramMessageType.media]: {
      icon: (
        <AddPhotoAlternateIcon
          sx={{
            width: '35px',
            height: '35px',
          }}
        />
      ),
      text: t('common.addPhoto'),
    },
    [TelegramMessageType.voice]: {
      icon: (
        <MicRoundedIcon
          sx={{
            width: '35px',
            height: '35px',
          }}
        />
      ),
      text: t('common.addAudio'),
    },

    [TelegramMessageType.videoNote]: {
      icon: (
        <SlowMotionVideoRoundedIcon
          sx={{
            width: '35px',
            height: '35px',
          }}
        />
      ),
      text: t('common.addVideo'),
    },
  };

  const onClickAddMedia = () => {
    switch (messageType) {
      case TelegramMessageType.media: {
        openMediaModal();
        return;
      }
      case TelegramMessageType.voice: {
        openVoiceModal();
        return;
      }
      case TelegramMessageType.videoNote: {
        openVideoNoteModal();
        return;
      }
    }
  };

  return (
    <Stack
      onClick={onClickAddMedia}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: '6px',
        border: '2px dashed',
        borderColor: 'grey.10',
        background: 'grey.13',
        width: '100px',
        minWidth: '100px',
        height: '102px',
        cursor: 'pointer',
      }}
    >
      {buttonDetails[messageType].icon}
      <Typography
        sx={{
          textAlign: 'center',
          mt: '5px',
        }}
      >
        {buttonDetails[messageType].text}
      </Typography>
    </Stack>
  );
};

interface TelegramMessageEditorProps {
  open: boolean;
  onClose: () => void;
  clearMessageState: () => void;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  newMessage: string;
  onAddRow: () => void;
  buttons: TelegramButton[][];
  isTelegramButtonsValid: boolean;
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddUrlButton: (buttonIndex: number) => void;
  onAddActionButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  onRemoveMedia: (_id: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  media: IMedia[];
  sendButtonDisabled: boolean;
  addNewButtonsRowDisabled: boolean;
  messageType: TelegramMessageType;
  onMessageTypeChange: (newType: TelegramMessageType) => void;
}

const TelegramMessageEditor: FC<TelegramMessageEditorProps> = ({
  open,
  onClose,
  clearMessageState,
  messageType,
  onMessageTypeChange,
  onInput,
  newMessage,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddUrlButton,
  onAddActionButton,
  onButtonChange,
  onRemoveMedia,
  onSubmit,
  media,
  sendButtonDisabled,
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  isTelegramButtonsValid,
}) => {
  const { t } = useTranslation();

  const [mediaTabIndex, setMediaTabIndex] = useState(0);
  const [buttonsModalOpen, setButtonsModalOpen] = useState(false);

  useEffect(() => {
    switch (messageType) {
      case TelegramMessageType.media: {
        setMediaTabIndex(0);
        return;
      }
      case TelegramMessageType.voice: {
        setMediaTabIndex(1);
        return;
      }
      case TelegramMessageType.videoNote: {
        setMediaTabIndex(2);
        return;
      }
    }
  }, [messageType]);

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    clearMessageState();
    switch (newValue) {
      case 0: {
        onMessageTypeChange(TelegramMessageType.media);
        return;
      }
      case 1: {
        onMessageTypeChange(TelegramMessageType.voice);
        return;
      }
      case 2: {
        onMessageTypeChange(TelegramMessageType.videoNote);
        return;
      }
    }
  };

  if (!open) return null;

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          borderRadius: '12px',
          p: '5px',
          backgroundColor: 'blue.1',
          mt: '10px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {t('flowNodes.messageLabel')}
        </Typography>
      </Stack>

      <Tabs
        value={mediaTabIndex}
        onChange={onTabChange}
        sx={{
          mx: '-12px',
          borderBottom: '1px solid',
          borderColor: 'grey.10',
          '& .MuiTabs-indicator': {
            backgroundColor: 'green.2',
          },
          '& .MuiTab-root': {},
          '& .MuiTab-root.Mui-selected': {
            color: 'green.2',
          },
        }}
      >
        <Tab label="Media" sx={{ width: '33.33%', textTransform: 'none' }} />
        <Tab label="Voice" sx={{ width: '33.33%', textTransform: 'none' }} />
        <Tab label="Video" sx={{ width: '33.33%', textTransform: 'none' }} />
      </Tabs>

      <Stack flexDirection="row" alignItems="center" sx={{ padding: '12px 0' }}>
        <Stack flexDirection="row" gap="10px">
          <AddMediaButton
            openMediaModal={openMediaModal}
            openVideoNoteModal={openVideoNoteModal}
            openVoiceModal={openVoiceModal}
            messageType={messageType}
          />
          <AttachedMediaPreview
            media={media}
            onRemoveMedia={onRemoveMedia}
            size="small"
            maxWidth="220px"
          />
        </Stack>
      </Stack>
      <Stack>
        <TextField
          sx={{
            backgroundColor: 'grey.15',
          }}
          inputProps={{
            maxLength: TELEGRAM_MESSAGE_MAX_LENGTH,
          }}
          multiline
          placeholder={
            messageType === TelegramMessageType.videoNote
              ? t('common.notSupportMessagePlaceholder')
              : t('common.enterMessagePlaceholder')
          }
          value={newMessage}
          minRows={5}
          maxRows={10}
          onChange={onInput}
          disabled={messageType === TelegramMessageType.videoNote}
        />
      </Stack>
      <Stack
        sx={{
          mt: '5px',
          textAlign: 'end',
          opacity: '0.4',
        }}
      >
        {newMessage.length}/{TELEGRAM_MESSAGE_MAX_LENGTH}
      </Stack>
      <Stack
        sx={{
          mt: '10px',
        }}
      >
        <TelegramButtons buttons={buttons} />
        <Button
          sx={{
            color: 'blue.2',
          }}
          disabled={Boolean(media.length > 1)}
          onClick={() => {
            setButtonsModalOpen(true);
          }}
        >
          {t('common.editButtons')}
        </Button>
      </Stack>

      <ButtonsModal
        open={buttonsModalOpen}
        isTelegramButtonsValid={isTelegramButtonsValid}
        onClose={() => {
          setButtonsModalOpen(false);
        }}
        buttons={buttons}
        onAddUrlButton={onAddUrlButton}
        onAddActionButton={onAddActionButton}
        onButtonChange={onButtonChange}
        onRemoveButton={onRemoveButton}
        onAddButtonsRow={onAddRow}
        onRemoveButtonsRow={onRemoveRow}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            width: '50%',
            color: 'red.2',
          }}
        >
          {t('common.cancel')}
        </Button>

        <Button
          sx={{
            width: '50%',
            fontSize: '18px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.2',
              color: 'grey.15',
            },
          }}
          variant="contained"
          onClick={onSubmit}
          disabled={sendButtonDisabled}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TelegramMessageEditor;
