import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CopyableText from '../common/copyableText/copyableText';
import ModalBox from '../common/modal/modal-box';

import { IUser } from './user.interface';

interface UserModalApiProps {
  open: boolean;
  handleClose: () => void;
  revokeApiKey: () => void;
  user: IUser;
}

const UserModalApi: FC<UserModalApiProps> = ({
  open,
  handleClose,
  revokeApiKey,
  user,
}) => {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  return (
    <ModalBox open={open} onClose={handleClose} maxWidth="555px">
      <Stack>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '130%',
            textAlign: 'center',
            color: 'grey.5',
            textTransform: 'none',
          }}
        >
          API Key
        </Typography>
        <Typography
          sx={{
            mt: '10px',
          }}
        >
          {t('user.apiText')}
        </Typography>
        <Stack
          sx={{
            margin: '5px 0 12px',
            backgroundColor: 'grey.15',
            border: '1px solid ',
            borderColor: 'grey.10',
            borderRadius: '6px',
            p: '10px',
          }}
        >
          <CopyableText
            text={`${API_URL}/postback/${user.apiKey}/{{clickId}}?status=REG&params[sub2]=processed`}
          />
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap="8px">
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '130%',
              color: 'grey.5',
            }}
          >
            API key
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '130%',
              color: 'green.2',
            }}
          >
            {user.apiKey}
          </Typography>
          <Button
            onClick={revokeApiKey}
            startIcon={<RefreshIcon sx={{ width: '24px', height: '24px' }} />}
            variant="contained"
            sx={{
              ml: '15px',
              height: '40px',
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'green.2',
              backgroundColor: 'green.2',
              display: 'flex',
              alignItems: 'center',
              color: 'grey.15',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '130%',
              maxWidth: '128px',
              ':hover': { backgroundColor: 'green.2' },
            }}
          >
            {t('user.updateButton')}
          </Button>
        </Stack>
        <Stack gap="15px" sx={{ mt: '10px' }}>
          <Typography>
            status - {t('user.apiKey.status')} lead/reg/dep
          </Typography>
          <Typography>clickId - {t('user.apiKey.clickId')}</Typography>
          <Typography>params[key]=value - {t('user.apiKey.params')}</Typography>
        </Stack>
        <Button
          variant="contained"
          sx={{
            mt: '22px',
            p: '10px',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '130%',
            textAlign: 'center',
            color: 'grey.1',
            backgroundColor: 'blue.2',
            borderRadius: '6px',
            textTransform: 'none',
            ':hover': { backgroundColor: 'blue.3' },
          }}
          onClick={handleClose}
        >
          {t('user.closeModalBtn')}
        </Button>
      </Stack>
    </ModalBox>
  );
};

export default UserModalApi;
