import { CreateBroadcast } from '../../telegram-bots/broadcasts/interfaces';
import axios from '../axios';

const calculateBroadcastReceivers = async (
  telegramBotId: string,
  broadcastData: Pick<CreateBroadcast, 'statusConditions' | 'paramConditions'>,
) => {
  const { data } = await axios.post(
    `/broadcasts/${telegramBotId}/calculate-receivers`,
    broadcastData,
  );

  return data;
};

export default calculateBroadcastReceivers;
