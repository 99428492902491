import { CreateFlowFolder } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const createFolder = async (newFolder: CreateFlowFolder) => {
  const { data } = await axios.post('/flows/new-folder', newFolder);

  return data;
};

export default createFolder;
