import type { SxProps } from '@mui/material';
import { alpha, Modal, Stack } from '@mui/material';
import React, { FC } from 'react';

import useThemeColors from '../../utils/hooks/useThemeColors';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  maxWidth?: string;
  onClose: () => void;
  sx?: SxProps;
}

const ModalBox: FC<ModalProps> = ({
  children,
  open,
  onClose,
  maxWidth = '303px',
  sx = {},
}) => {
  const themeColors = useThemeColors();

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: alpha(themeColors.grey['16'], 0.3),
        backdropFilter: 'blur(2px)',
      }}
    >
      <Stack
        onClick={(e) => e.stopPropagation()}
        sx={{
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          borderRadius: '18px',
          padding: '18px',
          width: '100%',
          maxWidth,
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Modal>
  );
};

export default ModalBox;
