import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ModalBox from '../../common/modal/modal-box';
import TelegramButtonsRow from '../../common/telegram/telegram-buttons.row';
import { TelegramButton } from '../../interfaces/common';

interface ButtonsModalProps {
  open: boolean;
  isTelegramButtonsValid: boolean;
  onClose: () => void;
  buttons: TelegramButton[][];
  onButtonChange: (
    rowIndex: number,
    btnIndex: number,
    key: string,
    value: string,
  ) => void;
  onRemoveButtonsRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, btnIndex: number) => void;
  onAddButtonsRow: () => void;
  onAddUrlButton: (buttonIndex: number) => void;
  onAddActionButton: (buttonIndex: number) => void;
}

const ButtonsModal: FC<ButtonsModalProps> = ({
  open,
  isTelegramButtonsValid,
  onClose,
  buttons,
  onButtonChange,
  onRemoveButtonsRow,
  onRemoveButton,
  onAddButtonsRow,
  onAddUrlButton,
  onAddActionButton,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="650px"
      sx={{ height: 'max-content', maxHeight: '90%', overflow: 'auto' }}
    >
      <Stack>
        <Stack gap="5px">
          {buttons.map((row, rowIndex) => (
            <TelegramButtonsRow
              key={'buttons-row-' + rowIndex}
              urlExtended={true}
              buttons={row}
              onRemoveRow={() => {
                onRemoveButtonsRow(rowIndex);
              }}
              showAddActionButton
              onRemoveButton={(buttonIndex) => {
                onRemoveButton(rowIndex, buttonIndex);
              }}
              onAddNewUrlButton={() => {
                onAddUrlButton(rowIndex);
              }}
              onAddNewActionButton={() => {
                onAddActionButton(rowIndex);
              }}
              onButtonChange={(buttonIndex, key, value) => {
                onButtonChange(rowIndex, buttonIndex, key, value);
              }}
              isTelegramButtonsValid={isTelegramButtonsValid}
            />
          ))}
        </Stack>
        <Button
          sx={{
            color: 'blue.2',
            fontSize: '14px',
            fontWeight: '600',
            backgroundColor: 'transparent',
            border: 'none',
            ':hover': { border: 'none' },
          }}
          variant="outlined"
          disabled={buttons.length > 4}
          onClick={onAddButtonsRow}
        >
          {t('telegramButtons.addRow')} +
        </Button>
        <Stack>
          <Button
            onClick={onClose}
            sx={{
              borderRadius: '6px',
              backgroundColor: 'blue.2',
              color: 'grey.1',
              width: '290px',
              height: '43px',
              margin: '10px auto 0',
              '&:hover': { backgroundColor: 'blue.3' },
            }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default ButtonsModal;
