import { IChannelActivatorWithParams } from '../../telegram-bots/channels/interfaces';
import axios from '../axios';

const createChannelActivator = async (
  botId: string,
  channelId: string,
  newChannelActivator: Omit<
    IChannelActivatorWithParams,
    | 'createdAt'
    | 'updatedAt'
    | '_id'
    | 'channel'
    | 'hidden'
    | 'inviteLink'
    | 'joinRequestsCount'
  >,
) => {
  const { data } = await axios.post(
    `/telegram-channels/activator/${botId}/${channelId}`,
    newChannelActivator,
  );

  return data;
};

export default createChannelActivator;
