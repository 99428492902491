import { IFlow, IFlowActivator } from '../../telegram-bots/flows/interfaces';
import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

export const deleteChannelActivator = async (
  botId: ITelegramBot['_id'],
  channelId: IFlow['_id'],
  channelActivatorId: IFlowActivator['_id'],
) => {
  const { data } = await axios.delete(
    `/telegram-channels/activator/${botId}/${channelId}/${channelActivatorId}`,
  );

  return data;
};
