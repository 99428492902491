import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TooltipInfo from '../../common/tooltip/tooltip';
import { IProjectMember } from '../../projects/project.interface';
import {
  ITelegramUserAccess,
  TelegramUserMessengerAccess,
} from '../telegram-user.interface';

interface AccessTableProps {
  owner: string;
  members: IProjectMember[];
  accesses: ITelegramUserAccess[];
  setAccesses: (accesses: ITelegramUserAccess[]) => void;
}

function AccessTableUserBot({
  members,
  accesses,
  setAccesses,
  owner,
}: AccessTableProps) {
  const { t } = useTranslation();

  const handleCheckboxChange = (
    memberId: string,
    key: keyof ITelegramUserAccess,
    value: boolean | TelegramUserMessengerAccess,
  ) => {
    setAccesses(
      accesses.map((access) =>
        access.user === memberId ? { ...access, [key]: value } : access,
      ),
    );
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', flex: '1 1 auto' }}>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          maxHeight: 440,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              backgroundColor: 'grey.10',
              borderBottom: '1px solid rgba(81, 81, 81, 1)',
            }}
          >
            <TableRow
              sx={{
                backgroundColor: 'grey.12',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '6px',
                position: 'sticky',
                top: '0',
                zIndex: '12',
              }}
            >
              <TableCell
                sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
              >
                {t('common.name')}
              </TableCell>

              <TableCell
                sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
              >
                {t('common.lastName')}
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
              >
                {t('common.role')}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={<ChatRoundedIcon sx={{ color: 'grey.5' }} />}
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessMessenger',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.messenger')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={<SupportAgentRoundedIcon sx={{ color: 'grey.5' }} />}
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessManager',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.manager')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={<StarIcon sx={{ color: 'grey.5' }} />}
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessAdmin',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.admin')}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member, index) => {
              const access =
                accesses.find((access) => access.user === member.user._id) ||
                ({} as ITelegramUserAccess);
              const isEven = index % 2 === 0;
              return (
                <TableRow
                  sx={{
                    backgroundColor: isEven ? 'grey.15' : 'grey.13',
                    borderBottom: '1px solid rgba(81, 81, 81, 1)',
                  }}
                  key={member._id}
                >
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member.user.firstName}
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member.user.lastName ?? ''}
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member.roleDescription}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      border: 'none',
                      padding: '6px 16px',
                    }}
                  >
                    <FormControlLabel
                      sx={{ margin: '0' }}
                      control={
                        <Checkbox
                          disabled={member.user._id === owner}
                          checked={
                            access.messenger !==
                              TelegramUserMessengerAccess.none ||
                            member.user._id === owner
                          }
                          onChange={(event) =>
                            handleCheckboxChange(
                              member.user._id,
                              'messenger',
                              event.target.checked
                                ? TelegramUserMessengerAccess.manager
                                : TelegramUserMessengerAccess.none,
                            )
                          }
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      checked={
                        access.messenger === TelegramUserMessengerAccess.manager
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          member.user._id,
                          'messenger',
                          TelegramUserMessengerAccess.manager,
                        )
                      }
                      disabled={
                        access.messenger === TelegramUserMessengerAccess.none ||
                        member.user._id === owner
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      checked={
                        access.messenger ===
                          TelegramUserMessengerAccess.admin ||
                        member.user._id === owner
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          member.user._id,
                          'messenger',
                          TelegramUserMessengerAccess.admin,
                        )
                      }
                      disabled={
                        access.messenger === TelegramUserMessengerAccess.none ||
                        member.user._id === owner
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default AccessTableUserBot;
