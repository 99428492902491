import { Stack } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import Navbar from '../navbar/navbar';

interface WithNavProps {
  children: ReactNode;
}

const WithNav: FC<WithNavProps> = ({ children }) => {
  return (
    <Stack>
      <Navbar />
      {children}
    </Stack>
  );
};

export default WithNav;
