import PersonIcon from '@mui/icons-material/Person';
import { Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconRobot from '../assets/icons/RobotIcon';
import defaultBotAvatar from '../assets/images/defaultBotAvatar.png';
import { ProjectContext } from '../projects/project.context';
import ProjectScreens from '../projects/screens/project-screens.enum';
import { ITelegramBotWithAccess } from '../telegram-bots/telegram-bot.interface';
import truncate from '../utils/truncate';

import { ITelegramUserAccountWithAccess } from './telegram-user.interface';

interface TelegramBotAndAccountThumbProps {
  bot: ITelegramBotWithAccess | ITelegramUserAccountWithAccess;
  botType: string;
  selected: boolean;
}

const TelegramBotAndAccountThumb: FC<TelegramBotAndAccountThumbProps> = ({
  bot,
  botType,
  selected,
}) => {
  const { project } = useContext(ProjectContext);
  const navigate = useNavigate();

  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [nameSplit, setNameSplit] = useState<string[]>(['', '']);

  const onSelect = () => {
    if ('userAccount' in bot) {
      navigate(
        `/project/${project._id}/${ProjectScreens.userAccount}/${bot.userAccount._id}`,
      );
    } else if ('bot' in bot) {
      navigate(`/project/${project._id}/${ProjectScreens.bot}/${bot.bot._id}`);
    }
  };

  useEffect(() => {
    if ('userAccount' in bot) {
      setAvatarUrl(bot.userAccount.avatarUrl);
      setNameSplit(bot.userAccount.name.split(' '));
    } else if ('bot' in bot) {
      setAvatarUrl(bot.bot.avatarUrl);
      setNameSplit(bot.bot.name.split(' '));
    }
  }, [bot]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'relative',
        height: '95px',
        cursor: 'pointer',
        backgroundColor: selected ? 'green.1' : 'transparent',
      }}
      onClick={onSelect}
    >
      <img
        src={avatarUrl ? avatarUrl : defaultBotAvatar}
        alt=""
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
        }}
      />
      <Typography
        sx={{
          fontSize: '12px',
          color: selected ? 'grey.1' : 'grey.6',
        }}
      >
        {truncate(nameSplit[0], 10)}
      </Typography>

      <Typography
        sx={{
          fontSize: '12px',
          color: selected ? 'grey.1' : 'grey.6',
        }}
      >
        {truncate(nameSplit[1] ?? '', 10)}
      </Typography>
      <Stack
        sx={{
          position: 'absolute',
          top: '2px',
          right: '0',
          width: '17px',
          height: '17px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: botType === 'BOT' ? 'blue.2' : 'purple.4',
        }}
      >
        {botType === 'BOT' ? (
          <IconRobot style={{ padding: '2px' }} />
        ) : (
          <PersonIcon sx={{ padding: '4px' }} />
        )}
      </Stack>
    </Stack>
  );
};

export default TelegramBotAndAccountThumb;
