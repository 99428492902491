import { Stack } from '@mui/material';
import React, { CSSProperties, FC } from 'react';

interface PhotoPreviewProps {
  imageSrc: string;
  imgStyle?: CSSProperties;
}

const PhotoPreview: FC<PhotoPreviewProps> = ({ imageSrc, imgStyle = {} }) => {
  return (
    <Stack>
      <img
        src={imageSrc}
        alt=""
        style={{
          borderRadius: '4px',
          maxWidth: '100%',
          maxHeight: '250px',
          ...imgStyle,
        }}
      />
    </Stack>
  );
};

export default PhotoPreview;
