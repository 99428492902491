import { IFlowActivatorWithParams } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const createFlowActivator = async (
  flowId: string,
  newFlowActivator: Omit<
    IFlowActivatorWithParams,
    'createdAt' | 'updatedAt' | '_id' | 'flow'
  >,
) => {
  const { data } = await axios.post(
    `/flows/${flowId}/new-activator`,
    newFlowActivator,
  );

  return data;
};

export default createFlowActivator;
