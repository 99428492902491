import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import leaveProject from '../api/projects/leave-project';
import { AppContext } from '../AppContext';
import { GlobalContext } from '../GlobalContext';
import { UserContext } from '../user/user.context';

import IProject from './project.interface';
import ProjectScreens from './screens/project-screens.enum';

const authBotUsername = process.env.REACT_APP_TELEGRAM_AUTH_BOT_USERNAME;

interface ProjectCardProps {
  project: IProject;
}

const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { refetchProjects } = useContext(GlobalContext);
  const { openConfirmationDialog, triggerSnackbar } = useContext(AppContext);

  const navigate = useNavigate();

  const onSelectProject = () => {
    navigate(`/project/${project._id}`);
  };

  const onGoToSettings = () => {
    navigate(`/project/${project._id}/${ProjectScreens.settings}`);
  };

  const onCopyText = () => {
    navigator.clipboard.writeText(
      `https://t.me/${authBotUsername}?start=jp-${project?.inviteCode}` as string,
    );
    triggerSnackbar(`${t('projects.triggerInviteLink')}`);
  };
  return (
    <Stack
      sx={{
        width: '272px',
        backgroundColor: 'grey.14',
        borderRadius: '18px',
        padding: '12px',
        border: '1px solid',
        borderColor: 'grey.10',
        borderLeft: '5px solid',
        borderLeftColor: project.color,
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        zIndex: '1',
      }}
      onClick={onSelectProject}
    >
      <Typography sx={{ width: '75%' }}>{project.name}</Typography>
      <Typography
        sx={{
          mt: '10px',
          mb: '6px',
          opacity: '0.4',
        }}
      >
        {project.description}
      </Typography>

      <Stack
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: '12',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {project?.owner === user?._id ? (
          <Stack flexDirection="row">
            <IconButton
              sx={{ color: 'green.2', width: '30px', height: '30px' }}
              onClick={onCopyText}
            >
              <PersonAddAlt1Icon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
            <IconButton
              sx={{ width: '30px', height: '30px' }}
              onClick={onGoToSettings}
            >
              <SettingsIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Stack>
        ) : (
          <IconButton
            sx={{ width: '30px', height: '30px' }}
            onClick={() =>
              openConfirmationDialog(
                `${t('projects.settings.leave')} ${project.name}?`,
                `${t('common.leave')}`,
                () => {
                  leaveProject(project._id).then(refetchProjects);
                },
              )
            }
          >
            <LogoutIcon
              sx={{ color: 'red.2', width: '20px', height: '20px' }}
            />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default ProjectCard;
