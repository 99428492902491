import { EmojiClickData } from 'emoji-picker-react';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  IMedia,
  TelegramButton,
  TelegramMessageType,
} from '../../interfaces/common';

import useMediaEditor from './useMediaEditor';
import useTelegramButtonsEditor from './useTelegramButtonsEditor';

const useNewTelegramMessage = (
  initialMedia: IMedia[] = [],
  initialButtons: TelegramButton[][] = [],
) => {
  const [messageType, setMessageType] = useState<TelegramMessageType>(
    TelegramMessageType.media,
  );

  const [newMessageText, setNewMessageText] = useState<string>('');

  const mediaEditor = useMediaEditor(
    initialMedia,
    messageType !== TelegramMessageType.media,
  );
  const buttonsEditor = useTelegramButtonsEditor(initialButtons);

  const onChangeNewMessageText = (e: ChangeEvent<HTMLInputElement>) =>
    setNewMessageText(e.target.value);

  const clearMessageState = (initialText = '') => {
    setNewMessageText(initialText);
    buttonsEditor.updateButtons([]);
    mediaEditor.updateMedia([]);
  };

  const onEmojiPick = (data: EmojiClickData) => {
    setNewMessageText((prev) => prev + data.emoji);
  };

  const onMessageTypeChange = (newMessageType: TelegramMessageType) =>
    setMessageType(newMessageType);

  const onChangeInitialMessage = (
    text: string,
    buttons: TelegramButton[][],
    media: IMedia[],
    type?: TelegramMessageType,
  ) => {
    mediaEditor.updateMedia([...media]);
    setNewMessageText(text);
    setMessageType(type ?? TelegramMessageType.media);
    buttonsEditor.updateButtons([...buttons]);
  };

  const newMessageValid = useMemo(
    () =>
      (!mediaEditor.isMediaEmpty && buttonsEditor.isTelegramButtonsValid) ||
      (Boolean(newMessageText) && buttonsEditor.isEmptyButtons),
    [newMessageText, mediaEditor.isMediaEmpty, buttonsEditor.isEmptyButtons],
  );

  const addNewButtonsRowDisabled = useMemo(
    () => buttonsEditor.buttons.length > 4 || mediaEditor.media.length > 1,
    [mediaEditor.media, buttonsEditor.buttons],
  );

  useEffect(() => {
    if (mediaEditor.media.length > 1) {
      buttonsEditor.updateButtons([]);
    }
  }, [mediaEditor.media]);

  return {
    newMessageText,
    onChangeNewMessageText,
    setNewMessageText,
    onEmojiPick,
    clearMessageState,
    newMessageValid,
    messageType,
    onMessageTypeChange,
    onChangeInitialMessage,
    addNewButtonsRowDisabled,
    ...mediaEditor,
    ...buttonsEditor,
  };
};

export default useNewTelegramMessage;
