import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { io, Socket } from 'socket.io-client';

import { ITelegramBotDialogExtended } from '../../../interfaces/common';
import { BotContext } from '../../../telegram-bots/bot.context';
import { ITelegramUserDialogExtended } from '../../../telegram-messenger/dialogs/interfaces/user-dialog.interface';
import { UserAccountContext } from '../../../telegram-user-accounts/user-account.context';

import { ClientEventHandlers, ServerEvents } from './sockets.interfaces';

const APP_URL = process.env.REACT_APP_URL ?? '';

const token = window.localStorage.getItem('token');

interface ISocketContext {
  socket: Socket;
}

export const SocketContext = createContext<ISocketContext>(
  {} as ISocketContext,
);

const SocketContextProvider: FC<{
  isUserAccount: boolean;
  children: ReactNode;
}> = ({ isUserAccount, children }) => {
  const { bot } = useContext(BotContext);
  const { userAccount } = useContext(UserAccountContext);

  const socketRef = useRef<Socket | null>(null);
  const [isSocket, setIsSocket] = useState(false);

  useEffect(() => {
    try {
      socketRef.current = io(APP_URL, {
        autoConnect: true,
        withCredentials: true,
        query: {
          token,
          botId: isUserAccount ? '' : bot?._id,
          accountId: isUserAccount ? userAccount._id : '',
        },
        path: '/api/io',
      });

      const currentSocket = socketRef.current;

      setIsSocket(!!socketRef.current);
      return () => {
        currentSocket?.disconnect();
      };
    } catch {
      setIsSocket(false);
    }
  }, [bot, userAccount]);

  if (!isSocket) return null;

  return (
    <SocketContext.Provider
      value={{
        socket: isUserAccount
          ? (socketRef.current as Socket<
              ServerEvents,
              ClientEventHandlers<ITelegramUserDialogExtended>
            >)
          : (socketRef.current as Socket<
              ServerEvents,
              ClientEventHandlers<ITelegramBotDialogExtended>
            >),
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
