import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createTelegramBot from '../../../api/bots/create-bot';
import { AppContext } from '../../../AppContext';
import animation from '../../../assets/lottie/animation.json';
import TooltipInfo from '../../../common/tooltip/tooltip';
import BotSettings from '../../../telegram-bots/settings/bot.settings';
import { ITelegramBot } from '../../../telegram-bots/telegram-bot.interface';
import { ProjectContext } from '../../project.context';

const NewTelegramBot: FC = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { project, refetchBots } = useContext(ProjectContext);
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;

    if (newName.length < 25) {
      setName(newName);
    }
  };

  const [createdBot, setCreatedBot] = useState<ITelegramBot | null>(null);

  const onChangeToken = (e: ChangeEvent<HTMLInputElement>) => {
    const newToken = e.target.value;
    setToken(newToken);
  };

  const [loading, setLoading] = useState(false);

  const onCreateTelegramBot = async () => {
    setLoading(true);

    try {
      const createdBot = await createTelegramBot({
        name,
        token,
        project: project._id,
        defaultFlow: null,
      });

      setCreatedBot(createdBot);
      triggerSnackbar(t('snackbar.botCreated'), 'success');
    } catch (e) {
      triggerSnackbar(t('snackbar.botNotCreated'), 'error');
    }

    setLoading(false);
    refetchBots();
  };

  return (
    <Stack
      direction="row"
      gap="30px"
      justifyContent="center"
      sx={{
        padding: '40px',
      }}
    >
      {!loading && !createdBot && (
        <Stack
          sx={{
            maxHeight: '305px',
            minWidth: '380px',
            backgroundColor: 'grey.14',
            padding: '20px',
            borderRadius: '24px',
            border: '1px solid',
            borderColor: 'grey.10',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '130%',
              textAlign: 'center',
            }}
          >
            {t('projects.screen-new-telegram-bot.listTitle')}
          </Typography>
          <Stack>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '130%',
                mb: '8px',
              }}
            >
              {t('projects.screen-new-telegram-bot.listNameLabel')}
            </Typography>
            <TextField
              value={name}
              onChange={onChangeName}
              placeholder={t(
                'projects.screen-new-telegram-bot.listNamePlaceholder',
              )}
            />
            <Stack
              flexDirection="row"
              alignItems="center"
              gap="10px"
              sx={{ m: '8px 0' }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '130%',
                }}
              >
                {t('projects.screen-new-telegram-bot.listBotTokenLabel')}
              </Typography>
              <TooltipInfo
                icon={
                  <InfoOutlinedIcon
                    sx={{ color: 'grey.5', fontSize: '15px' }}
                  />
                }
                title={t('projects.screen-new-telegram-bot.tooltips.botToken')}
              />
            </Stack>
            <TextField
              value={token}
              onChange={onChangeToken}
              placeholder={t(
                'projects.screen-new-telegram-bot.listBotTokenPlaceholder',
              )}
            />
          </Stack>
          <Divider />
          <Button
            variant="contained"
            sx={{
              height: '44px',
              color: 'grey.1',
              backgroundColor: 'blue.2',
              fontWeight: '600',
              textTransform: 'none',
              cursor: 'pointer',
            }}
            onClick={onCreateTelegramBot}
            disabled={!name || !token || !token.includes(':')}
          >
            {t('projects.screen-new-telegram-bot.listButtonNext')}
          </Button>
        </Stack>
      )}

      {loading && (
        <Stack
          sx={{
            maxWidth: '400px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '28px',
              color: 'grey.6',
            }}
          >
            Створюємо вашого бота...
          </Typography>
          <Lottie animationData={animation} />
        </Stack>
      )}

      {createdBot && <BotSettings bot={createdBot} />}
    </Stack>
  );
};

export default NewTelegramBot;
