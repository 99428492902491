export enum MessagesServerEventNames {
  pushNewMessage = 'pushNewMessage',
  pushMessages = 'pushMessages',
  pushEditedMessage = 'pushEditedMessage',
}

export enum DialogsServerEventNames {
  pushDialogs = 'pushDialogs',
  pushUpdatedDialog = 'pushUpdatedDialog',
  pushTags = 'pushTags',
  hideDialogs = 'hideDialogs',
}

export enum AdminServerEventNames {
  pushMessengerModeUpdate = 'pushMessengerModeUpdate',
}
