import {
  CreateTelegramUserAccount,
  ITelegramUserAccount,
} from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const createTelegramUserAccount = async (
  newUserAccount: CreateTelegramUserAccount,
) => {
  const { data } = await axios.post('/telegram-users/', {
    ...newUserAccount,
  });

  return data as ITelegramUserAccount;
};

export default createTelegramUserAccount;
