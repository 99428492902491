import { ITelegramBotAccess } from '../../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccess } from '../../telegram-user-accounts/telegram-user.interface';

type AnyAccess = ITelegramBotAccess | ITelegramUserAccess;

const useTelegramAccess = (
  botAccess: ITelegramBotAccess,
  userAccountAccess: ITelegramUserAccess,
  isBot: boolean,
) => {
  return isBot ? (botAccess as AnyAccess) : (userAccountAccess as AnyAccess);
};

export default useTelegramAccess;
