import { Stack } from '@mui/material';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../AppContext';
import WithNav from '../common/layout/with-nav';

import UserCard from './user.card';
import { UserContext } from './user.context';
import UserModalApi from './user.modal-api';

const UserPage = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { user, handleEditUser, updateApiKey } = useContext(UserContext);

  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [clientLanguage, setClientLanguage] = useState('');

  if (!user) return null;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
  };

  const handleChangeClientLang = (lang: string) => {
    setClientLanguage(lang);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editUser = () => {
    handleEditUser({ firstName, lastName, clientLanguage });
    triggerSnackbar(t('common.changes_saved'));
  };
  const revokeApiKey = () => {
    updateApiKey();
    triggerSnackbar('API key changed');
  };

  useEffect(() => {
    setFirstName(user.firstName ?? '');
    setLastName(user.lastName ?? '');
    setClientLanguage(user.clientLanguage ?? '');
  }, [user]);

  const saveButtonDisabled =
    !Boolean(firstName) ||
    user.firstName + user.lastName + user.clientLanguage ===
      firstName + lastName + clientLanguage;

  return (
    <WithNav>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <UserCard
          disabledBtn={saveButtonDisabled}
          editUser={editUser}
          handleOpenModal={handleOpenModal}
          avatarUrl={user?.avatarUrl}
          firstName={firstName}
          lastName={lastName}
          handleChangeName={handleChangeName}
          handleChangeLastName={handleChangeLastName}
          clientLang={clientLanguage}
          onChangeClientLang={handleChangeClientLang}
        />
        <UserModalApi
          revokeApiKey={revokeApiKey}
          open={openModal}
          handleClose={handleCloseModal}
          user={user}
        />
      </Stack>
    </WithNav>
  );
};

export default UserPage;
