import {
  IFlow,
  IFlowActivator,
  UpdateFlowActivatorWithParams,
} from '../../telegram-bots/flows/interfaces';
import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const editChannelActivator = async (
  botId: ITelegramBot['_id'],
  channelId: IFlow['_id'],
  channelActivatorId: IFlowActivator['_id'],
  newFlowActivatorData: UpdateFlowActivatorWithParams,
) => {
  const { data } = await axios.patch(
    `/telegram-channels/activator/${botId}/${channelId}/${channelActivatorId}`,
    newFlowActivatorData,
  );

  return data;
};

export default editChannelActivator;
