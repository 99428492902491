import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
  Stack,
  Chip,
  FormControl,
  TextField,
  Typography,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import onBlurOutside from '../../hooks/useClickOutside';
import { ITag } from '../../interfaces/common';

interface TagsBoxProps {
  allTags: ITag[];
  tags: ITag[];
  setCurrentTagToDialog: (tag: ITag['_id']) => void;
  deleteCurrentTagFromDialog: (tag: ITag['_id']) => void;
  dialogTags: ITag[];
  createTag: () => void;
  visibleAllTags: boolean;
  setVisibleAllTags: (state: boolean) => void;
  inputTag: string;
  setInputTag: (value: string) => void;
}

const TagsBox: FC<TagsBoxProps> = ({
  allTags,
  tags,
  setCurrentTagToDialog,
  deleteCurrentTagFromDialog,
  dialogTags,
  createTag,
  visibleAllTags,
  setVisibleAllTags,
  inputTag,
  setInputTag,
}) => {
  const { t } = useTranslation();

  const ref = onBlurOutside(() => setVisibleAllTags(false));
  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      gap={'10px'}
      alignItems={'center'}
      sx={{
        width: '100%',
        padding: '10px 5px 12px 5px',
      }}
    >
      {dialogTags.map((tag: ITag) => (
        <Chip
          onDelete={() => deleteCurrentTagFromDialog(tag._id)}
          key={tag.text}
          label={tag.text}
          sx={{ backgroundColor: 'purple.1' }}
        />
      ))}
      <Stack sx={{ position: 'relative' }} ref={ref}>
        <FormControl variant="outlined">
          <TextField
            inputRef={(inp) => inp && inp.focus()}
            onChange={(e) => setInputTag(e.target.value)}
            value={inputTag}
            disabled={!visibleAllTags}
            sx={{
              border: 'none',
              p: '0',
              transition: 'all 0.25s ease',
              backgroundColor: 'purple.1',
              borderTopRightRadius: '13px',
              borderTopLeftRadius: '13px',
              borderBottomRightRadius: visibleAllTags ? '0' : '13px',
              borderBottomLeftRadius: visibleAllTags ? '0' : '13px',
              width: visibleAllTags ? '100%' : '30px',
              height: '30px',
              margin: '0',
            }}
            placeholder={t('messenger.currentDialog.tags.tagsPlaceholder')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') return createTag();
            }}
            variant="outlined"
            InputProps={{
              startAdornment: !visibleAllTags && (
                <InputAdornment
                  onClick={() => setVisibleAllTags(true)}
                  position="start"
                  sx={{
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    marginRight: '0',
                    marginLeft: '3px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon />
                </InputAdornment>
              ),
              endAdornment: visibleAllTags && (
                <InputAdornment
                  onClick={createTag}
                  disablePointerEvents={
                    Boolean(
                      allTags.filter((tag) => tag.text === inputTag).length,
                    ) || !inputTag
                  }
                  position="start"
                  sx={{
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px',
                    padding: '10px',
                    marginRight: '0',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.26)',
                    borderRadius: '50%',
                    margin: '5px 5px 0 0',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    },
                  }}
                >
                  <CheckIcon
                    sx={{
                      color: 'purple.1',
                    }}
                  />
                </InputAdornment>
              ),
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  background: 'transparent',
                },
                '& .MuiOutlinedInput-input.Mui-disabled': {
                  border: 'none',
                  background: 'transparent',
                },
                padding: '0',
                height: '30px',
              },
            }}
            inputProps={{
              maxLength: '20',
              sx: {
                width: visibleAllTags ? '100%' : '30px',
                backgroundColor: visibleAllTags
                  ? '#461973 !important'
                  : 'transparent',
                p: '0 10px!important',
                height: '30px',
                margin: '0',
              },
            }}
          />
        </FormControl>
        <Stack
          sx={{
            width: visibleAllTags ? '100%' : '0px',
            height: visibleAllTags ? '100%' : '30px',
            backgroundColor: 'purple.1',
            borderTopRightRadius: visibleAllTags ? '0' : '13px',
            borderTopLeftRadius: visibleAllTags ? '0' : '13px',
            borderBottomRightRadius: visibleAllTags ? '13px' : '0',
            borderBottomLeftRadius: visibleAllTags ? '13px' : '0',
            maxHeight: '100px',
            minHeight:
              tags.length > 2 ? '100px' : tags.length > 1 ? '72px' : '40px',
            zIndex: '12',
            overflowY: 'auto',
            position: 'absolute',
            top: '30px',
            left: '0',
          }}
        >
          {tags.length >= 1 ? (
            tags.map((tag) => (
              <MenuItem
                key={tag._id}
                onClick={() => setCurrentTagToDialog(tag._id)}
              >
                {tag.text}
              </MenuItem>
            ))
          ) : (
            <Typography
              sx={{ textAlign: 'center', mt: '5px', color: '#ffffff70' }}
            >
              {t('messenger.currentDialog.tags.noMoreTags')}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TagsBox;
