import React, { useCallback } from 'react';
import { Connection, Node } from 'reactflow';

import { IConditionsNodeData } from '../flow-nodes/conditions.node';
import { ITelegramMessageNodeData } from '../flow-nodes/telegram-message-node';
import { NodeData } from '../interfaces';

import updateActionButtons from './updateActionButtons';
import updateConditionsOnConnect from './updateConditionsOnConnect';

const useOnNodesConnect = (
  setNodes: React.Dispatch<
    React.SetStateAction<Node<NodeData, string | undefined>[]>
  >,
) => {
  return useCallback((params: Connection) => {
    setNodes((prev) => {
      const result = [];

      const split = params.sourceHandle?.split('-');

      let isAction = false;
      let isCondition = false;
      const [handleType, source, primeIndex, subIndex] = split ?? [];

      if (split) {
        isAction = Boolean(handleType === 'action');
        isCondition = Boolean(handleType === 'condition');
      }

      for (const node of prev) {
        if (isAction) {
          if (node.id !== source) {
            result.push(node as Node);
          } else {
            const newButtons = updateActionButtons(
              (node.data as ITelegramMessageNodeData).data.buttons,
              parseInt(primeIndex),
              parseInt(subIndex),
              params.target ?? '',
            );
            result.push({
              ...node,
              data: {
                ...node.data,
                data: {
                  ...(node.data as ITelegramMessageNodeData).data,
                  buttons: newButtons,
                },
              },
            });
          }

          continue;
        }

        if (isCondition) {
          if (node.id !== source) {
            result.push(node as Node);
          } else {
            const newConditions = updateConditionsOnConnect(
              (node.data as IConditionsNodeData).data.conditions,
              parseInt(primeIndex),
              params.target ?? '',
            );

            result.push({
              ...node,
              data: {
                ...node.data,
                data: {
                  ...(node.data as IConditionsNodeData).data,
                  conditions: newConditions,
                },
              },
            });
          }

          continue;
        }

        if (node.id !== params.source) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              next: params.target,
            },
          });
        }
      }

      return result;
    });
  }, []);
};

export default useOnNodesConnect;
