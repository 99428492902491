import {
  Autocomplete,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getAllTimezones from '../../../common/time/timezones.array';
import { convertNumberToTime } from '../../../utils/convertNumberToTime';
import { IDelayFlowNodeData } from '../interfaces';

interface DelayEditorProps {
  open: boolean;
  delayNode: IDelayFlowNodeData;
  onClose: () => void;
  onSubmit: (e: React.SyntheticEvent, delayNode: IDelayFlowNodeData) => void;
}

const timezones = getAllTimezones();

const isDateRangeValid = (
  applyTimeRange: boolean,
  startTime: string,
  endTime: string,
  timezone: string,
) => {
  if (!applyTimeRange) {
    return true;
  }

  if (!startTime || !endTime) {
    return false;
  }

  if (!timezone) {
    return false;
  }

  const startDateTime = dayjs(startTime, 'HH:mm');
  const endDateTime = dayjs(endTime, 'HH:mm');

  return endDateTime.isAfter(startDateTime);
};

const DelayEditor: FC<DelayEditorProps> = ({
  open,
  onClose,
  onSubmit,
  delayNode,
}) => {
  const { t } = useTranslation();

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [applyTimeRange, setApplyTimeRange] = useState<boolean>(false);
  const [daysRange, setDaysRange] = useState<number[]>([]);
  const [startTimeDate, setStartTimeDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTimeDate, setEndTimeDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');

  const delayInSeconds = seconds + minutes * 60 + hours * 3600 + days * 86400;

  const delayValid =
    delayInSeconds > 0 && !isNaN(delayInSeconds) && delayInSeconds < 80640 * 60;

  const dateRangeValid = isDateRangeValid(
    applyTimeRange,
    startTime,
    endTime,
    timezone,
  );

  useEffect(() => {
    const { days, hours, minutes, seconds } = convertNumberToTime(
      delayNode.delayForSeconds ?? 0,
    );

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);

    if (delayNode.startTime) {
      const date = new Date();
      date.setHours(Number(delayNode.startTime.slice(0, 2)));
      date.setMinutes(Number(delayNode.startTime.slice(3, 5)));
      setStartTimeDate(date);

      setStartTime(
        `${delayNode.startTime.slice(0, 2)}:${delayNode.startTime.slice(3, 5)}`,
      );
    }

    if (delayNode.endTime) {
      const date = new Date();
      date.setHours(Number(delayNode.endTime.slice(0, 2)));
      date.setMinutes(Number(delayNode.endTime.slice(3, 5)));
      setEndTimeDate(date);
      setEndTime(
        `${delayNode.endTime.slice(0, 2)}:${delayNode.endTime.slice(3, 5)}`,
      );
    }

    setApplyTimeRange(delayNode.applyTimeRange);
    setDaysRange(delayNode.days);
    setTimezone(delayNode.timezone);
  }, [delayNode]);

  const onDaysInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setDays(number);
    } else {
      setDays(0);
    }
  };

  const onHoursInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setHours(number);
    } else {
      setHours(0);
    }
  };

  const onMinutesInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setMinutes(number);
    } else {
      setMinutes(0);
    }
  };

  const onSecondsInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (!isNaN(number)) {
      setSeconds(number);
    } else {
      setSeconds(0);
    }
  };

  const handleChangeStartTime = (newTime: Date | null) => {
    const dateTime = newTime ? new Date(newTime) : new Date();
    const hours = dateTime?.getHours().toString().padStart(2, '0');
    const minutes = dateTime?.getMinutes().toString().padStart(2, '0');

    setStartTime(`${hours}:${minutes}`);
    setStartTimeDate(newTime);
  };

  const handleChangeEndTime = (newTime: Date | null) => {
    const dateTime = newTime ? new Date(newTime) : new Date();
    const hours = dateTime?.getHours().toString().padStart(2, '0');
    const minutes = dateTime?.getMinutes().toString().padStart(2, '0');

    setEndTime(`${hours}:${minutes}`);
    setEndTimeDate(newTime);
  };

  const handleChangeDaysRange = (day: number) => {
    setDaysRange((prev) => {
      if (prev.includes(day)) {
        return prev.filter((departureDay) => departureDay !== day);
      } else {
        return [...prev, day].sort((a, b) => a - b);
      }
    });
  };

  useEffect(() => {
    if (!applyTimeRange) {
      setDaysRange([]);
      setStartTime('');
      setStartTimeDate(null);
      setEndTimeDate(null);
      setEndTime('');
      setTimezone('');
    }
  }, [applyTimeRange]);

  if (!open) return null;

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
        }}
        gap="5px"
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '12px',
            p: '3px',
            backgroundColor: 'red.1',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {t('flowNodes.delayLabel')}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          flexWrap="wrap"
          mt="10px"
        >
          <TextField
            sx={{ maxWidth: '48%' }}
            value={days ? days : ''}
            onChange={onDaysInput}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    minWidth: '50%',
                    justifyContent: 'center',
                  }}
                  position="end"
                >
                  {t('common.days')}
                </InputAdornment>
              ),
            }}
            placeholder="0"
          />
          <TextField
            sx={{ maxWidth: '48%' }}
            value={hours ? hours : ''}
            onChange={onHoursInput}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    minWidth: '50%',
                    justifyContent: 'center',
                  }}
                  position="end"
                >
                  {t('common.hours')}
                </InputAdornment>
              ),
            }}
            placeholder="0"
          />
          <TextField
            sx={{ maxWidth: '48%' }}
            value={minutes ? minutes : ''}
            onChange={onMinutesInput}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    minWidth: '50%',
                    justifyContent: 'center',
                  }}
                  position="end"
                >
                  {t('common.minutes')}
                </InputAdornment>
              ),
            }}
            placeholder="0"
          />
          <TextField
            sx={{ maxWidth: '48%' }}
            value={seconds ? seconds : ''}
            onChange={onSecondsInput}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    minWidth: '50%',
                    justifyContent: 'center',
                  }}
                  position="end"
                >
                  {t('common.seconds')}
                </InputAdornment>
              ),
            }}
            placeholder="0"
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          m: '10px 0',
        }}
        onClick={() => setApplyTimeRange((prev) => !prev)}
      >
        <Typography>{t('flowNodes.range')}</Typography>
        <Checkbox
          sx={{
            color: 'blue.2',
            '&.Mui-checked': {
              color: 'blue.2',
            },
          }}
          checked={applyTimeRange}
        />
      </Stack>
      <Stack
        sx={{
          height: applyTimeRange ? '100%' : '0',
          overflow: applyTimeRange ? 'auto' : ' hidden',
          transition: '.3s all ease-in-out',
        }}
      >
        <Stack>
          <Stack direction="row" alignItems="center" gap="5px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" alignItems="center" gap="5px">
                <Typography>{t('common.from')}:</Typography>
                <TimePicker
                  ampm={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={dayjs(startTimeDate)}
                  onChange={handleChangeStartTime}
                />
              </Stack>
              <Stack direction="row" alignItems="center" gap="5px">
                <Typography>{t('common.to')}:</Typography>
                <TimePicker
                  ampm={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={dayjs(endTimeDate)}
                  onChange={handleChangeEndTime}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
        </Stack>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(1)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(1)}
            />
            <Typography>{t('common.weekDays.monday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(2)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(2)}
            />
            <Typography>{t('common.weekDays.tuesday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(3)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(3)}
            />
            <Typography>{t('common.weekDays.wednesday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(4)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(4)}
            />
            <Typography>{t('common.weekDays.thursday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(5)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(5)}
            />
            <Typography>{t('common.weekDays.friday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(6)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(6)}
            />
            <Typography>{t('common.weekDays.saturday')}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleChangeDaysRange(7)}
          >
            <Checkbox
              sx={{
                color: 'blue.2',
                '&.Mui-checked': {
                  color: 'blue.2',
                },
              }}
              checked={daysRange?.includes(7)}
            />
            <Typography>{t('common.weekDays.sunday')}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ m: '10px 0', textAlign: 'center' }}>
            {t('flowNodes.timeZoneTitle')}
          </Typography>
          <Autocomplete
            sx={{ '& .MuiInputBase-root': { backgroundColor: 'grey.15' } }}
            options={timezones}
            value={timezone}
            onChange={(_, newValue) => setTimezone(newValue ?? '')}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  p: '0',
                  '& .MuiInputBase-input': { p: '0' },
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        sx={{
          mt: '20px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            width: '50%',
            color: 'red.2',
            '&:hover': {
              backgroundColor: 'red.2',
              color: 'grey.15',
            },
          }}
        >
          {t('common.cancel')}
        </Button>

        <Button
          sx={{
            width: '50%',
            fontSize: '18px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.2',
              color: 'grey.15',
            },
          }}
          variant="contained"
          onClick={(e) => {
            onSubmit(e, {
              delayForSeconds: delayInSeconds,
              applyTimeRange,
              days: daysRange,
              startTime,
              endTime,
              timezone,
            });
          }}
          disabled={!delayValid || !dateRangeValid}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
export default DelayEditor;
