import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import AppContextProvider from './AppContext';
import ThemeContextProvider from './common/theme/theme.context';
import GlobalContextProvider from './GlobalContext';
import i18n from './i18n/config';
import UserContextProvider from './user/user.context';
import './styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <ThemeContextProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AppContextProvider>
              <GlobalContextProvider>
                <App />
              </GlobalContextProvider>
            </AppContextProvider>
          </I18nextProvider>
        </BrowserRouter>
      </ThemeContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
);
