import { ITemplate } from '../../telegram-messenger/templates/interfaces';
import axios from '../axios';

const deleteTemplate = async (
  telegramBotId: string,
  botType: string,
  templateId: ITemplate['_id'],
) => {
  const { data } = await axios.delete(
    `/templates/${telegramBotId}/${templateId}/${botType}`,
  );

  return data;
};

export default deleteTemplate;
