// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createTheme } from '@mui/material';

import Gilroy from '../../assets/font/Gilroy-Medium.woff2';

export const darkThemeColors = {
  grey: {
    1: '#FFFFFF',
    2: '#F4F3F4',
    3: '#DDDDDD',
    4: '#C7C6C7',
    5: '#B1B0B1',
    6: '#9C9B9C',
    7: '#878687',
    8: '#737273',
    9: '#5F5E5F',
    10: '#4C4B4C',
    11: '#434043',
    12: '#353335',
    13: '#292829',
    14: '#1E1D1E',
    15: '#161516',
    16: '#0A0909',
  },
  green: {
    1: '#3C4916',
    2: '#CAFA44',
    3: '#DEFF82',
  },
  blue: {
    1: '#122634',
    2: '#0495FF',
    3: '#3DADFF',
    4: '#194C71',
  },
  red: {
    1: '#451728',
    2: '#FF1F6E',
    3: '#FF639A',
  },
  purple: {
    1: '#461973',
    2: '#631BAB',
    3: '#751DCD',
    4: '#7E1DDE',
  },
  yellow: {
    1: '#453B17',
    2: '#FFCE1F',
  },
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    ...darkThemeColors,
    primary: {
      main: darkThemeColors.blue['2'],
    },
  },

  typography: {
    fontFamily: 'Gilroy, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          outline: 'none',
          '&:hover': { outline: 'none' },
          '&:active': { outline: 'none' },
          '&:focus-visible': { outline: 'none' },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy Regular'), url(${Gilroy}) format('woff2');
        }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            padding: '10px 5px',
            color: darkThemeColors.grey['2'],
            backgroundColor: darkThemeColors.grey['15'],
            borderRadius: '4px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.grey['10'],
            borderWidth: '1px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.grey['10'],
            borderWidth: '1px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.blue['2'],
            borderWidth: '1px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: darkThemeColors.blue['2'],
        },
      },
    },
  },
});

export default darkTheme;
