import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FolderCard from './folder.card';
import { IFlowFolder } from './interfaces';

interface FoldersListProps {
  folders: IFlowFolder[];
  onSelectFolder: (folder: IFlowFolder | null) => void;
  selectedFolder: IFlowFolder | null;
  showAllFlows: () => void;
  onEditFolder: (folder: IFlowFolder) => void;
  onDeleteFolder: (folder: IFlowFolder) => void;
}

const FoldersList: FC<FoldersListProps> = ({
  folders,
  onSelectFolder,
  selectedFolder,
  showAllFlows,
  onEditFolder,
  onDeleteFolder,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap="18px"
      sx={{
        borderRadius: '12px',
        border: '1.5px solid',
        borderColor: 'grey.10',
        backgroundColor: 'grey.15',
        p: '24px',
      }}
    >
      <Stack
        direction="row"
        gap="18px 12px"
        sx={{
          flexWrap: 'wrap',
          overflow: 'auto',
        }}
      >
        <Button
          startIcon={<FormatListBulletedIcon />}
          onClick={showAllFlows}
          sx={{
            width: '229px',
            height: '42px',
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'green.2',
            backgroundColor: 'green.1',
            color: 'grey.1',
            padding: '10px',
          }}
        >
          {t('flow.folders.allFlows')}
        </Button>
        {folders.map((folder) => (
          <FolderCard
            key={folder._id}
            selectedFolder={selectedFolder}
            folder={folder}
            onSelectFolder={onSelectFolder}
            onEdit={onEditFolder}
            onDelete={onDeleteFolder}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default FoldersList;
