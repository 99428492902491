import { IFlow, IFlowActivator } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

export const deleteFlowActivator = async (
  flowId: IFlow['_id'],
  flowActivatorId: IFlowActivator['_id'],
) => {
  const { data } = await axios.delete(
    `/flows/${flowId}/activator/${flowActivatorId}`,
  );

  return data;
};
