import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import { Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { darkThemeColors } from '../../../common/theme/dark.theme';

export interface IStartNodeData {
  _id: string;
  next: string | null;
  position: { x: number; y: number };
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
}

interface StartNodeProps extends Omit<NodeProps, 'data'> {
  data: IStartNodeData;
}

const StartNode: FC<StartNodeProps> = memo(({ data, xPos, yPos }) => {
  const { t } = useTranslation();

  useEffect(() => {
    data.onNodePositionChange(data._id, {
      x: xPos,
      y: yPos,
    });
  }, [xPos, yPos]);

  return (
    <Stack
      className="dragger"
      alignItems="center"
      sx={{
        width: '340px',
        backgroundColor: 'grey.14',
        borderRadius: '12px',
        padding: '18px',
        border: '1px solid',
        borderColor: 'grey.10',
      }}
    >
      <Handle
        type="source"
        id="start-node-handle"
        position={Position.Right}
        style={{
          marginTop: '40px',
          width: '20px',
          height: '20px',
          marginRight: '-5px',
          border: '4px solid',
          borderColor: darkThemeColors.green['2'],
        }}
      />
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 600,
        }}
      >
        Start
      </Typography>
      <Stack
        sx={{
          backgroundColor: 'grey.10',
          my: '12px',
          height: '1px',
          width: '100%',
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        gap="6px"
        sx={{
          width: '100%',
        }}
      >
        <PlayCircleFilledRoundedIcon
          sx={{
            color: 'green.2',
            fontSize: '38px',
          }}
        />
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          {t('flowNodes.startNode')}
        </Typography>
      </Stack>
    </Stack>
  );
});

StartNode.displayName = 'StartNode';

export default StartNode;
