export const convertNumberToTime = (number: number) => {
  const secondsInADay = 24 * 60 * 60;
  const secondsInAnHour = 60 * 60;
  const secondsInAMinute = 60;

  const days = Math.floor(number / secondsInADay);
  const remainingSeconds = number % secondsInADay;
  const hours = Math.floor(remainingSeconds / secondsInAnHour);
  const remainingMinutes = remainingSeconds % secondsInAnHour;
  const minutes = Math.floor(remainingMinutes / secondsInAMinute);
  const seconds = remainingMinutes % secondsInAMinute;

  return { days, hours, minutes, seconds };
};
