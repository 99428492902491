import zones from './timezones.json';

function getAllTimezones() {
  const allZones = [];

  for (const group of zones) {
    allZones.push(...group.utc);
  }

  return allZones;
}

export default getAllTimezones;
