import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import truncate from '../../utils/truncate';

import VoicePreview from './voice.preview';

interface VoiceItemProps {
  voiceUrl: string;
  name: string;
  isSelected: boolean;
  onSelect: () => void;
}

const VoiceItem: FC<VoiceItemProps> = ({
  voiceUrl,
  isSelected,
  onSelect,
  name,
}) => {
  return (
    <Stack
      sx={{
        width: '220px',
        backgroundColor: 'grey.15',
        padding: '5px 15px 5px 5px',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: isSelected ? 'green.2' : 'grey.10',
        cursor: 'pointer',
      }}
      onClick={onSelect}
    >
      <Typography
        sx={{
          color: 'grey.4',
        }}
      >
        {truncate(name, 20)}
      </Typography>
      <VoicePreview voiceUrl={voiceUrl} />
    </Stack>
  );
};

export default VoiceItem;
