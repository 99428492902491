import { Button, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fetchTelegramBotStatistics from '../api/bots/fetch-bot-statistics';
import getDialogsDb from '../api/bots/getDialogsDb';
import StatisticsContainer from '../common/statistics/statistics.container';

import { ITelegramBot } from './telegram-bot.interface';

interface StatisticsTabProps {
  botId: ITelegramBot['_id'];
}

const StatisticsTab: FC<StatisticsTabProps> = ({ botId }) => {
  const { t } = useTranslation();

  const [statistics, setStatistics] = useState<(string | number)[][]>([]);
  const [loadingStatistics, setLoadingStatistics] = useState<boolean>(false);
  const [loadingDB, setLoadingDB] = useState<boolean>(false);

  const onFetchStatistics = (
    params: string[],
    endDate: string,
    startDate: string,
    endDate2: string,
    startDate2: string,
  ) => {
    if (!endDate) return;
    setLoadingStatistics(true);
    fetchTelegramBotStatistics(botId, {
      params,
      endDate,
      startDate,
      endDate2,
      startDate2,
    })
      .then(setStatistics)
      .finally(() => setLoadingStatistics(false));
  };

  const onDownloadDb = () => {
    setLoadingDB(true);
    getDialogsDb(botId).finally(() => {
      setLoadingDB(false);
    });
  };

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 70px)',
        margin: '40px 0',
        width: '100%',
        maxWidth: '1200px',
      }}
    >
      <StatisticsContainer
        onFetchStatistics={onFetchStatistics}
        statistics={statistics}
        loadingStatistics={loadingStatistics}
      />
      <Button
        sx={{
          mt: '20px',
        }}
        color="success"
        onClick={onDownloadDb}
        disabled={loadingDB}
      >
        {t('common.downloadUsersDb')}
      </Button>
    </Stack>
  );
};

export default StatisticsTab;
