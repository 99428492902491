import {
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import { FC, ReactNode } from 'react';

interface TooltipInfo {
  icon: ReactNode;
  title: string | ReactNode;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4C4B4C',
    color: '#B1B0B1',
    borderRadius: '12px',
    fontSize: 12,
    padding: '6px 10px',
  },
}));

const TooltipInfo: FC<TooltipInfo> = ({
  icon,
  title,
  placement = 'right-start',
}) => {
  return (
    <CustomTooltip
      placement={placement}
      TransitionComponent={Zoom}
      sx={{ maxWidth: 'max-content' }}
      title={title}
    >
      <IconButton
        sx={{ padding: '0', fontSize: '15px', height: '15px', width: '15px' }}
      >
        {icon}
      </IconButton>
    </CustomTooltip>
  );
};

export default TooltipInfo;
