import { Stack } from '@mui/material';
import React, { FC } from 'react';

import axiomaLogo from '../../assets/images/axioma.png';
import monsterLogo from '../../assets/images/monster.png';
// import tdLogo from '../../assets/images/td_logo.png';

interface LogoProps {
  width?: number;
  alignSubtitle?: 'start' | 'center' | 'end';
  onClick: () => void;
}

const Logo: FC<LogoProps> = ({
  width = 110,
  alignSubtitle = 'start',
  onClick,
}) => {
  return (
    <Stack
      sx={{
        width,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img src={axiomaLogo} alt="logo" />
      <Stack alignItems={alignSubtitle}>
        <img src={monsterLogo} alt="logo" width={width / 2} />
      </Stack>
    </Stack>
  );
};

export default Logo;
