import IProject, { UpdateProject } from '../../projects/project.interface';
import axios from '../axios';

const editProject = async (
  _id: IProject['_id'],
  newProjectData: UpdateProject,
) => {
  const { data } = await axios.patch(`/projects/${_id}`, newProjectData);

  return data;
};

export default editProject;
