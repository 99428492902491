import { UpdateUser } from '../../user/user.interface';
import axios from '../axios';

const editUser = async (newUserData: UpdateUser) => {
  const { data } = await axios.patch(`/auth`, newUserData);

  return data;
};

export default editUser;
