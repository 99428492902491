import SearchIcon from '@mui/icons-material/Search';
import { Stack, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type SearchInputProps = {
  search: string;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};
const SearchInput = ({ search, handleChangeSearch }: SearchInputProps) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <TextField
        onChange={handleChangeSearch}
        value={search}
        placeholder={t('common.filterPlaceholder')}
        inputProps={{
          sx: { p: '8px 5px!important' },
        }}
        InputProps={{
          sx: {
            cursor: 'pointer',
            borderRadius: '33px',
            overflow: 'hidden',
          },

          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: 'grey.8',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default SearchInput;
