import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IOSSwitch } from '../../common/iosSwitch';
import ModalBox from '../../common/modal/modal-box';
import { IMedia, TelegramButton } from '../../interfaces/common';
import { getBotIdAndType } from '../../utils/getBotIdAndType';
import AttachedMediaPreview from '../dialogs/attached-media.preview';
import ButtonsEditor from '../dialogs/buttons.editor';

import { TemplateMessageType } from './interfaces';

interface TemplateModalProps {
  open: boolean;
  onClose: () => void;
  name: string;
  type: string;
  text: string;
  handleChangeName: (name: ChangeEvent<HTMLInputElement>) => void;
  handleChangeType: (type: TemplateMessageType) => void;
  handleChangeText: (text: ChangeEvent<HTMLInputElement>) => void;
  openModalByType: () => void;
  addNewButtonsRowDisabled: boolean;
  onRemoveMedia: (_id: string) => void;
  privateTemplate: boolean;
  handleChangePrivate: () => void;
  media: IMedia[];
  clearMessageState: () => void;
  buttons: TelegramButton[][];
  onAddRow: () => void;
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  isValid: boolean;
  isTelegramButtonsValid: boolean;
  onSubmit: () => void;
}

const TemplateModal: FC<TemplateModalProps> = ({
  open,
  onClose,
  name,
  type,
  text,
  media,
  clearMessageState,
  privateTemplate,
  handleChangePrivate,
  openModalByType,
  addNewButtonsRowDisabled,
  onRemoveMedia,
  handleChangeName,
  handleChangeType,
  handleChangeText,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddButton,
  onButtonChange,
  isValid,
  onSubmit,
  isTelegramButtonsValid,
}) => {
  const { t } = useTranslation();

  const botType = getBotIdAndType().botType;
  return (
    <ModalBox open={open} onClose={onClose} maxWidth="50%">
      <Stack
        sx={{
          maxHeight: '600px',
          overflow: 'auto',
        }}
      >
        <Typography sx={{ textAlign: 'center' }}>
          {t('messenger.templates.modal.title')}
        </Typography>
        <Divider sx={{ margin: '12px 0' }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '12px',
          }}
        >
          <Typography>{t('messenger.templates.modal.name')}</Typography>
          <TextField
            sx={{
              width: '80%',
            }}
            placeholder={t('messenger.templates.modal.namePlaceholder')}
            value={name}
            onChange={handleChangeName}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '12px',
          }}
        >
          <Typography>{t('messenger.templates.modal.typeTitle')}</Typography>
          <Stack flexDirection="row" gap="6px" sx={{ width: '80%' }}>
            <Button
              onClick={() => {
                handleChangeType(TemplateMessageType.media);
                clearMessageState();
              }}
              sx={{
                textTransform: 'none',
                width: '100%',
                fontSize: '14px',
                maxWidth: '174px',
                padding: '6px 0',
                borderRadius: '6px',
                border: ' 1px solid ',
                backgroundColor: type === 'media' ? 'blue.1' : 'grey.14',
                borderColor: type === 'media' ? 'blue.2' : 'grey.10',
                color: type === 'media' ? 'blue.2' : 'grey.5',
              }}
              variant="outlined"
            >
              {t('messenger.templates.modal.type.media')}
            </Button>

            <Button
              onClick={() => {
                handleChangeType(TemplateMessageType.videoNote);
                clearMessageState();
              }}
              sx={{
                textTransform: 'none',
                width: '100%',
                fontSize: '14px',
                padding: '6px 0',
                maxWidth: '174px',
                borderRadius: '6px',
                border: ' 1px solid ',
                borderColor: type === 'videoNote' ? 'blue.2' : 'grey.10',
                color: type === 'videoNote' ? 'blue.2' : 'grey.5',
                backgroundColor: type === 'videoNote' ? 'blue.1' : 'grey.14',
              }}
              variant="outlined"
            >
              {t('messenger.templates.modal.type.video')}
            </Button>

            <Button
              onClick={() => {
                handleChangeType(TemplateMessageType.voice);
                clearMessageState();
              }}
              sx={{
                borderRadius: '6px',
                border: ' 1px solid ',
                borderColor: type === 'voice' ? 'blue.2' : 'grey.10',
                backgroundColor: type === 'voice' ? 'blue.1' : 'grey.14',
                color: type === 'voice' ? 'blue.2' : 'grey.5',
                textTransform: 'none',
                width: '100%',
                maxWidth: '174px',
                fontSize: '14px',
                padding: '6px 0',
              }}
              variant="outlined"
            >
              {t('messenger.templates.modal.type.voice')}
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '12px',
          }}
        >
          <Typography>{t('messenger.templates.modal.media')}</Typography>
          <Stack
            flexDirection="row"
            gap="20px"
            alignItems="center"
            sx={{ width: '80%' }}
          >
            <Button
              onClick={openModalByType}
              endIcon={<AddIcon />}
              sx={{
                color: 'blue.2',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: '0',
                width: '174px',
                height: '36px',
                borderRadius: '6px',
                border: ' 1px solid ',
                borderColor: 'blue.2',
                backgroundColor: 'blue.1',
              }}
            >
              {t('messenger.templates.modal.imageBtn')}
            </Button>
            <Stack
              flexDirection="row"
              gap="10px"
              sx={{ maxWidth: '350px', overflow: 'auto' }}
            >
              <AttachedMediaPreview
                media={media}
                onRemoveMedia={onRemoveMedia}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: '12px',
          }}
        >
          <Typography>{t('messenger.templates.modal.text')}</Typography>
          <TextField
            sx={{
              backgroundColor: 'grey.15',
              width: '80%',
            }}
            disabled={type === TemplateMessageType.videoNote}
            multiline
            rows={4}
            placeholder={t('messenger.templates.modal.textPlaceholder')}
            value={text}
            onChange={handleChangeText}
          />
        </Stack>
        {botType === 'BOT' && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              mt: '12px',
            }}
          >
            <Typography>{t('messenger.templates.modal.buttons')}</Typography>
            <Stack sx={{ width: '80%' }}>
              <ButtonsEditor
                buttons={buttons}
                addNewButtonsRowDisabled={addNewButtonsRowDisabled}
                onAddButton={onAddButton}
                onButtonChange={onButtonChange}
                onRemoveButton={onRemoveButton}
                onAddRow={onAddRow}
                onRemoveRow={onRemoveRow}
                isTelegramButtonsValid={isTelegramButtonsValid}
              />
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: '12px',
          }}
        >
          <Typography>{t('messenger.templates.modal.private')}</Typography>
          <Stack sx={{ ml: '60px' }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={privateTemplate}
                  onChange={handleChangePrivate}
                />
              }
              label={t('messenger.templates.modal.privateTemplate')}
            />
          </Stack>
        </Stack>
        <Divider sx={{ margin: '12px 0' }} />
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="6px"
          sx={{ widht: '100px' }}
        >
          <Button
            onClick={onClose}
            fullWidth
            variant="contained"
            sx={{
              borderRadius: '3px',
              color: 'grey.1',
              backgroundColor: 'red.2',
              textTransform: 'none',
              fontWeight: 600,
              '&:hover': { color: 'grey.2', backgroundColor: 'red.1' },
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!isValid}
            fullWidth
            variant="contained"
            sx={{
              borderRadius: '3px',
              color: 'grey.1',
              backgroundColor: 'blue.2',
              textTransform: 'none',
              fontWeight: 600,
              '&:hover': { color: 'grey.2', backgroundColor: 'blue.1' },
            }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default TemplateModal;
