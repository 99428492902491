import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { UserContext } from '../../user/user.context';
import Logo from '../logo';
import ThreeDotsMenu from '../threeDotsMenu/three-dots-menu.component';

const Navbar = () => {
  const { user, logout } = useContext(UserContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const goToHomepage = () => {
    navigate('/');
    localStorage.removeItem('projectId');
    localStorage.removeItem('bot');
    localStorage.removeItem('channelId');
    localStorage.removeItem('channelTabIndex');
    localStorage.removeItem('currentDialog');
    localStorage.removeItem('tabIndexMessenger');
    localStorage.removeItem('tabIndex');
  };

  const goToUserEdit = () => {
    navigate('/user');
  };

  if (!user) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: '100%',
        height: '70px',
        padding: '10px 10px 10px 24px',
        backgroundColor: 'grey.15',
        borderBottom: '1px solid',
        borderColor: 'grey.13',
      }}
    >
      <Logo width={105} alignSubtitle="start" onClick={goToHomepage} />

      <Stack alignItems="center" justifyContent="center">
        <Avatar
          sx={{ backgroundColor: 'red.2', color: 'grey.1', cursor: 'pointer' }}
          src={user.avatarUrl}
          onClick={openMenu}
        >
          {user.firstName[0]}
        </Avatar>
        <ThreeDotsMenu
          open={menuOpen}
          onClose={closeMenu}
          anchor={anchorEl}
          menuItems={[
            [
              {
                text: t('common.projects'),
                onClick: goToHomepage,
                renderIcon: () => <DesktopMacIcon />,
              },
              {
                text: t('common.settings'),
                onClick: goToUserEdit,
                renderIcon: () => <SettingsIcon />,
              },
            ],
            [
              {
                text: t('common.logout'),
                renderIcon: () => <LogoutIcon />,

                onClick: () => {
                  openConfirmationDialog(
                    t('common.logoutConfirmation'),
                    t('common.logout'),
                    () => {
                      logout();
                    },
                  );
                },
              },
            ],
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default Navbar;
