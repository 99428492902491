import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import ModalBox from '../../common/modal/modal-box';
import { TelegramLeadParamKeyValueObject } from '../../interfaces/common';
import { MessengerContext } from '../messenger.context';

interface ParamsProps {
  params: TelegramLeadParamKeyValueObject[];
  telegramId: number;
  project: string;
  isEditParamsModalOpen: boolean;
  onClose: () => void;
  dialogId: string;
}

const EditParamsModal: FC<ParamsProps> = ({
  params,
  isEditParamsModalOpen,
  telegramId,
  project,
  onClose,
  dialogId,
}) => {
  const { t } = useTranslation();

  const { updateDialogParams, deleteDialogParam } =
    useContext(MessengerContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const [paramsToUpdate, setParamsToUpdate] = useState<
    TelegramLeadParamKeyValueObject[]
  >([]);

  const onChangeParams = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setParamsToUpdate((prev) =>
      prev.map((param) =>
        param.key === key ? { ...param, value: value } : param,
      ),
    );
  };

  const onUpdateParams = () => {
    updateDialogParams(dialogId, paramsToUpdate);
    onClose();
  };

  const disabledSubmit = paramsToUpdate.some(
    (param) => param.value.trim() === '',
  );

  useEffect(() => {
    setParamsToUpdate(params);
  }, [params]);

  return (
    <ModalBox
      open={isEditParamsModalOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="378px"
      sx={{ p: '12px' }}
    >
      <Stack gap="10px">
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {t('flowNodes.editParam')}
        </Typography>
        {paramsToUpdate.map((param) => (
          <Stack key={param.key}>
            <Typography sx={{ mt: '5px' }}>{param.key}</Typography>
            <Stack direction="row" gap="5px">
              <TextField
                value={param.value}
                placeholder={param.value}
                onChange={(e) =>
                  onChangeParams(
                    param.key,
                    e as React.ChangeEvent<HTMLInputElement>,
                  )
                }
                fullWidth
              />
              <IconButton
                onClick={() => {
                  openConfirmationDialog(
                    t('flowNodes.deleteParamDialog'),
                    t('common.delete'),
                    () => {
                      deleteDialogParam(
                        dialogId,
                        telegramId,
                        project,
                        param.key,
                      );
                    },
                  );
                }}
              >
                <DeleteIcon sx={{ color: 'red.2' }} />
              </IconButton>
            </Stack>
          </Stack>
        ))}
        <Divider sx={{ m: '6px 0' }} />
        <Stack direction="row" gap="12px">
          <Button
            sx={{
              borderRadius: '6px',
              width: '100%',
              color: 'grey.1',
              backgroundColor: 'blue.2',
              '&:hover': { backgroundColor: 'blue.3' },
            }}
            disabled={disabledSubmit}
            onClick={onUpdateParams}
          >
            {t('common.save')}
          </Button>
          <Button
            sx={{
              borderRadius: '6px',
              width: '100%',
              color: 'grey.1',
              backgroundColor: 'grey.11',
              '&:hover': { backgroundColor: 'grey.12' },
            }}
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default EditParamsModal;
