import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { alpha, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { darkThemeColors } from '../../../common/theme/dark.theme';
import truncate from '../../../utils/truncate';
import {
  ConditionsFlowNodeConditionData,
  ConditionTypes,
  IConditionsFlowNodeData,
} from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface IConditionsNodeData {
  onDelete: () => void;
  onSelect: (conditions: ConditionsFlowNodeConditionData[]) => void;
  onCopy: (
    conditions: ConditionsFlowNodeConditionData[],
    position: { x: number; y: number },
  ) => void;
  exitEditor: () => void;
  position: { x: number; y: number };
  data: IConditionsFlowNodeData;
  next: string | null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface ConditionsNodeProps extends Omit<NodeProps, 'data'> {
  data: IConditionsNodeData;
}

const ConditionsNode: FC<ConditionsNodeProps> = memo(
  ({ data, xPos, yPos, selected }) => {
    useEffect(() => {
      data.onNodePositionChange(data._id, {
        x: xPos,
        y: yPos,
      });
    }, [xPos, yPos]);

    const { t } = useTranslation();

    const nodeRef = useRef<HTMLDivElement>(null);

    const [isNewNode, setIsNewNode] = useState(false);

    useEffect(() => {
      const digitsId = Number(data._id);

      setIsNewNode(!isNaN(digitsId));
    }, []);

    useEffect(() => {
      const node = nodeRef.current;
      const preventZoom = (event: WheelEvent) => event.stopPropagation();

      if (node) {
        node.addEventListener('wheel', preventZoom);
        return () => {
          node.removeEventListener('wheel', preventZoom);
        };
      }
    }, []);

    useEffect(() => {
      if (!selected) {
        data.exitEditor();
      }
    }, [selected]);

    const conditions = data.data.conditions;

    const edit = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onSelect(conditions);
    };
    return (
      <Stack
        sx={{
          position: 'relative',
        }}
        onDoubleClick={edit}
      >
        {selected && (
          <Stack
            direction="row"
            justifyContent="center"
            gap="10px"
            sx={{
              position: 'absolute',
              top: -50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={edit}
            >
              <ModeEditOutlineRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={() => {
                data.onCopy(conditions, {
                  x: data.position.x + 400,
                  y: data.position.y,
                });
              }}
            >
              <ContentCopyRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={data.onDelete}
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'red.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <CloseRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
          </Stack>
        )}

        <Stack
          className="dragger"
          sx={{
            width: '340px',
            border: '1px solid',
            borderColor: selected ? 'purple.2' : 'grey.10',
            backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
            padding: '10px',
            borderRadius: '18px',
            cursor: 'default',
            animation: isNewNode
              ? `${pulsingAnimation} 0.5s linear infinite  alternate`
              : '',
            animationIterationCount: 6,
          }}
          ref={nodeRef}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={'edge-' + data._id}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              height: '100%',
              width: '100%',
              borderRadius: '0',
              zIndex: -1,
            }}
          />

          <Handle
            type="source"
            id={'src-' + data._id}
            position={Position.Bottom}
            style={{
              width: '20px',
              height: '20px',
              marginRight: '-5px',
              border: '4px solid',
              display: Boolean(conditions.length) ? 'block' : 'none',
              borderColor: darkThemeColors.green['2'],
            }}
          />

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack
              gap="10px"
              sx={{
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {t('flowNodes.conditionsLabel')}
              </Typography>

              {!conditions.length && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    backgroundColor: 'grey.12',
                    p: '5px',
                    borderRadius: '6px',
                  }}
                >
                  <MediationRoundedIcon
                    sx={{
                      fontSize: '28px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '22px',
                      fontStyle: 'italic',
                    }}
                  >
                    {t('flowNodes.conditionsEmpty')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack flexDirection="column" gap="10px">
            {conditions.map((condition, index) => {
              switch (condition.type) {
                case ConditionTypes.checkParam: {
                  return (
                    <Stack>
                      <Stack
                        className="conditionContainer"
                        sx={{
                          border: '1px solid',
                          borderRadius: '6px',
                          borderColor: 'grey.10',
                          backgroundColor: 'grey.15',
                          p: '10px 18px',
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          Check param
                        </Typography>
                        <Stack
                          key={'checkParam' + index}
                          direction="row"
                          alignItems="center"
                          gap="15px"
                          sx={{
                            mt: index && '10px',
                          }}
                        >
                          <FactCheckIcon />
                          <Stack
                            direction="row"
                            gap="10px"
                            flexWrap="wrap"
                            alignItems="center"
                          >
                            <Typography
                              sx={{
                                width: 'max-content',
                              }}
                            >
                              {condition.key}
                            </Typography>
                            <Typography sx={{ lineHeight: '1' }}>=</Typography>
                            <Typography
                              sx={{
                                width: 'max-content',
                              }}
                            >
                              {condition.value}
                            </Typography>
                          </Stack>

                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              fontSize: '12px',
                              padding: '5px',
                              borderRadius: '6px',
                              color: 'white',
                              textDecoration: 'none',
                              position: 'relative',
                              ml: 'auto',
                            }}
                          >
                            <Stack
                              sx={{
                                top: '-15px',
                                right: '-5px',
                                position: 'absolute',
                              }}
                            >
                              <Handle
                                type="source"
                                id={`condition-${data._id}-${index}-none`}
                                position={Position.Right}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  border: '4px solid',
                                  borderColor: darkThemeColors.green['2'],
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>

                      <ArrowDownwardRoundedIcon
                        sx={{
                          mx: 'auto',
                          mt: '3px',
                          color: 'red.2',
                        }}
                      />
                    </Stack>
                  );
                }
                case ConditionTypes.checkChannelMembership: {
                  return (
                    <Stack>
                      <Stack
                        className="conditionContainer"
                        sx={{
                          border: '1px solid',
                          borderRadius: '6px',
                          borderColor: 'grey.10',
                          backgroundColor: 'grey.15',
                          p: '10px 18px',
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          Check channel
                        </Typography>
                        <Stack direction="row" alignItems="center" gap="15px">
                          <CampaignIcon />
                          <Typography>
                            {truncate(condition.channelName, 25)}
                          </Typography>
                        </Stack>

                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            fontSize: '12px',
                            borderRadius: '6px',
                            color: 'white',
                            textDecoration: 'none',
                            position: 'relative',
                            ml: 'auto',
                          }}
                        >
                          <Stack
                            sx={{
                              top: '-33px',
                              right: '-5px',
                              position: 'absolute',
                            }}
                          >
                            <Handle
                              type="source"
                              id={`condition-${data._id}-${index}-none`}
                              position={Position.Right}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: '4px solid',
                                borderColor: darkThemeColors.green['2'],
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      <ArrowDownwardRoundedIcon
                        sx={{
                          mx: 'auto',
                          mt: '3px',
                          color: 'red.2',
                        }}
                      />
                    </Stack>
                  );
                }

                case ConditionTypes.checkStatus: {
                  return (
                    <Stack>
                      <Stack
                        className="conditionContainer"
                        sx={{
                          border: '1px solid',
                          borderRadius: '6px',
                          borderColor: 'grey.10',
                          backgroundColor: 'grey.15',
                          p: '10px 18px',
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          Check status
                        </Typography>
                        <Stack
                          key={'checkStatus' + index}
                          direction="row"
                          alignItems="center"
                          gap="15px"
                          sx={{
                            mt: index && '10px',
                          }}
                        >
                          <HowToRegRoundedIcon />
                          <Stack
                            direction="row"
                            gap="10px"
                            flexWrap="wrap"
                            alignItems="center"
                          >
                            <Typography
                              sx={{
                                width: 'max-content',
                              }}
                            >
                              {condition.status}
                            </Typography>
                          </Stack>

                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              fontSize: '12px',
                              padding: '5px',
                              borderRadius: '6px',
                              color: 'white',
                              textDecoration: 'none',
                              position: 'relative',
                              ml: 'auto',
                            }}
                          >
                            <Stack
                              sx={{
                                top: '-15px',
                                right: '-5px',
                                position: 'absolute',
                              }}
                            >
                              <Handle
                                type="source"
                                id={`condition-${data._id}-${index}-none`}
                                position={Position.Right}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  border: '4px solid',
                                  borderColor: darkThemeColors.green['2'],
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>

                      <ArrowDownwardRoundedIcon
                        sx={{
                          mx: 'auto',
                          mt: '3px',
                          color: 'red.2',
                        }}
                      />
                    </Stack>
                  );
                }
              }
            })}
          </Stack>

          {Boolean(conditions.length) && (
            <Stack
              className="conditionContainer"
              sx={{
                border: '1px solid',
                borderRadius: '6px',
                borderColor: 'grey.10',
                backgroundColor: 'grey.15',
                p: '10px',
                mt: '5px',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap="15px"
              >
                <Typography sx={{ fontWeight: '600' }}>
                  {t('common.nothingMatches')}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  },
);

ConditionsNode.displayName = 'ConditionsNode';

export default ConditionsNode;
