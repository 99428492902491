import { Stack } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';

import fetchFlows from '../api/flows/fetch-flows';
import fetchFlowNodes from '../api/flows/fetch-nodes';

import { BotContext } from './bot.context';
import FlowsList from './flows/flows.list';
import {
  IFlow,
  IFlowNodeExtended,
  IFlowWithActivatorsAndFolder,
} from './flows/interfaces';
import FlowEditor from './flows/nodes-editor';

interface FlowsTabProps {
  collapseDrawer: () => void;
}

const FlowsTab: FC<FlowsTabProps> = ({ collapseDrawer }) => {
  const { bot, access } = useContext(BotContext);

  const [selectedFlow, setSelectedFlow] = useState<IFlow | null>(null);
  const [flowNodes, setFlowNodes] = useState<IFlowNodeExtended[]>([]);

  useEffect(() => {
    if (!bot || !access?.flows) return;

    fetchFlows(bot._id);
  }, [bot, access]);

  const fetchAndSelectFlow = (flowId: string) => {
    if (!bot || !access?.flows) return;

    fetchFlows(bot._id).then((flows: IFlow[]) => {
      const prevSelectedFlow = flows.find(({ _id }) => flowId === _id);

      if (!prevSelectedFlow) return;

      fetchFlowNodes(flowId).then((nodes: IFlowNodeExtended[]) => {
        setFlowNodes(nodes);
        setSelectedFlow(prevSelectedFlow);
      });
    });
  };

  const selectFlow = (flow: IFlowWithActivatorsAndFolder) => {
    collapseDrawer();
    fetchAndSelectFlow(flow._id);
  };

  const unselectFlow = () => setSelectedFlow(null);

  return (
    <Stack sx={{ height: '100%' }}>
      {!Boolean(selectedFlow) && <FlowsList selectFlow={selectFlow} />}

      {Boolean(selectedFlow) && (
        <FlowEditor
          flow={selectedFlow as IFlow}
          flowNodes={flowNodes}
          onBack={unselectFlow}
          refetchFlow={() => {
            fetchAndSelectFlow(selectedFlow?._id as string);
          }}
        />
      )}
    </Stack>
  );
};

export default FlowsTab;
