import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import { IconButton, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import changeFlowDefault from '../../api/flows/change-flow-default';
import { deleteFlow } from '../../api/flows/delete-flow';
import { AppContext } from '../../AppContext';
import ModalBox from '../../common/modal/modal-box';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import { ProjectContext } from '../../projects/project.context';
import { BotContext } from '../bot.context';

import FlowActivatorsModal from './flow-activators.modal';
import { IFlowWithActivatorsAndFolder } from './interfaces';

interface FlowItemProps {
  flow: IFlowWithActivatorsAndFolder;
  openNodesEditor: (flow: IFlowWithActivatorsAndFolder) => void;
  onCopyFlow: (flow: IFlowWithActivatorsAndFolder) => void;
  openEditFlowModal: (flow: IFlowWithActivatorsAndFolder) => void;
}

const FlowItem: FC<FlowItemProps> = ({
  flow,
  openNodesEditor,
  onCopyFlow,
  openEditFlowModal,
}) => {
  const { openConfirmationDialog } = useContext(AppContext);
  const { refetchFlows, bot } = useContext(BotContext);
  const { refetchBots } = useContext(ProjectContext);

  const { t } = useTranslation();

  const [activatorsModalOpen, setActivatorsModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const openFlowMenu = (e: React.SyntheticEvent) => {
    setMenuAnchor(e.currentTarget);
  };

  const isDefault = flow._id === bot.defaultFlow;

  return (
    <>
      <TableRow
        sx={{
          ':hover': {
            backgroundColor: 'grey.15',
            cursor: 'pointer',
            transition: ' all 0.2s ease-out',
          },
        }}
        onClick={() => openNodesEditor(flow)}
      >
        <TableCell sx={{ textAlign: 'center', pr: '42px' }}>
          {flow.name}
        </TableCell>
        <TableCell sx={{ textAlign: 'center', pr: '42px' }}>
          {flow?.activationsCount}
        </TableCell>
        <TableCell sx={{ textAlign: 'center', pr: '42px' }}>
          {dayjs(flow.updatedAt).fromNow(true)}
        </TableCell>
        <TableCell sx={{ textAlign: 'center', pr: '42px' }}>
          {flow?.folder?.name ?? '-'}
        </TableCell>
        <TableCell
          sx={{
            pr: '42px',
            justifyContent: 'end',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                sx={{
                  mr: '20px',
                  color: 'green.2',
                }}
              >
                {isDefault ? 'Default' : ''}
              </Typography>
            </Stack>
            <Stack
              gap="15px"
              alignItems="center"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: ' space-between',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                sx={{
                  cursor: 'pointer',
                  border: '1px solid',
                  borderColor: 'grey.5',
                  backgroundColor: 'grey.13',
                  borderRadius: '6px',
                  height: '36px',
                  width: '36px',
                }}
                onClick={() => setActivatorsModalOpen(true)}
              >
                <InsertLinkRoundedIcon />
              </IconButton>
              <IconButton
                onClick={openFlowMenu}
                sx={{
                  alignItems: 'start',
                  height: 'fit-content',
                  padding: '0',
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <ThreeDotsMenu
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchor={menuAnchor}
                menuItems={[
                  [
                    {
                      text: t('common.copy'),
                      renderIcon: () => <ContentCopyIcon />,
                      onClick: () => {
                        onCopyFlow(flow);
                      },
                    },
                  ],
                  [
                    {
                      text: t('common.edit'),
                      renderIcon: () => <EditRoundedIcon />,
                      onClick: () => {
                        openEditFlowModal(flow);
                      },
                    },
                  ],
                  isDefault
                    ? [
                        {
                          text: 'Remove default',
                          renderIcon: () => <CloseRoundedIcon />,
                          onClick: () => {
                            changeFlowDefault(flow._id, false).then(
                              refetchBots,
                            );
                          },
                        },
                      ]
                    : [
                        {
                          text: 'Make default',
                          renderIcon: () => (
                            <MyLocationRoundedIcon sx={{ color: 'green.2' }} />
                          ),
                          onClick: () => {
                            changeFlowDefault(flow._id, true).then(refetchBots);
                          },
                        },
                      ],
                  [
                    {
                      text: t('common.delete'),
                      renderIcon: () => <DeleteIcon sx={{ color: 'red.2' }} />,
                      onClick: () => {
                        openConfirmationDialog(
                          t('flow.deleteFlow'),
                          t('common.delete'),
                          () => {
                            deleteFlow(flow._id ?? '').then(refetchFlows);
                          },
                        );
                      },
                    },
                  ],
                ]}
              />
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>

      <ModalBox
        open={activatorsModalOpen}
        onClose={() => setActivatorsModalOpen(false)}
        maxWidth="50%"
      >
        <FlowActivatorsModal flow={flow} />
      </ModalBox>
    </>
  );
};

export default FlowItem;
