import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import deleteUserAccount from '../../api/telegram-accounts/delete-user-account';
import loginTelegramUserAccount from '../../api/telegram-accounts/login-telegram-account';
import { AppContext } from '../../AppContext';
import defaultBotAvatar from '../../assets/images/defaultBotAvatar.png';
import loadingRefreshBotJson from '../../assets/lottie/loading.json';
import CopyableText from '../../common/copyableText/copyableText';
import { ProjectContext } from '../../projects/project.context';
import { ITelegramUserAccountSafe } from '../telegram-user.interface';

import UserAccountLogin from './login';

interface TelegramUserCardProps {
  userAccount: ITelegramUserAccountSafe;
  loggingIn: boolean;
  toggleLoggingIn: (loggingIn: boolean) => void;
}

const MAX_NAME_LENGTH = 25;

const UserAccountCard: FC<TelegramUserCardProps> = ({
  userAccount,
  loggingIn,
  toggleLoggingIn,
}) => {
  const { t } = useTranslation();
  const { openConfirmationDialog, triggerSnackbar } = useContext(AppContext);
  const { project, refetchUserAccounts } = useContext(ProjectContext);

  const navigate = useNavigate();

  const [name, setName] = useState<string>(userAccount.name);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onRefreshInfo = async () => {
    setRefreshLoading(true);
    try {
      triggerSnackbar('Not implemented yet', 'error');
    } catch (e) {
      triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
    }
    setRefreshLoading(false);
  };

  const onEdit = () => {
    // editBot(bot._id, { name, token }).then(refetchBots);
  };

  const onLoginClick = () => {
    loginTelegramUserAccount(userAccount._id)
      .then(() => {
        toggleLoggingIn(true);
      })
      .catch(() => {
        triggerSnackbar('Some error occurred', 'error');
      });
  };

  const onPasswordSet = () => {
    setTimeout(() => {
      refetchUserAccounts();
      toggleLoggingIn(false);
    }, 1000);
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('bot-page.settings.deleteBotText')}`,
      `${t('common.agree')}`,
      () => {
        deleteUserAccount(userAccount._id).then(() => {
          navigate(`/project/${project._id}`);
          refetchUserAccounts();
        });
      },
    );
  };

  const submitDisabled = useMemo(
    () => Boolean(name === userAccount.name),
    [name],
  );

  if (loggingIn) {
    return (
      <UserAccountLogin
        accountId={userAccount._id}
        onPasswordSet={onPasswordSet}
      />
    );
  }

  return (
    <Stack
      sx={{
        width: '340px',
      }}
    >
      {refreshLoading && (
        <Stack
          sx={{
            width: '280px',
            height: '500px',
            position: 'absolute',
            zIndex: '11',
            mt: '60px',
          }}
        >
          <Lottie animationData={loadingRefreshBotJson} />
        </Stack>
      )}

      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          width: 'max-content',
          borderRadius: '8px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          padding: '10px',
          gap: '18px',
          opacity: refreshLoading ? '0.5' : '1',
        }}
      >
        <Stack alignItems="center" sx={{ position: 'relative' }}>
          {userAccount.authorized ? (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green.2',
                border: '1.5px solid',
                borderColor: 'grey.1',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: '15px',
                  color: 'green.1',
                }}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'grey.1',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <ErrorIcon
                sx={{
                  fontSize: '16px',
                  color: 'red.2',
                }}
              />
            </Stack>
          )}
          <img
            src={
              userAccount.avatarUrl ? userAccount.avatarUrl : defaultBotAvatar
            }
            alt=""
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              position: 'relative',
            }}
          />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Typography>{t('common.name')}</Typography>
          <TextField
            value={name}
            onChange={onChangeName}
            placeholder={t('common.name')}
          />
        </Stack>
        {userAccount.username && (
          <Stack sx={{ width: '100%' }}>
            <Typography>username</Typography>
            <CopyableText text={`@${userAccount.username}`} />
          </Stack>
        )}

        <Stack sx={{ width: '100%' }}>
          <Typography>IDc</Typography>
          <CopyableText text={`${userAccount._id}`} color="blue.2" />
        </Stack>
        <Divider />

        <Stack direction="column" gap="12px">
          {userAccount.authorized && (
            <Button
              startIcon={<RefreshIcon />}
              onClick={onRefreshInfo}
              color="inherit"
              sx={{
                borderRadius: '6px',
                border: '2px solid',
                borderColor: 'grey.1',
                minWidth: '48px',
              }}
            >
              Refresh info
            </Button>
          )}

          {!userAccount.authorized && (
            <Button
              onClick={onLoginClick}
              color="inherit"
              sx={{
                borderRadius: '6px',
                border: '2px solid',
                borderColor: 'grey.1',
                minWidth: '48px',
              }}
            >
              Login
            </Button>
          )}
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
            }}
          >
            <Button
              sx={{
                width: '264px',
                borderRadius: '6px',
                backgroundColor: 'blue.2',
                color: 'grey.1',
                lineHeight: '130%',
                p: '9px 0',
                '&:hover': {
                  backgroundColor: 'blue.3',
                },
              }}
              color="inherit"
              variant="contained"
              onClick={onEdit}
              disabled={submitDisabled}
            >
              {t('common.save')}
            </Button>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
              mt: '20px',
            }}
          >
            <Button
              sx={{
                width: '264px',
                borderRadius: '6px',
                backgroundColor: 'red.2',
                color: 'grey.1',
                lineHeight: '130%',
                p: '9px 0',
                '&:hover': {
                  backgroundColor: 'red.3',
                },
              }}
              color="inherit"
              variant="contained"
              onClick={onDeleteClick}
            >
              {t('common.delete')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserAccountCard;
