import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ThreeDotsMenu from '../../../common/threeDotsMenu/three-dots-menu.component';
import { IUser } from '../../../user/user.interface';
import { IProjectMember } from '../../project.interface';

interface Column {
  id: 'firstName' | 'lastName' | 'roleDescription';
  label: string;
  minWidth?: number;
}

interface ParticipantsTableProps {
  members: IProjectMember[];
  user: IUser | null;
  onEditRoleMember: (currentMember: IProjectMember) => void;
  onDeleteMember: (currentMember: IProjectMember) => void;
  isProjectOwner: boolean;
}

const MembersTable: FC<ParticipantsTableProps> = ({
  members,
  user,
  onEditRoleMember,
  onDeleteMember,
  isProjectOwner,
}) => {
  const { t } = useTranslation();

  const [menuState, setMenuState] = useState<{
    anchor: Element | null;
    member: IProjectMember | null;
  }>({ anchor: null, member: null });

  const toggleListSettings = (
    e: React.SyntheticEvent,
    member: IProjectMember,
  ) => {
    setMenuState({ anchor: e.currentTarget, member });
  };

  const columns: readonly Column[] = [
    {
      id: 'firstName',
      label: t('common.name'),
      minWidth: 170,
    },
    {
      id: 'lastName',
      label: t('common.lastName'),
      minWidth: 170,
    },
    {
      id: 'roleDescription',
      label: t('projects.settings.labelRole'),
      minWidth: 170,
    },
  ];

  return (
    <Paper sx={{ width: '100%', maxHeight: '85%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'grey.12',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '6px',
                position: 'sticky',
                top: '0',
                zIndex: '12',
              }}
            >
              {columns.map((column) => (
                <TableCell
                  sx={{ padding: '8px 12px', backgroundColor: 'transparent' }}
                  key={column.id}
                  align={'left'}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((member, index) => {
              const isEven = index % 2 === 0;
              return (
                <TableRow
                  sx={{
                    position: 'relative',
                    backgroundColor: isEven ? 'grey.15' : 'grey.13',
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column) => {
                    let value = '';
                    if (column.id === 'firstName' || column.id === 'lastName') {
                      value = member.user[column.id] ?? '';
                    }

                    if (column.id === 'roleDescription') {
                      value = member.roleDescription;
                    }

                    return (
                      <TableCell
                        sx={{ padding: '11px 10px' }}
                        key={column.id}
                        align={'left'}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      border: 'none',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isProjectOwner && user?._id !== member.user._id && (
                      <>
                        <IconButton
                          onClick={(e) => toggleListSettings(e, member)}
                          sx={{
                            color: 'grey.5',
                            alignItems: 'start',
                            height: 'fit-content',
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>

                        <ThreeDotsMenu
                          open={Boolean(menuState.anchor)}
                          onClose={() =>
                            setMenuState({ anchor: null, member: null })
                          }
                          anchor={menuState.anchor}
                          menuItems={[
                            [
                              {
                                text: t('common.edit'),
                                onClick: () => {
                                  onEditRoleMember(
                                    menuState.member as IProjectMember,
                                  );
                                },
                              },
                            ],
                            [
                              {
                                text: t('common.delete'),
                                onClick: () => {
                                  onDeleteMember(
                                    menuState.member as IProjectMember,
                                  );
                                },
                              },
                            ],
                          ]}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default MembersTable;
