import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import editProjectMember from '../../../api/projects/edit-project-member';
import ModalBox from '../../../common/modal/modal-box';
import { GlobalContext } from '../../../GlobalContext';
import IProject, { IProjectMember } from '../../project.interface';

interface TeamModalEditRoleContainerProps {
  open: boolean;
  onClose: () => void;
  member: IProjectMember;
  project: IProject;
}

const MAX_ROLE_LENGTH = 40;

const EditRoleDescriptionModal: FC<TeamModalEditRoleContainerProps> = ({
  open,
  onClose,
  member,
  project,
}) => {
  const { refetchProjects } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [role, setRole] = useState<IProjectMember['roleDescription']>('');

  useEffect(() => {
    setRole(member?.roleDescription ?? '');
  }, [open]);

  const onSubmitHandler = () => {
    editProjectMember(project._id, { roleDescription: role }, member._id).then(
      refetchProjects,
    );

    onClose();
  };
  const handleClose = () => {
    onClose();
    setRole('');
  };

  const disabledButton =
    Boolean(!role) || Boolean(member?.roleDescription === role);
  const onRoleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_ROLE_LENGTH) {
      setRole(value);
    }
  };
  return (
    <ModalBox open={open} onClose={handleClose}>
      <Stack>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '130%',
            textAlign: 'center',
          }}
        >
          {t('projects.settings.titleEditMemberRole')}
        </Typography>
      </Stack>
      <Stack sx={{ gap: '16px', mt: '16px' }}>
        <Stack>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '130%',
              mb: '8px',
            }}
          >
            {t('projects.settings.roleDescriptionLabel')}
          </Typography>
          <TextField
            value={role ?? ''}
            onChange={onRoleInput}
            placeholder={t('projects.settings.roleDescriptionLabel')}
            variant="outlined"
          />
        </Stack>

        <Divider />
      </Stack>
      <Button
        disabled={disabledButton}
        onClick={onSubmitHandler}
        sx={{
          mt: '16px',
          backgroundColor: 'blue.2',
          color: 'grey.1',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '130%',
          height: '58px',
          textTransform: 'none',
          minHeight: '58px',
        }}
      >
        {t('common.save')}
      </Button>
    </ModalBox>
  );
};

export default EditRoleDescriptionModal;
