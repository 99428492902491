import { TelegramButton } from '../interfaces/common';

import { isEqualNestedObjects } from './isEqualNestedObjects';

export const isEqualArrays = (
  array1: TelegramButton[][],
  array2: TelegramButton[][],
) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (!isEqualNestedObjects(array1[i], array2[i])) {
      return false;
    }
  }

  return true;
};
