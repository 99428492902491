import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import setTelegramAccountCode from '../../api/telegram-accounts/set-telegram-account-code';
import setTelegramAccountPassword from '../../api/telegram-accounts/set-telegram-account-password';

const UserAccountLogin = ({
  accountId,
  onPasswordSet,
}: {
  accountId: string;
  onPasswordSet: () => void;
}) => {
  const [code, setCode] = useState('');
  const [codeSet, setCodeSet] = useState(false);

  const [password, setPassword] = useState('');

  const [passwordSet, setPasswordSet] = useState(false);

  const onSendCode = () => {
    setTelegramAccountCode(accountId, code).then(() => {
      setCodeSet(true);
    });
  };

  const onSendPassword = () => {
    setTelegramAccountPassword(accountId, password).then(() => {
      setPasswordSet(true);
      onPasswordSet();
    });
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        width: '340px',
        borderRadius: '8px',
        backgroundColor: 'grey.14',
        border: '1px solid',
        borderColor: 'grey.10',
        padding: '10px',
        gap: '18px',
      }}
    >
      <Typography>Logging In</Typography>

      <Stack sx={{ width: '100%', mt: '5px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <TextField
            value={code}
            disabled={codeSet}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            placeholder="Code"
          />
          <Button disabled={codeSet} onClick={onSendCode}>
            Send
          </Button>
        </Stack>

        {codeSet && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{
              mt: '5px',
            }}
          >
            <TextField
              value={password}
              disabled={passwordSet}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
            <Button disabled={passwordSet} onClick={onSendPassword}>
              Send
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default UserAccountLogin;
