import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import deleteProject from '../api/projects/delete-project';
import editProject from '../api/projects/edit-project';
import { AppContext } from '../AppContext';
import ColorPicker from '../common/color-picker';
import { GlobalContext } from '../GlobalContext';

import IProject from './project.interface';

const MAX_NAME_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 30;

type EditProject = {
  project: IProject | null;
};
const ProjectEdit: FC<EditProject> = ({ project }) => {
  const { t } = useTranslation();
  const { refetchProjects } = useContext(GlobalContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const navigate = useNavigate();

  const [name, setName] = useState(project?.name);
  const [description, setDescription] = useState(project?.description);
  const [webhookUrl, setWebhookUrl] = useState(project?.webhookUrl);

  const [color, setColor] = useState(project?.color);

  const editButtonDisabled =
    Boolean(!name) ||
    (Boolean(name === project?.name) &&
      Boolean(description === project?.description) &&
      Boolean(color === project?.color) &&
      webhookUrl === project?.webhookUrl);

  const onSubmitClick = () => {
    if (!project) return;

    editProject(project?._id, { name, description, color, webhookUrl }).then(
      refetchProjects,
    );
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('projects.settings.deleteProjectText')}`,
      `${t('projects.settings.deleteProjectAgree')}`,
      () => {
        deleteProject(project?._id as string)
          .then(refetchProjects)
          .then(() => {
            navigate(`/projects`);
          });
      },
    );
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onWebhookInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setWebhookUrl(value);
  };

  const onDescriptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(value);
    }
  };

  return (
    <Stack>
      <Typography
        sx={{
          mt: '30px',
        }}
      >
        {t('common.nameLabel')}
      </Typography>
      <TextField value={name} onChange={onNameInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('common.descriptionLabel')}
      </Typography>
      <TextField value={description} onChange={onDescriptionInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        Webhook URL
      </Typography>
      <TextField value={webhookUrl} onChange={onWebhookInput} />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('common.colorLabel')}
      </Typography>
      <ColorPicker color={color ?? ''} setColor={setColor} />
      <Divider sx={{ margin: '20px 0' }} />
      <Button
        variant="contained"
        disabled={editButtonDisabled}
        sx={{
          backgroundColor: 'blue.2',
          color: 'grey.1',
        }}
        onClick={onSubmitClick}
      >
        {t('common.save')}
      </Button>

      <Button
        variant="contained"
        color="error"
        sx={{
          backgroundColor: 'red.1',
          color: 'grey.1',
          mt: '20px',
        }}
        onClick={onDeleteClick}
      >
        {t('common.delete')} {t('common.project')}
      </Button>
    </Stack>
  );
};

export default ProjectEdit;
