import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const refreshBot = async (botId: ITelegramBot['_id']) => {
  const { data } = await axios.get(`/telegram-bots/${botId}/refresh`);

  return data;
};

export default refreshBot;
