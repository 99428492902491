import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Stack } from '@mui/material';
import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';

const ConnectionEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  data,
}: EdgeProps<{
  deleteEdge: (id: string, source?: string, target?: string) => void;
  source: string;
  target: string;
}>) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onDeleteClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    data?.deleteEdge(id, data?.source, data?.target);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        {selected && (
          <Stack
            sx={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all',
              backgroundColor: 'grey.13',
              border: '1px solid',
              borderColor: 'grey.10',
              padding: '8px',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
            onClick={onDeleteClick}
          >
            <DeleteForeverRoundedIcon
              sx={{
                color: 'red.2',
                fontSize: '28px',
              }}
            />
          </Stack>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default ConnectionEdge;
