import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import ModalBox from '../../common/modal/modal-box';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import { ITag } from '../../interfaces/common';
import SearchInput from '../filter/search-input';
import { MessengerContext } from '../messenger.context';

interface EditTagModalProps {
  isEditTagModalOpen: boolean;
  onClose: () => void;
}

const EditTagModal: FC<EditTagModalProps> = ({
  isEditTagModalOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { tags, updateTag, deleteTag, dialogs } = useContext(MessengerContext);
  const { openConfirmationDialog } = useContext(AppContext);

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [editTagText, setEditTagText] = useState<string>('');
  const [isEditTag, setIsEditTag] = useState<boolean>(false);
  const [inputTag, setInputTag] = useState<string>('');
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);

  const onSelectTag = () => {
    if (!selectedTag) return;
    setIsEditTag(true);
    setEditTagText(selectedTag?.text);
  };

  const onCloseInsideModal = () => {
    setSelectedTag(null);
    setEditTagText('');
    setIsEditTag(false);
  };

  const onChangeTagText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditTagText(value);
  };

  const openProjectMenu = (e: React.SyntheticEvent, tag: ITag) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setSelectedTag(tag);
  };

  const onUpdateTag = () => {
    if (!selectedTag) return;
    updateTag(
      selectedTag._id,
      editTagText,
      dialogs.map((dialog) => dialog._id),
    );
    setInputTag('');
    onCloseInsideModal();
  };

  useEffect(() => {
    setFilteredTags(tags);
  }, [tags]);

  useEffect(() => {
    setFilteredTags(
      tags.filter((tag) => tag.text.includes(inputTag.toLowerCase())),
    );
  }, [inputTag]);
  return (
    <ModalBox
      open={isEditTagModalOpen}
      onClose={() => {
        onClose();
        setInputTag('');
      }}
      maxWidth="378px"
      sx={{ p: '12px' }}
    >
      <Stack sx={{ gap: '12px' }}>
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '130%',
            fontWeight: 500,
            textAlign: 'center',
            color: 'grey.1',
          }}
        >
          {t('messenger.settingTagsLabel')}
        </Typography>
        <Divider />
        <SearchInput
          search={inputTag}
          handleChangeSearch={(e) => setInputTag(e.target.value)}
        />
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          gap="5px"
          sx={{
            maxHeight: '140px',
            overflow: 'auto',
          }}
        >
          {!filteredTags.length && (
            <Typography
              sx={{
                opacity: '0.4',
                textAlign: 'center',
                width: '100%',
                mt: '8px',
              }}
            >
              {t('messenger.noTagsFound')}
            </Typography>
          )}
          {filteredTags.map((tag: ITag) => (
            <>
              <Chip
                icon={
                  <MoreHorizIcon
                    sx={{
                      position: 'absolute',
                      right: '5px',
                      color: 'grey.5',
                      alignItems: 'start',
                      height: 'fit-content',
                      padding: '0',
                      cursor: 'pointer',
                      '&.MuiSvgIcon-root': { margin: '0' },
                    }}
                    onClick={(e) => openProjectMenu(e, tag)}
                  />
                }
                key={tag.text}
                label={tag.text}
                sx={{
                  backgroundColor: 'purple.1',
                  paddingRight: '20px',
                  position: 'relative',
                }}
              />
              <ThreeDotsMenu
                open={Boolean(menuAnchor)}
                onClose={() => {
                  setMenuAnchor(null);
                }}
                anchor={menuAnchor}
                menuItems={[
                  [
                    {
                      text: t('common.edit'),
                      onClick: () => {
                        onSelectTag();
                      },
                      renderIcon: () => <EditIcon />,
                    },
                    {
                      text: t('common.delete'),
                      onClick: () => {
                        openConfirmationDialog(
                          t('messenger.removeTag'),
                          t('common.agree'),
                          () => {
                            if (!selectedTag) return;

                            deleteTag(
                              selectedTag._id,
                              dialogs.map((dialog) => dialog._id),
                            );
                          },
                        );
                      },
                      renderIcon: () => <DeleteIcon sx={{ color: 'red.2' }} />,
                    },
                  ],
                ]}
              />
            </>
          ))}
        </Stack>
      </Stack>
      <ModalBox
        open={Boolean(isEditTag)}
        onClose={onCloseInsideModal}
        maxWidth="378px"
        sx={{ p: '12px' }}
      >
        <Stack gap="16px">
          <Typography sx={{ textAlign: 'center' }}>
            {t('messenger.editTag')}
          </Typography>
          <TextField
            placeholder={t('messenger.editTag')}
            value={editTagText}
            onChange={onChangeTagText}
          />
          <Divider />
          <Stack flexDirection="row" gap="12px">
            <Button
              disabled={Boolean(
                selectedTag?.text === editTagText || !editTagText,
              )}
              variant="outlined"
              sx={{ width: '100%', backgroundColor: 'blue.2', color: 'grey.1' }}
              onClick={onUpdateTag}
            >
              {t('common.save')}
            </Button>
            <Button
              onClick={onCloseInsideModal}
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: 'grey.11',
                color: 'grey.1',
              }}
            >
              {t('common.cancel')}
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
    </ModalBox>
  );
};

export default EditTagModal;
