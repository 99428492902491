import { Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';

import ThreeDotsMenu from '../common/threeDotsMenu/three-dots-menu.component';

export enum LanguageCodes {
  ukrainian = 'uk',
  english = 'en',
}

const Flags = {
  [LanguageCodes.ukrainian]: '🇺🇦',
  [LanguageCodes.english]: '🇺🇸',
};

interface TranslatorControlProps {
  langCode: LanguageCodes;
  handleChangeLanguage: (newLang: LanguageCodes) => void;
}

const TranslatorControl: FC<TranslatorControlProps> = ({
  langCode,
  handleChangeLanguage,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ml: '10px',
        color: 'grey.1',
        mt: '-3px',
      }}
      gap="15px"
    >
      <Typography
        sx={{
          textTransform: 'none',
        }}
      >
        Translate
      </Typography>
      <Stack
        onClick={(e) => openMenu(e)}
        sx={{
          width: '150px',
          position: 'relative',
        }}
        alignItems="start"
        justifyContent="start"
      >
        <Typography
          style={{
            fontSize: '22px',
          }}
        >
          {Flags[langCode]}
        </Typography>
      </Stack>

      <ThreeDotsMenu
        open={menuOpen}
        onClose={closeMenu}
        anchor={anchorEl}
        menuItems={[
          [
            {
              text: 'English',
              onClick: () => {
                handleChangeLanguage(LanguageCodes.english);
              },
            },
            {
              text: 'Українська',
              onClick: () => {
                handleChangeLanguage(LanguageCodes.ukrainian);
              },
            },
          ],
        ]}
      />
    </Stack>
  );
};

export default TranslatorControl;
