import axios from '../axios';

const startFlowDialog = async (dialogId: string, flowId: string) => {
  const { data } = await axios.post(
    `/dialogs/${dialogId}/start-flow/${flowId}`,
  );

  return data;
};

export default startFlowDialog;
