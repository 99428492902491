import { IMedia, TelegramButton } from '../interfaces/common';

export const isEqualNestedObjects = (
  nestedArray1: IMedia[] | TelegramButton[],
  nestedArray2: IMedia[] | TelegramButton[],
) => {
  if (nestedArray1.length !== nestedArray2.length) {
    return false;
  }

  if (nestedArray1.length === nestedArray2.length) {
    for (let i = 0; i < nestedArray1.length; i++) {
      const obj1 = nestedArray1[i];
      const obj2 = nestedArray2[i];

      const obj1Values = Object.values(obj1);
      const obj2Values = Object.values(obj2);

      if (!obj1Values.every((value) => obj2Values.includes(value))) {
        return false;
      }
    }
  }

  return true;
};
