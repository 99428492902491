import axios from '../axios';

const login = async (authCode: string) => {
  const { data } = await axios.post('/auth/login', {
    authCode,
  });

  return data;
};

export default login;
