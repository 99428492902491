import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getProjectTelegramBotAccesses from '../../api/bots/getBotAccesses';
import updateTelegramBotAccesses from '../../api/bots/update-accesses';
import { AppContext } from '../../AppContext';
import TooltipInfo from '../../common/tooltip/tooltip';
import { ProjectContext } from '../../projects/project.context';
import {
  ITelegramBot,
  ITelegramBotAccess,
  TelegramMessengerAccess,
  TelegramMessengerMode,
} from '../telegram-bot.interface';

import AccessesTable from './accesses.table';
import BotCard from './bot.card';

interface BotSettingsProps {
  bot: ITelegramBot;
}

function compareBotAccess(
  access1: ITelegramBotAccess,
  access2: ITelegramBotAccess,
) {
  return (
    access1?.broadcasts === access2?.broadcasts &&
    access1?.channels === access2?.channels &&
    access1?.flows === access2?.flows &&
    access1?.messenger === access2?.messenger &&
    access1?.statistics === access2?.statistics
  );
}

const BotSettings: FC<BotSettingsProps> = ({ bot }) => {
  const { project, refetchBots } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { t } = useTranslation();

  const [accesses, setAccesses] = useState<ITelegramBotAccess[]>([]);
  const [prevAccesses, setPrevAccesses] = useState<ITelegramBotAccess[]>([]);
  const [isEqualAccess, setIsEqualAccess] = useState<boolean>(true);

  useEffect(() => {
    setIsEqualAccess(
      accesses.every((accessObj, index) =>
        compareBotAccess(accessObj, prevAccesses[index]),
      ),
    );
  }, [accesses, prevAccesses, bot]);

  useEffect(() => {
    getProjectTelegramBotAccesses(bot._id).then(setPrevAccesses);
  }, []);

  useEffect(() => {
    const members = project.members ?? [];
    setAccesses(
      members.map((member) => {
        const userAccess = prevAccesses.find((a) => a.user === member.user._id);

        return {
          user: member.user._id,
          broadcasts: Boolean(userAccess?.broadcasts),
          flows: Boolean(userAccess?.flows),
          statistics: Boolean(userAccess?.statistics),
          messenger: userAccess?.messenger
            ? userAccess.messenger
            : TelegramMessengerAccess.none,
          channels: Boolean(userAccess?.channels),
          messengerMode: userAccess?.messengerMode
            ? userAccess.messengerMode
            : TelegramMessengerMode.offline,
          _id: userAccess?._id ?? member._id,
        };
      }),
    );
  }, [project, prevAccesses]);

  const onUpdateAccesses = () => {
    updateTelegramBotAccesses(bot._id, accesses)
      .then(() => {
        refetchBots();
        triggerSnackbar(t('common.changes_saved'), 'success');
      })
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
      });
  };

  return (
    <Stack
      direction="row"
      alignItems="start"
      justifyContent="center"
      gap="18px"
      sx={{
        padding: '40px 18px',
        width: '100%',
        maxHeight: 'calc(100vh - 70px)',
      }}
    >
      <BotCard bot={bot} />

      <Stack
        sx={{
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: '#4C4B4C',
          borderRadius: '12px',
          padding: '18px',
          height: '100%',
          maxWidth: '72%',
        }}
        gap="10px"
      >
        <Stack flexDirection="row" gap="10px" alignItems="center">
          <Typography sx={{ color: 'grey.5' }}>
            {t('projects.screen-new-telegram-bot.tableTitle')}
          </Typography>
          <TooltipInfo
            icon={<InfoOutlinedIcon sx={{ color: 'grey.5' }} />}
            title={t('projects.screen-new-telegram-bot.tableTooltipSetting')}
          />
        </Stack>
        <AccessesTable
          owner={project.owner}
          members={project.members}
          accesses={accesses}
          setAccesses={setAccesses}
        />
        <Button
          variant={'contained'}
          disabled={isEqualAccess}
          sx={{
            color: 'grey.1',
            backgroundColor: 'blue.2',
            fontWeight: 600,
            textDecoration: 'none',
          }}
          onClick={onUpdateAccesses}
        >
          {t('projects.screen-new-telegram-bot.saveAccesses')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default BotSettings;
