import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import TurnLeftRoundedIcon from '@mui/icons-material/TurnLeftRounded';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createChannelActivator from '../../api/channels/create-channel-activator';
import fetchChannelStatistics from '../../api/channels/fetch-channel-statistics';
import StatisticsContainer from '../../common/statistics/statistics.container';
import truncate from '../../utils/truncate';
import { BotContext } from '../bot.context';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import ChannelActivators from './channel-activators';
import {
  IChannelActivatorWithParams,
  ITelegramChannelWithActivators,
} from './interfaces';

interface SelectedChannelProps {
  channel: ITelegramChannelWithActivators;
  onBackClick: () => void;
  getChannels: () => void;
  flows: IFlowWithActivatorsAndFolder[];
}

const SelectedChannel: FC<SelectedChannelProps> = ({
  channel,
  onBackClick,
  getChannels,
  flows,
}) => {
  const { t } = useTranslation();
  const { bot } = useContext(BotContext);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [statistics, setStatistics] = useState<(string | number)[][]>([]);
  const [loadingStatistics, setLoadingStatistics] = useState<boolean>(false);

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
    localStorage.setItem('channelTabIndex', newValue.toString());
  };

  const onCreateActivator = (
    newChannelActivator: Omit<
      IChannelActivatorWithParams,
      | 'createdAt'
      | 'updatedAt'
      | '_id'
      | 'channel'
      | 'hidden'
      | 'inviteLink'
      | 'joinRequestsCount'
    >,
  ) => {
    createChannelActivator(
      channel.telegramBot,
      channel._id,
      newChannelActivator,
    ).then(getChannels);
  };

  const onFetchStatistics = (
    params: string[],
    endDate: string,
    startDate: string,
    endDate2: string,
    startDate2: string,
  ) => {
    if (!endDate || !bot || !channel) return;
    setLoadingStatistics(true);
    fetchChannelStatistics(bot?._id, channel?._id, {
      params,
      endDate,
      startDate,
      endDate2,
      startDate2,
    })
      .then(setStatistics)
      .finally(() => setLoadingStatistics(false));
  };

  useEffect(() => {
    const lsTabIndex = Number(localStorage.getItem('channelTabIndex'));

    if (!lsTabIndex) return;

    setCurrentTabIndex(lsTabIndex);
  }, []);
  return (
    <Stack
      sx={{
        width: '100%',
        margin: '0 auto',
      }}
      gap="18px"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="24px"
        sx={{
          maxWidth: '787px',
          margin: '0 auto',
          width: '100%',
          padding: '12px',
          borderRadius: '12px',
          backgroundColor: 'grey.13',
          border: '1px solid',
          borderColor: 'grey.10',
        }}
      >
        <Stack
          sx={{
            width: '44px',
            height: '44px',
            backgroundColor: 'grey.15',
            border: '1px solid',
            borderColor: 'blue.2',
            borderRadius: '12px',
            zIndex: 1,
            cursor: 'pointer',
          }}
          alignItems="center"
          justifyContent="center"
          onClick={onBackClick}
        >
          <TurnLeftRoundedIcon />
        </Stack>
        <Stack direction="row" alignItems="center" gap="12px">
          {Boolean(channel.avatarUrl) ? (
            <img
              src={channel.avatarUrl}
              alt=""
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <CampaignRoundedIcon
              sx={{
                fontSize: '40px',
                color: 'blue.3',
              }}
            />
          )}

          <Stack>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              {truncate(channel.title, 18)}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: 'grey.5',
              }}
            >
              {channel.subscribersCount} {t('channels.channelCard.subscribers')}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '475px',
            ml: 'auto',
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'grey.10',
            backgroundColor: 'grey.14',
            overflow: 'hidden',
          }}
        >
          <Tabs
            variant="fullWidth"
            value={currentTabIndex}
            onChange={(e, newValue) => {
              onChangeTab(e, newValue);
            }}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'green.2',
              },
              '& .MuiTab-root.Mui-selected': {
                color: 'grey.1',
              },
              '& .MuiTab-root': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Tab
              label={
                <Stack>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('channels.selectedChannel.links')}
                  </Typography>
                </Stack>
              }
            />

            <Tab
              label={
                <Stack>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('channels.selectedChannel.statistics')}
                  </Typography>
                </Stack>
              }
            />
          </Tabs>
        </Stack>
      </Stack>

      {currentTabIndex === 0 && (
        <ChannelActivators
          flows={flows}
          channel={channel}
          getChannels={getChannels}
          onCreateActivator={onCreateActivator}
        />
      )}
      {currentTabIndex === 1 && (
        <StatisticsContainer
          onFetchStatistics={onFetchStatistics}
          statistics={statistics}
          loadingStatistics={loadingStatistics}
        />
      )}
    </Stack>
  );
};

export default SelectedChannel;
