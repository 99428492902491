import {
  ITelegramBot,
  ITelegramBotAccess,
} from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const updateTelegramBotAccesses = async (
  botId: string,
  accesses: ITelegramBotAccess[],
) => {
  const { data } = await axios.patch(`/telegram-bots/${botId}/accesses`, {
    accesses,
  });

  return data as ITelegramBot;
};

export default updateTelegramBotAccesses;
