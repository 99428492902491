import {
  ITemplate,
  UpdateTemplate,
} from '../../telegram-messenger/templates/interfaces';
import axios from '../axios';

const editTemplate = async (
  telegramBotId: string,
  botType: string,
  templateId: ITemplate['_id'],
  newTemplateData: UpdateTemplate,
) => {
  const { data } = await axios.patch(
    `/templates/${telegramBotId}/${templateId}/${botType}`,
    newTemplateData,
  );

  return data;
};

export default editTemplate;
