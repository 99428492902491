import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import startFlowDialog from '../../api/dialogs/start-flow-dialog';
import { AppContext } from '../../AppContext';
import {
  IMedia,
  ITag,
  TelegramButton,
  TelegramMessageType,
} from '../../interfaces/common';
import { BotContext } from '../../telegram-bots/bot.context';
import {
  FlowTypes,
  IFlowWithActivatorsAndFolder,
} from '../../telegram-bots/flows/interfaces';
import truncate from '../../utils/truncate';
import { MessengerContext, TelegramDialog } from '../messenger.context';
import { ITemplate } from '../templates/interfaces';

import DialogDescription from './dialog-description';
import EditParamsModal from './edit-params.modal';
import EditTagModal from './edit-tag.modal';
import TagsBox from './tags.box';
import TemplatesBox from './templates.box';

interface CurrentDialogInfoProps {
  dialog: TelegramDialog;
  templates: ITemplate[];
  onTemplateClick: (
    text: string,
    buttons: TelegramButton[][],
    media: IMedia[],
    type: TelegramMessageType,
  ) => void;
  isBot: boolean;
}

const CurrentDialogInfo: FC<CurrentDialogInfoProps> = ({
  dialog,
  templates,
  onTemplateClick,
  isBot,
}) => {
  const { t } = useTranslation();

  const { flows = [] } = useContext(BotContext);

  const { createTag, tags, assignTags, toggleBlockStatus, messengerType } =
    useContext(MessengerContext);

  const { openConfirmationDialog } = useContext(AppContext);

  const [inputTag, setInputTag] = useState<string>('');
  const [visibleAllTags, setVisibleAllTags] = useState<boolean>(false);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const [dialogTags, setDialogTags] = useState<ITag[]>([]);
  const [paramsExpanded, setParamsExpanded] = useState<boolean>(false);
  const [channelMemberExpanded, setChannelMemberExpanded] =
    useState<boolean>(false);
  const [isEditTagModalOpen, setIsEditTagModal] = useState<boolean>(false);
  const [isEditParamsModalOpen, setIsEditParamsModal] =
    useState<boolean>(false);

  const setCurrentTagToDialog = (tag: ITag['_id']) => {
    assignTags(dialog._id, [...dialogTags.map((tag) => tag._id), tag]);
    setInputTag('');
    setVisibleAllTags(false);
  };

  const onCreateTag = () => {
    if (!inputTag) return;
    createTag(dialog._id, inputTag);
    setVisibleAllTags(false);
    setInputTag('');
  };

  const deleteCurrentTagFromDialog = (tagId: ITag['_id']) => {
    assignTags(dialog._id, [
      ...dialogTags.filter((tag) => tag._id !== tagId).map((tag) => tag._id),
    ]);
  };

  const handleBlockStatus = () => {
    if (!dialog) return;
    toggleBlockStatus(dialog._id, !dialog.blockedByUser);
  };

  const onOpenTagModal = () => {
    setIsEditTagModal(true);
  };

  const onCloseTagModal = () => {
    setIsEditTagModal(false);
  };

  const onOpenParamsModal = () => {
    setIsEditParamsModal(true);
  };

  const onCloseParamsModal = () => {
    setIsEditParamsModal(false);
  };

  const onSendFlow = (flow: IFlowWithActivatorsAndFolder) => {
    openConfirmationDialog(
      `${t('common.send')} ${flow?.name}?`,
      t('common.send'),
      () => {
        startFlowDialog(dialog._id, flow._id);
      },
    );
  };

  const onOpenExtraInfo = (name: string) => {
    switch (name) {
      case 'params':
        setParamsExpanded((prev) => !prev);
        setChannelMemberExpanded(false);
        break;
      case 'channels':
        setParamsExpanded(false);
        setChannelMemberExpanded((prev) => !prev);
        break;
      default:
        setParamsExpanded(false);
        setChannelMemberExpanded(false);
    }
  };

  useEffect(() => {
    setDialogTags(
      dialog.tags.filter((tag) =>
        tags.map((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
    setFilteredTags(tags);
  }, [tags]);

  useEffect(() => {
    setDialogTags(
      dialog.tags.filter((tag) =>
        tags.map((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
  }, [dialog]);

  useEffect(() => {
    setFilteredTags(
      tags?.filter(
        (tag: ITag) =>
          !dialogTags.some((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
  }, [dialogTags]);

  useEffect(() => {
    setFilteredTags(
      tags.filter(
        (tag) =>
          tag.text.includes(inputTag.toLowerCase()) &&
          !dialogTags.some((dialogTag: ITag) => dialogTag._id === tag._id),
      ),
    );
  }, [inputTag]);

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          mb: '12px',
          position: 'sticky',
          top: '0',
          zIndex: '1',
          backgroundColor: paramsExpanded ? 'red.1' : 'blue.1',
          borderBottom: '1px solid',
          borderColor: paramsExpanded
            ? 'red.2'
            : channelMemberExpanded
            ? 'blue.2'
            : 'transparent',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            cursor: 'pointer',
            height: '37px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            sx={{
              width: isBot ? '50%' : '100%',
              backgroundColor: 'red.1',
              borderBottom: '1px solid',
              borderColor: paramsExpanded ? 'transparent' : 'red.2',
            }}
            onClick={() => onOpenExtraInfo('params')}
          >
            <KeyboardArrowDownRoundedIcon
              sx={{
                transform: paramsExpanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s ease',
              }}
            />
            <Typography>Params</Typography>
          </Stack>
          {isBot && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              sx={{
                width: '50%',
                backgroundColor: 'blue.1',
                borderBottom: '1px solid',
                borderColor: channelMemberExpanded ? 'transparent' : 'blue.2',
              }}
              onClick={() => onOpenExtraInfo('channels')}
            >
              <KeyboardArrowDownRoundedIcon
                sx={{
                  transform: channelMemberExpanded ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s ease',
                }}
              />
              <Typography>Channels</Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            maxHeight: !paramsExpanded && !channelMemberExpanded ? 0 : 'unset',
            overflow: 'hidden',
          }}
        >
          <Stack
            gap="5px"
            sx={{
              padding: '10px',
            }}
          >
            {paramsExpanded && (
              <Stack>
                {dialog.params.map((param, index) => (
                  <Stack
                    key={'param' + index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="20px"
                  >
                    <Typography
                      sx={{
                        color: 'grey.4',
                        fontSize: '18px',
                      }}
                    >
                      {param.key}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      {param.value ? param.value : '-'}
                    </Typography>
                  </Stack>
                ))}
                <Button
                  onClick={onOpenParamsModal}
                  sx={{
                    borderRadius: '6px',
                    border: ' 1px solid',
                    borderColor: 'red.2',
                    backgroundColor: 'red.2',
                    color: 'grey.1',
                    mt: '10px',
                    '&:hover': {
                      borderColor: 'red.2',
                      backgroundColor: 'red.2',
                      color: 'grey.1',
                    },
                  }}
                  startIcon={<EditIcon />}
                >
                  {t('flowNodes.editParam')}
                </Button>
                <EditParamsModal
                  params={dialog.params}
                  telegramId={dialog.telegramId}
                  project={dialog.project}
                  dialogId={dialog._id}
                  isEditParamsModalOpen={isEditParamsModalOpen}
                  onClose={onCloseParamsModal}
                />
              </Stack>
            )}

            {channelMemberExpanded &&
              dialog.channelMemberships?.map((member, index) => (
                <Stack
                  key={'member' + index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="20px"
                >
                  <Typography
                    sx={{
                      color: 'grey.4',
                      fontSize: '14px',
                    }}
                  >
                    {truncate(member.telegramChannel.title ?? '', 22)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {member.status}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ p: '0 10px' }}>
        <Stack flexDirection="row" gap="6px" alignItems="center">
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
            }}
          >
            {t('messenger.currentDialog.tags.tagsTitle')}
          </Typography>
          <IconButton
            onClick={onOpenTagModal}
            sx={{
              p: '4px',
              borderRadius: '52px',
              border: ' 1px solid',
              borderColor: 'purple.4',
              backgroundColor: 'purple.2',
            }}
          >
            <SettingsIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </IconButton>
          <EditTagModal
            isEditTagModalOpen={isEditTagModalOpen}
            onClose={onCloseTagModal}
          />
        </Stack>
        <TagsBox
          allTags={tags}
          tags={filteredTags}
          createTag={onCreateTag}
          visibleAllTags={visibleAllTags}
          setVisibleAllTags={setVisibleAllTags}
          inputTag={inputTag}
          dialogTags={dialogTags}
          setCurrentTagToDialog={setCurrentTagToDialog}
          deleteCurrentTagFromDialog={deleteCurrentTagFromDialog}
          setInputTag={setInputTag}
        />
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Stack
        sx={{
          p: '0 10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          {t('messenger.currentDialog.note.title')}
        </Typography>
        <DialogDescription dialog={dialog} />
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Stack sx={{ px: '9px' }}>
        <TemplatesBox
          templates={templates}
          onTemplateClick={onTemplateClick}
          flows={
            flows?.filter((flow) => flow.type.includes(FlowTypes.messenger)) ??
            []
          }
          onSendFlow={onSendFlow}
          disableFlows={messengerType !== 'bot'}
        />
      </Stack>
      <Stack
        sx={{
          width: '-webkit-fill-available',
          position: 'fixed',
          bottom: '0',
          backgroundColor: 'grey.15',
        }}
      >
        <Button
          onClick={handleBlockStatus}
          sx={{
            color: dialog.blockedByUser ? 'green.2' : 'red.2',
            height: '60px',
            cursor: 'pointer',
          }}
          startIcon={
            dialog.blockedByUser ? (
              <LockOpenIcon
                sx={{
                  color: 'green.2',
                }}
              />
            ) : (
              <DoDisturbAltIcon
                sx={{
                  color: 'red.2',
                }}
              />
            )
          }
        >
          {dialog.blockedByUser
            ? t('messenger.unblockUser')
            : t('messenger.blockUser')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CurrentDialogInfo;
