import { CreateBroadcast } from '../../telegram-bots/broadcasts/interfaces';
import axios from '../axios';

const createBroadcast = async (
  telegramBotId: string,
  broadcastData: CreateBroadcast,
) => {
  const { data } = await axios.post(
    `/broadcasts/${telegramBotId}`,
    broadcastData,
  );

  return data;
};

export default createBroadcast;
