import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode, useContext } from 'react';

import { UserContext } from '../../user/user.context';

import darkTheme from './dark.theme';
import lightTheme from './light.theme';

interface ThemeContextProviderProps {
  children: ReactNode;
}

const ThemeContextProvider: FC<ThemeContextProviderProps> = ({ children }) => {
  const { currentTheme } = useContext(UserContext);

  const theme = {
    dark: darkTheme,
    light: lightTheme,
    lol: lightTheme,
  };

  return (
    <ThemeProvider theme={theme[currentTheme]}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeContextProvider;
