import { useCallback, useMemo, useState } from 'react';

import { IMedia } from '../../interfaces/common';

const useMediaEditor = (initialMedia: IMedia[] = [], single = false) => {
  const [media, setMedia] = useState<IMedia[]>(initialMedia);

  const onSelectMedia = useCallback(
    (newMedia: IMedia) => {
      if (single) {
        setMedia((prev) => {
          if (prev.map(({ _id }) => _id).includes(newMedia._id)) {
            return [];
          }

          return [newMedia];
        });

        return;
      }

      setMedia((prev) => {
        if (prev.map(({ _id }) => _id).includes(newMedia._id)) {
          return prev.filter((media) => media._id !== newMedia._id);
        }

        if (prev.length === 10) {
          return [...prev];
        }

        return [...prev, newMedia];
      });
    },
    [single],
  );

  const onRemoveMedia = (_id: string) => {
    setMedia(media.filter((media) => media?._id !== _id));
  };

  const updateMedia = (media: IMedia[]) => {
    setMedia(media);
  };

  const isMediaEmpty = useMemo(() => !Boolean(media.length), [media]);

  return {
    media,
    updateMedia,
    onSelectMedia,
    onRemoveMedia,
    isMediaEmpty,
  };
};
export default useMediaEditor;
