import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { Stack } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import ReactPlayer from 'react-player';

interface VideoPreviewProps {
  videoSrc: string;
  playerHeight?: number | string;
  playerWidth?: number | string;
  onOpenModal?: () => void;
}

const VideoPreview: FC<VideoPreviewProps> = ({
  videoSrc,
  playerHeight = 200,
  playerWidth = 135,
  onOpenModal,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = (e: SyntheticEvent) => {
    e.stopPropagation();

    setIsPlaying((prev) => !prev);
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        height: playerHeight,
        width: playerWidth,
        ':hover': {
          '.pauseButton': {
            display: 'block',
          },
        },
      }}
      onClick={onOpenModal}
    >
      <ReactPlayer
        className="react-player"
        url={videoSrc}
        width={playerWidth}
        height={playerHeight}
        playing={isPlaying}
        onEnded={onVideoEnd}
      />

      {!isPlaying && (
        <PlayCircleRoundedIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '48px',
            cursor: 'pointer',
          }}
          onClick={togglePlay}
        />
      )}

      {isPlaying && (
        <PauseCircleFilledRoundedIcon
          className="pauseButton"
          sx={{
            display: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '48px',
            cursor: 'pointer',
          }}
          onClick={togglePlay}
        />
      )}
    </Stack>
  );
};

export default VideoPreview;
