import AddIcon from '@mui/icons-material/Add';
import { alpha, Button, Stack, TextField } from '@mui/material';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import copyFlowNodes from '../../api/flows/copy-flow-nodes';
import createFlow from '../../api/flows/create-flow';
import createFolder from '../../api/flows/create-folder';
import deleteFolder from '../../api/flows/delete-folder';
import editFlow from '../../api/flows/edit-flow';
import editFolder from '../../api/flows/edit-folder';
import fetchFlowFolders from '../../api/flows/fetch-folders';
import { AppContext } from '../../AppContext';
import { NAVBAR_HEIGHT_PX } from '../../common/constants/constants';
import ModalBox from '../../common/modal/modal-box';
import useThemeColors from '../../utils/hooks/useThemeColors';
import { BotContext } from '../bot.context';

import CreateEditFlowModal from './create-edit-flow.modal';
import CreateEditFolderModal from './create-edit-folder.modal';
import DeleteFolderModal from './delete-folder.modal';
import FlowTable from './flow.table';
import FoldersList from './folders.list';
import {
  CreateFlow,
  IFlowFolder,
  IFlowWithActivatorsAndFolder,
} from './interfaces';

interface FlowListProps {
  selectFlow: (flow: IFlowWithActivatorsAndFolder) => void;
}

const FlowsList: FC<FlowListProps> = ({ selectFlow }) => {
  const { bot, flows, refetchFlows } = useContext(BotContext);

  const { t } = useTranslation();
  const colors = useThemeColors();

  const { openConfirmationDialog } = useContext(AppContext);
  const [flowModalOpen, setFlowModalOpen] = useState(false);
  const [isEditFlowModal, setIsEditFlowModal] = useState<boolean>(false);
  const [selectedFlow, setSelectedFlow] =
    useState<IFlowWithActivatorsAndFolder | null>(null);
  const [search, setSearch] = useState('');

  const [folders, setFolders] = useState<IFlowFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<IFlowFolder | null>(
    null,
  );
  const [flowFolderModalOpen, setFlowFolderModalOpen] = useState(false);
  const [deleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [isCreateFlowFolder, setIsCreateFolder] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState<IFlowFolder | null>(null);

  const getFolders = () => {
    fetchFlowFolders(bot._id).then(setFolders);
  };

  const onCloseModalFolder = () => {
    setFlowFolderModalOpen(false);
    setIsCreateFolder(false);
    setDeleteFolderModalOpen(false);
    setCurrentFolder(null);
  };
  const openEditFolderModal = (folder: IFlowFolder) => {
    setCurrentFolder(folder);
    setFlowFolderModalOpen(true);
    setIsCreateFolder(false);
  };

  const onCreateModalFolder = () => {
    setFlowFolderModalOpen(true);
    setIsCreateFolder(true);
  };

  const openDeleteFolderModal = (folder: IFlowFolder) => {
    setCurrentFolder(folder);
    setDeleteFolderModalOpen(true);
  };

  const onSubmitFolder = (name: string) => {
    if (isCreateFlowFolder) {
      createFolder({
        name,
        telegramBot: bot._id,
      }).then(getFolders);
    } else {
      if (!currentFolder) return;
      editFolder(currentFolder?._id, { name }).then(() => {
        getFolders();
        refetchFlows();
      });
    }
  };

  const onDeleteFolder = (deleteWithFlow: boolean) => {
    if (!currentFolder) return;
    deleteFolder(currentFolder?._id, deleteWithFlow).then(() => {
      getFolders();
      refetchFlows();
    });
    onCloseModalFolder();
  };

  const filteredFlows = useMemo(() => {
    if (search) {
      return flows.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (selectedFolder) {
      return flows.filter(({ folder }) => folder?._id === selectedFolder._id);
    }

    return flows;
  }, [search, selectedFolder, flows]);

  const onCloseFlowModal = () => {
    setFlowModalOpen(false);
    setIsEditFlowModal(false);
    setSelectedFlow(null);
  };

  const onSelectFolder = (folder: IFlowFolder | null) => {
    setSelectedFolder(folder);
  };

  const onEditFlowModal = (flow: IFlowWithActivatorsAndFolder) => {
    setFlowModalOpen(true);
    setIsEditFlowModal(true);
    setSelectedFlow(flow);
  };

  const onCopyFlow = (flow: IFlowWithActivatorsAndFolder) => {
    openConfirmationDialog(
      `Скопіювати сценарій "${flow.name}"?`,
      `Скопіювати`,
      () => {
        copyFlowNodes(flow._id).then(() => {
          refetchFlows();
        });
      },
    );
  };

  const showAllFlows = () => {
    setSelectedFolder(null);
  };

  const onSubmit = ({
    name,
    folder,
    type,
  }: Omit<CreateFlow, 'telegramBot'>) => {
    if (!isEditFlowModal) {
      createFlow({
        name,
        folder,
        type,
        telegramBot: bot._id,
      }).then(refetchFlows);
      setFlowModalOpen(false);
    } else {
      if (!selectedFlow) return;
      editFlow(selectedFlow?._id, {
        name,
        type,
        folder,
      }).then(() => {
        setSelectedFlow(null);
        setFlowModalOpen(false);
        setIsEditFlowModal(false);
        refetchFlows();
        getFolders();
      });
    }
  };

  useEffect(() => {
    getFolders();
  }, [bot]);

  return (
    <Stack
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}ps)`,
        padding: '0 40px 20px',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'sticky',
          top: '0',
          py: '20px',
          mx: '-40px',
          px: '40px',
          backgroundColor: alpha(colors.grey['14'], 0.3),
          backdropFilter: 'blur(4px)',
          mb: '20px',
          borderBottom: '1.5px solid',
          borderColor: 'grey.10',
          zIndex: 3,
        }}
      >
        <Stack direction="row" gap="15px">
          <Button
            variant="contained"
            onClick={() => setFlowModalOpen(true)}
            startIcon={<AddIcon />}
            sx={{
              width: '250px',
              borderRadius: '6px',
              backgroundColor: 'blue.2',
              color: 'grey.1',
              textTransform: 'none',
              fontWeight: '600',
            }}
          >
            {t('flow.createFlowBtn')}
          </Button>

          <Button
            startIcon={<AddIcon />}
            onClick={onCreateModalFolder}
            sx={{
              width: '180px',
              height: '42px',
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'blue.2',
              backgroundColor: 'blue.1',
              color: 'grey.1',
              padding: '10px',
            }}
          >
            {t('flow.createFolder')}
          </Button>
        </Stack>

        <TextField
          sx={{
            width: '400px',
          }}
          autoFocus
          placeholder={t('common.filterPlaceholder')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Stack>

      <FoldersList
        selectedFolder={selectedFolder}
        onSelectFolder={onSelectFolder}
        folders={folders}
        showAllFlows={showAllFlows}
        onDeleteFolder={openDeleteFolderModal}
        onEditFolder={openEditFolderModal}
      />
      <Stack
        sx={{
          mt: '20px',
          borderRadius: '12px',
          border: '1.5px solid',
          borderColor: 'grey.10',
          backgroundColor: 'grey.15',
          p: '18px',
        }}
        gap="5px"
      >
        <Stack gap="5px" sx={{ overflow: 'auto' }}>
          <FlowTable
            shownFlows={filteredFlows}
            openEditFlowModal={onEditFlowModal}
            openNodesEditor={selectFlow}
            onCopyFlow={onCopyFlow}
          />
        </Stack>
      </Stack>

      <ModalBox open={flowModalOpen} onClose={onCloseFlowModal}>
        <CreateEditFlowModal
          folders={folders}
          selectedFlow={selectedFlow}
          onSubmit={onSubmit}
        />
      </ModalBox>

      <ModalBox open={flowFolderModalOpen} onClose={onCloseModalFolder}>
        <CreateEditFolderModal
          createFolder={isCreateFlowFolder}
          folder={currentFolder}
          onClose={onCloseModalFolder}
          onSubmit={onSubmitFolder}
        />
      </ModalBox>

      <ModalBox open={deleteFolderModalOpen} onClose={onCloseModalFolder}>
        <DeleteFolderModal
          folder={currentFolder}
          onDeleteFolder={onDeleteFolder}
          onCloseModalFolder={onCloseModalFolder}
        />
      </ModalBox>
    </Stack>
  );
};

export default FlowsList;
