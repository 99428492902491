import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { alpha, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { darkThemeColors } from '../../../common/theme/dark.theme';
import truncate from '../../../utils/truncate';
import { ActionTaskTypes, IActionsFlowNodeData } from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface IActionsNodeData {
  onDelete: () => void;
  onSelect: (tasks: IActionsFlowNodeData['tasks']) => void;
  onCopy: (
    tasks: IActionsFlowNodeData['tasks'],
    position: { x: number; y: number },
  ) => void;
  exitEditor: () => void;
  data: IActionsFlowNodeData;
  position: { x: number; y: number };
  next: string | null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface ActionsNodeProps extends Omit<NodeProps, 'data'> {
  data: IActionsNodeData;
}

const ActionsNode: FC<ActionsNodeProps> = memo(
  ({ data, xPos, yPos, selected }) => {
    useEffect(() => {
      data.onNodePositionChange(data._id, {
        x: xPos,
        y: yPos,
      });
    }, [xPos, yPos]);

    const { t } = useTranslation();

    const [isNewNode, setIsNewNode] = useState(false);

    useEffect(() => {
      const digitsId = Number(data._id);

      setIsNewNode(!isNaN(digitsId));
    }, []);

    const nodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const node = nodeRef.current;
      const preventZoom = (event: WheelEvent) => event.stopPropagation();

      if (node) {
        node.addEventListener('wheel', preventZoom);
        return () => {
          node.removeEventListener('wheel', preventZoom);
        };
      }
    }, []);

    useEffect(() => {
      if (!selected) {
        data.exitEditor();
      }
    }, [selected]);

    const tasks = data.data.tasks;

    const edit = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onSelect(tasks);
    };

    const copy = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onCopy(tasks, { x: data.position.x + 400, y: data.position.y });
    };

    return (
      <Stack
        sx={{
          position: 'relative',
        }}
        onDoubleClick={edit}
      >
        {selected && (
          <Stack
            direction="row"
            justifyContent="center"
            gap="10px"
            sx={{
              position: 'absolute',
              top: -50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={edit}
            >
              <ModeEditOutlineRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'green.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={copy}
            >
              <ContentCopyRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={data.onDelete}
              sx={{
                width: '36px',
                height: '36px',
                overflow: 'hidden',
                backgroundColor: 'red.2',
                padding: '5px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <CloseRoundedIcon
                sx={{
                  color: 'grey.14',
                }}
              />
            </Stack>
          </Stack>
        )}

        <Stack
          className="dragger"
          sx={{
            width: '340px',
            border: '1px solid',
            borderColor: selected ? 'yellow.2' : 'grey.10',
            backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
            padding: '10px',
            borderRadius: '18px',
            cursor: 'default',
            animation: isNewNode
              ? `${pulsingAnimation} 0.5s linear infinite  alternate`
              : '',
            animationIterationCount: 6,
          }}
          ref={nodeRef}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={'edge-' + data._id}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              height: '100%',
              width: '100%',
              borderRadius: '0',
              zIndex: -1,
            }}
          />
          <Handle
            type="source"
            id={'src-' + data._id}
            position={Position.Right}
            style={{
              width: '20px',
              height: '20px',
              marginRight: '-5px',
              border: '4px solid',
              borderColor: darkThemeColors.green['2'],
            }}
          />

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack
              gap="10px"
              sx={{
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {t('flowNodes.actionsLabel')}
              </Typography>

              {!tasks.length && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    backgroundColor: 'grey.12',
                    p: '5px',
                    borderRadius: '6px',
                  }}
                >
                  <FlashOnRoundedIcon
                    sx={{
                      fontSize: '28px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '22px',
                      fontStyle: 'italic',
                    }}
                  >
                    {t('flowNodes.actionsEmpty')}
                  </Typography>
                </Stack>
              )}
              <Stack flexDirection="column" gap="10px">
                {tasks.map((task, index) => {
                  switch (task.type) {
                    case ActionTaskTypes.assignParam: {
                      return (
                        <Stack
                          className="taskContainer"
                          sx={{
                            border: '1px solid',
                            borderRadius: '6px',
                            borderColor: 'grey.10',
                            backgroundColor: 'grey.15',
                            p: '10px 18px',
                          }}
                        >
                          <Typography sx={{ fontWeight: '600' }}>
                            Assign param
                          </Typography>
                          <Stack
                            key={'assignParam' + index}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            sx={{
                              mt: index && '10px',
                            }}
                          >
                            <PlaylistAddIcon />
                            <Stack
                              direction="row"
                              gap="10px"
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              <Typography
                                sx={{
                                  width: 'max-content',
                                }}
                              >
                                {task.key}
                              </Typography>
                              <Typography sx={{ lineHeight: '1' }}>
                                =
                              </Typography>
                              <Typography
                                sx={{
                                  width: 'max-content',
                                }}
                              >
                                {task.value}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    }
                    case ActionTaskTypes.deleteParam: {
                      return (
                        <Stack
                          className="taskContainer"
                          sx={{
                            border: '1px solid',
                            borderRadius: '6px',
                            borderColor: 'grey.10',
                            backgroundColor: 'grey.15',
                            p: '10px 18px',
                          }}
                        >
                          <Typography sx={{ fontWeight: '600' }}>
                            {t('flowNodes.deleteParam')}
                          </Typography>
                          <Stack
                            key={'deleteParam' + index}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            sx={{
                              mt: index && '10px',
                            }}
                          >
                            <PlaylistRemoveIcon />
                            <Stack
                              direction="row"
                              gap="10px"
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              <Typography
                                sx={{
                                  width: 'max-content',
                                }}
                              >
                                {task.key}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    }
                    case ActionTaskTypes.acceptToChannel: {
                      return (
                        <Stack
                          className="taskContainer"
                          sx={{
                            border: '1px solid',
                            borderRadius: '6px',
                            borderColor: 'grey.10',
                            backgroundColor: 'grey.15',
                            p: '10px 18px',
                          }}
                        >
                          <Typography sx={{ fontWeight: '600' }}>
                            Accept to channel
                          </Typography>
                          <Stack
                            key={'assignParam' + index}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            sx={{
                              mt: '10px',
                            }}
                          >
                            <PersonAddIcon />
                            <Typography>
                              {truncate(task.channelName, 25)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }
                    case ActionTaskTypes.unbanInChannel: {
                      return (
                        <Stack
                          className="taskContainer"
                          sx={{
                            border: '1px solid',
                            borderRadius: '6px',
                            borderColor: 'grey.10',
                            backgroundColor: 'grey.15',
                            p: '10px 18px',
                          }}
                        >
                          <Typography sx={{ fontWeight: '600' }}>
                            Unban in channel
                          </Typography>
                          <Stack
                            key={'assignParam' + index}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            sx={{
                              mt: '10px',
                            }}
                          >
                            <PersonAddIcon />
                            <Typography>
                              {truncate(task.channelName, 25)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }
                    case ActionTaskTypes.startFlow: {
                      return (
                        <Stack
                          className="taskContainer"
                          sx={{
                            border: '1px solid',
                            borderRadius: '6px',
                            borderColor: 'grey.10',
                            backgroundColor: 'grey.15',
                            p: '10px 18px',
                          }}
                        >
                          <Typography sx={{ fontWeight: '600' }}>
                            Start flow
                          </Typography>
                          <Stack
                            key={'assignParam' + index}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            sx={{
                              mt: '10px',
                            }}
                          >
                            <PlayCircleIcon />
                            <Typography>
                              {truncate(task.flowName, 25)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }
                  }
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

ActionsNode.displayName = 'ActionsNode';

export default ActionsNode;
