import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import deleteProjectMember from '../../../api/projects/delete-project-member';
import editInviteLink from '../../../api/projects/edit-invite-link';
import { AppContext } from '../../../AppContext';
import CopyableText from '../../../common/copyableText/copyableText';
import { GlobalContext } from '../../../GlobalContext';
import { UserContext } from '../../../user/user.context';
import IProject, { IProjectMember } from '../../project.interface';

import EditRoleDescriptionModal from './edit-role-description.modal';
import MembersTable from './members-table';

interface ProjectMembersProps {
  project: IProject;
}

const authBotUsername = process.env.REACT_APP_TELEGRAM_AUTH_BOT_USERNAME;

const ProjectMembers: FC<ProjectMembersProps> = ({ project }) => {
  const { t } = useTranslation();
  const { openConfirmationDialog } = useContext(AppContext);
  const { refetchProjects } = useContext(GlobalContext);
  const { user } = useContext(UserContext);

  const [currentMember, setCurrentMember] = useState<IProjectMember | null>(
    null,
  );
  const [editRoleModalOpen, setEditRoleModalOpen] = useState<boolean>(false);

  const closeModal = () => {
    setEditRoleModalOpen(false);
  };

  const onEditRoleDescription = (currentMember: IProjectMember) => {
    setCurrentMember(currentMember);
    setEditRoleModalOpen(true);
  };

  const onRevokeInviteLink = () => {
    editInviteLink(project._id).then(refetchProjects);
  };

  const isProjectOwner = user?._id === project.owner;

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.14',
        border: '1px solid ',
        borderColor: 'grey.10',
        borderRadius: '24px',
        minWidth: '70%',
        padding: '17px',
        height: '100%',
        maxHeight: '70vh',
        overflow: 'auto',
      }}
    >
      <Stack>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '18px',
            color: 'grey.1',
            lineHeight: '130%',
            textAlign: 'center',
            mb: '17px',
          }}
        >
          {t('projects.settings.title')}
        </Typography>
      </Stack>
      <Stack sx={{ gap: '17px', overflow: 'hidden', height: '100%' }}>
        <MembersTable
          members={project.members}
          isProjectOwner={isProjectOwner}
          user={user}
          onDeleteMember={(currentMember) => {
            openConfirmationDialog(
              `${t('projects.settings.deleteMember1Part')} ${
                currentMember.user.firstName
              } ${t('projects.settings.deleteMember2Part')}?`,
              t('common.delete'),
              () => {
                deleteProjectMember(project._id, currentMember._id).then(
                  refetchProjects,
                );
              },
            );
          }}
          onEditRoleMember={onEditRoleDescription}
        />
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            mt: 'auto',
            padding: '12px 18px',
            backgroundColor: 'grey.15',
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '6px',
          }}
        >
          <Stack>
            <Typography>{t('projects.settings.inviteText')}</Typography>
            <CopyableText
              text={`https://t.me/${authBotUsername}?start=jp-${project?.inviteCode}`}
            />
          </Stack>
          <Stack
            sx={{
              mt: 'auto',
            }}
          >
            <Button
              onClick={onRevokeInviteLink}
              startIcon={<RefreshIcon sx={{ mt: '-2px' }} />}
              sx={{
                ml: 'auto',
                width: '230px',
                height: '48px',
                color: 'grey.15',
                fontWeight: 600,
                backgroundColor: 'green.2',
                '&:hover': { backgroundColor: 'green.3', color: 'grey.15' },
              }}
            >
              {t('projects.settings.revokeInviteLink')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <EditRoleDescriptionModal
        open={editRoleModalOpen}
        onClose={closeModal}
        member={currentMember as IProjectMember}
        project={project}
      />
    </Stack>
  );
};

export default ProjectMembers;
