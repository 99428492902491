import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyableText from '../../common/copyableText/copyableText';
import { TelegramLeadStatus } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

interface CreateActivatorProps {
  postbackFlows: IFlowWithActivatorsAndFolder[];
}

interface IPostbackParam {
  key: string;
  value: string;
}

const BuildPostback: FC<CreateActivatorProps> = ({ postbackFlows }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { projectParams } = useContext(ProjectContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const [postbackFlow, setPostbackFlow] = useState<string | null>(null);
  const [leadStatuses, setLeadStatuses] = useState<string>('');
  const [params, setParams] = useState<IPostbackParam[]>([]);
  const [postbackLink, setPostbackLink] = useState('');

  const valid = useMemo(
    () =>
      params.every(({ key, value }) => Boolean(key) && Boolean(value)) &&
      Boolean(leadStatuses.length),
    [params, leadStatuses],
  );

  const toggleStatus = (status: string) => {
    setLeadStatuses(status);
  };

  const onAddParam = () => {
    setParams((prev) => [...prev, { key: '', value: '' }]);
  };

  const onParamChange = (
    index: number,
    fieldName: 'key' | 'value',
    newValue: string,
  ) => {
    setParams((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          [fieldName]: newValue,
        });
      }

      return result;
    });
  };

  const onParamDelete = (index: number) => {
    setParams((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChangePostbackFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setPostbackFlow(value);
  };

  const handleChangeParamKey = (index: number, key: string) => {
    setParams((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          key,
        };
      });
    });
  };

  const handleButtonClick = () => {
    if (!user) return;

    const queryParams = [];
    if (postbackFlow) {
      queryParams.push(`postbackflow=${postbackFlow}`);
    }
    if (leadStatuses) {
      queryParams.push(`status=${leadStatuses}`);
    }
    params.forEach((param) => {
      queryParams.push(`params[${param.key}]=${param.value}`);
    });
    const queryString = queryParams.join('&');
    setPostbackLink(
      `${API_URL}/postback/${user.apiKey}/{{clickId}}?${queryString}`,
    );
  };

  useEffect(() => {
    if (valid) {
      handleButtonClick();
    }
  }, [params, leadStatuses, postbackFlow]);
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      {Boolean(postbackFlows.length) && (
        <Stack
          sx={{ mt: '12px' }}
          direction="row"
          alignItems="center"
          gap="15px"
        >
          <Typography sx={{ width: '30%', fontSize: '14px', fontWeight: 500 }}>
            {t('postback.flow')}
          </Typography>
          <FormControl fullWidth>
            <InputLabel sx={{ top: '-4px' }}>{t('postback.flow')}</InputLabel>
            <Select
              sx={{
                border: 'none',
                backgroundColor: 'grey.15',
                '& .MuiSelect-select': {
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
              }}
              label={t('postback.flow')}
              value={postbackFlow ?? ''}
              onChange={handleChangePostbackFlow}
            >
              {postbackFlows.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
      <Stack sx={{ mt: '12px' }} direction="row" alignItems="center" gap="15px">
        <Typography sx={{ width: '30%', fontSize: '14px', fontWeight: 500 }}>
          {t('common.status')}
        </Typography>
        <Stack sx={{ width: '100%' }} gap="10px" direction="row">
          <Stack
            sx={{
              padding: '10px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
              border: '1px solid',
              borderColor: leadStatuses.includes(TelegramLeadStatus.lead)
                ? 'blue.2'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.lead)
                ? 'blue.1'
                : 'transparent',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.lead);
            }}
          >
            Lead
          </Stack>

          <Stack
            sx={{
              padding: '10px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
              border: '1px solid',
              borderColor: leadStatuses.includes(TelegramLeadStatus.reg)
                ? 'blue.2'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.reg)
                ? 'blue.1'
                : 'transparent',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.reg);
            }}
          >
            Reg
          </Stack>
          <Stack
            sx={{
              padding: '10px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
              border: '1px solid',
              borderColor: leadStatuses.includes(TelegramLeadStatus.dep)
                ? 'blue.2'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.dep)
                ? 'blue.1'
                : 'transparent',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.dep);
            }}
          >
            Dep
          </Stack>
          <Stack
            sx={{
              padding: '10px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
              border: '1px solid',
              borderColor: leadStatuses.includes(TelegramLeadStatus.qual)
                ? 'blue.2'
                : 'grey.10',
              backgroundColor: leadStatuses.includes(TelegramLeadStatus.qual)
                ? 'blue.1'
                : 'transparent',
            }}
            onClick={() => {
              toggleStatus(TelegramLeadStatus.qual);
            }}
          >
            Qual
          </Stack>
        </Stack>
      </Stack>
      {Boolean(params.length) && (
        <Typography
          sx={{
            mt: '15px',
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {t('common.parameters')}
        </Typography>
      )}
      <Stack
        sx={{
          mt: '15px',
        }}
      >
        {params.map((param, index) => (
          <Stack
            key={'param' + index}
            direction="row"
            alignItems="center"
            gap="15px"
            justify-content="end"
            sx={{
              mt: index && '10px',
            }}
          >
            <Box sx={{ width: '30%', maxWidth: '150px' }}>
              <FormControl fullWidth>
                <InputLabel>{t('common.key')}</InputLabel>
                <Select
                  value={param.key}
                  label={t('common.key')}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChangeParamKey(index, value);
                  }}
                  sx={{
                    '.MuiSelect-select': {
                      backgroundColor: 'grey.15',
                      py: '14px',
                    },
                  }}
                >
                  {projectParams.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                width: '70%',
                maxWidth: '280px',
                '& .MuiOutlinedInput-input': { padding: '14px 5px' },
              }}
              placeholder="Value"
              value={param.value}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'value', value);
              }}
            />
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'red.1',
                border: '1px solid ',
                borderColor: 'red.2',
                borderRadius: ' 6px',
                minWidth: '42px',
                height: '42px',
                cursor: 'pointer',
              }}
              onClick={() => {
                onParamDelete(index);
              }}
            >
              <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Button
        sx={{
          textTransform: 'none',
          color: 'blue.2',
          mt: '10px',
        }}
        onClick={onAddParam}
      >
        {t('channels.channelActivators.addParam')}
        <AddRoundedIcon
          sx={{
            ml: '5px',
          }}
        />
      </Button>
      {Boolean(postbackLink) && (
        <Stack
          sx={{ mt: '12px' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap="15px"
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            {t('common.link')}
          </Typography>
          <Stack
            gap="6px"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', wordBreak: 'break-all' }}
          >
            <CopyableText text={postbackLink} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
export default BuildPostback;
