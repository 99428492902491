import { IChannelMember } from '../telegram-bots/channels/interfaces';
import { IUserSafe } from '../user/user.interface';

export enum TelegramButtonType {
  action = 'action',
  url = 'url',
}

export interface ITelegramActionButton {
  type: TelegramButtonType.action;
  action: string;
  label: string;
}

export interface ITelegramUrlButton {
  type: TelegramButtonType.url;
  url: string;
  label: string;
}

export type TelegramButton = ITelegramActionButton | ITelegramUrlButton;

export enum TelegramMessageType {
  media = 'media',
  voice = 'voice',
  videoNote = 'videoNote',
  service = 'service',
  date = 'date',
  sticker = 'sticker',
}

export enum TelegramMessageDirection {
  outcome = 'outcome',
  income = 'income',
  service = 'service',
}

export enum TelegramMessageReason {
  messenger = 'messenger',
  service = 'service',
  broadcast = 'broadcast',
  flow = 'flow',
  postback = 'postback',
  channel = 'channel',
}

export interface ITelegramMessage {
  _id: string;
  text: string | null;
  chatId: number;
  telegramId: number;
  dialog: string;
  buttons: TelegramButton[][];
  media: string[];
  type: TelegramMessageType;
  direction: TelegramMessageDirection;
  reason: TelegramMessageReason;
  seen: boolean;
  sender?: string;
  senderName?: string;
  translation?: string;
  deleted?: boolean;
  replyMessageId?: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface ITag {
  _id: string;
  owner: string;
  project: string;
  text: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum MediaType {
  photo = 'photo',
  video = 'video',
  videoNote = 'videoNote',
  audio = 'audio',
  voice = 'voice',
}

export interface IMedia {
  _id: string;
  type: MediaType;
  url: string;
  owner: string;
  name?: string;
}

export interface ITelegramMediaMessage
  extends Omit<ITelegramMessage, 'type' | 'media'> {
  type: TelegramMessageType.media;
  text: string;
  media: IMedia[];
}

export interface ITelegramVoiceMessage
  extends Omit<ITelegramMessage, 'type' | 'media'> {
  type: TelegramMessageType.voice;
  text: string;
  media: IMedia[];
}

export interface ITelegramVideoNoteMessage
  extends Omit<ITelegramMessage, 'type' | 'media'> {
  type: TelegramMessageType.videoNote;
  media: IMedia[];
}

export interface ITelegramServiceMessage
  extends Omit<
    ITelegramMessage,
    | 'type'
    | 'media'
    | 'buttons'
    | 'telegramId'
    | 'sender'
    | 'chatId'
    | 'direction'
  > {
  text: string;
  type: TelegramMessageType.service;
}

export interface ITelegramDateMessage
  extends Omit<ITelegramServiceMessage, 'type'> {
  text: string;
  type: TelegramMessageType.date;
}

export type ITelegramDisplayedMessage = (
  | ITelegramMessage
  | ITelegramMediaMessage
  | ITelegramDateMessage
  | ITelegramVoiceMessage
  | ITelegramVideoNoteMessage
  | ITelegramServiceMessage
) & { replyText?: string } & { replyMedia?: string };

export type SendTelegramMessage = Omit<
  ITelegramMessage,
  '_id' | 'createdAt' | 'updatedAt' | 'telegramId' | 'mediaGroupId'
>;

export type SendEditTelegramMessage = Pick<
  ITelegramMessage,
  '_id' | 'text' | 'buttons' | 'media'
>;

export type EditTelegramMessage = Pick<
  ITelegramMessage,
  '_id' | 'text' | 'buttons'
> & { media: IMedia[] } & { type?: TelegramMessageType };

export enum TelegramLeadStatus {
  lead = 'LEAD',
  reg = 'REG',
  dep = 'DEP',
  qual = 'QUAL',
}

export interface ITelegramLeadParam {
  _id: string;
  project: string;
  telegramId: number;
  key: string;
  value: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITelegramBotDialog {
  _id: string;
  telegramId: number;
  firstName: string;
  lastName: string;
  username: string;
  avatarUrl: string;
  banned: boolean;
  started: boolean;
  botId: string;
  project: string;
  owner: string | null;
  currentManager: string | null;
  notSeenMessagesCount: number;
  notSeenSince: Date | null;
  description: string;
  blockedByUser: boolean;
  tags: string[];
  params: string[];
  status: string;
  sentFirstManagerMessage: boolean;
  sentFirstManagerMessageWithLink: boolean;
  receivedFirstMessage: boolean;
  createdAt: string;
  updatedAt: string;
}

export type TelegramLeadParamKeyValueObject = Omit<
  ITelegramLeadParam,
  'createdAt' | 'updatedAt' | 'project' | 'telegramId'
>;

export interface ITelegramBotDialogExtended
  extends Omit<ITelegramBotDialog, 'params' | 'tags' | 'currentManager'> {
  params: TelegramLeadParamKeyValueObject[];
  tags: ITag[];
  channelMemberships: IChannelMember[];
  currentManager: IUserSafe | null;
}

export type CreateTelegramDialog = Omit<
  ITelegramBotDialog,
  '_id' | 'createdAt' | 'updatedAt'
>;

export type UpdateTelegramDialog = Partial<
  Omit<ITelegramBotDialog, '_id' | 'createdAt' | 'updatedAt'>
>;

export type Pagination = {
  limit?: number;
  skip?: number;
};
