import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import getProjectTelegramBots from '../api/bots/get-bots';
import fetchProjectParams from '../api/projects/fetch-params';
import fetchProjectMedia from '../api/projects/get-media';
import getProjectTelegramUserAccounts from '../api/telegram-accounts/get-user-accounts';
import { GlobalContext } from '../GlobalContext';
import { IMedia } from '../interfaces/common';
import { ITelegramBotWithAccess } from '../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccountWithAccess } from '../telegram-user-accounts/telegram-user.interface';

import ProjectNotFound from './project-not-found';
import IProject from './project.interface';

interface IProjectContext {
  project: IProject;
  telegramBots: ITelegramBotWithAccess[];
  telegramUsers: ITelegramUserAccountWithAccess[];
  projectMedia: IMedia[];
  projectParams: string[];
  refetchMedia: () => void;
  refetchBots: () => void;
  refetchUserAccounts: () => void;
  refetchParams: () => void;
}

export const ProjectContext = createContext<IProjectContext>(
  {} as IProjectContext,
);

const ProjectContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { projects } = useContext(GlobalContext);

  const [project, setProject] = useState<IProject>();
  const [notFound, setNotFound] = useState(false);

  const [loading, setLoading] = useState(true);

  const [telegramBots, setTelegramBots] = useState<ITelegramBotWithAccess[]>(
    [],
  );

  const [telegramUsers, setTelegramUsers] = useState<
    ITelegramUserAccountWithAccess[]
  >([]);

  const [projectMedia, setProjectMedia] = useState<IMedia[]>([]);
  const [projectParams, setProjectParams] = useState<string[]>([]);

  const { projectId } = useParams();

  useEffect(() => {
    setLoading(true);

    const found = projects.find((project) => project._id === projectId);

    if (found) {
      setProject(found);
      setNotFound(false);
    } else {
      setNotFound(true);
    }

    setLoading(false);
  }, [projectId, projects]);

  const getProjectMedia = () => {
    if (!project) return;

    fetchProjectMedia(project._id).then(setProjectMedia);
  };

  const getTelegramBots = () => {
    if (!project) return;

    getProjectTelegramBots(project._id).then(setTelegramBots);
  };

  const getTelegramUsersAccount = () => {
    if (!project) return;

    getProjectTelegramUserAccounts(project._id).then(setTelegramUsers);
  };

  const getProjectParams = () => {
    if (!project) return;
    fetchProjectParams(project._id).then(setProjectParams);
  };

  useEffect(() => {
    getProjectMedia();
    getTelegramBots();
    getTelegramUsersAccount();
    getProjectParams();
  }, [project]);

  if (loading) {
    return null;
  }

  if (notFound) {
    return <ProjectNotFound />;
  }

  return (
    <ProjectContext.Provider
      value={{
        project: project as IProject,
        telegramBots,
        telegramUsers,
        projectMedia,
        projectParams,
        refetchMedia: getProjectMedia,
        refetchBots: getTelegramBots,
        refetchUserAccounts: getTelegramUsersAccount,
        refetchParams: getProjectParams,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
