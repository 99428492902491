import IProject from '../../projects/project.interface';
import axios from '../axios';

const deleteProject = async (_id: IProject['_id']) => {
  const { data } = await axios.delete(`/projects/${_id}`);

  return data;
};

export default deleteProject;
