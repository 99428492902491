/* eslint-disable @typescript-eslint/ban-ts-comment */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer';
import { CSSObject, styled } from '@mui/material/styles';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IMessengerTab } from './messenger.page';

const drawerWidth = 240;

interface DrawerMixinProps {
  theme: Theme;
  open: boolean;
}

const openedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `80px`,
});

interface CustomDrawerProps extends DrawerProps {
  open: boolean;
}

const SDrawer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomDrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  height: 'calc(100vh)',
  flexShrink: 0,
  zIndex: 9,
  // @ts-ignore
  backgroundColor: theme.palette.grey['15'],
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin({ theme, open }),
    '& .MuiDrawer-paper': openedMixin({ theme, open }),
  }),
  ...(!open && {
    ...closedMixin({ theme, open }),
    '& .MuiDrawer-paper': closedMixin({ theme, open }),
  }),
}));

interface BotDrawerProps {
  tabs: IMessengerTab[];
  currentTabIndex: number;
  onTabChange: (_: React.SyntheticEvent, newIndex: number) => void;
}

const Drawer: FC<BotDrawerProps> = ({ tabs, currentTabIndex, onTabChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(
    JSON.parse(sessionStorage.getItem('isDrawerOpened') as string) ?? true,
  );

  useEffect(() => {
    sessionStorage.setItem('isDrawerOpened', JSON.stringify(open));
  }, [open]);

  return (
    <SDrawer open={open}>
      <Tabs
        orientation="vertical"
        value={currentTabIndex}
        onChange={onTabChange}
        TabIndicatorProps={{
          sx: { left: '0', width: '5px' },
        }}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'green.2',
            backgroundColor: 'grey.14',
          },
        }}
      >
        {tabs.map(({ label, icon, disabled, control }) => (
          <Tab
            key={'tab-' + label}
            disableRipple={Boolean(control)}
            sx={{
              borderRadius: '12px',
              mx: '5px',
              minWidth: 'unset',
              alignItems: 'start',
              '&:hover': {
                backgroundColor: Boolean(control) ? 'transparent' : 'grey.14',
              },
            }}
            label={
              open ? (
                <Stack
                  direction="row"
                  gap="10px"
                  sx={{
                    width: '100%',
                    padding: '5px 8px',
                    opacity: disabled ? 0.3 : 1,
                  }}
                >
                  {icon}
                  {control ? (
                    control
                  ) : (
                    <Typography
                      sx={{
                        color: 'grey.1',
                        textAlign: 'left',
                        ml: '10px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {label}
                    </Typography>
                  )}
                </Stack>
              ) : (
                <Stack
                  sx={{
                    padding: '5px 8px',
                    opacity: disabled ? 0.3 : 1,
                  }}
                >
                  {icon}
                </Stack>
              )
            }
          />
        ))}
      </Tabs>

      <Divider sx={{ mt: 'auto' }} />
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{ padding: '18px', cursor: 'pointer' }}
        onClick={() => setOpen((prev: boolean) => !prev)}
      >
        <IconButton>
          {!open ? (
            <ArrowForwardIosRoundedIcon />
          ) : (
            <ArrowBackIosNewRoundedIcon />
          )}
        </IconButton>
        {open && (
          <Typography sx={{ mr: '50px' }}>{t('common.collapse')}</Typography>
        )}
      </Stack>
    </SDrawer>
  );
};

export default Drawer;
