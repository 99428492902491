import { TelegramButton, TelegramButtonType } from '../../../interfaces/common';

const resetActionButtonsTargets = (buttons: TelegramButton[][]) => {
  const newNodeButtons: TelegramButton[][] = [];

  for (let i = 0; i < buttons.length; i++) {
    const currentRow = buttons[i];

    const updatedRow: TelegramButton[] = [];
    for (let j = 0; j < currentRow.length; j++) {
      const button = currentRow[j];

      if (button.type !== TelegramButtonType.action) {
        updatedRow.push(button);
        continue;
      }

      updatedRow.push({
        ...button,
        action: '',
      });
    }

    newNodeButtons.push(updatedRow);
  }
  return newNodeButtons;
};

export default resetActionButtonsTargets;
