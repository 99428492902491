import {
  IBroadcast,
  UpdateBroadcast,
} from '../../telegram-bots/broadcasts/interfaces';
import axios from '../axios';

const editBroadcast = async (
  telegramBotId: string,
  _id: IBroadcast['_id'],
  newBroadcastData: UpdateBroadcast,
) => {
  const { data } = await axios.patch(
    `/broadcasts/${telegramBotId}/${_id}`,
    newBroadcastData,
  );

  return data;
};

export default editBroadcast;
