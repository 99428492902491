import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TooltipInfo from '../../common/tooltip/tooltip';

import {
  CreateFlow,
  FlowTypes,
  IFlowFolder,
  IFlowWithActivatorsAndFolder,
} from './interfaces';

const MAX_NAME_LENGTH = 45;

interface FlowModalProps {
  folders: IFlowFolder[];
  selectedFlow: IFlowWithActivatorsAndFolder | null;
  onSubmit: (newFlow: Omit<CreateFlow, 'telegramBot'>) => void;
}

const CreateEditFlowModal: FC<FlowModalProps> = ({
  folders,
  onSubmit,
  selectedFlow,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [type, setType] = useState<FlowTypes[]>([
    FlowTypes.broadcast,
    FlowTypes.messenger,
    FlowTypes.join,
    FlowTypes.leave,
    FlowTypes.postback,
  ]);
  const [folder, setFolder] = useState<IFlowFolder['_id'] | null>(null);

  const handleChangeFolder = (event: SelectChangeEvent) => {
    setFolder(event.target.value);
  };

  const createButtonDisabled = Boolean(!name);

  const onSubmitClick = () => {
    onSubmit({
      name,
      folder,
      type,
    });
    setName('');
    setFolder(null);
    setType([]);
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const handleCheckboxChange = (names: FlowTypes[], value: boolean) => {
    setType((prev) => {
      if (value) {
        return [...prev, ...names];
      } else {
        return prev.filter((type) => !names.includes(type));
      }
    });
  };

  useEffect(() => {
    if (selectedFlow) {
      setName(selectedFlow.name);
      setType(selectedFlow.type);
      setFolder(selectedFlow.folder?._id ?? null);
    }
  }, [selectedFlow]);
  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: '500',
          textAlign: 'center',
        }}
      >
        {selectedFlow ? t('flow.editFlowTitle') : t('flow.createFlowTitle')}
      </Typography>
      <Typography
        sx={{
          mt: '30px',
        }}
      >
        {t('common.nameLabel')}
      </Typography>
      <TextField
        placeholder={t('common.nameLabelPlaceholder')}
        value={name}
        onChange={onNameInput}
      />

      <Typography
        sx={{
          mt: '20px',
        }}
      >
        {t('flow.folder')}
      </Typography>

      <Box sx={{ minWidth: 120, mt: '20px' }}>
        <FormControl fullWidth>
          <InputLabel>Folder</InputLabel>
          <Select
            value={
              folder
                ? folders.find((flowFolder) =>
                    flowFolder._id.includes(folder ?? ''),
                  )?._id
                : ''
            }
            label="Folder"
            onChange={handleChangeFolder}
          >
            {folders.map(({ _id, name }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Stack
        sx={{
          mt: '16px',
        }}
      >
        <Stack flexDirection="row" gap="8px">
          <Typography
            sx={{
              color: 'grey.5',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '130%',
            }}
          >
            {t('flow.types.title')}
          </Typography>
          <TooltipInfo
            icon={<InfoOutlinedIcon sx={{ color: 'grey.5' }} />}
            title={t('flow.types.tooltip')}
            placement="top"
          />
        </Stack>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.broadcast],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.broadcast)}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.broadcast')}
            />
          </Stack>

          <Stack flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.postback],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.postback)}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.postback')}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.messenger],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.messenger)}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.messenger')}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center" sx={{ mr: '5px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.join, FlowTypes.leave],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.join)}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.channels')}
            />
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ margin: '20px 0' }} />
      <Button
        variant="contained"
        disabled={createButtonDisabled}
        sx={{
          backgroundColor: 'blue.2',
          color: 'grey.1',
        }}
        onClick={onSubmitClick}
      >
        {selectedFlow ? t('common.save') : t('common.create')}
      </Button>
    </Stack>
  );
};

export default CreateEditFlowModal;
