import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import truncate from '../../utils/truncate';

import VideoNotePreview from './video-note.preview';

interface VoiceItemProps {
  videoUrl: string;
  name: string;
  isSelected: boolean;
  onSelect: () => void;
}

const VideoNoteItem: FC<VoiceItemProps> = ({
  videoUrl,
  isSelected,
  onSelect,
  name,
}) => {
  return (
    <Stack>
      <Stack
        sx={{
          width: '204px',
          height: '204px',
          borderRadius: '50%',
          border: '2px solid',
          borderColor: isSelected ? 'green.2' : 'grey.10',
          cursor: 'pointer',
        }}
        onClick={onSelect}
      >
        <VideoNotePreview videoSrc={videoUrl} />
      </Stack>
      <Typography
        sx={{
          color: 'grey.4',
          textAlign: 'center',
        }}
      >
        {truncate(name, 20)}
      </Typography>
    </Stack>
  );
};

export default VideoNoteItem;
