import axios from '../axios';

const deleteProjectMember = async (
  projectId: string,
  projectMemberId: string,
) => {
  const { data } = await axios.delete(
    `/projects/${projectId}/delete-member/${projectMemberId}`,
  );

  return data;
};

export default deleteProjectMember;
