import SendIcon from '@mui/icons-material/Send';
import { Button, Stack, Tab, Tabs, TextField } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalBox from '../../common/modal/modal-box';
import {
  IMedia,
  TelegramButton,
  ITelegramMessage,
  TelegramMessageType,
} from '../../interfaces/common';
import { getBotIdAndType } from '../../utils/getBotIdAndType';

import AddMediaButton from './add-media.button';
import AttachedMediaPreview from './attached-media.preview';
import ButtonsEditor from './buttons.editor';

interface ModalPreviewProps {
  open: boolean;
  onClose: () => void;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  newMessage: string;
  onAddRow: () => void;
  buttons: TelegramButton[][];
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  onRemoveMedia: (_id: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  media: IMedia[];
  sendButtonDisabled: boolean;
  addNewButtonsRowDisabled: boolean;
  isTelegramButtonsValid: boolean;
  messageType: TelegramMessageType;
  onMessageTypeChange: (newType: TelegramMessageType) => void;
  clearMessageState: () => void;
  editingMessageId: ITelegramMessage['_id'] | null;
}

const MessagePreviewModal: FC<ModalPreviewProps> = ({
  open,
  onClose,
  onInput,
  newMessage,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddButton,
  onButtonChange,
  onRemoveMedia,
  onSubmit,
  media,
  sendButtonDisabled,
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  addNewButtonsRowDisabled,
  messageType,
  onMessageTypeChange,
  clearMessageState,
  isTelegramButtonsValid,
  editingMessageId,
}) => {
  const { t } = useTranslation();

  const botType = getBotIdAndType().botType;

  const [mediaTabIndex, setMediaTabIndex] = useState(0);

  useEffect(() => {
    switch (messageType) {
      case TelegramMessageType.media: {
        setMediaTabIndex(0);
        return;
      }
      case TelegramMessageType.voice: {
        setMediaTabIndex(1);
        return;
      }
      case TelegramMessageType.videoNote: {
        setMediaTabIndex(2);
        return;
      }
    }
  }, [messageType]);

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0: {
        onMessageTypeChange(TelegramMessageType.media);
        clearMessageState();
        return;
      }
      case 1: {
        onMessageTypeChange(TelegramMessageType.voice);
        clearMessageState();
        return;
      }
      case 2: {
        onMessageTypeChange(TelegramMessageType.videoNote);
        clearMessageState();
        return;
      }
    }
  };

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth={'594px'}
      sx={{ p: '12px' }}
    >
      <Tabs
        value={mediaTabIndex}
        onChange={onTabChange}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'grey.10',
          '& .MuiTabs-indicator': {
            backgroundColor: 'green.2',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'green.2',
          },
        }}
      >
        <Tab
          disabled={
            Boolean(editingMessageId) &&
            messageType !== TelegramMessageType.media
          }
          label="Photo/Video"
          sx={{ width: '190px', textTransform: 'none' }}
        />
        <Tab
          disabled={
            Boolean(editingMessageId) &&
            messageType !== TelegramMessageType.voice
          }
          label="Voice Message"
          sx={{ width: '190px', textTransform: 'none' }}
        />
        <Tab
          disabled={
            Boolean(editingMessageId) &&
            messageType !== TelegramMessageType.videoNote
          }
          label="Video Note"
          sx={{ width: '190px', textTransform: 'none' }}
        />
      </Tabs>

      <Stack flexDirection="row" alignItems="center" sx={{ padding: '12px 0' }}>
        <Stack flexDirection="row" gap="10px">
          <AddMediaButton
            openMediaModal={openMediaModal}
            openVideoNoteModal={openVideoNoteModal}
            openVoiceModal={openVoiceModal}
            messageType={messageType}
          />
          <AttachedMediaPreview media={media} onRemoveMedia={onRemoveMedia} />
        </Stack>
      </Stack>
      <Stack>
        <TextField
          sx={{
            backgroundColor: 'grey.15',
          }}
          multiline
          placeholder={
            messageType === TelegramMessageType.videoNote
              ? t('common.notSupportMessagePlaceholder')
              : t('common.enterMessagePlaceholder')
          }
          value={newMessage}
          minRows={5}
          maxRows={10}
          onChange={onInput}
          disabled={messageType === TelegramMessageType.videoNote}
        />
      </Stack>
      {botType === 'BOT' && (
        <ButtonsEditor
          buttons={buttons}
          addNewButtonsRowDisabled={addNewButtonsRowDisabled}
          onAddButton={onAddButton}
          onButtonChange={onButtonChange}
          onRemoveButton={onRemoveButton}
          onAddRow={onAddRow}
          onRemoveRow={onRemoveRow}
          isTelegramButtonsValid={isTelegramButtonsValid}
        />
      )}

      <Stack alignItems="end">
        <Button
          sx={{
            mt: '10px',
            fontSize: '18px',
            fontWeight: '600',
            width: '90%',
            maxWidth: '183px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            '&:hover': {
              backgroundColor: 'green.1',
              color: 'grey.1',
            },
          }}
          variant="contained"
          onClick={onSubmit}
          disabled={sendButtonDisabled}
        >
          {t('common.send')}
          <SendIcon sx={{ ml: '10px' }} />
        </Button>
      </Stack>
    </ModalBox>
  );
};

export default MessagePreviewModal;
