import { Container, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WithNav from '../common/layout/with-nav';
import { GlobalContext } from '../GlobalContext';

import CreateProjectButton from './create-project.button';
import CreateFirstProject from './first-project';
import ProjectCard from './project.card';

const ProjectsListPage = () => {
  const { projects, refetchProjects } = useContext(GlobalContext);

  const { t } = useTranslation();

  useEffect(() => {
    refetchProjects();
  }, []);

  const hasProjects = Boolean(projects.length);

  if (!hasProjects) {
    return <CreateFirstProject />;
  }

  return (
    <WithNav>
      <Stack
        sx={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <Container
          sx={{
            mt: '50px',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '30px',
              lineHeight: '130%',
              color: 'grey.5',
              m: '30px auto 36px',
            }}
          >
            {t('projects.chooseProjectOrCreate')}
          </Typography>
          <Stack
            direction="row"
            gap="15px"
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <CreateProjectButton width="272px" height="94px" />
            {projects.map((project) => (
              <ProjectCard key={project._id} project={project} />
            ))}
          </Stack>
        </Container>
      </Stack>
    </WithNav>
  );
};

export default ProjectsListPage;
