import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import CopyableText from '../../common/copyableText/copyableText';
import { BotContext } from '../bot.context';

import {
  FlowParamObject,
  IFlowActivatorWithParams,
  UpdateFlowActivatorWithParams,
} from './interfaces';

const MAX_NAME_LENGTH = 35;

interface CreateNewActivatorProps {
  onCreateNewActivator: (
    newFlowActivator: Omit<
      IFlowActivatorWithParams,
      'createdAt' | 'updatedAt' | '_id' | 'flow'
    >,
  ) => void;
  creatingNewActivator: boolean;
  isCopyActivator: boolean;
  onEditActivator: (activatorToUpdate: UpdateFlowActivatorWithParams) => void;
  selectedActivator?: IFlowActivatorWithParams | null;
  onResetSelectedActivator: () => void;
}

const CreateEditActivator: FC<CreateNewActivatorProps> = ({
  onCreateNewActivator,
  creatingNewActivator,
  isCopyActivator,
  onEditActivator,
  selectedActivator,
  onResetSelectedActivator,
}) => {
  const { t } = useTranslation();
  const { bot } = useContext(BotContext);

  const [name, setName] = useState('');

  const [params, setParams] = useState<FlowParamObject[]>([]);

  if (!bot) return null;

  const valid = useMemo(
    () =>
      Boolean(name) &&
      Boolean(
        selectedActivator?.params !== params ||
          selectedActivator?.name !== name,
      ) &&
      params.every(({ key, value }) => Boolean(key) && Boolean(value)),
    [name, params],
  );

  const onAddParam = () => {
    setParams((prev) => [...prev, { key: '', value: '' }]);
  };

  const onParamChange = (
    index: number,
    fieldName: 'key' | 'value',
    newValue: string,
  ) => {
    setParams((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          [fieldName]: newValue,
        });
      }

      return result;
    });
  };
  const onParamDelete = (index: number) => {
    setParams((prev) => prev.filter((_, i) => i !== index));
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onSave = () => {
    if (creatingNewActivator || isCopyActivator) {
      onCreateNewActivator({
        params,
        name,
      });
      onResetSelectedActivator();
    } else {
      onEditActivator({ name, params });
    }
  };

  useEffect(() => {
    if (selectedActivator) {
      setName(selectedActivator?.name as string);
      setParams(selectedActivator?.params as FlowParamObject[]);
    } else {
      setName('');
      setParams([]);
    }
  }, [selectedActivator]);
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap="15px">
        <Typography>{t('channels.channelActivators.name')}</Typography>
        <TextField
          placeholder={t('channels.channelActivators.namePlaceholder')}
          value={name}
          onChange={onNameInput}
          sx={{
            width: '100%',
          }}
        />
      </Stack>
      {selectedActivator && !isCopyActivator && (
        <Stack
          direction="row"
          alignItems="center"
          gap="15px"
          sx={{
            mt: '10px',
          }}
        >
          <Typography sx={{ width: '100px' }}>
            {t('channels.channelActivators.inviteLink')}
          </Typography>
          <CopyableText
            text={`https://t.me/${bot.username}?start=f-${selectedActivator._id}`}
          />
        </Stack>
      )}
      {Boolean(params.length) && (
        <Typography
          sx={{
            mt: '15px',
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {t('common.parameters')}
        </Typography>
      )}
      <Stack
        gap="10px"
        sx={{
          mt: '15px',
          maxHeight: '500px',
          overflow: 'auto',
        }}
      >
        {params.map((param, index) => (
          <Stack
            key={'param' + index}
            direction="row"
            alignItems="center"
            gap="15px"
          >
            <TextField
              sx={{
                width: '50%',
              }}
              placeholder="Key"
              value={param.key}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'key', value);
              }}
            />
            <TextField
              sx={{
                width: '50%',
              }}
              placeholder="Value"
              value={param.value}
              onChange={(e) => {
                const value = e.target.value.trim();
                onParamChange(index, 'value', value);
              }}
            />
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'red.1',
                border: '1px solid ',
                borderColor: 'red.2',
                borderRadius: ' 6px',
                minWidth: '42px',
                height: '42px',
                cursor: 'pointer',
              }}
              onClick={() => {
                onParamDelete(index);
              }}
            >
              <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Button
        sx={{
          textTransform: 'none',
          color: 'blue.2',
          mt: '10px',
        }}
        onClick={onAddParam}
      >
        {t('channels.channelActivators.addParam')}
        <AddRoundedIcon
          sx={{
            ml: '5px',
          }}
        />
      </Button>
      <Button
        disabled={!valid}
        variant="contained"
        sx={{
          mt: '10px',
          backgroundColor: 'blue.2',
          textTransform: 'none',
          color: 'grey.1',
        }}
        onClick={onSave}
      >
        {t('channels.channelActivators.save')}
      </Button>
    </Stack>
  );
};

export default CreateEditActivator;
