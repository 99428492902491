import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Alert, Snackbar, Stack } from '@mui/material';
import React, { FC, useContext } from 'react';

import { AppContext } from '../../AppContext';

const Index: FC = () => {
  const { snackbarOpen, snackbarText, snackbarSeverity, closeSnackbar } =
    useContext(AppContext);
  return (
    <Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        color={snackbarSeverity === 'success' ? 'success' : 'error'}
      >
        <Alert
          color={snackbarSeverity === 'success' ? 'success' : 'error'}
          onClose={closeSnackbar}
          icon={
            snackbarSeverity === 'success' ? (
              <DoneIcon fontSize="inherit" />
            ) : (
              <ErrorOutlineRoundedIcon fontSize="inherit" />
            )
          }
          sx={{
            width: '100%',
            backgroundColor: '#1E1D1E',
            border:
              snackbarSeverity === 'success'
                ? '1px solid #CAFA44'
                : '1px solid #A70740',
            boxShadow:
              snackbarSeverity === 'success'
                ? '0px 4px 10px rgba(203, 251, 69, 0.15)'
                : '0px 4px 10px rgba(167, 7, 64, 0.15)',
            borderRadius: ' 6px',
            color: '#DDDDDD',
            // fontFamily: 'Gilroy',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '130%',
          }}
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Index;
