import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Checkbox, Divider, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import uploadFile from '../../api/user/upload-file';
import { AppContext } from '../../AppContext';
import uploadingAnimation from '../../assets/lottie/uploading.json';
import { IMedia, MediaType } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';
import ModalBox from '../modal/modal-box';

import MediaContent from './media.content';
import MediaPreview from './media.preview';

interface MediaModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
  openMediaModalBuffer?: () => void;
}

const MAX_FILE_SIZE = 10000000; //10 Mb

const MediaModal: FC<MediaModalProps> = ({
  open,
  onClose,
  onSelectMedia,
  selectedMedia,
  openMediaModalBuffer,
}) => {
  const { t } = useTranslation();
  const { projectMedia, refetchMedia, project } = useContext(ProjectContext);
  const { triggerSnackbar } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const [onlyOwning, setOnlyOwning] = useState(true);
  const [displayedMedia, setDisplayedMedia] = useState<IMedia[]>([]);
  const [isPasteBuffer, setIsPasteBuffer] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;
    if (onlyOwning) {
      setDisplayedMedia(projectMedia.filter(({ owner }) => owner === user._id));
    } else {
      setDisplayedMedia(projectMedia);
    }
  }, [onlyOwning, projectMedia]);

  const [fileSrc, setFileSrc] = useState<string | undefined>();
  const [file, setFile] = useState<Blob | null>(null);

  const [uploading, setUploading] = useState(false);

  const resetState = () => {
    setFileSrc(undefined);
    setFile(null);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    const file = acceptedFiles[0];

    if (file.size > MAX_FILE_SIZE) {
      triggerSnackbar('Файл перевищує 10 Мб', 'error');
      return;
    }

    setFile(file);
    const imageUrl = URL.createObjectURL(file);
    setFileSrc(imageUrl);
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp': ['.webp'],
      'video/mp4': ['.mp4'],
    },
  });

  const uploadMedia = async () => {
    if (!file) {
      throw new Error();
    }

    const formData = new FormData();
    formData.append('file', file);
    return uploadFile(formData, project._id);
  };

  const onUpload = async () => {
    setUploading(true);
    try {
      await uploadMedia();

      triggerSnackbar('Media uploaded', 'success');
      refetchMedia();
    } catch (e) {
      triggerSnackbar('Error uploading file', 'error');
    }

    setUploading(false);
    resetState();
  };

  const onCancel = () => {
    resetState();
  };

  const handlePaste = (event: ClipboardEvent) => {
    const items = event.clipboardData?.items;
    const readFile = (file: File) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (result && typeof result === 'string') {
          if (openMediaModalBuffer) {
            openMediaModalBuffer();
            setIsPasteBuffer(true);
          }

          if (file.size > MAX_FILE_SIZE) {
            triggerSnackbar('Файл перевищує 10 Мб', 'error');
            return;
          }

          setFile(file);
          const imageUrl = URL.createObjectURL(file);
          setFileSrc(imageUrl);
        }
      };

      reader.readAsDataURL(file);
    };
    if (items) {
      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          if (file) {
            readFile(file);
          }
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (openMediaModalBuffer) {
      document.addEventListener('paste', handlePaste);

      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    }
  }, []);

  useEffect(() => {
    if (isPasteBuffer) {
      onSelectMedia(displayedMedia[0]);
      onClose();
    }
  }, [displayedMedia]);
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="746px"
      sx={{ height: '500px', pb: '0', overflow: 'hidden' }}
    >
      <Stack
        sx={{
          minHeight: '390px',
        }}
      >
        <Stack
          direction="row"
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Stack
            alignSelf="start"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'grey.14',
              }}
              onClick={() => setOnlyOwning((prev) => !prev)}
            >
              <Checkbox
                sx={{
                  color: 'blue.2',
                  '&.Mui-checked': {
                    color: 'blue.2',
                  },
                }}
                checked={onlyOwning}
                onChange={() => {
                  //
                }}
              />
              <Typography>Only mine</Typography>
            </Stack>
            <MediaContent
              key="media-content"
              media={displayedMedia.filter(
                ({ type }) =>
                  type === MediaType.photo || type === MediaType.video,
              )}
              selectedMedia={selectedMedia}
              onSelectMedia={onSelectMedia}
            />
          </Stack>

          <Divider
            orientation="vertical"
            sx={{ ml: '12px', mr: '12px', position: 'sticky', top: -10 }}
          />

          <Stack
            sx={{
              width: '145px',
              position: 'sticky',
              top: '0',
              margin: '0 auto 20px',
              pt: '40px',
            }}
            justifyContent="space-between"
          >
            {uploading && (
              <Stack
                sx={{
                  width: '135px',
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              >
                <Lottie animationData={uploadingAnimation} />
              </Stack>
            )}

            {!uploading && (
              <Stack>
                <Stack
                  sx={{
                    maxWidth: '135px',
                  }}
                  alignItems="center"
                >
                  {file && (
                    <MediaPreview
                      file={file}
                      onCancel={onCancel}
                      fileSrc={fileSrc}
                      onUpload={onUpload}
                    />
                  )}

                  {!file && (
                    <>
                      <Stack
                        sx={{
                          width: '135px',
                          height: '132px',
                          border: '2px dashed',
                          borderColor: 'grey.10',
                          backgroundColor: 'grey.13',
                          borderRadius: '12px',
                          cursor: 'pointer',
                        }}
                        justifyContent="center"
                        alignItems="center"
                        {...getRootProps()}
                      >
                        <AddPhotoAlternateIcon
                          sx={{ mb: '10px', fontSize: '48px' }}
                        />
                        <Typography>{t('uploadMedia.upload')}</Typography>
                        <Typography>{t('uploadMedia.media')}</Typography>
                      </Stack>
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mt: '10px',
                          fontSize: '14px',
                          textAlign: 'center',
                          fontWeight: 600,
                          lineHeight: '130%',
                          color: 'red.2',
                        }}
                      >
                        {t('uploadMedia.attention')}
                        <ErrorIcon sx={{ ml: '6px', fontSize: '16px' }} />
                      </Typography>
                      <Typography
                        sx={{
                          mt: '6px',
                          width: '113px',
                          fontSize: '10px',
                          textAlign: 'center',
                          fontWeight: 600,
                          lineHeight: '130%',
                          color: 'grey.5',
                        }}
                      >
                        {t('uploadMedia.warning')}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
            )}

            <Button
              onClick={onClose}
              sx={{
                color: 'grey.1',
                backgroundColor: 'blue.2',
                width: '135px',
                ':hover': {
                  backgroundColor: 'blue.2',
                },
              }}
            >
              {t('common.done')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default MediaModal;
