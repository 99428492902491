import { IFlow, UpdateFlow } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const editFlow = async (flowId: IFlow['_id'], newFlowData: UpdateFlow) => {
  const { data } = await axios.patch(`/flows/${flowId}`, newFlowData);

  return data;
};

export default editFlow;
