import { Divider, Stack } from '@mui/material';
import React, { FC } from 'react';

import FormattedText from '../../common/formatted-text';

interface ServiceMessageComponentProps {
  text: string;
}

const ServiceMessageComponent: FC<ServiceMessageComponentProps> = ({
  text,
}) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }}
      gap="20px"
    >
      <Divider sx={{ width: '28%', maxWidth: '150px' }} />
      <Stack
        sx={{
          zIndex: 1,
          mh: '5px',
          textAlign: 'center',
        }}
      >
        <FormattedText text={text} />
      </Stack>
      <Divider sx={{ width: '28%', maxWidth: '150px' }} />
    </Stack>
  );
};

export default ServiceMessageComponent;
