import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import TurnLeftRoundedIcon from '@mui/icons-material/TurnLeftRounded';
import { IconButton, Stack } from '@mui/material';
import React, { FC } from 'react';

interface FlowControlsProps {
  saveFlow: () => void;
  onBack: () => void;
  onAddClick: (e: React.SyntheticEvent) => void;
  nodesChanged: boolean;
  isFlowValid: boolean;
  setDefaultNodesAndEdges: () => void;
}

const FlowControls: FC<FlowControlsProps> = ({
  onBack,
  saveFlow,
  isFlowValid,
  setDefaultNodesAndEdges,
  nodesChanged,
  onAddClick,
}) => {
  return (
    <>
      <Stack
        sx={{
          position: 'absolute',
          width: '50px',
          height: '50px',
          backgroundColor: 'grey.15',
          border: '1px solid',
          borderColor: 'blue.2',
          borderRadius: '12px',
          top: 20,
          left: 20,
          zIndex: 1,
          cursor: 'pointer',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          sx={{
            width: '100%',
            height: '100%',
            '&:hover': { backgroundColor: 'transparent' },
          }}
          disabled={nodesChanged}
          onClick={onBack}
        >
          <TurnLeftRoundedIcon
            sx={{
              ml: '-5px',
              fontSize: '32px',
            }}
          />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '50px',
          height: '50px',
          borderRadius: '12px',
          top: 80,
          right: 80,
          zIndex: 1,
          cursor: 'pointer',
        }}
        alignItems="center"
        justifyContent="center"
      >
        {!isFlowValid && (
          <ErrorRoundedIcon
            sx={{
              fontSize: '32px',
              color: 'red.2',
            }}
          />
        )}
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '50px',
          backgroundColor: 'grey.15',
          borderRadius: '12px',
          border: '1px solid',
          borderColor: 'blue.2',
          top: 20,
          right: 20,
          zIndex: 1,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '50px',
            cursor: 'pointer',
          }}
          onClick={onAddClick}
        >
          <AddRoundedIcon />
        </Stack>
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          width: '50px',
          backgroundColor: 'blue.2',
          borderRadius: '12px',
          border: '1px solid',
          borderColor: 'blue.1',
          top: 80,
          right: 20,
          zIndex: 1,
          opacity: nodesChanged && isFlowValid ? 1 : 0.3,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '50px',
            cursor: 'pointer',
          }}
        >
          <IconButton
            sx={{
              width: '100%',
              height: '100%',
              '&:hover': { backgroundColor: 'transparent' },
            }}
            onClick={saveFlow}
            disabled={Boolean(!isFlowValid)}
          >
            <SaveRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          width: '50px',
          backgroundColor: 'red.1',
          borderRadius: '12px',
          border: '1px solid',
          borderColor: 'red.2',
          top: 240,
          right: 20,
          zIndex: 1,
          opacity: nodesChanged ? 1 : 0.3,
        }}
        onClick={setDefaultNodesAndEdges}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '50px',
            cursor: 'pointer',
          }}
        >
          <ClearRoundedIcon />
        </Stack>
      </Stack>
    </>
  );
};

export default FlowControls;
