import IProject, { IProjectMember } from '../../projects/project.interface';
import axios from '../axios';

const editProjectMember = async (
  _id: IProject['_id'],
  newTeamMemberData: { roleDescription: string },
  memberId: IProjectMember['_id'],
) => {
  const { data } = await axios.patch(
    `/projects/${_id}/role-description/${memberId}`,
    newTeamMemberData,
  );

  return data;
};

export default editProjectMember;
