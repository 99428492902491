import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FC, useEffect, useState } from 'react';

import fetchProjectStatistics from '../api/projects/fetch-statistics';
import IProject from '../projects/project.interface';

interface Column {
  id: 'firstName' | 'lastName' | 'roleDescription';
  label: string;
  minWidth?: number;
}

interface ProjectStatisticsProps {
  project: IProject;
}

const ProjectStatistics: FC<ProjectStatisticsProps> = ({ project }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchProjectStatistics(project._id).then(setCount);
  }, []);

  const columns: readonly Column[] = [
    {
      id: 'firstName',
      label: 'param',
      minWidth: 170,
    },
    {
      id: 'lastName',
      label: 'total',
      minWidth: 170,
    },
    {
      id: 'roleDescription',
      label: 'red',
      minWidth: 170,
    },
    {
      id: 'roleDescription',
      label: 'dep',
      minWidth: 170,
    },
  ];

  return (
    <Stack
      sx={{
        padding: '100px',
      }}
    >
      <Paper sx={{ width: '100%', maxHeight: '85%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'grey.12',
                  border: '1px solid',
                  borderColor: 'grey.10',
                  borderRadius: '6px',
                  position: 'sticky',
                  top: '0',
                  zIndex: '12',
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ padding: '8px 12px', backgroundColor: 'transparent' }}
                    key={column.id}
                    align={'left'}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                    {count}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[]?.map((member, index) => {
                const isEven = index % 2 === 0;
                return (
                  <TableRow
                    sx={{
                      position: 'relative',
                      backgroundColor: isEven ? 'grey.15' : 'grey.13',
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {columns.map((column) => {
                      let value = 'e';
                      value = 'f';
                      if (
                        column.id === 'firstName' ||
                        column.id === 'lastName'
                      ) {
                        // value = member.user[column.id] ?? '';
                      }

                      if (column.id === 'roleDescription') {
                        // value = member.roleDescription;
                      }

                      return (
                        <TableCell
                          sx={{ padding: '11px 10px' }}
                          key={column.id}
                          align={'left'}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};

export default ProjectStatistics;
