import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../user/user.context';
import { IMessengerTab } from '../messenger.page';

import { ITemplate, UpdateTemplate } from './interfaces';
import TemplateCard from './template-card';

interface TemplateListProps {
  templates: ITemplate[];
  openModal: () => void;
  onEdit: (template: UpdateTemplate) => void;
  onDelete: (_id: ITemplate['_id']) => void;
}

interface TemplatePanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TemplateTabPanel(props: TemplatePanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const TemplateList: FC<TemplateListProps> = ({
  templates,
  openModal,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const [value, setValue] = useState<number>(0);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const TABS: IMessengerTab[] = [
    {
      label: t('messenger.templates.generalLabel'),
    },
    {
      label: t('messenger.templates.privateLabel'),
    },
  ];

  return (
    <Stack
      sx={{
        margin: '0 auto',
        height: '100%',
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChangeTabIndex}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'green.2',
              },
              '& .MuiTab-root.Mui-selected': {
                color: 'green.2',
              },
            }}
          >
            {TABS.map((TAB, index) => (
              <Tab
                key={index}
                label={TAB.label}
                sx={{ width: '50%', maxWidth: 'none' }}
              />
            ))}
          </Tabs>
        </Box>
        <>
          <Button
            sx={{
              color: 'grey.1',
              fontSize: '18px',
              textTransform: 'none',
              fontWeight: 600,
              lineHeight: '130%',
              width: '100%',
              mt: '15px',
              height: '48px',
              borderRadius: ' 6px',
              backgroundColor: 'blue.2',
              '&:hover': {
                backgroundColor: 'blue.3',
              },
            }}
            onClick={openModal}
          >
            <AddIcon
              sx={{ width: '24px', height: '24px', marginRight: '10px' }}
            />
            {t('messenger.templates.createTemplateBtn')}
          </Button>
          {TABS.map((TAB, index) => {
            return (
              <TemplateTabPanel key={index} value={value} index={index}>
                <Stack
                  flexDirection="row"
                  flexWrap="wrap"
                  gap="28px"
                  sx={{
                    margin: '20px auto 0',
                    maxHeight: 'calc(100vh - 170px)',
                    overflow: 'auto',
                    width: '100%',
                  }}
                >
                  {templates
                    .filter((template) =>
                      TAB.label === 'Приватні'
                        ? template.isPrivate &&
                          template.owner.includes(user?._id ?? '')
                        : !template.isPrivate,
                    )
                    ?.map(
                      ({
                        _id,
                        name,
                        text,
                        buttons,
                        media,
                        isPrivate,
                        type,
                        owner,
                      }) => (
                        <TemplateCard
                          key={_id}
                          title={name}
                          media={media}
                          text={text}
                          buttons={buttons}
                          onEdit={() => {
                            onEdit({
                              _id,
                              name,
                              media,
                              text,
                              buttons,
                              isPrivate,
                              type,
                            });
                          }}
                          isDisabledDelete={Boolean(
                            !owner.includes(user?._id ?? ''),
                          )}
                          onDelete={() => onDelete(_id)}
                        />
                      ),
                    )}
                </Stack>
              </TemplateTabPanel>
            );
          })}
        </>
      </Box>
    </Stack>
  );
};

export default TemplateList;
