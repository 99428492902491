import { CreateFlow } from '../../telegram-bots/flows/interfaces';
import axios from '../axios';

const createFlow = async (newFlow: CreateFlow) => {
  const { data } = await axios.post('/flows/', newFlow);

  return data;
};

export default createFlow;
