import { useContext, useEffect, useMemo, useState } from 'react';
import { Node } from 'reactflow';

import saveFlowNodes from '../../../api/flows/save-flow-nodes';
import { AppContext } from '../../../AppContext';
import { isEqualArrays } from '../../../utils/isEqualArrays';
import { isEqualNestedObjects } from '../../../utils/isEqualNestedObjects';
import isTelegramMessageValid from '../../../utils/isTelegramMessageValid';
import { IActionsNodeData } from '../flow-nodes/actions-node';
import { IChatActionNodeData } from '../flow-nodes/chat-action.node';
import { IConditionsNodeData } from '../flow-nodes/conditions.node';
import { IDelayNodeData } from '../flow-nodes/delay.node';
import { ITelegramMessageNodeData } from '../flow-nodes/telegram-message-node';
import { FlowNodeTypes, IFlow, IFlowNode, NodeData } from '../interfaces';

const leavePageHandler = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

const useReactFlowEditor = (
  flow: IFlow,
  initialNodes: Node<NodeData>[],
  currentNodes: Node<NodeData>[],
) => {
  const { triggerSnackbar } = useContext(AppContext);
  const [removedNodes, setRemovedNodes] = useState<Node<NodeData>[]>([]);
  const [newNodes, setNewNodes] = useState<Node<NodeData>[]>([]);
  const [changedNodes, setChangedNodes] = useState<Node<NodeData>[]>([]);
  const [startNode, setStartNode] = useState<{
    position: {
      x: number;
      y: number;
    };
    next: string | null;
  }>({
    position: flow.startPosition,
    next: flow.rootNode,
  });

  const [isFlowValid, setIsFlowValid] = useState(false);

  const nodesChanged = useMemo(() => {
    const changedNodes = [];
    const removedNodes = [];
    const newNodes = [];
    let nodesValid = true;
    let startChanged = false;

    for (const node of currentNodes) {
      const initialNode = initialNodes.find(({ id }) => id === node.id);

      switch (node.type) {
        case FlowNodeTypes.telegramMessage: {
          const data = node.data as ITelegramMessageNodeData;

          const isValid = isTelegramMessageValid({
            text: data.data.text,
            media: data.data.media.map(({ _id }) => _id),
            _id: data._id,
            buttons: data.data.buttons,
            type: data.data.telegramMessageType,
          });

          if (nodesValid && !isValid) {
            nodesValid = false;
          }

          if (!initialNode) {
            newNodes.push(node);
            continue;
          }

          const initialData = initialNode.data as ITelegramMessageNodeData;

          const messageTextChanged = data.data.text !== initialData.data.text;
          const buttonsChanged = Boolean(
            !isEqualArrays(data.data.buttons, initialData.data.buttons),
          );
          const mediaChanged = Boolean(
            !isEqualNestedObjects(data.data.media, initialData.data.media),
          );

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const nextChanged = node.data.next !== initialNode.data.next;

          if (
            messageTextChanged ||
            mediaChanged ||
            positionChanged ||
            buttonsChanged ||
            nextChanged
          ) {
            changedNodes.push(node);
          }

          break;
        }

        case FlowNodeTypes.actions: {
          const data = node.data as IActionsNodeData;

          if (!data.data.tasks.length) {
            nodesValid = false;
          }

          if (!initialNode) {
            newNodes.push(node);
            continue;
          }

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const dataChanged =
            JSON.stringify(data.data) !==
            JSON.stringify((initialNode.data as IActionsNodeData).data);

          const nextChanged = node.data.next !== initialNode.data.next;

          if (positionChanged || nextChanged || dataChanged) {
            changedNodes.push(node);
          }

          break;
        }

        case FlowNodeTypes.delay: {
          const data = node.data as IDelayNodeData;

          if (!data.data.delayForSeconds) {
            nodesValid = false;
          }

          if (!initialNode) {
            newNodes.push(node);
            continue;
          }

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const dataChanged =
            JSON.stringify(data.data) !==
            JSON.stringify((initialNode.data as IDelayNodeData).data);

          const nextChanged = node.data.next !== initialNode.data.next;

          if (positionChanged || nextChanged || dataChanged) {
            changedNodes.push(node);
          }

          break;
        }

        case FlowNodeTypes.conditions: {
          const data = node.data as IConditionsNodeData;

          if (!data.data.conditions.length) {
            nodesValid = false;
          }

          if (!initialNode) {
            newNodes.push(node);
            continue;
          }

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const dataChanged =
            JSON.stringify(data.data) !==
            JSON.stringify((initialNode.data as IConditionsNodeData).data);

          const nextChanged = node.data.next !== initialNode.data.next;

          if (positionChanged || nextChanged || dataChanged) {
            changedNodes.push(node);
          }

          break;
        }

        case FlowNodeTypes.chatAction: {
          const data = node.data as IChatActionNodeData;

          if (!data.data.actionType) {
            nodesValid = false;
          }

          if (!initialNode) {
            newNodes.push(node);
            continue;
          }

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const dataChanged =
            JSON.stringify(data.data) !==
            JSON.stringify((initialNode.data as IChatActionNodeData).data);

          const nextChanged = node.data.next !== initialNode.data.next;

          if (positionChanged || nextChanged || dataChanged) {
            changedNodes.push(node);
          }

          break;
        }

        case FlowNodeTypes.menu: {
          nodesValid = false;
          break;
        }

        case FlowNodeTypes.start: {
          if (!initialNode) {
            continue;
          }

          const positionChanged =
            node.position.x !== initialNode.position.x ||
            node.position.y !== initialNode.position.y;

          const rootNodeChanged =
            node.data.next !== initialNode.data.next ||
            node.data.next !== startNode.next;

          if (positionChanged || rootNodeChanged) {
            setStartNode({
              position: {
                x: node.position.x,
                y: node.position.y,
              },
              next: node.data.next,
            });

            startChanged = true;
          }
        }
      }
    }

    for (const node of initialNodes) {
      const initialId = node.id;

      const found = currentNodes.find(({ id }) => id === initialId);

      if (!found) {
        removedNodes.push(node);
      }
    }

    setIsFlowValid(nodesValid);

    setChangedNodes(changedNodes);
    setNewNodes(newNodes);
    setRemovedNodes(removedNodes);

    return (
      Boolean(changedNodes.length) ||
      Boolean(newNodes.length) ||
      Boolean(removedNodes.length) ||
      startChanged
    );
  }, [initialNodes, currentNodes]);

  const saveFlow = async () => {
    if (!nodesChanged || !isFlowValid) return;

    return new Promise((res) => {
      const nodesToCreate: Omit<IFlowNode, 'createdAt' | 'updatedAt'>[] = [];
      const nodesToUpdate: Omit<IFlowNode, 'createdAt' | 'updatedAt'>[] = [];
      const nodesToRemove: IFlowNode['_id'][] = [];

      for (const newNode of newNodes) {
        switch (newNode.type) {
          case FlowNodeTypes.telegramMessage: {
            nodesToCreate.push({
              flow: flow._id,
              position: newNode.position,
              _id: newNode.id,
              data: {
                buttons: (newNode.data as ITelegramMessageNodeData).data
                  .buttons,
                text: (newNode.data as ITelegramMessageNodeData).data.text,
                media: (
                  newNode.data as ITelegramMessageNodeData
                ).data.media.map(({ _id }) => _id),
                telegramMessageType: (newNode.data as ITelegramMessageNodeData)
                  .data.telegramMessageType,
              },
              next: newNode.data.next,
              type: FlowNodeTypes.telegramMessage,
            });
            break;
          }

          case FlowNodeTypes.actions: {
            nodesToCreate.push({
              flow: flow._id,
              position: newNode.position,
              _id: newNode.id,
              data: {
                tasks: (newNode.data as IActionsNodeData).data.tasks,
              },
              next: newNode.data.next,
              type: FlowNodeTypes.actions,
            });

            break;
          }

          case FlowNodeTypes.delay: {
            nodesToCreate.push({
              flow: flow._id,
              position: newNode.position,
              _id: newNode.id,
              data: {
                delayForSeconds: (newNode.data as IDelayNodeData).data
                  .delayForSeconds,
                applyTimeRange: (newNode.data as IDelayNodeData).data
                  .applyTimeRange,
                days: (newNode.data as IDelayNodeData).data.days,
                startTime: (newNode.data as IDelayNodeData).data.startTime,
                endTime: (newNode.data as IDelayNodeData).data.endTime,
                timezone: (newNode.data as IDelayNodeData).data.timezone,
              },
              next: newNode.data.next,
              type: FlowNodeTypes.delay,
            });

            break;
          }

          case FlowNodeTypes.conditions: {
            nodesToCreate.push({
              flow: flow._id,
              position: newNode.position,
              _id: newNode.id,
              data: {
                conditions: (newNode.data as IConditionsNodeData).data
                  .conditions,
              },
              next: newNode.data.next,
              type: FlowNodeTypes.conditions,
            });

            break;
          }

          case FlowNodeTypes.chatAction: {
            nodesToCreate.push({
              flow: flow._id,
              position: newNode.position,
              _id: newNode.id,
              data: {
                actionType: (newNode.data as IChatActionNodeData).data
                  .actionType,
                duration: (newNode.data as IChatActionNodeData).data.duration,
              },
              next: newNode.data.next,
              type: FlowNodeTypes.chatAction,
            });

            break;
          }
        }
      }

      for (const updatedNode of changedNodes) {
        switch (updatedNode.type) {
          case FlowNodeTypes.telegramMessage: {
            nodesToUpdate.push({
              flow: flow._id,
              position: updatedNode.position,
              _id: updatedNode.id,
              data: {
                buttons: (updatedNode.data as ITelegramMessageNodeData).data
                  .buttons,
                text: (updatedNode.data as ITelegramMessageNodeData).data.text,
                media: (
                  updatedNode.data as ITelegramMessageNodeData
                ).data.media.map(({ _id }) => _id),
                telegramMessageType: (
                  updatedNode.data as ITelegramMessageNodeData
                ).data.telegramMessageType,
              },

              next: updatedNode.data.next,
              type: FlowNodeTypes.telegramMessage,
            });

            break;
          }

          case FlowNodeTypes.actions: {
            nodesToUpdate.push({
              flow: flow._id,
              position: updatedNode.position,
              _id: updatedNode.id,
              data: {
                tasks: (updatedNode.data as IActionsNodeData).data.tasks,
              },
              next: updatedNode.data.next,
              type: FlowNodeTypes.actions,
            });

            break;
          }

          case FlowNodeTypes.delay: {
            nodesToUpdate.push({
              flow: flow._id,
              position: updatedNode.position,
              _id: updatedNode.id,
              data: {
                delayForSeconds: (updatedNode.data as IDelayNodeData).data
                  .delayForSeconds,
                applyTimeRange: (updatedNode.data as IDelayNodeData).data
                  .applyTimeRange,
                days: (updatedNode.data as IDelayNodeData).data.days,
                startTime: (updatedNode.data as IDelayNodeData).data.startTime,
                endTime: (updatedNode.data as IDelayNodeData).data.endTime,
                timezone: (updatedNode.data as IDelayNodeData).data.timezone,
              },
              next: updatedNode.data.next,
              type: FlowNodeTypes.delay,
            });

            break;
          }

          case FlowNodeTypes.conditions: {
            nodesToUpdate.push({
              flow: flow._id,
              position: updatedNode.position,
              _id: updatedNode.id,
              data: {
                conditions: (updatedNode.data as IConditionsNodeData).data
                  .conditions,
              },
              next: updatedNode.data.next,
              type: FlowNodeTypes.conditions,
            });

            break;
          }

          case FlowNodeTypes.chatAction: {
            nodesToUpdate.push({
              flow: flow._id,
              position: updatedNode.position,
              _id: updatedNode.id,
              data: {
                actionType: (updatedNode.data as IChatActionNodeData).data
                  .actionType,
                duration: (updatedNode.data as IChatActionNodeData).data
                  .duration,
              },
              next: updatedNode.data.next,
              type: FlowNodeTypes.chatAction,
            });

            break;
          }
        }
      }

      for (const node of removedNodes) {
        switch (node.type) {
          case FlowNodeTypes.telegramMessage:
          case FlowNodeTypes.actions:
          case FlowNodeTypes.delay:
          case FlowNodeTypes.chatAction:
          case FlowNodeTypes.conditions: {
            nodesToRemove.push(node.id);
            break;
          }
        }
      }

      saveFlowNodes(flow._id, {
        removedNodeIds: nodesToRemove,
        newNodes: nodesToCreate,
        updatedNodes: nodesToUpdate,
        startNode,
      }).then(() => {
        triggerSnackbar('Changes Saved', 'success');
        setNewNodes([]);
        setRemovedNodes([]);
        setChangedNodes([]);
        res(true);
      });
    });
  };

  useEffect(() => {
    window.removeEventListener('beforeunload', leavePageHandler);
  }, []);

  useEffect(() => {
    if (nodesChanged) {
      window.addEventListener('beforeunload', leavePageHandler);
    } else {
      window.removeEventListener('beforeunload', leavePageHandler);
    }
  }, [nodesChanged]);

  return {
    nodesChanged,
    isFlowValid,
    saveFlow,
  };
};

export default useReactFlowEditor;
