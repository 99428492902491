import { ITelegramUserAccount } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const deleteUserAccount = async (_id: ITelegramUserAccount['_id']) => {
  const { data } = await axios.delete(`/telegram-users/${_id}`);

  return data;
};

export default deleteUserAccount;
