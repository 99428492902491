import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import deleteBot from '../../api/bots/delete-bot';
import editBot from '../../api/bots/edit-bot';
import refreshBot from '../../api/bots/refresh-bot';
import { AppContext } from '../../AppContext';
import defaultBotAvatar from '../../assets/images/defaultBotAvatar.png';
import loadingRefreshBotJson from '../../assets/lottie/loading.json';
import CopyableText from '../../common/copyableText/copyableText';
import { ProjectContext } from '../../projects/project.context';
import { ITelegramBot } from '../telegram-bot.interface';

interface BotCardProps {
  bot: ITelegramBot;
}

const MAX_NAME_LENGTH = 25;

const BotCard: FC<BotCardProps> = ({ bot }) => {
  const { t } = useTranslation();
  const { openConfirmationDialog, triggerSnackbar } = useContext(AppContext);
  const { project, refetchBots } = useContext(ProjectContext);

  const navigate = useNavigate();

  const [name, setName] = useState<string>(bot.name);
  const [token, setToken] = useState<string>(bot.token);
  const [loadingRefreshBot, setLoadingRefreshBot] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onRefreshBot = async () => {
    setLoadingRefreshBot(true);
    try {
      await refreshBot(bot._id).then(refetchBots);
    } catch (e) {
      triggerSnackbar(t('triggerSnackbar.errorRefreshBot'), 'error');
    }
    setLoadingRefreshBot(false);
  };

  const onEdit = () => {
    editBot(bot._id, { name, token }).then(refetchBots);
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('bot-page.settings.deleteBotText')}`,
      `${t('common.agree')}`,
      () => {
        deleteBot(bot._id).then(() => {
          navigate(`/project/${project._id}`);
          refetchBots();
        });
      },
    );
  };

  const submitDisabled = useMemo(
    () =>
      Boolean(name === bot.name) &&
      Boolean(
        token === bot.token || token.split(':')[0] !== bot.token.split(':')[0],
      ),
    [name, token, bot],
  );
  return (
    <Stack
      sx={{
        maxWidth: '28%',
        overflow: 'auto',
      }}
    >
      {loadingRefreshBot && (
        <Stack
          sx={{
            width: '280px',
            height: '500px',
            position: 'absolute',
            zIndex: '11',
            mt: '60px',
          }}
        >
          <Lottie animationData={loadingRefreshBotJson} />
        </Stack>
      )}
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          width: 'max-content',
          borderRadius: '8px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          padding: '10px',
          gap: '18px',
          opacity: loadingRefreshBot ? '0.5' : '1',
        }}
      >
        <Stack alignItems="center" sx={{ position: 'relative' }}>
          {bot.authorized ? (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green.2',
                border: '1.5px solid',
                borderColor: 'grey.1',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: '15px',
                  color: 'green.1',
                }}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                position: 'absolute',
                right: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'grey.1',
                borderRadius: '50%',
                zIndex: '2',
              }}
            >
              <ErrorIcon
                sx={{
                  fontSize: '16px',
                  color: 'red.2',
                }}
              />
            </Stack>
          )}
          <img
            src={bot.avatarUrl ? bot.avatarUrl : defaultBotAvatar}
            alt=""
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              position: 'relative',
            }}
          />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Typography>{t('common.name')}</Typography>
          <TextField
            value={name}
            onChange={onChangeName}
            placeholder={t('common.name')}
          />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Typography>bot token</Typography>
          <TextField
            value={token}
            onChange={(e) => setToken(e.target.value)}
            placeholder="bot token"
          />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Typography>bot username</Typography>
          <CopyableText text={`@${bot.username}`} />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Typography>bot _id</Typography>
          <CopyableText text={`${bot._id}`} color="blue.2" />
        </Stack>
        <Divider />
        <Stack direction="column" gap="12px">
          <Button
            startIcon={<RefreshIcon />}
            onClick={onRefreshBot}
            color="inherit"
            sx={{
              borderRadius: '6px',
              border: '2px solid',
              borderColor: 'grey.1',
              minWidth: '48px',
            }}
          >
            {t('common.refresh')}
          </Button>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
            }}
          >
            <Button
              sx={{
                width: '264px',
                borderRadius: '6px',
                backgroundColor: 'blue.2',
                color: 'grey.1',
                lineHeight: '130%',
                p: '9px 0',
                '&:hover': {
                  backgroundColor: 'blue.3',
                },
              }}
              color="inherit"
              variant="contained"
              onClick={onEdit}
              disabled={submitDisabled}
            >
              {t('common.save')}
            </Button>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              color: 'blue.2',
              mt: '20px',
            }}
          >
            <Button
              sx={{
                width: '264px',
                borderRadius: '6px',
                backgroundColor: 'red.2',
                color: 'grey.1',
                lineHeight: '130%',
                p: '9px 0',
                '&:hover': {
                  backgroundColor: 'red.3',
                },
              }}
              color="inherit"
              variant="contained"
              onClick={onDeleteClick}
            >
              {t('common.delete')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BotCard;
