import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { alpha, Button, Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TelegramButton, TelegramButtonType } from '../../interfaces/common';
import useThemeColors from '../../utils/hooks/useThemeColors';

interface TelegramButtonsRowProps {
  buttons: TelegramButton[];
  onButtonChange: (index: number, key: string, value: string) => void;
  onRemoveButton: (index: number) => void;
  onAddNewUrlButton: () => void;
  onAddNewActionButton?: () => void;
  showAddActionButton?: boolean;
  onRemoveRow: () => void;
  isTelegramButtonsValid?: boolean;
  urlExtended?: boolean;
}

const TelegramButtonsRow: FC<TelegramButtonsRowProps> = ({
  buttons,
  onButtonChange,
  onAddNewUrlButton,
  onAddNewActionButton,
  onRemoveButton,
  onRemoveRow,
  isTelegramButtonsValid,
  urlExtended = false,
  showAddActionButton = false,
}) => {
  const { t } = useTranslation();
  const themeColors = useThemeColors();

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.13',
        padding: '5px',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {buttons.map((button, btnIndex) => {
        if (button.type === TelegramButtonType.url) {
          return (
            <Stack
              key={'row-key-' + btnIndex}
              gap="5px"
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ padding: '5px' }}
            >
              <InsertLinkOutlinedIcon
                sx={{
                  color: 'green.2',
                  mr: '5px',
                }}
              />
              <TextField
                fullWidth
                sx={{
                  width: urlExtended ? '35%' : '100%',
                }}
                value={button.label}
                onChange={(e) =>
                  onButtonChange(btnIndex, 'label', e.target.value)
                }
                placeholder="Label"
              />
              <TextField
                fullWidth
                sx={{
                  width: urlExtended ? '65%' : '100%',
                }}
                InputProps={{
                  sx: {
                    '*.MuiOutlinedInput-notchedOutline': {
                      borderColor: isTelegramButtonsValid ? 'grey.10' : 'red.2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isTelegramButtonsValid ? 'grey.10' : 'red.2',
                    },
                  },
                }}
                value={button.url}
                onChange={(e) =>
                  onButtonChange(btnIndex, 'url', e.target.value)
                }
                placeholder="Url"
              />
              <Button
                sx={{
                  maxWidth: '64px',
                  ml: { xs: '0', md: '10px' },
                  width: { xs: '100%', md: 'auto' },
                  color: 'red.2',
                  borderColor: 'red.2',
                  ':hover': {
                    borderColor: 'red.2',
                  },
                }}
                variant="outlined"
                onClick={() => {
                  onRemoveButton(btnIndex);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </Stack>
          );
        }

        return (
          <Stack
            key={'row-key-' + btnIndex}
            gap="5px"
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ padding: '5px' }}
          >
            <FlashOnRoundedIcon
              sx={{
                color: 'green.2',
                mr: '5px',
              }}
            />
            <TextField
              fullWidth
              sx={{
                width: '100%',
              }}
              inputProps={{
                sx: {},
              }}
              value={button.label}
              onChange={(e) =>
                onButtonChange(btnIndex, 'label', e.target.value)
              }
              placeholder="Label"
            />
            <Button
              sx={{
                maxWidth: '64px',
                ml: { xs: '0', md: '10px' },
                width: { xs: '100%', md: 'auto' },
                color: 'red.2',
                borderColor: 'red.2',
                ':hover': {
                  borderColor: 'red.2',
                },
              }}
              variant="outlined"
              onClick={() => {
                onRemoveButton(btnIndex);
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          </Stack>
        );
      })}

      <Stack
        direction="row"
        alignItems="center"
        gap="15px"
        sx={{
          borderTop: buttons.length ? '1px solid' : '',
          borderColor: 'grey.10',
          mt: buttons.length ? '5px' : '',
          pt: buttons.length ? '5px' : '',
        }}
      >
        <Button
          sx={{
            color: themeColors.blue['2'],
          }}
          disabled={buttons?.length > 4}
          onClick={onAddNewUrlButton}
        >
          + {t('telegramButtons.button')}
        </Button>
        {showAddActionButton && (
          <Button
            sx={{
              color: themeColors.blue['2'],
            }}
            disabled={buttons?.length > 4}
            onClick={
              onAddNewActionButton
                ? onAddNewActionButton
                : () => {
                    //
                  }
            }
          >
            + Action Button
          </Button>
        )}

        <Button
          sx={{
            color: themeColors.red['2'],
            ':hover': {
              backgroundColor: alpha(themeColors.red['2'], 0.1),
            },
          }}
          onClick={onRemoveRow}
        >
          {t('telegramButtons.deleteRow')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TelegramButtonsRow;
