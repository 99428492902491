import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Lottie from 'lottie-react';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';

import createTelegramUserAccount from '../../../api/telegram-accounts/create-telegram-account';
import { AppContext } from '../../../AppContext';
import animation from '../../../assets/lottie/animation.json';
import TooltipInfo from '../../../common/tooltip/tooltip';
import { ProjectContext } from '../../project.context';
import ProjectScreens from '../project-screens.enum';

const NewUserAccount: FC = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { project, telegramBots, refetchUserAccounts } =
    useContext(ProjectContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [appId, setAppId] = useState<string>('');
  const [appHash, setAppHash] = useState<string>('');
  const [telegramBot, setTelegramBot] = useState<string>('');

  const handleChangeBot = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setTelegramBot(value);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;

    if (newName.length < 25) {
      setName(newName);
    }
  };

  const onChangeAppId = (e: ChangeEvent<HTMLInputElement>) => {
    const newAppId = e.target.value.trim();
    if (isNaN(Number(newAppId)) && newAppId !== '') return;

    setAppId(newAppId);
  };

  const onChangeAppHash = (e: ChangeEvent<HTMLInputElement>) => {
    const newAppHash = e.target.value;
    setAppHash(newAppHash);
  };

  const onChangePhone = (val: string) => {
    setPhone(val);
  };

  const [loading, setLoading] = useState(false);

  const onCreateTelegramUserAccount = async () => {
    setLoading(true);

    try {
      const acc = await createTelegramUserAccount({
        name,
        telegramAppId: +appId,
        telegramAppHash: appHash,
        telegramBot,
        project: project._id,
        phone: phone.toString(),
      });

      triggerSnackbar(t('snackbar.botCreated'), 'success');
      refetchUserAccounts();

      navigate(
        `/project/${project._id}/${ProjectScreens.userAccount}/${acc._id}/settings`,
      );
    } catch (e) {
      triggerSnackbar(t('snackbar.botNotCreated'), 'error');
    }

    setLoading(false);
  };

  return (
    <Stack
      direction="row"
      gap="30px"
      justifyContent="center"
      sx={{
        padding: '40px',
      }}
    >
      {!loading && (
        <Stack
          sx={{
            minWidth: '380px',
            backgroundColor: 'grey.14',
            padding: '20px',
            borderRadius: '24px',
            border: '1px solid',
            borderColor: 'grey.10',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '130%',
              textAlign: 'center',
            }}
          >
            {t('projects.screen-new-telegram-user-account.listTitle')}
          </Typography>
          <Stack>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '130%',
                mb: '8px',
              }}
            >
              {t('common.nameLabel')}
            </Typography>
            <TextField
              value={name}
              onChange={onChangeName}
              placeholder={t('common.nameLabelPlaceholder')}
            />
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ m: '20px 0 10px 0' }}
            >
              <FormControl fullWidth>
                <InputLabel sx={{ top: '-4px' }}>
                  {t('projects.screen-new-telegram-user-account.telegramBot')}
                </InputLabel>
                <Select
                  sx={{
                    border: 'none',
                    backgroundColor: 'grey.15',
                    '& .MuiSelect-select': {
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    },
                  }}
                  label={t(
                    'projects.screen-new-telegram-user-account.telegramBot',
                  )}
                  value={telegramBot ?? ''}
                  onChange={handleChangeBot}
                >
                  {telegramBots.map(({ bot }) => (
                    <MenuItem key={bot._id} value={bot._id}>
                      {bot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              gap="10px"
              sx={{ m: '8px 0' }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '130%',
                }}
              >
                Telegram App Api Id and Hash
              </Typography>
              <TooltipInfo
                icon={
                  <InfoOutlinedIcon
                    sx={{ color: 'grey.5', fontSize: '15px' }}
                  />
                }
                title={
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '130%',
                      }}
                    >
                      {t(
                        'projects.screen-new-telegram-user-account.telegramAppIdAndHash',
                      )}{' '}
                      <Link
                        to="https://my.telegram.org/"
                        style={{
                          all: 'unset',
                          color: 'white',
                          cursor: 'pointer',
                        }}
                        target="_blank"
                      >
                        https://my.telegram.org
                      </Link>
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
            <TextField
              value={appId}
              onChange={onChangeAppId}
              placeholder="App api id"
            />
            <TextField
              sx={{
                mt: '5px',
              }}
              value={appHash}
              onChange={onChangeAppHash}
              placeholder="App api hash"
            />

            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '130%',
                my: '8px',
              }}
            >
              {t('projects.screen-new-telegram-user-account.phoneLabel')}
            </Typography>
            <PhoneInput
              international
              defaultCountry="UA"
              value={phone}
              onChange={onChangePhone}
              style={{
                padding: '5px',
              }}
            />
          </Stack>
          <Divider />
          <Button
            variant="contained"
            sx={{
              height: '44px',
              color: 'grey.1',
              backgroundColor: 'blue.2',
              fontWeight: '600',
              textTransform: 'none',
              cursor: 'pointer',
            }}
            onClick={onCreateTelegramUserAccount}
            disabled={!name || !appId || !appHash || !phone || !telegramBot}
          >
            {t('projects.screen-new-telegram-bot.listButtonNext')}
          </Button>
        </Stack>
      )}

      {loading && (
        <Stack
          sx={{
            maxWidth: '400px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '28px',
              color: 'grey.6',
            }}
          >
            {t('projects.creatingBotText')}
          </Typography>
          <Lottie animationData={animation} />
        </Stack>
      )}
    </Stack>
  );
};

export default NewUserAccount;
