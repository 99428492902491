import { ITelegramUserAccount } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const loginTelegramUserAccount = async (
  accountId: ITelegramUserAccount['_id'],
) => {
  const { data } = await axios.put(`/telegram-users/${accountId}/login`);

  return data;
};

export default loginTelegramUserAccount;
