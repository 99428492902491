import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const deleteBot = async (_id: ITelegramBot['_id']) => {
  const { data } = await axios.delete(`/telegram-bots/${_id}`);

  return data;
};

export default deleteBot;
