import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import ModalBox from '../../common/modal/modal-box';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import getChannelScriptText from './get-channel-script-text';
import getFlowScriptText from './get-flow-script-text';

interface ChannelScriptProps {
  type: 'channel';
  botId: string;
  channelId: string;
  flows: IFlowWithActivatorsAndFolder[];
}

interface FlowScriptProps {
  type: 'flow';
  botId: string;
  flowId: string;
  botUsername: string;
}

interface CommonScriptInfoProps {
  scriptModalOpen: boolean;
  handleScriptModalClose: () => void;
}

type ModalScriptInfoProps = CommonScriptInfoProps &
  (FlowScriptProps | ChannelScriptProps);

const ModalScriptInfo: FC<ModalScriptInfoProps> = (props) => {
  const { t } = useTranslation();
  const { triggerSnackbar } = useContext(AppContext);

  const { scriptModalOpen, handleScriptModalClose, type, botId } = props;
  const [availableFlows, setAvailableFlows] = useState<
    IFlowWithActivatorsAndFolder[]
  >([]);

  useEffect(() => {
    if (type === 'channel') {
      const { flows } = props as ChannelScriptProps;
      setAvailableFlows([...flows]);
    }
  }, []);

  const buttonId = useMemo(
    () => (type === 'channel' ? props.channelId : props.flowId),
    [],
  );

  const [joinFlow, setJoinFlow] = useState<string | undefined>(undefined);
  const [leaveFlow, setLeaveFlow] = useState<string | undefined>(undefined);

  const handleChangeJoinFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setJoinFlow(value);
  };
  const handleChangeLeaveFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setLeaveFlow(value);
  };

  const onClearJoinFlow = () => {
    setJoinFlow(undefined);
  };

  const onClearLeaveFlow = () => {
    setLeaveFlow(undefined);
  };

  let scriptText = '';

  if (type === 'channel') {
    scriptText = getChannelScriptText(
      botId,
      props.channelId,
      buttonId,
      joinFlow,
      leaveFlow,
    );
  }
  if (type === 'flow') {
    scriptText = getFlowScriptText(
      botId,
      props.botUsername,
      props.flowId,
      buttonId,
    );
  }

  const copyScript = () => {
    navigator.clipboard.writeText(scriptText as string);
    triggerSnackbar(t('copyableText.text'));
  };

  return (
    <ModalBox
      open={scriptModalOpen}
      onClose={handleScriptModalClose}
      maxWidth={'600px'}
    >
      <Stack sx={{ position: 'relative' }}>
        <Typography sx={{ textAlign: 'center', mb: '10px' }}>
          {t('common.script')}
        </Typography>
        <Divider />
        <Typography sx={{ m: '12px auto' }}>
          {`${t('script.instruction.firstPart')} </body>, ${t(
            'script.instruction.secondPart',
          )} <button id="${t(
            'script.instruction.copyIdHere',
          )}">go to telegram</button>`}
        </Typography>
        {type === 'channel' && (
          <Stack
            sx={{
              mb: '12px',
            }}
          >
            <Stack flexDirection="row" gap="10px">
              <FormControl fullWidth>
                <InputLabel>{t('common.subscriptionFlow')}</InputLabel>
                <Select
                  sx={{
                    backgroundColor: 'grey.15',
                  }}
                  label={t('common.subscriptionFlow')}
                  value={joinFlow ?? ''}
                  onChange={handleChangeJoinFlow}
                >
                  {availableFlows.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'red.1',
                  border: '1px solid ',
                  borderColor: 'red.2',
                  borderRadius: ' 6px',
                  minWidth: '58px',
                  height: '58px',
                  cursor: 'pointer',
                }}
                onClick={onClearJoinFlow}
              >
                <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
              </Stack>
            </Stack>
            <Stack flexDirection="row" gap="10px" sx={{ mt: '10px' }}>
              <FormControl fullWidth>
                <InputLabel>{t('common.unsubscribeFlow')}</InputLabel>
                <Select
                  sx={{
                    backgroundColor: 'grey.15',
                  }}
                  label={t('common.unsubscribeFlow')}
                  value={leaveFlow ?? ''}
                  onChange={handleChangeLeaveFlow}
                >
                  {availableFlows.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'red.1',
                  border: '1px solid ',
                  borderColor: 'red.2',
                  borderRadius: ' 6px',
                  minWidth: '58px',
                  height: '58px',
                  cursor: 'pointer',
                }}
                onClick={onClearLeaveFlow}
              >
                <DeleteOutlineRoundedIcon sx={{ color: 'red.2' }} />
              </Stack>
            </Stack>
          </Stack>
        )}
        <TextField multiline rows={4} disabled value={scriptText} />

        <Button
          startIcon={<ContentCopyIcon />}
          sx={{
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'green.2',
            backgroundColor: 'green.1',
            color: 'green.2',
            fontFamily: 'Gilroy',
            fontSize: '18px',
            lineHeight: '130%',
            fontWeight: '600',
            textTransform: 'none',
            mt: '22px',
            '&:hover': {
              borderColor: 'green.1',
              color: 'green.1',
              backgroundColor: 'green.2',
            },
          }}
          onClick={copyScript}
        >
          {t('common.copy')}
        </Button>
        <Stack
          sx={{
            position: 'absolute',
            width: '600px',
            bottom: '-100px',
            left: '-20px',
            backgroundColor: 'grey.14',
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '18px',
            p: '10px',
          }}
          direction="row"
          alignItems="center"
          gap="5px"
        >
          <Typography>Button id:</Typography>

          <CopyableText text={buttonId} />
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default ModalScriptInfo;
