import { TelegramButton, TelegramButtonType } from '../../../interfaces/common';

const updateActionButtons = (
  prevButtons: TelegramButton[][],
  rowIndex: number,
  buttonIndex: number,
  target?: string,
) => {
  const newNodeButtons: TelegramButton[][] = [];

  for (let i = 0; i < prevButtons.length; i++) {
    const currentRow = prevButtons[i];

    if (rowIndex !== i) {
      newNodeButtons.push(currentRow);
      continue;
    }

    const updatedRow: TelegramButton[] = [];
    for (let j = 0; j < currentRow.length; j++) {
      const button = currentRow[j];

      if (buttonIndex !== j || button.type !== TelegramButtonType.action) {
        updatedRow.push(button);
        continue;
      }

      updatedRow.push({
        ...button,
        action: target ? 'nfn-' + target : '',
      });
    }

    newNodeButtons.push(updatedRow);
  }

  return newNodeButtons;
};

export default updateActionButtons;
