import { alpha, Button, Checkbox, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';

import { IMedia, MediaType } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';
import useThemeColors from '../../utils/hooks/useThemeColors';
import ModalBox from '../modal/modal-box';

import VideoNoteItem from './video-note.item';

interface VideoNoteModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
}

const authBotUsername = process.env.REACT_APP_TELEGRAM_AUTH_BOT_USERNAME;

const VideoNoteModal: FC<VideoNoteModalProps> = ({
  open,
  onClose,
  selectedMedia,
  onSelectMedia,
}) => {
  const { projectMedia, refetchMedia, project } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const [onlyOwning, setOnlyOwning] = useState(true);
  const [displayedMedia, setDisplayedMedia] = useState<IMedia[]>([]);

  const themeColors = useThemeColors();

  useEffect(() => {
    if (!user) return;
    if (onlyOwning) {
      setDisplayedMedia(projectMedia.filter(({ owner }) => owner === user._id));
    } else {
      setDisplayedMedia(projectMedia);
    }
  }, [onlyOwning, projectMedia]);

  const onUploadClick = () => {
    window.open(
      `https://t.me/${authBotUsername}?start=up-${project._id}`,
      '_blank',
    );
  };

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="746px"
      sx={{ height: '500px', pb: '0', overflow: 'hidden' }}
    >
      <Stack
        sx={{
          minHeight: '390px',
        }}
      >
        <Stack
          direction="row"
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Stack
            alignSelf="start"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                cursor: 'pointer',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'grey.14',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                onClick={() => setOnlyOwning((prev) => !prev)}
              >
                <Checkbox
                  sx={{
                    color: 'blue.2',
                    '&.Mui-checked': {
                      color: 'blue.2',
                    },
                  }}
                  checked={onlyOwning}
                />
                <Typography>Only mine</Typography>
              </Stack>
              <Stack direction="row" gap="10px">
                <Button
                  sx={{
                    color: 'grey.2',
                  }}
                  onClick={refetchMedia}
                >
                  Refresh video messages
                </Button>

                <Button
                  sx={{
                    color: 'blue.2',
                  }}
                  onClick={onUploadClick}
                >
                  Upload via Telegram
                </Button>
              </Stack>
            </Stack>

            <Stack>
              <Stack
                direction="row"
                gap="10px"
                sx={{
                  flexWrap: 'wrap',
                  mb: '20px',
                }}
              >
                {displayedMedia
                  .filter((media) => media.type === MediaType.videoNote)
                  .map((media) => {
                    const isSelected = selectedMedia[0]?._id === media._id;

                    return (
                      <VideoNoteItem
                        key={media._id}
                        videoUrl={media.url}
                        name={media.name ?? ''}
                        isSelected={isSelected}
                        onSelect={() => {
                          onSelectMedia(media);
                        }}
                      />
                    );
                  })}
              </Stack>

              <Stack
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  py: '10px',
                  backgroundColor: alpha(themeColors.grey['14'], 0.8),
                }}
                alignItems="end"
              >
                <Button
                  onClick={onClose}
                  sx={{
                    color: 'grey.1',
                    width: '135px',
                    backgroundColor: 'blue.2',
                    ':hover': {
                      backgroundColor: 'blue.2',
                    },
                  }}
                >
                  Done
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default VideoNoteModal;
